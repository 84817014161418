define("nfe-customer-console-v2/adapters/certificate-v2", ["exports", "@ember-data/adapter/rest", "ember-simple-auth/mixins/data-adapter-mixin", "ember-data-url-templates", "nfe-customer-console-v2/config/environment"], function (_exports, _rest, _dataAdapterMixin, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_emberDataUrlTemplates.default, _dataAdapterMixin.default, {
    sessionData: Ember.inject.service(),
    host: _environment.default.APP.NFSE_V2_API_URL,
    namespace: 'v2',
    queryUrlTemplate: '{+host}/{+namespace}/companies/{company_id}/certificates{?active}',
    createRecordUrlTemplate: '{+host}/{+namespace}/companies/{company_id}/certificates',
    queryRecordUrlTemplate: '{+host}/{+namespace}/companies/{company_id}',
    urlSegments: {
      active: function (type, id, snapshot, query) {
        return query.active;
      }
    },
    headers: Ember.computed.readOnly('sessionData.getRequestHeaders'),

    handleResponse(status, headers, payload, requestData) {
      let superArgs = this._super(...arguments);

      superArgs.code = status;
      return superArgs;
    },

    uploadCertificate(company_id, file, password) {
      const options = {
        data: {
          file,
          password
        },
        headers: this.sessionData.getRequestHeaders
      };
      const url = this.buildURL('certificate-v2', null, {
        company_id
      }, 'createRecord');
      return file.upload(url, options);
    }

  });

  _exports.default = _default;
});