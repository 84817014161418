define("nfe-customer-console-v2/routes/companies/service-invoices/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    titleToken: "Notas fiscais da empresa",

    async model() {
      let params = this.paramsFor('companies.service-invoices');
      let account = await this.sessionData.getCurrentAccount();
      return {
        accountId: account.id,
        companyId: params.companyId
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', 1);
        controller.set('perPage', 10);
        controller.set('status', "none");
        controller.set('issueBegin', null);
        controller.set('borrowerFederalTaxNumber', null);
        controller.set('allSearchProps', controller.allSearchProps.map(element => {
          if (Ember.isPresent(element.value)) delete element.value;
          return element;
        }));
        controller.set('exportNfseModal', false);
        controller.set('exportCsvModal', false);
        controller.set('exportResponseModal', false);
      }
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});