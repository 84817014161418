define("nfe-customer-console-v2/templates/companies/edit/apikeys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZiUc3gPr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"layout-row-form\"],[8],[0,\"\\n  \"],[5,\"toolbar\",[],[[\"@title\",\"@subtitle\"],[[28,\"t\",[\"companies.edit.title\"],null],[24,[\"model\",\"company\",\"name\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"service-invoice-actions-button\",[],[[\"@companyId\",\"@defaultActions\"],[[24,[\"model\",\"company\",\"id\"]],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"md-card-form\",[],[[\"@titleText\",\"@returnButton\"],[[28,\"t\",[\"companies.edit.apikeys.title\"],null],[28,\"transition-to\",[\"companies.edit\",[24,[\"model\",\"company\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"layout-column layout-align-start-stretch flex-grow\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"layout-column layout-align-start-stretch\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"flex-nogrow bs-callout bs-callout-primary\"],[10,\"id\",\"callout-alerts-no-default\"],[8],[0,\"\\n                    \"],[7,\"h4\",true],[10,\"id\",\"no-default-class\"],[8],[0,\"\\n                        \"],[1,[28,\"t\",[\"companies.edit.apikeys.accessKeyDetails.title\"],null],false],[0,\"\\n                    \"],[9],[0,\"\\n                    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"companies.edit.apikeys.accessKeyDetails.text\"],[[\"htmlSafe\"],[true]]],false],[9],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"flex-80 layout-column layout-align-start-stretch\"],[8],[0,\"\\n                    \"],[5,\"list-api-keys\",[],[[\"@keys\"],[[24,[\"model\",\"apiKeys\"]]]]],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/companies/edit/apikeys.hbs"
    }
  });

  _exports.default = _default;
});