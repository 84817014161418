define("nfe-customer-console-v2/models/apikey", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "nfe-customer-console-v2/models/enums"], function (_exports, _model, _fragment, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    description: (0, _model.attr)('string'),
    parentId: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    createdOn: (0, _model.attr)('string'),
    modifiedOn: (0, _model.attr)('string'),
    getAccessKeyInfo: Ember.computed('description', function () {
      return _enums.default.accessKeysInfo.filter(item => {
        let value = this.description;
        return value === item.id;
      })[0];
    })
  });

  _exports.default = _default;
});