define("nfe-customer-console-v2/components/company-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    tagName: '',
    accountId: '',
    companyId: '',

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const params = {
        accountId: this.accountId,
        companyId: this.companyId
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* ({
      accountId,
      companyId
    }) {
      if (Ember.isBlank(accountId) && Ember.isBlank(companyId)) return;
      this.set('results', yield this.store.queryRecord('company', {
        accountId,
        companyId
      }));
    }).restartable()
  });

  _exports.default = _default;
});