define("nfe-customer-console-v2/services/leads", ["exports", "nfe-customer-console-v2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    sendLeads(name, email, phone, company) {
      let options = {
        type: "POST",
        data: JSON.stringify({
          "source": "App Register",
          name,
          email,
          phone,
          company,
          analytics_source_data_3: "Cadastro na plataforma (app.nfe.io)"
        }),
        contentType: "application/json"
      };
      return this.ajax.request(_environment.default.APP.LEADS_URL, options);
    }

  });

  _exports.default = _default;
});