define("nfe-customer-console-v2/components/nfe-sheet-loader/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isLoading: true,
    hasData: false,
    allCheckedDisabled: false,
    results: null,
    retroactiveInvoices: false,
    showStepper: true,
    hasAnyChecked: Ember.computed('results.@each.checked', function () {
      return this.results ? this.results.filterBy('checked', true).length == 0 : false;
    }),
    hasRetroactiveInvoices: Ember.computed('results.@each.Data_Competencia', function () {
      return this.results ? this.results.filterBy('Data_Competencia').length > 0 : false;
    }),
    hasEmail: Ember.computed('results.@each.Email', function () {
      return this.results ? this.results.filterBy('Email').length > 0 : false;
    }),
    hasCnaeCode: Ember.computed('results.@each.CNAE', function () {
      return this.results ? this.results.filterBy('CNAE').length > 0 : false;
    }),
    hasMunicipalTaxNumber: Ember.computed('results.@each.Inscricao_Municipal', function () {
      return this.results ? this.results.filterBy('Inscricao_Municipal').length > 0 : false;
    }),
    hasTaxRegime: Ember.computed('results.@each.TaxRegime', function () {
      return this.results ? this.results.filterBy('Regime_Tributario_Tomador').length > 0 : false;
    }),
    hasAdditionalInformation: Ember.computed('results.@each.Informacoes_Adicionais', function () {
      return this.results ? this.results.filterBy('Informacoes_Adicionais').length > 0 : false;
    }),
    hasTaxationType: Ember.computed('results.@each.Tipo_Tributacao', function () {
      return this.results ? this.results.filterBy('Tipo_Tributacao').length > 0 : false;
    }),
    hasIssRate: Ember.computed('results.@each.Aliquota_ISS', function () {
      return this.results ? this.results.filterBy('Aliquota_ISS').length > 0 : false;
    }),
    hasIssTaxAmount: Ember.computed('results.@each.Valor_ISS', function () {
      return this.results ? this.results.filterBy('Valor_ISS').length > 0 : false;
    }),
    hasIrAmountWithheld: Ember.computed('results.@each.Retencao_IR', function () {
      return this.results ? this.results.filterBy('Retencao_IR').length > 0 : false;
    }),
    hasPisAmountWithheld: Ember.computed('results.@each.Retencao_PIS', function () {
      return this.results ? this.results.filterBy('Retencao_PIS').length > 0 : false;
    }),
    hasCofinsAmountWithheld: Ember.computed('results.@each.Retencao_COFINS', function () {
      return this.results ? this.results.filterBy('Retencao_COFINS').length > 0 : false;
    }),
    hasCsllAmountWithheld: Ember.computed('results.@each.Retencao_CSLL', function () {
      return this.results ? this.results.filterBy('Retencao_CSLL').length > 0 : false;
    }),
    hasInssAmountWithheld: Ember.computed('results.@each.Retencao_INSS', function () {
      return this.results ? this.results.filterBy('Retencao_INSS').length > 0 : false;
    }),
    hasIssAmountWithheld: Ember.computed('results.@each.Retencao_ISS', function () {
      return this.results ? this.results.filterBy('Retencao_ISS').length > 0 : false;
    }),
    hasOthersAmountWithheld: Ember.computed('results.@each.Retencao_OUTROS', function () {
      return this.results ? this.results.filterBy('Retencao_OUTROS').length > 0 : false;
    }),
    hasDeductionsAmount: Ember.computed('results.@each.Valor_Deducoes', function () {
      return this.results ? this.results.filterBy('Valor_Deducoes').length > 0 : false;
    }),
    hasDiscountUnconditionedAmount: Ember.computed('results.@each.Valor_Desconto_Incondicionado', function () {
      return this.results ? this.results.filterBy('Valor_Desconto_Incondicionado').length > 0 : false;
    }),
    hasDiscountConditionedAmount: Ember.computed('results.@each.Valor_Desconto_Condicionado', function () {
      return this.results ? this.results.filterBy('Valor_Desconto_Condicionado').length > 0 : false;
    }),
    hasBorrowerType: Ember.computed('results.@each.Tipo_Tomador', function () {
      return this.results ? this.results.filterBy('Tipo_Tomador').length > 0 : false;
    }),
    hasLocationState: Ember.computed('results.@each.Estado_Prestacao_Servico', function () {
      return this.results ? this.results.filterBy('Estado_Prestacao_Servico').length > 0 : false;
    }),
    hasLocationCity: Ember.computed('results.@each.Cidade_Prestacao_Servico', function () {
      return this.results ? this.results.filterBy('Cidade_Prestacao_Servico').length > 0 : false;
    }),
    hasLocationCityCode: Ember.computed('results.@each.Codigo_Cidade_Prestacao_Servico', function () {
      return this.results ? this.results.filterBy('Codigo_Cidade_Prestacao_Servico').length > 0 : false;
    }),
    hasExternalId: Ember.computed('results.@each.ID_Externo', function () {
      return this.results ? this.results.filterBy('ID_Externo').length > 0 : false;
    }),
    hasPaidAmount: Ember.computed('results.@each.Valor_Recebido', function () {
      return this.results ? this.results.filterBy('Valor_Recebido').length > 0 : false;
    }),
    allCheckedDisabledChange: Ember.observer('allCheckedDisabled', function () {
      let workbook = this.results;
      workbook.forEach(item => {
        if (item.isSuccess == true || item.isError == true) {
          return;
        } else {
          item.set('disabled', this.allCheckedDisabled);
        }
      });
    }),
    actions: {
      resetBatch() {
        this.set('results', null);
        this.set('showStepper', true);
      }

    }
  });

  _exports.default = _default;
});