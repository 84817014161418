define("nfe-customer-console-v2/controllers/companies/service-invoices/batch-issue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSendBatchRunning: false,
    isExitingModal: false,
    transitionRetry: false,
    actions: {
      continueTransition() {
        this.set('transitionRetry', true);
        this.transition.retry();
      }

    }
  });

  _exports.default = _default;
});