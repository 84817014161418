define("nfe-customer-console-v2/controllers/onboard/service-invoice/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: ['success', 'resetSteps', 'company'],
    success: false,
    resetSteps: false,
    company: null,
    actions: {
      skipOnboard() {
        this.metrics.trackEvent({
          event: 'Skipped onboarding page',
          category: 'Onboarding service invoice',
          action: 'Skipped onboarding page',
          label: `accountID: ${this.get('session.data.authenticated.current_account.id')}`
        });
        this.features.enable('DFeTech.Onboarding.ServiceInvoice');
        this.transitionToRoute('dashboard');
      },

      goToDashboard() {
        this.metrics.trackEvent({
          event: 'Go to dashboard',
          category: 'Onboarding service invoice',
          action: 'Go to dashboard'
        });
        this.transitionToRoute('dashboard');
      }

    }
  });

  _exports.default = _default;
});