define("nfe-customer-console-v2/controllers/companies/edit/certificate", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CertificateQueryParams = void 0;
  const CertificateQueryParams = new _emberParachute.default({
    showStepper: {
      as: 'upload-stepper',
      defaultValue: false,
      refresh: true,
      replace: true
    },
    onboarding: {
      defaultValue: false,
      refresh: true,
      replace: true
    }
  });
  _exports.CertificateQueryParams = CertificateQueryParams;

  var _default = Ember.Controller.extend(CertificateQueryParams.Mixin, {
    queryParamsChanged: Ember.computed.or('queryParamsState.{onboarding,showStepper}.changed'),
    actions: {
      uploadCertificate(file) {
        this.set('file', file);
        this.set('certificate', file.name);
        this.set('activateFileValidations', true);
      },

      routeTransition() {
        if (this.onboarding) this.transitionToRoute("onboard.service-invoice", {
          queryParams: {
            company: this.model.id,
            resetSteps: null
          }
        });else this.transitionToRoute("companies.edit", this.model.id);
      }

    }
  });

  _exports.default = _default;
});