define("nfe-customer-console-v2/components/account-payment-methods-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const {
        accountId,
        type
      } = this;
      this.fetchData.perform(accountId, type);
    },

    fetchData: (0, _emberConcurrency.task)(function* (accountId, type) {
      if (Ember.isBlank(accountId)) return;
      const paymentMethods = yield this.store.query('payment-method', {
        accountId,
        $filter: type
      });
      paymentMethods.toArray().map(item => {
        if (item.type === "CreditCard") {
          item.card.image = `/assets/images/${item.card.brand.toLocaleLowerCase()}.png`;
        }

        return item;
      });
      return this.set('results', paymentMethods);
    }).restartable()
  });

  _exports.default = _default;
});