define("nfe-customer-console-v2/components/organization-form/component", ["exports", "nfe-customer-console-v2/models/enums", "nfe-customer-console-v2/helpers/cnpj-validator"], function (_exports, _enums, _cnpjValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    address: Ember.inject.service(),
    store: Ember.inject.service(),
    taxRegimes: _enums.default.taxRegimesAccount,
    selectedTaxRegime: Ember.computed('model.taxRegime', function () {
      const taxRegime = this.model.taxRegime;
      return taxRegime ? {
        name: _enums.default.taxRegimesAccount.filter(item => item.code === taxRegime)[0].name
      } : null;
    }),
    selectedState: Ember.computed('model.address.stateOrProvince', function () {
      const state = this.model.address.stateOrProvince;
      return state ? {
        code: state
      } : null;
    }),
    getCnpj: Ember.observer('model.federalTaxNumber', async function () {
      const taxNumber = this.get('model.federalTaxNumber');
      if ((0, _cnpjValidator.cnpjValidator)(taxNumber) === false) return;
      const companyAdapter = this.store.adapterFor('company');
      const company = await companyAdapter.getCompanyByCnpj(taxNumber);
      const {
        postalCode,
        street,
        streetSuffix,
        number,
        additionalInformation,
        district,
        state,
        city
      } = company.address;
      this.model.setProperties({
        formalName: company.name,
        taxRegime: null,
        address: {
          postalCode,
          line1: `${streetSuffix} ${street}`,
          line2: number,
          line3: additionalInformation,
          districtOrCounty: district,
          stateOrProvince: state,
          city
        }
      });
    }),
    getCities: Ember.observer('model.address.stateOrProvince', async function () {
      const state = this.model.address.stateOrProvince;

      if (state) {
        let isInCities = false;

        try {
          const cities = await this.address.getCitiesByState(state);
          this.set('cities', cities);
          let cityCode = this.get('model.address.city.code') || 0;
          isInCities = cities.some(city => city.code.toString() === cityCode);

          if (!isInCities) {
            return this.set('model.address.city', cities[0]);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }),

    async init() {
      this._super(...arguments);

      let states = await this.address.getStates();
      this.set('states', states);
      const state = this.get('model.address.stateOrProvince');

      if (state) {
        let cities = await this.address.getCitiesByState(state);
        this.set('cities', cities);
      } else {
        this.set('model.address', this.store.createRecord('address-organization'));
      }
    }

  });

  _exports.default = _default;
});