define("nfe-customer-console-v2/serializers/organization", ["exports", "nfe-customer-console-v2/serializers/base-odata", "nfe-customer-console-v2/helpers/format-cnpj"], function (_exports, _baseOdata, _formatCnpj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OrganizationSerializer extends _baseOdata.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'accountNumber');
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let superArgs = super.normalizeResponse(...arguments);
      let newPayload = superArgs.data.attributes;
      let {
        city,
        cityCode
      } = payload.address;

      if (city) {
        newPayload.address.city = {
          name: city,
          code: cityCode
        };
      }

      return superArgs;
    }

    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);
      json.federalTaxNumber = (0, _formatCnpj.formatCnpj)([json.federalTaxNumber]);
      const {
        code,
        name
      } = json.address.city;
      json.address.city = name;
      json.address.cityCode = code;
      json.address.country = "BRA";
      return json;
    }

    serializeIntoHash(hash, type, record, options) {
      // remove modelName from payload
      return Ember.assign(hash, this.serialize(record, options));
    }

  }

  _exports.default = OrganizationSerializer;
  ;
});