define("nfe-customer-console-v2/components/state-tax-form/component", ["exports", "nfe-customer-console-v2/models/enums"], function (_exports, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    states: null,
    address: Ember.inject.service(),
    selectedState: Ember.computed('model.code', function () {
      return this.model.code ? {
        code: this.model.code
      } : null;
    }),
    selectedType: Ember.computed('model.type', function () {
      return this.model.type ? {
        name: this.model.type
      } : null;
    }),
    specialTaxRegimes: _enums.default.specialTaxRegimes,
    types: _enums.default.nfeTypes,
    selectedSpecialTaxRegime: Ember.computed('model.specialTaxRegime', function () {
      if (Ember.isEmpty(this.model.specialTaxRegime)) this.set('model.specialTaxRegime', 'Nenhum');
      const value = this.model.specialTaxRegime;
      return _enums.default.specialTaxRegimes.filter(v => v.code === value)[0];
    }),

    async init() {
      this._super(...arguments);

      let states = await this.address.getStates();
      this.set('states', states);
      this.set('touched', false);
    },

    trySwitchEnvironment: false,
    actions: {
      changeEnvironment(model) {
        this.updateCertificate(model);
        this.set('trySwitchEnvironment', false);
      }

    }
  });

  _exports.default = _default;
});