define("nfe-customer-console-v2/adapters/export", ["exports", "nfe-customer-console-v2/adapters/base-odata", "ember-data-url-templates", "nfe-customer-console-v2/config/environment"], function (_exports, _baseOdata, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ExportAdapter extends _baseOdata.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryUrlTemplate", "{+host}/accounts('{accountId}')/subscriptions('{subscriptionId}')/exports");

      _defineProperty(this, "queryRecordUrlTemplate", "{+host}/accounts('{accountId}')/subscriptions('{subscriptionId}')/exports('{exportId}')");

      _defineProperty(this, "createRecordUrlTemplate", "{+host}/accounts('{accountId}')/subscriptions('{subscriptionId}')/exports");

      _defineProperty(this, "urlSegments", {
        host() {
          return _environment.default.APP.EXPORT_API_URL;
        },

        accountId(type, id, snapshot, query) {
          let accountId;

          if (query) {
            accountId = query.accountId;
          } else if (snapshot) {
            accountId = snapshot.accountId;
          } else {
            accountId = id;
          }

          return accountId;
        },

        subscriptionId(type, id, snapshot, query) {
          let subscriptionId;

          if (query) {
            subscriptionId = query.subscriptionId;
          } else if (snapshot) {
            subscriptionId = snapshot.subscriptionId;
          } else {
            subscriptionId = id;
          }

          return subscriptionId;
        },

        exportId(type, id, snapshot, query) {
          return snapshot.exportId;
        }

      });
    }

    parsePrefix(id, prefix) {
      return prefix + id.replace(prefix, '');
    }

    async getExportFileUrl(accountId, subscriptionId, exportId) {
      accountId = this.parsePrefix(accountId, 'acc_');
      subscriptionId = this.parsePrefix(subscriptionId, 'sub_');
      const url = this.buildURL('export', null, {
        accountId,
        subscriptionId,
        exportId
      }, 'queryRecord') + '/Api.GetFileUrl';
      const file = await this.ajax(url);
      return window.open(file.url);
    }

    newExport(accountId, subscriptionId, options) {
      accountId = this.parsePrefix(accountId, 'acc_');
      subscriptionId = this.parsePrefix(subscriptionId, 'sub_');
      const url = this.buildURL('export', null, {
        accountId,
        subscriptionId
      }, 'createRecord');
      return this.ajax(url, "POST", {
        data: options
      });
    }

  }

  _exports.default = ExportAdapter;
  ;
});