define("nfe-customer-console-v2/components/ticket-form/component", ["exports", "nfe-customer-console-v2/models/ticket-enums", "nfe-customer-console-v2/helpers/truncate", "nfe-customer-console-v2/helpers/format-cnpj", "ember-concurrency"], function (_exports, _ticketEnums, _truncate, _formatCnpj, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const usedProviders = ["DFeTech.ProductInvoice", "DFeTech.ServiceInvoice.Batch", "DFeTech.ConsumerInvoice", "DFeTech.ServiceInvoice", "DFeTech.ProductInvoice.Inbound", "DFeTech.TransportationInvoice.Inbound", "DataTech.LegalEntity", "DataTech.ProductInvoice", "DataTech.NaturalPerson", "DataTech.Batch", "DataTech.Batch.ProductInvoice.Pdf", "DataTech.Batch.ProductInvoice.Xml"];
  const queueSizeLimit = 5;

  var _default = Ember.Component.extend({
    tagName: 'ticket-form',
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    files: Ember.inject.service(),
    fileQueueService: Ember.inject.service('file-queue'),
    disableUploadInput: false,
    fileQueue: null,
    ticketModel: null,
    productsInUse: null,
    showTypeInput: false,
    touched: false,
    currentAccount: null,
    companies: null,
    // Computed properties to translate enums names
    categories: Ember.computed(function () {
      return this.setupEnum('categories');
    }),
    departments: Ember.computed(function () {
      return this.setupEnum('departments');
    }),
    financialProblemSubtypes: Ember.computed(function () {
      return this.setupEnum('financialProblemSubtypes');
    }),
    technicalProblemSubtypes: Ember.computed(function () {
      return this.setupEnum('technicalProblemSubtypes');
    }),
    dataQuerySubtypes: Ember.computed(function () {
      return this.setupEnum('dataQuerySubtypes');
    }),
    serviceInvoiceSubtypes: Ember.computed(function () {
      return this.setupEnum('DFeTech.ServiceInvoice.Subtypes');
    }),
    productInvoiceSubtypes: Ember.computed(function () {
      return this.setupEnum('DFeTech.ProductInvoice.Subtypes');
    }),
    consumerInvoiceSubtypes: Ember.computed(function () {
      return this.setupEnum('DFeTech.ConsumerInvoice.Subtypes');
    }),
    integrationSubtypes: Ember.computed(function () {
      return this.setupEnum('integrationSubtypes');
    }),
    fiscalSubtypes: Ember.computed(function () {
      return this.setupEnum('fiscalSubtypes');
    }),
    clientDepartment: Ember.computed(function () {
      return this.setupEnum('clientDepartment');
    }),
    // Computed properties to get the translated property
    selectedCategory: Ember.computed('ticketModel.category', function () {
      return this.ticketModel.category ? this.categories.find(obj => obj.code == this.ticketModel.category) : null;
    }),
    selectedProduct: Ember.computed('ticketModel.product.providerNamespace', function () {
      return this.ticketModel.product.providerNamespace ? {
        name: this.intl.t(`account.providers.tableBody.${this.ticketModel.product.providerNamespace}`)
      } : null;
    }),
    selectedDepartment: Ember.computed('ticketModel.department', function () {
      return this.ticketModel.department ? this.departments.find(obj => obj.code == this.ticketModel.department) : null;
    }),
    selectedType: Ember.computed('ticketModel.type', function () {
      if (Ember.isEmpty(this.ticketModel.type)) return null;

      if (this.ticketModel.department.toLowerCase() === 'faturamento') {
        return this.financialProblemSubtypes.find(obj => obj.code == this.ticketModel.type);
      } else {
        return this.technicalProblemSubtypes.find(obj => obj.code == this.ticketModel.type);
      }
    }),
    selectedCompany: Ember.computed('ticketModel.product.companyId', function () {
      let selectedCompany = this.companies.find(obj => obj.code === this.ticketModel.product.companyId);

      if (selectedCompany) {
        this.set('ticketModel.product.federalTaxNumber', selectedCompany.federalTaxNumber);
        return selectedCompany;
      }

      return null;
    }),
    selectedSubtype: Ember.computed('ticketModel.subtype', function () {
      return this.ticketModel.subtype ? this.subtypes.find(x => x.code === this.ticketModel.subtype) : null;
    }),
    subtypes: Ember.computed('ticketModel.{product,type}', function () {
      let result = null;

      if (this.ticketModel.type == "integracoes") {
        result = this.integrationSubtypes;
      } else if (this.ticketModel.type == "fiscal") {
        result = this.fiscalSubtypes;
      } else if (this.showDataQueryRelatedInputs) {
        result = this.dataQuerySubtypes;
      } else if (this.ticketModel.product.providerNamespace.includes('Service')) {
        result = this.serviceInvoiceSubtypes;
      } else if (this.ticketModel.product.providerNamespace.includes('Product')) {
        result = this.productInvoiceSubtypes;
      } else if (this.ticketModel.product.providerNamespace.includes('Consumer')) {
        result = this.consumerInvoiceSubtypes;
      }

      return result;
    }),
    queryParameterType: Ember.computed('ticketModel.product.providerNamespace', function () {
      const findArgument = this.ticketModel.product.providerNamespace == "DataTech.Batch" ? "DataTech.ProductInvoice" : this.ticketModel.product.providerNamespace;
      return _ticketEnums.default.queryParameterTypes.find(element => element.type == findArgument);
    }),
    selectedClientDepartment: Ember.computed('ticketModel.clientDepartment', function () {
      return this.ticketModel.clientDepartment ? this.clientDepartment.find(obj => obj.code == this.ticketModel.clientDepartment) : null;
    }),
    // Observer to decide to show type input based on category and department properties
    toggleTypeInput: Ember.observer('ticketModel.{category,department}', function () {
      if (Ember.isEmpty(this.ticketModel.department) || Ember.isEmpty(this.ticketModel.category)) return;

      if (this.ticketModel.category != 'Cancelamento' && this.ticketModel.department != 'Comercial') {
        this.set('showTypeInput', true);
      } else {
        this.set('showTypeInput', false);
      }

      this.set('ticketModel.type', undefined);
    }),
    // Layout validation computed properties
    shouldSkipAdvancedDataStep: Ember.computed('ticketModel.{department,category}', function () {
      return this.ticketModel.department != "Tecnico" || this.ticketModel.category == "Cancelamento";
    }),
    showDataQueryRelatedInputs: Ember.computed('ticketModel.product.providerNamespace', function () {
      return this.ticketModel.product.providerNamespace && this.ticketModel.product.providerNamespace.includes('DataTech');
    }),

    setupEnum(prop) {
      return _ticketEnums.default[prop].map(x => {
        return {
          name: this.intl.t(x.name),
          code: x.code
        };
      });
    },

    async loadCompanies() {
      let currentCompanies;
      if (this.ticketModel.product.providerNamespace == 'DFeTech.ProductInvoice' || this.ticketModel.product.providerNamespace == 'DFeTech.ConsumerInvoice' || this.ticketModel.product.providerNamespace == 'DFeTech.TransportationInvoice.Inbound' || this.ticketModel.product.providerNamespace == 'DFeTech.ProductInvoice.Inbound') currentCompanies = await this.store.query('company-v2', {
        limit: 50
      });else currentCompanies = await this.store.query('company', {
        accountId: this.currentAccount.id,
        pageIndex: 1,
        pageCount: 50
      });
      if (currentCompanies.length == 0) return null;
      currentCompanies = currentCompanies.sortBy('name');
      let companiesOptions = currentCompanies.map(company => {
        return {
          code: company.id,
          name: `${(0, _truncate.truncate)([company.name, 80, true])} ${company.federalTaxNumber ? `- ${(0, _formatCnpj.formatCnpj)([company.federalTaxNumber])}` : ''}`,
          federalTaxNumber: company.federalTaxNumber
        };
      });
      if (companiesOptions.length == 1) this.set('ticketModel.product.companyId', companiesOptions[0].code);
      this.set('companies', companiesOptions);
    },

    async init() {
      this._super(...arguments);

      if (Ember.isEmpty(this.ticketModel)) {
        this.set('ticketModel', this.store.createRecord('ticket', {
          product: this.store.createRecord('ticket/product')
        }));
      }

      const currentAccount = await this.sessionData.getCurrentAccount();
      const currentUser = await this.user.getCurrent.perform();
      this.set('currentAccount', currentAccount);
      this.set('ticketModel.accountId', currentAccount.id);
      this.set('ticketModel.accountEnvironment', currentAccount.environment);
      this.set('ticketModel.accountStatus', currentAccount.status);
      this.set('ticketModel.email', currentUser.email);
      this.set('ticketModel.name', currentUser.name);
      let providers = currentAccount.providers.filter(provider => usedProviders.indexOf(provider) != -1);
      providers = providers.map(provider => {
        return {
          code: provider,
          name: this.intl.t(`account.providers.tableBody.${provider}`)
        };
      });
      this.set('productsInUse', providers);
      this.maxDate = new Date(); // Create queue to upload files with ember-file-upload

      let queue = this.fileQueueService.find(`${currentAccount.id}/ticketAttachments`);
      if (Ember.isNone(queue)) queue = this.fileQueueService.create(`${currentAccount.id}/ticketAttachments`);
      this.set('fileQueue', queue);
    },

    actions: {
      async basicDataStepValidation(nextStep, goTo) {
        this.set('touched', true);
        if (this.ticketModel.validations.attrs.category.isInvalid || this.ticketModel.validations.attrs.department.isInvalid || this.ticketModel.product.validations.attrs.providerNamespace.isInvalid) return;
        if (this.showTypeInput && this.ticketModel.validations.attrs.type.isInvalid) return;

        if (this.shouldSkipAdvancedDataStep) {
          this.set('touched', false);
          return goTo(2);
        }

        if (this.showDataQueryRelatedInputs === false) await this.loadCompanies();
        this.set('touched', false);
        nextStep();
      },

      advancedDataValidation(nextStep) {
        this.set('touched', true);

        if (this.ticketModel.product.providerNamespace.includes('DFeTech')) {
          // When provider namespace start with DFeTech only validate the items below
          if (this.ticketModel.product.validations.attrs.companyId.isInvalid) return;
        } else {
          // When provider namespace start with DataTech only validate the items below
          if (this.ticketModel.product.validations.attrs.queryParameter.isInvalid || this.ticketModel.product.validations.attrs.queryDate.isInvalid) return; // DataTech.NaturalPerson exclusive validation

          if (this.ticketModel.product.providerNamespace == 'DataTech.NaturalPerson' && this.ticketModel.product.validations.attrs.naturalPersonbirthDate.isInvalid) return;
        } // Subtype is always required


        if (this.ticketModel.validations.attrs.subtype.isValid) {
          this.set('touched', false);
          nextStep();
        }
      },

      identificationStepValidation(direction, nextStep, previousStep, goTo) {
        if (direction === 'backward') {
          if (this.shouldSkipAdvancedDataStep) return goTo(0);
          previousStep();
        }

        this.set('touched', true);

        if (this.ticketModel.validations.attrs.name.isValid && this.ticketModel.validations.attrs.description.isValid && this.ticketModel.validations.attrs.email.isValid && this.ticketModel.validations.attrs.clientDepartment.isValid) {
          this.set('touched', false);
          nextStep();
          this.submitTicket.perform();
        }
      },

      removeFile(file) {
        this.fileQueue.remove(file);
        if (this.fileQueue.files.length < queueSizeLimit && this.disableUploadInput) this.set('disableUploadInput', false);
      },

      fileQueueLimit(file) {
        if (this.fileQueue.files.length >= queueSizeLimit) this.set('disableUploadInput', true);
        if (this.fileQueue.files.length > queueSizeLimit) this.fileQueue.remove(file);
      }

    },
    submitTicket: (0, _emberConcurrency.task)(function* () {
      let response = yield this.store.adapterFor('ticket').sendTicket(this.ticketModel, this.fileQueue.files);

      if (response.status === 'success') {
        response.message = this.intl.t('enums.ticket.submitMessages.success');
        response.icon = "check";
      } else {
        response.message = this.intl.t('enums.ticket.submitMessages.error');
        response.icon = "error-outline";
      }

      return response;
    }),

    willDestroyElement() {
      this.fileQueue.files.forEach(file => Ember.set(file, 'queue', null));
      Ember.set(this, 'fileQueue.files', Ember.A());

      this._super(...arguments);
    },

    didDestroyElement() {
      this.set('ticketModel', null);
    }

  });

  _exports.default = _default;
});