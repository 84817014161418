define("nfe-customer-console-v2/controllers/companies-v2/edit/cte-dist", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    activateCteValidations: false,
    minDate: Ember.computed('', function () {
      let date = new Date();
      date.setDate(date.getDate() - 89);
      return date;
    }),
    currentDate: new Date(),
    submitCteParams: (0, _emberConcurrency.task)(function* (companyId, cte) {
      const hasCertificateActive = yield this.checkCertificate(companyId);

      if (!hasCertificateActive) {
        return this.notify.error(this.intl.t("companies.v2.edit.cte.submit.certificateError"));
      }

      try {
        yield cte.save({
          adapterOptions: {
            companyId
          }
        });
        this.notify.success(this.intl.t("companies.v2.edit.cte.submit.success"));
        this.set('developmentAccountModal', false);
      } catch (error) {
        this.notify.error(this.intl.t("companies.v2.edit.cte.submit.error"));
      }
    }),
    // updateCteParams: task(function* (companyId, cte) {
    //   try {
    //     yield this.store.adapterFor('cte-dist').updateCteParams(companyId, cte);
    //     this.notify.success(this.intl.t("companies.v2.edit.cte.update.success"));
    //   } catch (error) {
    //     this.notify.error(this.intl.t("companies.v2.edit.cte.update.error"));
    //   }
    // }),
    deleteCteParams: (0, _emberConcurrency.task)(function* (companyId, cte) {
      try {
        yield cte.destroyRecord({
          adapterOptions: {
            companyId
          }
        });
        this.notify.success(this.intl.t("companies.v2.edit.cte.delete.success"));
        this.set('disableCteModal', false);
        Ember.run.later(this, function () {
          location.reload();
        }, 750);
      } catch (error) {
        this.notify.error(this.intl.t("companies.v2.edit.cte.delete.error"));
      }
    }),
    actions: {
      handleSelectDate(model, date) {
        if (!date) return;
        const dates = date.split('/');
        model.set('startFromDate', new Date(`${dates[2]}-${dates[1]}-${dates[0]}T00:00:00`).toISOString());
      }

    },

    async checkCertificate(companyId) {
      try {
        const certificates = await this.store.query('certificate-v2', {
          company_id: companyId,
          active: true
        });
        return certificates.length > 0 && certificates.toArray().some(item => item.status === "Active");
      } catch (error) {
        console.log(error);
      }
    }

  });

  _exports.default = _default;
});