define("nfe-customer-console-v2/mirage/factories/apikey", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    parentId: "54244a1be340420fdc94ab59",
    description: _emberCliMirage.faker.list.cycle('Dados (open.nfe.io)', 'Nota Fiscal (api.nfe.io)', 'Nota Fiscal (api.nfe.io)'),
    createdOn: "2019-02-15T17:59:49.8770393+00:00",
    modifiedOn: "2018-09-19T20:06:58.0863057+00:00",
    status: "Active"
  });

  _exports.default = _default;
});