define("nfe-customer-console-v2/components/list-taxes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "list-taxes",
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    filterTax: null,
    showRegisterTaxSteps: false,
    perPage: null,
    page: 1,
    taxes: null,
    taxData: false,
    shouldSelectTax: false,
    debounceSearch: true,
    selectedTax: null,
    perPageOptions: Ember.A([10, 50, 100, 500, 1000]),
    selectedTax: null,
    autoCompleteQueryParams: Ember.computed('company', 'session.data.authenticated.current_account', function () {
      const {
        name,
        email
      } = this.get('session.data.authenticated.current_account');
      const {
        federalTaxNumber,
        taxRegime
      } = this.get('company');
      return {
        firstname: name,
        email,
        "TICKET.cnpj": federalTaxNumber,
        "TICKET.regime_tributario": taxRegime
      };
    }),
    actions: {
      onChangePage(action, page, totalPages) {
        if (action === "increment") {
          this.set('page', page + 1 > totalPages ? page : page + 1);
        } else {
          this.set('page', page - 1 < 1 ? 1 : page - 1);
        }
      },

      onChangePerPage(perPage) {
        this.set('page', 1);
        this.set('perPage', perPage);
      },

      onSearchTermChange(value) {
        if (value == null) this.set('filterTax', null);

        if (value) {
          let trimmedValue = value.trim();
          if (trimmedValue.length >= 3) this.set('filterTax', trimmedValue);
        }
      },

      lineClick(tax, taxes) {
        if (this.shouldSelectTax) {
          taxes.forEach(taxItem => {
            taxItem.set('checked', false);
          });
          tax.set('checked', true);
          this.set('selectedTax', tax);
        }

        this.set('taxData', tax);
      },

      showRegisterTaxForm() {
        this.set('showRegisterTaxForm', true);
        this.metrics.trackEvent({
          event: 'Clicked help register tax button',
          category: 'Onboarding service invoice',
          action: 'Clicked help register tax button'
        });
      }

    }
  });

  _exports.default = _default;
});