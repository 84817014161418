define("nfe-customer-console-v2/routes/logout", ["exports", "nfe-customer-console-v2/config/environment", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _environment, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountLogoutRoute extends _authenticated.default {
    async model() {
      const configs = _environment.default["ember-simple-auth-oauth2-implicit"];
      const queryString = `id_token_hint=${this.get('session.data.authenticated.id_token')}&post_logout_redirect_uri=${window.location.origin}`;
      await this.session.invalidate();
      window.location.replace(`${configs.host}${configs.endsessionEnpoint}?${queryString}`);
    }

  }

  _exports.default = AccountLogoutRoute;
});