define("nfe-customer-console-v2/services/session-data", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    router: Ember.inject.service(),
    isImpersonated: Ember.computed('session.data.authenticated.current_account_impersonate', function () {
      return this.session.data.authenticated && Ember.isPresent(this.session.data.authenticated.current_account_impersonate);
    }),

    getSessionCurrentAccount() {
      const {
        current_account,
        current_account_impersonate
      } = this.session.data.authenticated;
      return current_account_impersonate ? current_account_impersonate : current_account;
    },

    getCurrentApikey() {
      const accountInUse = this.isImpersonated ? 'current_account_impersonate' : 'current_account';
      return Ember.get(this.session.data.authenticated, `${accountInUse}.apiKey`);
    },

    getRequestHeaders: Ember.computed('session.data.authenticated.{current_account,current_account_impersonate}', function () {
      if (this.get('session.isAuthenticated')) return {
        Authorization: this.getCurrentApikey()
      };
      return {};
    }),
    getRequestTokenHeaders: Ember.computed('session.{data.authenticated.access_token,isAuthenticated}', function () {
      if (this.get('session.isAuthenticated')) return {
        Authorization: `Bearer ${this.session.data.authenticated.access_token}`
      };
      return {};
    }),
    enableFeatures: (0, _emberConcurrency.task)(function* (accountId) {
      let currentAccountImpersonate = this.get('session.data.authenticated.current_account_impersonate');
      let currentAccount = this.get('session.data.authenticated.current_account');
      this.set('account', currentAccountImpersonate ? currentAccountImpersonate : currentAccount);

      if (!Ember.isEmpty(this.get('account').providers)) {
        this.get('account').providers.map(provider => {
          this.features.enable(provider);
        });
      } else {
        this.set('account.providers', []);
        const providersQuery = yield this.store.query('provider', {
          account_id: accountId
        });
        providersQuery.map(provider => {
          if (provider.status == 'Active') {
            this.features.enable(provider.namespace);
            this.get('account').providers.addObject(provider.namespace);
          } else {
            this.features.disable(provider.namespace);
          }
        });
      }

      this.session.store.persist(this.get('session.data'));
    }).enqueue(),

    async impersonate(accountId) {
      if (!accountId) return null;
      let account = await this.store.findRecord('account', accountId);
      if (!account) return null;
      const apiKeys = await account.getAllApiKeys();
      const apiKey = apiKeys.filterBy('description', 'Nota Fiscal (api.nfe.io)', 'Dados (open.nfe.io)'); // Transform do JSON to save on the store

      account = account.toJSON();

      if (!Ember.isEmpty(apiKey)) {
        account.apiKey = apiKey.get('firstObject').id;
        account.apiKeys = apiKeys;
      } else {
        this.router.transitionTo("error");
      }

      this.set('session.data.authenticated.current_account_impersonate', account);
      this.session.store.persist(this.get('session.data'));
      await this.enableFeatures.perform(account.id);
      return account;
    },

    getUserAccounts() {
      return this.getUserAccountsEqueue.perform();
    },

    getUserAccountsEqueue: (0, _emberConcurrency.task)(function* () {
      let userAccounts = this.get('session.data.authenticated.accounts');
      if (Ember.isPresent(userAccounts) && Ember.isPresent(userAccounts[0].id)) return userAccounts;

      try {
        const accountsQuery = yield this.store.query('account', {});
        const accounts = accountsQuery.map(e => e.toJSON());
        this.set('session.data.authenticated.accounts', accounts);
        this.session.store.persist(this.get('session.data'));
        return accounts;
      } catch (error) {
        if (!error) return this.router.replaceWith("error");
        const statusCode = error.errors[0].status;

        if (statusCode === "404") {
          return {
            accounts: []
          };
        }

        if (statusCode === "500") {
          return this.router.replaceWith("error");
        }
      }
    }).enqueue(),

    async getCurrentAccount() {
      return this.getCurrentAccountEqueue.perform();
    },

    getCurrentAccountEqueue: (0, _emberConcurrency.task)(function* () {
      let impersonated = this.get('session.data.authenticated.current_account_impersonate');

      if (impersonated) {
        yield this.enableFeatures.perform(impersonated.id);
        return Ember.RSVP.resolve(impersonated);
      }

      let account = this.get('session.data.authenticated.current_account');

      if (Ember.isEmpty(account) || Ember.isEmpty(account.apiKey)) {
        const accounts = yield this.getUserAccounts();

        if (Ember.isEmpty(accounts) || Ember.isEmpty(accounts[0]) || Ember.isEmpty(accounts[0].id)) {
          yield null;
          return Ember.RSVP.resolve(null);
        }

        account = accounts[0];
        const adapter = this.store.adapterFor('account');
        const apiKeys = yield adapter.getAllApiKeys(account);

        if (Ember.isEmpty(apiKeys)) {
          this.router.replaceWith('error', {
            queryParams: {
              error: "apikeys"
            }
          });
        }

        const apiKey = apiKeys.filterBy('description', 'Nota Fiscal (api.nfe.io)');

        if (Ember.isPresent(apiKey)) {
          account.apiKey = apiKey[0].id;
        }

        account.apiKeys = apiKeys;
        this.set('session.data.authenticated.current_account', account);
        this.session.store.persist(this.get('session.data'));
      }

      yield this.enableFeatures.perform(account.id);
      return Ember.RSVP.resolve(account);
    }).enqueue()
  });

  _exports.default = _default;
});