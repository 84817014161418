define("nfe-customer-console-v2/routes/login", ["exports", "ember-simple-auth/authenticators/oauth2-implicit-grant"], function (_exports, _oauth2ImplicitGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoginRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class LoginRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "authenticateImplicit", _descriptor2, this);
    }

    async activate() {
      if (!this.session) return;
      let redirect = 'index';

      if (this.session.data && this.session.data.hash) {
        const token = (0, _oauth2ImplicitGrant.parseResponse)(this.get("session.data.hash"));
        await this.session.authenticate('authenticator:oauth2-implicit-grant', token);
        this.session.set("data.hash", null);
      }

      if (this.session.isAuthenticated) {
        redirect = await this.session.authenticationSucceeded();
      } else if (!this.session.isAuthenticated) {
        const location = this.authenticateImplicit.mountRedirectUrl();
        Ember.debug(`redirect to: ${location}`);
        return window.location.replace(location);
      }

      Ember.debug(`redirect to: ${redirect}`);
      return this.replaceWith(redirect);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authenticateImplicit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoginRoute;
});