define("nfe-customer-console-v2/models/ticket-enums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TicketEnums extends Ember.Object.extend() {}

  _exports.default = TicketEnums;

  _defineProperty(TicketEnums, "categories", [{
    code: 'Problema',
    name: 'enums.ticket.categories.problem'
  }, {
    code: 'Dúvidas',
    name: 'enums.ticket.categories.doubt'
  }, {
    code: 'Cancelamento',
    name: 'enums.ticket.categories.cancellation'
  }]);

  _defineProperty(TicketEnums, "departments", [{
    code: 'Faturamento',
    name: 'enums.ticket.departments.financial'
  }, {
    code: 'Tecnico',
    name: 'enums.ticket.departments.technical'
  }, {
    code: 'Comercial',
    name: 'enums.ticket.departments.comercial'
  }]);

  _defineProperty(TicketEnums, "financialProblemSubtypes", [{
    name: 'enums.ticket.financialProblemSubtypes.changeTicketExpirationDate',
    code: 'alteracao_vencimento_boleto'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.accessBlock',
    code: 'bloqueio_acesso'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.paymentSlip',
    code: 'envio_boleto'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.refund',
    code: 'estorno_reembolso'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.missingInvoice',
    code: 'falta_nota_fiscal'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.generateTicketCopy',
    code: 'segunda_via_boleto'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.invoiceInfo',
    code: 'informacao_fatura'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.fine',
    code: 'multa_juros'
  }, {
    name: 'enums.ticket.financialProblemSubtypes.cancellation',
    code: 'cancelamento_contrato'
  }]);

  _defineProperty(TicketEnums, "technicalProblemSubtypes", [{
    name: 'enums.ticket.technicalProblemSubtypes.integration',
    code: 'integracoes'
  }, {
    name: 'enums.ticket.technicalProblemSubtypes.product',
    code: 'produto'
  }, {
    name: 'enums.ticket.technicalProblemSubtypes.fiscal',
    code: 'fiscal'
  }]);

  _defineProperty(TicketEnums, "dataQuerySubtypes", [{
    code: 'dados_retorno',
    name: 'enums.ticket.dataQuerySubtypes.queryResponse'
  }, {
    code: 'falha_consulta',
    name: 'enums.ticket.dataQuerySubtypes.queryFailed'
  }, {
    code: 'outros',
    name: 'enums.ticket.dataQuerySubtypes.others'
  }]);

  _defineProperty(TicketEnums, 'DFeTech.ServiceInvoice.Subtypes', [{
    code: 'falha_emissao',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.issueFailed'
  }, {
    code: 'estagio_processamento',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.invoiceStuckedInProcessingState'
  }, {
    code: 'email_tomador',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.borrowerEmail'
  }, {
    code: 'status_divergente',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.divergingStatus'
  }, {
    code: 'informacoes',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.invoiceInfomation'
  }, {
    code: 'pdf',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.invoicePdf'
  }, {
    code: 'certificado_digital',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.certificate'
  }, {
    code: 'outros',
    name: 'enums.ticket.DFeTech.ServiceInvoice.Subtypes.others'
  }]);

  _defineProperty(TicketEnums, 'DFeTech.ProductInvoice.Subtypes', [{
    code: 'falha_emitir',
    name: 'enums.ticket.DFeTech.ProductInvoice.Subtypes.issueFailed'
  }, {
    code: 'nfe_denegada',
    name: 'enums.ticket.DFeTech.ProductInvoice.Subtypes.invoiceDenied'
  }, {
    code: 'nfe_recusada',
    name: 'enums.ticket.DFeTech.ProductInvoice.Subtypes.invoiceRefused'
  }, {
    code: 'erro_enviar_dados',
    name: 'enums.ticket.DFeTech.ProductInvoice.Subtypes.errorSendInvoiceData'
  }, {
    code: 'pdf',
    name: 'enums.ticket.DFeTech.ProductInvoice.Subtypes.invoicePdf'
  }, {
    code: 'certificado_digital',
    name: 'enums.ticket.DFeTech.ProductInvoice.Subtypes.certificate'
  }, {
    code: 'outros',
    name: 'enums.ticket.DFeTech.ProductInvoice.Subtypes.others'
  }]);

  _defineProperty(TicketEnums, 'DFeTech.ConsumerInvoice.Subtypes', [{
    code: 'falha_emissao',
    name: 'enums.ticket.DFeTech.ConsumerInvoice.Subtypes.issueFailed'
  }, {
    code: 'erro_enviar_dados',
    name: 'enums.ticket.DFeTech.ConsumerInvoice.Subtypes.errorSendInvoiceData'
  }, {
    code: 'certificado_digital',
    name: 'enums.ticket.DFeTech.ConsumerInvoice.Subtypes.certificate'
  }, {
    code: 'outros',
    name: 'enums.ticket.DFeTech.ConsumerInvoice.Subtypes.others'
  }]);

  _defineProperty(TicketEnums, "integrationSubtypes", [{
    name: 'enums.ticket.integrationSubtypes.internIntegration',
    code: "nfeio_integration"
  }, {
    name: 'enums.ticket.integrationSubtypes.batchPlataformIssue',
    code: "Planilha"
  }, {
    name: 'enums.ticket.integrationSubtypes.customerIntegration',
    code: 'API'
  }, {
    name: 'enums.ticket.integrationSubtypes.vindi',
    code: "Vindi"
  }, {
    name: 'enums.ticket.integrationSubtypes.mundipagg',
    code: "Mundipagg"
  }, {
    name: 'enums.ticket.integrationSubtypes.pluga',
    code: "Pluga"
  }, {
    name: 'enums.ticket.integrationSubtypes.WooCommerce',
    code: "WooCommerce"
  }, {
    name: 'enums.ticket.integrationSubtypes.whmcs',
    code: "WHMCS"
  }, {
    name: 'enums.ticket.integrationSubtypes.gestaoDs',
    code: "Gestao_ds"
  }]);

  _defineProperty(TicketEnums, "fiscalSubtypes", [{
    name: 'enums.ticket.fiscalSubtypes.cityServiceCodeRegistration',
    code: 'codigo-de-Serviço'
  }, {
    name: 'enums.ticket.fiscalSubtypes.fiscalFailure',
    code: 'erro-de-emissao'
  }, {
    name: 'enums.ticket.fiscalSubtypes.fiscalDoubt',
    code: 'duvida-fiscal'
  }, {
    name: 'enums.ticket.fiscalSubtypes.prefectureIntegration',
    code: 'integracao-prefeitura'
  }, {
    name: 'enums.ticket.fiscalSubtypes.taxCalculation',
    code: 'calculo-imposto'
  }, {
    name: 'enums.ticket.fiscalSubtypes.others',
    code: 'outros'
  }]);

  _defineProperty(TicketEnums, "queryParameterTypes", [{
    mask: "99.999.999/9999-99",
    type: "DataTech.LegalEntity",
    name: 'CNPJ'
  }, {
    masks: {
      federalTaxNumber: "999.999.999-99"
    },
    type: "DataTech.NaturalPerson",
    name: 'CPF'
  }, {
    mask: "99999999999999999999999999999999999999999999",
    type: "DataTech.ProductInvoice",
    name: 'NF-e'
  }]);

  _defineProperty(TicketEnums, "clientDepartment", [{
    code: 'Financeiro',
    name: 'enums.ticket.departments.financial'
  }, {
    code: 'Técnico',
    name: 'enums.ticket.departments.technical'
  }, {
    code: 'Operacional',
    name: 'enums.ticket.departments.comercial'
  }, {
    code: 'Outros',
    name: 'enums.ticket.departments.others'
  }]);
});