define("nfe-customer-console-v2/components/toolbar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ToolbarComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "title", '');

      _defineProperty(this, "subtitle", '');
    }

  }

  _exports.default = ToolbarComponent;
});