define("nfe-customer-console-v2/components/toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1bByOINY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"paper-toolbar-tools\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"returnPage\"]]],null,{\"statements\":[[0,\"    \"],[5,\"paper-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[22,\"returnPage\"]]],{\"statements\":[[0,\"\\n      \"],[5,\"paper-icon\",[],[[\"@icon\"],[\"arrow_back\"]]],[0,\"\\n      \"],[5,\"ember-tooltip\",[],[[\"@side\",\"@text\"],[\"top\",[28,\"t\",[\"returnBtn\"],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[22,\"title\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"subtitle\"]]],null,{\"statements\":[[0,\"      \"],[7,\"em\",true],[10,\"class\",\"truncate\"],[8],[0,\"| \"],[1,[22,\"subtitle\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \\n  \"],[7,\"span\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/toolbar/template.hbs"
    }
  });

  _exports.default = _default;
});