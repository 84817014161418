define("nfe-customer-console-v2/adapters/hook-v2", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _baseRest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class HookV2Adapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", _environment.default.APP.NFSE_V2_API_URL);

      _defineProperty(this, "namespace", 'v2');

      _defineProperty(this, "findAllUrlTemplate", '{+host}/{+namespace}/webhooks/');

      _defineProperty(this, "queryRecordUrlTemplate", '{+host}/{+namespace}/webhooks/{hookId}');

      _defineProperty(this, "createRecordUrlTemplate", '{+host}/{+namespace}/webhooks/');

      _defineProperty(this, "updateRecordUrlTemplate", '{+host}/{+namespace}/webhooks/{hookId}');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/{+namespace}/webhooks/{hookId}');

      _defineProperty(this, "urlSegments", {
        host() {
          return this.get('host');
        },

        namespace() {
          return this.get('namespace');
        },

        hookId(type, id, snapshot, query) {
          let hookId;

          if (query) {
            hookId = query.hookId;
          } else if (snapshot && snapshot.id) {
            hookId = snapshot.id;
          } else {
            hookId = snapshot.hookId;
          }

          return hookId;
        }

      });
    }

    get headers() {
      return super.get('headersApiKey');
    }

  }

  _exports.default = HookV2Adapter;
  ;
});