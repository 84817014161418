define("nfe-customer-console-v2/components/issue-stepper-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionData: Ember.inject.service(),
    features: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.fetchData.perform();
    },

    fetchData: (0, _emberConcurrency.task)(function* () {
      //check onboarding provider
      if (this.get('features.dFeTechOnboardingServiceInvoice')) return;
      const account = this.sessionData.getSessionCurrentAccount(); //make a query to get the NFE.io service invoice

      let queryResult = yield this.store.query('service-invoice-query', {
        accountId: account.id,
        companyId: this.companyId,
        page: 1,
        perPage: 10,
        borrowerFederalTaxNumber: 18792479000101
      });

      if (queryResult.length > 0) {
        //check at which issue step the service invoice stopped
        yield this.checkCurrentStep.perform(queryResult.firstObject);
        this.set('results', queryResult.firstObject);
      } else {
        this.set('results', queryResult);
      }
    }),
    checkCurrentStep: (0, _emberConcurrency.task)(function* (onboardInvoice) {
      let {
        status,
        flowStatus,
        id
      } = onboardInvoice;
      this.set('invoiceId', id);

      if (status == 'Created') {
        this.checkStepIndex(this.issueProcessSteps, flowStatus);
        this.set('currentStep', 4);
        this.testIssueProcess.perform(onboardInvoice);
      } else if (status == 'Issued' && flowStatus == 'Issued' || status == 'Error' && flowStatus == 'IssueFailed' || status == 'Issued' && flowStatus == 'WaitingDownload') {
        this.checkStepIndex(this.issueProcessSteps, flowStatus);
        this.testIssueProcess.perform(onboardInvoice);
      } else if (status == 'Issued' && (flowStatus == 'WaitingSendCancel' || flowStatus == 'WaitingReturn' || flowStatus == 'CancelFailed') || status == 'Cancelled' && (flowStatus == 'WaitingDownload' || flowStatus == 'Cancelled')) {
        this.checkStepIndex(this.cancelProcessSteps, flowStatus);
        this.set('currentStep', 6);
        this.testCancelProcess.perform(onboardInvoice);
      }
    }),

    checkStepIndex(steps, flowStatus) {
      let stepIndex = steps.findIndex(step => step.invoiceStatus === flowStatus);

      if (stepIndex == -1) {
        stepIndex = steps.length;
      }

      while (stepIndex != 0) {
        stepIndex--;
        steps[stepIndex].status = 'completed';
      }
    }

  });

  _exports.default = _default;
});