define("nfe-customer-console-v2/helpers/format-cpf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCpf = formatCpf;
  _exports.default = void 0;

  function formatCpf([value]) {
    if (Ember.isEmpty(value)) return;
    return value.toString().padStart(11, '0').replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  var _default = Ember.Helper.helper(formatCpf);

  _exports.default = _default;
});