define("nfe-customer-console-v2/initializers/ember-faker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {}

  var _default = {
    name: 'ember-faker',
    initialize
  };
  _exports.default = _default;
});