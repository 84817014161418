define("nfe-customer-console-v2/routes/companies/service-invoices/batch-issue", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    titleToken: "Emissão de notas fiscais em lote",

    async model() {
      let params = this.paramsFor('companies.service-invoices');
      let account = await this.sessionData.getCurrentAccount();
      let company = await this.store.queryRecord('company', {
        accountId: account.id,
        companyId: params.companyId
      });
      return Ember.Object.create({
        company,
        accountId: account.id
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('isExitingModal', false);
      }
    },

    actions: {
      willTransition(transition) {
        let controller = this.controller;
        if (controller.transitionRetry) return;

        if (controller.isSendBatchRunning) {
          controller.set('isExitingModal', true);
          controller.set('transition', transition);
          transition.abort();
        }
      }

    }
  });

  _exports.default = _default;
});