define("nfe-customer-console-v2/adapters/usage-summary", ["exports", "nfe-customer-console-v2/adapters/base-odata", "ember-data-url-templates", "nfe-customer-console-v2/config/environment"], function (_exports, _baseOdata, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UsageSummaryAdapter extends _baseOdata.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryRecordUrlTemplate", "{+host}/accounts('{accountId}')/subscriptions('{subscriptionId}')/Api.GetUsageSummary({date*})");

      _defineProperty(this, "urlSegments", {
        host() {
          return _environment.default.APP.USAGES_API_URL;
        },

        accountId(type, id, snapshot, query) {
          return id ? id : query.accountId;
        },

        subscriptionId(type, id, snapshot, query) {
          return `sub_${query.subscriptionId}`;
        },

        date(type, id, snapshot, query) {
          return query.date;
        }

      });
    }

  }

  _exports.default = UsageSummaryAdapter;
  ;
});