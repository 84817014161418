define("nfe-customer-console-v2/helpers/nfse-enumerable", ["exports", "nfe-customer-console-v2/models/enums"], function (_exports, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enumerable = enumerable;
  _exports.default = void 0;

  function enumerable([type, value]) {
    let option, result, enumProp;

    switch (type) {
      case 'legalNature':
        option = _enums.default.legalNatures;
        break;

      case 'taxRegime':
        option = _enums.default.taxRegimes;
        break;

      case 'specialTaxRegime':
        option = _enums.default.specialTaxRegimes;
        break;

      default:
        option = _enums.default[type];
        break;
    }

    if (Ember.isPresent(option)) {
      enumProp = option.filter(v => v.code == value);
      result = Ember.isPresent(enumProp) && Ember.isPresent(enumProp[0].name) ? enumProp[0].name : null;
    }

    return result || null;
  }

  var _default = Ember.Helper.helper(enumerable);

  _exports.default = _default;
});