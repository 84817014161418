define("nfe-customer-console-v2/serializers/company", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CompanySerializer extends _rest.default {
    _normalizeFields(company) {
      if (Ember.isEmpty(company)) return;
      company.federalTaxNumber = company.federalTaxNumber.toString().padStart(14, '0');
      let {
        municipalTaxNumber
      } = company;

      if (Ember.isPresent(municipalTaxNumber) && municipalTaxNumber.includes(':')) {
        let splited = municipalTaxNumber.split(':');
        company.municipalTaxNumber = splited.shift();
        if (splited.length > 1) company.authentication = splited.join(':');else company.authentication = splited;
      }
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.page || payload.totalPages || payload.totalResults) {
        payload.meta = {
          page: payload.page,
          totalPages: payload.totalPages,
          totalResults: payload.totalResults
        };
        delete payload.page;
        delete payload.totalPages;
        delete payload.totalResults;
      }

      if (Ember.isEmpty(payload.companies)) return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);

      if (Array.isArray(payload.companies)) {
        payload.companies = payload.companies.map(company => {
          this._normalizeFields(company);

          return company;
        });
      } else if (Ember.isPresent(payload.companies.federalTaxNumber)) {
        this._normalizeFields(payload.companies);
      }

      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

    serialize() {
      let json = super.serialize(...arguments);
      let {
        municipalTaxNumber,
        authentication,
        issRate
      } = json;
      if (authentication) json.municipalTaxNumber = `${municipalTaxNumber}:${authentication}`;
      delete json.authentication;
      json.issRate = parseFloat(issRate.toFixed(4));
      return json;
    }

    serializeIntoHash(hash, type, record, options) {
      // remove modelName from payload
      return Ember.assign(hash, this.serialize(record, options));
    }

  }

  _exports.default = CompanySerializer;
  ;
});