define("nfe-customer-console-v2/serializers/prefecture", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PrefectureSerializer extends _rest.default {
    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.value) {
        payload = {
          prefectures: payload.value
        };
      }

      return super.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
    }

    normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        prefecture: payload
      };
      return super.normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = PrefectureSerializer;
});