define("nfe-customer-console-v2/validators/intl-tel", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const IntlTel = _base.default.extend({
    validate(value, options, model, attribute) {
      if (!model.metaData || !model.metaData[attribute]) return true;

      if (model.metaData[attribute] && model.metaData[attribute].isValidNumber) {
        return true;
      } else {
        return options.message;
      }
    }

  });

  var _default = IntlTel;
  _exports.default = _default;
});