define("nfe-customer-console-v2/components/payment-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tiwiglEG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"status\",\"message\",\"payCreditCard\",\"wireTransfer\",\"instantPayment\",\"isRunning\",\"pay\"],[[24,[\"paymentStatus\"]],[24,[\"paymentMessage\"]],[24,[\"payCreditCard\"]],[24,[\"wireTransfer\"]],[24,[\"instantPayment\"]],[24,[\"isRunning\"]],[28,\"action\",[[23,0,[]],\"pay\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/payment-actions/template.hbs"
    }
  });

  _exports.default = _default;
});