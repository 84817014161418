define("nfe-customer-console-v2/routes/account/subscription", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    store: Ember.inject.service('store'),
    titleToken: "Informações da assinatura",

    resetController(controller, isExiting) {
      if (isExiting) controller.get('model').rollbackAttributes();
    },

    async model() {
      let account = await this.sessionData.getCurrentAccount();
      return await this.store.queryRecord('account', {
        account_id: account.id
      });
    }

  });

  _exports.default = _default;
});