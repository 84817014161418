define("nfe-customer-console-v2/routes/companies-v2/edit/basic-info", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    titleToken: "Informações da empresa",

    model(params) {
      let company = this.store.peekRecord('company-v2', params.id);
      return company || this.store.queryRecord('company-v2', {
        company_id: params.id
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
      }
    },

    actions: {
      async updateCompany(model, resolve, reject) {
        try {
          await model.save();
          this.notify.success('Empresa alterada com sucesso!');
          resolve();
          return this.transitionTo('companies-v2');
        } catch (error) {
          this.notify.error('Erro ao alterar empresa!');
          reject();
        }
      }

    }
  });

  _exports.default = _default;
});