define("nfe-customer-console-v2/components/users-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service('store'),

    init() {
      this._super(...arguments);

      this.users = null;
    },

    didReceiveAttrs() {
      this.fetchData.perform();
    },

    fetchData: (0, _emberConcurrency.task)(function* () {
      const adapter = this.store.adapterFor("account-user");
      const data = yield adapter.getUsers(this.accountId);
      this.set('users', data);
    }).restartable()
  });

  _exports.default = _default;
});