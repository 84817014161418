define("nfe-customer-console-v2/mirage/fixtures/service-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": "5c6ab6b9185d820f8c0f6a98",
    "environment": "Development",
    "flowStatus": "Cancelled",
    "provider": {
      "tradeName": "",
      "taxRegime": "MicroempreendedorIndividual",
      "specialTaxRegime": "Nenhum",
      "legalNature": "Empresario",
      "economicActivities": [],
      "regionalTaxNumber": 0,
      "municipalTaxNumber": "48177687",
      "issRate": 0.0,
      "parentId": "54244a1be340420fdc94ab59",
      "id": "5a61cb02398026069460a21b",
      "name": "CAIO COSTA",
      "federalTaxNumber": 12499520000170,
      "address": {
        "country": "BRA",
        "postalCode": "70073901",
        "street": "Outros Quadra 1 Bloco G Lote 32",
        "number": "S/N",
        "additionalInformation": "QUADRA 01 BLOCO G",
        "district": "Asa Sul",
        "city": {
          "code": "3550308",
          "name": "São Paulo"
        },
        "state": "SP"
      },
      "status": "Active",
      "type": "LegalPerson, Company",
      "createdOn": "2018-01-19T10:40:02.5617439+00:00",
      "modifiedOn": "2019-02-15T12:31:05.0344488+00:00"
    },
    "borrower": {
      "taxRegime": "LucroPresumido",
      "legalNature": 0,
      "economicActivities": [],
      "issRate": 0.0,
      "id": "5c6ab6b9185d820f8c0f6a99",
      "name": "PORTO SEGURO COMPANHIA DE SEGUROS GERAIS",
      "federalTaxNumber": 61198164000160,
      "address": {
        "country": "BRA",
        "postalCode": "7500000",
        "street": "Rua jose moreno mostazo",
        "number": "319",
        "district": "centro",
        "city": {
          "code": "5220280",
          "name": "São Paulo"
        },
        "state": "SP"
      },
      "status": "Active",
      "type": "LegalPerson",
      "createdOn": "2019-02-18T13:44:25.1369073+00:00"
    },
    "batchNumber": 16796984187554,
    "batchCheckNumber": "216796984187554",
    "number": 2,
    "checkCode": "216796984187554",
    "status": "Cancelled",
    "rpsType": "Rps",
    "rpsStatus": "Normal",
    "taxationType": "WithinCity",
    "issuedOn": "2019-02-18T10:44:25.1994073-03:00",
    "cancelledOn": "2019-02-20T14:23:32.9367358-03:00",
    "rpsSerialNumber": "1000",
    "rpsNumber": 2,
    "cityServiceCode": "1116",
    "federalServiceCode": "07.05",
    "description": "Descrição de exemplo\n\n\nCONFORME LEI 12.741/2012 o valor aproximado dos tributos é R$1,77 (17,74%), FONTE: IBPT/empresometro.com.br (18.2.C)",
    "servicesAmount": 10.0,
    "deductionsAmount": 0.0,
    "discountUnconditionedAmount": 0.0,
    "discountConditionedAmount": 0.0,
    "baseTaxAmount": 10.0,
    "issRate": 0.05,
    "issTaxAmount": 0.0,
    "irAmountWithheld": 0.0,
    "pisAmountWithheld": 0.0,
    "cofinsAmountWithheld": 0.0,
    "csllAmountWithheld": 0.0,
    "inssAmountWithheld": 0.0,
    "issAmountWithheld": 0.0,
    "othersAmountWithheld": 0.0,
    "amountWithheld": 0.0,
    "amountNet": 10.0,
    "approximateTax": {
      "source": "IBPT/empresometro.com.br",
      "version": "18.2.C",
      "totalRate": 0.1774
    },
    "createdOn": "2019-02-18T13:44:25.1369073+00:00",
    "modifiedOn": "2019-02-20T17:23:37.3026785+00:00"
  }, {
    "id": "5c6ab6cc185d820f8c0f6b49",
    "environment": "Development",
    "flowStatus": "Issued",
    "provider": {
      "tradeName": "",
      "taxRegime": "MicroempreendedorIndividual",
      "specialTaxRegime": "Nenhum",
      "legalNature": "Empresario",
      "economicActivities": [],
      "regionalTaxNumber": 0,
      "municipalTaxNumber": "48177687",
      "issRate": 0.0,
      "parentId": "54244a1be340420fdc94ab59",
      "id": "5a61cb02398026069460a21b",
      "name": "CAIO COSTA",
      "federalTaxNumber": 12499520000170,
      "address": {
        "country": "BRA",
        "postalCode": "70073901",
        "street": "Outros Quadra 1 Bloco G Lote 32",
        "number": "S/N",
        "additionalInformation": "QUADRA 01 BLOCO G",
        "district": "Asa Sul",
        "city": {
          "code": "3550308",
          "name": "São Paulo"
        },
        "state": "SP"
      },
      "status": "Active",
      "type": "LegalPerson, Company",
      "createdOn": "2018-01-19T10:40:02.5617439+00:00",
      "modifiedOn": "2019-02-15T12:31:05.0344488+00:00"
    },
    "borrower": {
      "id": "5c6ab6cc185d820f8c0f6b4a",
      "name": "RAFAEL CAVALCANTE RIBEIRO RAMOS",
      "federalTaxNumber": 762641304,
      "address": {
        "country": "BRA",
        "postalCode": "7500000",
        "street": "Rua jose moreno mostazo",
        "number": "319",
        "district": "centro",
        "city": {
          "code": "5220280",
          "name": "São Paulo"
        },
        "state": "SP"
      },
      "status": "Active",
      "type": "NaturalPerson",
      "createdOn": "2019-02-18T13:44:44.1730981+00:00"
    },
    "batchNumber": 16030539527148,
    "batchCheckNumber": "416030539527148",
    "number": 4,
    "checkCode": "416030539527148",
    "status": "Issued",
    "rpsType": "Rps",
    "rpsStatus": "Normal",
    "taxationType": "WithinCity",
    "issuedOn": "2018-11-24T22:00:00-02:00",
    "rpsSerialNumber": "1000",
    "rpsNumber": 4,
    "cityServiceCode": "1116",
    "federalServiceCode": "07.05",
    "description": "Descrição de exemplo\n\n\nCONFORME LEI 12.741/2012 o valor aproximado dos tributos é R$1,77 (17,74%), FONTE: IBPT/empresometro.com.br (18.2.C)",
    "servicesAmount": 10.0,
    "deductionsAmount": 0.0,
    "discountUnconditionedAmount": 0.0,
    "discountConditionedAmount": 0.0,
    "baseTaxAmount": 10.0,
    "issRate": 0.05,
    "issTaxAmount": 0.0,
    "irAmountWithheld": 0.0,
    "pisAmountWithheld": 0.0,
    "cofinsAmountWithheld": 0.0,
    "csllAmountWithheld": 0.0,
    "inssAmountWithheld": 0.0,
    "issAmountWithheld": 0.0,
    "othersAmountWithheld": 0.0,
    "amountWithheld": 0.0,
    "amountNet": 10.0,
    "approximateTax": {
      "source": "IBPT/empresometro.com.br",
      "version": "18.2.C",
      "totalRate": 0.1774
    },
    "createdOn": "2019-02-18T13:44:44.1730981+00:00",
    "modifiedOn": "2019-02-18T13:44:47.8829021+00:00"
  }, {
    "id": "5c66b04ef0f50105983d2d47",
    "environment": "Development",
    "flowStatus": "IssueFailed",
    "flowMessage": "max retry reached on set calculate taxes stage last error was 'city service code '620910001' was not found for city code '3550308' for calculate taxes'",
    "provider": {
      "tradeName": "",
      "taxRegime": "MicroempreendedorIndividual",
      "specialTaxRegime": "Nenhum",
      "legalNature": "Empresario",
      "economicActivities": [],
      "regionalTaxNumber": 0,
      "municipalTaxNumber": "48177687",
      "issRate": 0.0,
      "parentId": "54244a1be340420fdc94ab59",
      "id": "5a61cb02398026069460a21b",
      "name": "CAIO COSTA",
      "federalTaxNumber": 12499520000170,
      "address": {
        "country": "BRA",
        "postalCode": "70073901",
        "street": "Outros Quadra 1 Bloco G Lote 32",
        "number": "S/N",
        "additionalInformation": "QUADRA 01 BLOCO G",
        "district": "Asa Sul",
        "city": {
          "code": "3550308",
          "name": "São Paulo"
        },
        "state": "SP"
      },
      "status": "Active",
      "type": "LegalPerson, Company",
      "createdOn": "2018-01-19T10:40:02.5617439+00:00",
      "modifiedOn": "2019-02-11T13:35:35.469429+00:00"
    },
    "borrower": {
      "taxRegime": "LucroPresumido",
      "legalNature": 0,
      "economicActivities": [],
      "issRate": 0.0,
      "id": "5c66b04ef0f50105983d2d48",
      "name": "F12 - ITAIPAVA - Colchoes dos Sonhos Macedo Noel Ltda.",
      "federalTaxNumber": 8233452000125,
      "address": {
        "country": "BRA",
        "postalCode": "22621-200",
        "street": "Avenida Olegario Maciel",
        "number": "101",
        "additionalInformation": "303",
        "district": "Barra da Tijuca",
        "city": {
          "code": "3304557",
          "name": "Rio de Janeiro"
        },
        "state": "RJ"
      },
      "status": "Active",
      "type": "LegalPerson",
      "createdOn": "2019-02-15T12:27:58.7275369+00:00"
    },
    "batchNumber": 0,
    "number": 0,
    "status": "Error",
    "rpsType": "Rps",
    "rpsStatus": "Normal",
    "taxationType": "WithinCity",
    "issuedOn": "2019-01-28T22:00:00-02:00",
    "rpsSerialNumber": "1000",
    "rpsNumber": 0,
    "cityServiceCode": "620910001",
    "description": "SERVIÇOS DE INTERMEDIAÇÃO FINANCEIRA",
    "servicesAmount": 0.5,
    "deductionsAmount": 0.0,
    "discountUnconditionedAmount": 0.0,
    "discountConditionedAmount": 0.0,
    "baseTaxAmount": 0.5,
    "issRate": 0.0,
    "issTaxAmount": 0.0,
    "irAmountWithheld": 0.0,
    "pisAmountWithheld": 0.0,
    "cofinsAmountWithheld": 0.0,
    "csllAmountWithheld": 0.0,
    "inssAmountWithheld": 0.0,
    "issAmountWithheld": 0.0,
    "othersAmountWithheld": 0.0,
    "amountWithheld": 0.0,
    "amountNet": 0.5,
    "approximateTax": {
      "totalRate": 0.0
    },
    "createdOn": "2019-02-15T12:27:58.7275369+00:00",
    "modifiedOn": "2019-02-15T12:38:42.3510584+00:00"
  }, {
    "id": "5c10f9a81690676480af8e57",
    "environment": "Development",
    "flowStatus": "WaitingCalculateTaxes",
    "flowMessage": "ibpt code '' was not found for state 'SP' to calculate approximate tax burden",
    "provider": {
      "tradeName": "e-inscricao",
      "taxRegime": "LucroReal",
      "specialTaxRegime": "Automatico",
      "legalNature": "Empresario",
      "economicActivities": [],
      "regionalTaxNumber": 0,
      "municipalTaxNumber": "48177687",
      "issRate": 0.0,
      "parentId": "54244a1be340420fdc94ab59",
      "id": "54244e0ee340420fdc94ad09",
      "name": "GABRIEL ALEJANDRO DA SILVA MARQUEZ EPP",
      "federalTaxNumber": 18792479000101,
      "address": {
        "country": "",
        "postalCode": "05761-280",
        "street": "Rua João Baptista de Camargo",
        "number": "5856",
        "additionalInformation": "",
        "district": "Jardim Maria Virginia",
        "city": {
          "code": "3550308",
          "name": "São Paulo"
        },
        "state": "SP"
      },
      "status": "Active",
      "type": "LegalPerson, Company",
      "createdOn": "2014-09-25T17:17:02.2517842+00:00",
      "modifiedOn": "2018-12-11T15:39:07.1800467-02:00"
    },
    "borrower": {
      "id": "5c10f9a81690676480af8e58",
      "name": "NAO INFORMADO",
      "federalTaxNumber": 0,
      "email": "n@mailinator.com",
      "address": {
        "country": "BRA",
        "postalCode": "00000-000",
        "street": "Rua NAO INFORMADO",
        "number": "SN",
        "additionalInformation": "302",
        "district": "NAO INFORMADO",
        "city": {
          "code": "3304557",
          "name": "Rio de Janeiro"
        },
        "state": "RJ "
      },
      "status": "Active",
      "type": "NaturalPerson",
      "createdOn": "2018-12-12T12:06:00.4696348+00:00"
    },
    "batchNumber": 0,
    "number": 0,
    "status": "Created",
    "rpsType": "Rps",
    "rpsStatus": "Normal",
    "taxationType": "WithinCity",
    "issuedOn": "2017-08-14T14:50:28.5775182-03:00",
    "rpsSerialNumber": "IO",
    "rpsNumber": 6,
    "cityServiceCode": "10500200",
    "description": "Curso",
    "servicesAmount": 10.0,
    "deductionsAmount": 0.0,
    "discountUnconditionedAmount": 0.0,
    "discountConditionedAmount": 0.0,
    "baseTaxAmount": 10.0,
    "issRate": 0.0,
    "issTaxAmount": 0.0,
    "irAmountWithheld": 0.0,
    "pisAmountWithheld": 0.0,
    "cofinsAmountWithheld": 0.0,
    "csllAmountWithheld": 0.0,
    "inssAmountWithheld": 0.0,
    "issAmountWithheld": 0.0,
    "othersAmountWithheld": 0.0,
    "amountWithheld": 0.0,
    "amountNet": 10.0,
    "approximateTax": {
      "totalRate": 0.0
    },
    "createdOn": "2018-12-12T12:06:00.4656315+00:00",
    "modifiedOn": "2018-12-12T12:14:43.0206679+00:00"
  }];
  _exports.default = _default;
});