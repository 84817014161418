define("nfe-customer-console-v2/services/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AddressService = (_dec = Ember.inject.service, (_class = (_temp = class AddressService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async getStates() {
      let localStates = localStorage.getItem('address_states');

      if (localStates) {
        return JSON.parse(localStates);
      }

      try {
        let allStates = await this.store.findAll('state');
        let states = allStates.map(item => {
          return {
            code: item.abbreviation,
            name: item.name
          };
        });
        localStorage.setItem('address_states', JSON.stringify(states));
        return states;
      } catch (error) {
        console.log(error);
      }
    }

    async getCities() {
      const localCities = localStorage.getItem('address_all_cities');

      if (localCities) {
        return JSON.parse(localCities);
      }

      try {
        const allCities = await this.store.findAll('city');
        const cities = allCities.map(item => item.toJSON({
          includeId: true
        }));
        localStorage.setItem('address_all_cities', JSON.stringify(cities));
        return cities;
      } catch (error) {
        console.log(error);
      }
    }

    async getCitiesByState(state) {
      if (!state) return;
      if (state.code) state = state.code;
      const cityKey = `address_${state.toLowerCase()}_cities`;
      const localCitiesByState = localStorage.getItem(cityKey);

      if (localCitiesByState) {
        return JSON.parse(localCitiesByState);
      }

      const allCities = await this.getCities();
      const citiesByState = allCities.filter(city => city.state === state);
      const cities = citiesByState.map(item => {
        return {
          code: item.id,
          name: item.name
        };
      });
      localStorage.setItem(cityKey, JSON.stringify(cities));
      return cities;
    }

    async getAddressByPostalCode(postalCode) {
      if (!postalCode) return null;

      try {
        const addressAdapter = this.store.adapterFor('address');
        let result = await addressAdapter.getAddress(postalCode);
        result.address.street = `${result.address.streetSuffix} ${result.address.street}`.trim();
        return result.address;
      } catch (error) {
        return null;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AddressService;
});