define("nfe-customer-console-v2/controllers/account/webhooks/edit", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionData: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    webhook: Ember.inject.service(),
    queryParams: ["version"],
    updateHook: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateHookValidations', true);
      if (model.get('validations.isInvalid')) return;
      const response = yield this.webhook.updateHook(model);

      if (response.ok) {
        this.transitionToRoute('account.webhooks');
      }
    }).drop(),
    deleteHook: (0, _emberConcurrency.task)(function* (model) {
      const response = yield this.webhook.deleteHook(model);

      if (response.ok) {
        this.transitionToRoute('account.webhooks');
      }

      this.set('deleteWebhookModal', false);
    }).drop()
  });

  _exports.default = _default;
});