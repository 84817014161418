define("nfe-customer-console-v2/services/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompanyService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = (_temp = class CompanyService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "metrics", _descriptor3, this);
    }

    async uploadCertificate(companyId, file, password) {
      const adapter = this.store.adapterFor("company");
      const response = await adapter.uploadCertificate(companyId, file, password);

      if (response.status === 'success') {
        response.message = this.intl.t('companies.edit.certificate.updatedWithSuccess');
        this.metrics.trackPage({
          title: '/companies/edit/upload-certificate'
        });
      } else {
        const {
          message
        } = response;

        if (message == "certificate password is not valid") {
          response.message = this.intl.t('companies.edit.certificate.errors.invalidPassword');
        } else if (message.includes("certificate is expired")) {
          response.message = this.intl.t('companies.edit.certificate.errors.overdue');
        } else if (message.includes("certificate tax number is not equal")) {
          response.message = this.intl.t('companies.edit.certificate.errors.invalidCnpj');
        } else {
          response.message = this.intl.t('companies.edit.certificate.errors.default');
        }
      }

      return response;
    }

    async saveEnvironment(accountId, companyId, enviroment) {
      const adapter = this.store.adapterFor("company");
      let message, status;

      try {
        await adapter.saveEnvironment(accountId, companyId, enviroment);
        status = "success";
        message = this.intl.t("companies.edit.environment.success");
      } catch (error) {
        status = "error";
        const errorMessage = error.description;

        if (errorMessage === "account is not on production enviroment") {
          message = this.intl.t("companies.edit.environment.error.needActivate");
        } else if (errorMessage.includes("not ready to production")) {
          message = this.intl.t("companies.edit.environment.error.notReady");
        } else {
          message = this.intl.t("companies.edit.environment.error.default");
        }
      }

      return {
        status,
        message
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CompanyService;
  ;
});