define("nfe-customer-console-v2/components/selectable-table-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['md-row'],
    classNameBindings: ['selected:selected-row'],
    attributeBindings: ['style', 'disabled'],
    style: Ember.computed('onClick', function () {
      return this.get('onClick') ? Ember.String.htmlSafe('cursor: pointer;') : Ember.String.htmlSafe('');
    }),
    selected: false,

    click() {
      this.get('onClick')();
    },

    actions: {
      close() {
        if (this.get('onClose')) {
          this.get('onClose')(this);
        }
      }

    }
  });

  _exports.default = _default;
});