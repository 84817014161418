define("nfe-customer-console-v2/components/intl-tel-input/component", ["exports", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin"], function (_exports, _focusableMixin, _colorMixin, _childMixin, _validationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_focusableMixin.default, _colorMixin.default, _childMixin.default, _validationMixin.default, {
    tagName: 'md-input-container',
    classNames: ['md-default-theme'],
    classNameBindings: ['hasValue:md-input-has-value', 'isInvalidAndTouched:md-input-invalid', 'hasLeftIcon:md-icon-left', 'hasRightIcon:md-icon-right', 'focused:md-input-focused', 'block:md-block', 'placeholder:md-input-has-placeholder'],
    preferredCountries: ['br', 'us'],
    inputElementId: Ember.computed('elementId', {
      get() {
        return `input-${this.get('elementId')}`;
      },

      set(key, value) {
        return set(this, "elementId", value);
      }

    }),
    isTouched: false,
    validationProperty: 'number',
    isInvalidAndTouched: Ember.computed.and('isInvalid', 'isTouched'),
    isInvalid: Ember.computed('metaData.isValidNumber', function () {
      return this.metaData ? !this.metaData.isValidNumber : null || this.hasErrorMessages;
    }),
    hasValue: Ember.computed('model', function () {
      return this.model;
    }),

    didRender() {
      this.set('errors', this.model.validations.attrs[this.valuePath].messages);
    },

    init() {
      this._super(...arguments);

      this.set('model.metaData', {}); // let customValidations = [
      //   {
      //     message: 'Insira um telefone válido',
      //     validate: (inputValue) => this.metaData.isValidNumber
      //   }
      // ]
      // this.set('customValidations', customValidations);
    },

    actions: {
      handleUpdate(number, metaData) {
        let attr = this.valuePath;
        this.set(`model.${attr}`, metaData.isValidNumber ? metaData.numberFormat.INTERNATIONAL : number);
        this.set(`model.metaData.${attr}`, metaData);
        this.set('metaData', metaData);
        this.notifyValidityChange();
      },

      handleBlur(e) {
        this.set('isTouched', true);
        this.notifyValidityChange();
      }

    }
  });

  _exports.default = _default;
});