define("nfe-customer-console-v2/components/taxes-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    _taxesResponseData: null,
    filterTax: null,
    perPage: null,
    page: null,
    hasMore: true,
    store: Ember.inject.service(),
    metrics: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const params = {
        cityCode: this.cityCode,
        searchTerm: this.filterTax,
        perPage: this.perPage,
        page: this.page
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params.cityCode)) return;

      if (Ember.isBlank(this._taxesResponseData)) {
        const taxAdapter = this.store.adapterFor('tax');
        this._taxesResponseData = yield taxAdapter.getTaxes(params.cityCode);
      } else {
        yield (0, _emberConcurrency.timeout)(1000);
      }

      let taxes = this.paginateTaxes(params, this._taxesResponseData.taxes);
      return this.set('results', taxes.map(tax => Ember.Object.create(tax)));
    }).restartable(),

    paginateTaxes(params, taxes) {
      if (Ember.isPresent(params.searchTerm)) taxes = this.filterTaxes(params.searchTerm, taxes);
      const perPage = params.perPage || 10;
      this.set('hasMore', Math.ceil(taxes.length / perPage) > this.get('page'));

      if (taxes.length == 0 || taxes.length < perPage) {
        this.set('hasMore', false);
        return taxes;
      }

      return taxes.slice(perPage * (this.get('page') - 1), perPage * this.get('page'));
    },

    filterTaxes(searchTerm, taxes) {
      if (Ember.isBlank(taxes) || Ember.isBlank(searchTerm)) return;
      let filteredTaxes = taxes.filter(tax => {
        let code = tax.cityService.code;
        let description = this.removeAccents(tax.cityService.description);
        return code.match(searchTerm) || description.match(searchTerm);
      });

      if (this.onboarding) {
        let city = taxes[0].city.name;
        this.metrics.trackEvent({
          event: 'Filtered tax',
          category: 'Onboarding service invoice',
          action: 'Filtered tax',
          label: filteredTaxes.length > 0 ? `${filteredTaxes.length} taxes found; searhTearm = ${searchTerm}; city: ${city}` : `No taxes found; searhTearm = ${searchTerm}; city: ${city}`,
          city
        });
      }

      return filteredTaxes;
    },

    removeAccents(str) {
      str = str.toLowerCase().replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a').replace(new RegExp('[ÉÈÊ]', 'gi'), 'e').replace(new RegExp('[ÍÌÎ]', 'gi'), 'i').replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o').replace(new RegExp('[ÚÙÛ]', 'gi'), 'u').replace(new RegExp('[Ç]', 'gi'), 'c');
      return str;
    }

  });

  _exports.default = _default;
});