define("nfe-customer-console-v2/serializers/service-invoice-query", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServiceInvoiceQuerySerializer extends _rest.default.extend() {
    _normalizerInvoiceAttributes(invoice) {
      invoice.invoiceProvider = invoice.provider;
      delete invoice.provider;
      return invoice;
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && payload.page || payload.totalPages || payload.totalResults) {
        payload.meta = {
          page: payload.page,
          totalPages: payload.totalPages,
          totalResults: payload.totalResults
        };
        delete payload.page;
        delete payload.totalPages;
        delete payload.totalResults;
      }

      if (payload && payload.serviceInvoices && Array.isArray(payload.serviceInvoices)) {
        payload.serviceInvoiceQueries = payload.serviceInvoices.map(item => {
          return this._normalizerInvoiceAttributes(item);
        });
        delete payload.serviceInvoices;
      } else if (payload && typeof payload == 'object') {
        payload = {
          serviceInvoice: this._normalizerInvoiceAttributes(payload)
        };
      }

      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = ServiceInvoiceQuerySerializer;
});