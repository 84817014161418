define("nfe-customer-console-v2/components/fiscal-form/component", ["exports", "nfe-customer-console-v2/models/enums"], function (_exports, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    prefectures: Ember.inject.service(),
    needsUserAndPassword: false,

    async init() {
      this._super(...arguments);

      const companyCityCode = this.model.address.city.code;
      const prefecture = await this.store.findRecord('prefecture', companyCityCode);
      if (prefecture && prefecture.webService.authenticationMethod === 'UserAndPassword') this.set('needsUserAndPassword', true);
    },

    specialTaxRegimes: _enums.default.specialTaxRegimes,
    taxDeterminations: _enums.default.taxDeterminations,
    selectedSpecialTaxRegime: Ember.computed('model.specialTaxRegime', function () {
      const value = this.get('model.specialTaxRegime');

      if (value === 0) {
        return _enums.default.specialTaxRegimes[0];
      } else {
        return _enums.default.specialTaxRegimes.filter(v => v.code === value)[0];
      }
    }),
    selectedMunicipalTaxDetermination: Ember.computed('model.municipalTaxDetermination', function () {
      const value = this.get('model.municipalTaxDetermination');

      if (value === 0) {
        return _enums.default.taxDeterminations[0];
      } else {
        return _enums.default.taxDeterminations.filter(v => v.code === value)[0];
      }
    }),
    selectedFederalTaxDetermination: Ember.computed('model.federalTaxDetermination', function () {
      const value = this.get('model.federalTaxDetermination');

      if (value === 0) {
        return _enums.default.taxDeterminations[0];
      } else {
        return _enums.default.taxDeterminations.filter(v => v.code === value)[0];
      }
    })
  });

  _exports.default = _default;
});