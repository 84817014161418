define("nfe-customer-console-v2/models/company", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: '6',
        message: 'Muito curto (minimo de 6 caracteres)'
      })]
    },
    federalTaxNumber: {
      validators: [(0, _emberCpValidations.validator)('cnpj')]
    },
    rpsSerialNumber: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^([a-zA-Z0-9]{1,5})$/,
        message: 'No máximo 5 caracteres, somente letras e números'
      })]
    },
    rpsNumber: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('number', {
        integer: true,
        allowString: true,
        message: 'Insira um número de RPS válido.'
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        gte: 0,
        message: 'Insira um número inteiro maior ou igual que zero.'
      })]
    },
    issRate: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio.'
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        gte: 0,
        message: 'Insira um número maior ou igual a 0,00 %.'
      }), (0, _emberCpValidations.validator)('number', {
        integer: false,
        allowString: true,
        lte: 0.05,
        message: 'Insira um número menor ou igual a 5,00 %'
      })]
    },
    municipalTaxNumber: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9\/\.:-]+$/g,
        allowBlank: true,
        allowString: true,
        message: 'Insira somente letras e números'
      })]
    },
    authentication: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9!@#$&()\\:\-\`\.\+\,\/\"]*$/g,
        allowBlank: true,
        message: 'Insira somente letras, números e símbolos'
      }), (0, _emberCpValidations.validator)('length', {
        min: '3',
        message: 'Minimo de 3 caracteres'
      })]
    },
    regionalTaxNumber: {
      validators: [(0, _emberCpValidations.validator)('number', {
        integer: true,
        gte: 0,
        allowString: true,
        allowBlank: true,
        message: 'Insira somente números'
      })]
    }
  });
  let CompanyModel = (_dec = Ember.inject.service, _dec2 = (0, _model.belongsTo)('account'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string', {
    defaultValue: 'Development'
  }), _dec20 = (0, _model.attr)('string', {
    defaultValue: 'IO'
  }), _dec21 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec22 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec23 = (0, _model.attr)('date'), _dec24 = (0, _model.attr)('date'), _dec25 = (0, _model.attr)('date'), _dec26 = (0, _attributes.fragment)('address'), _dec27 = (0, _attributes.fragment)('certificate'), _dec28 = Ember.computed.equal('certificate.status', 'Active'), _dec29 = Ember.computed.equal('certificate.status', 'Pending'), _dec30 = Ember.computed('certificate'), _dec31 = Ember.computed('certificate'), _dec32 = Ember.computed.equal('fiscalStatus', 'Active'), _dec33 = Ember.computed.equal('fiscalStatus', 'Pending'), _dec34 = Ember.computed('certificate'), _dec35 = Ember.computed('certificate', 'fiscalStatus'), _dec36 = Ember.computed.not('isDevelopmentEnvironment'), _dec37 = Ember.computed.not('isProductionEnvironment'), _dec38 = Ember.computed.equal('environment', "Development"), _dec39 = Ember.computed.equal('environment', "Production"), _dec40 = Ember.computed('isCertificateActive', 'isCertificatePending', 'isCertificateExpiring', 'isCertificateExpired'), _dec41 = Ember.computed('environment'), _dec42 = Ember.computed.and('validations.isValid', 'address.validations.isValid'), _dec43 = Ember.computed.or('validations.isInvalid', 'address.validations.isInvalid'), (_class = (_temp = class CompanyModel extends _model.default.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "tradeName", _descriptor4, this);

      _initializerDefineProperty(this, "federalTaxNumber", _descriptor5, this);

      _initializerDefineProperty(this, "municipalTaxNumber", _descriptor6, this);

      _initializerDefineProperty(this, "authentication", _descriptor7, this);

      _initializerDefineProperty(this, "regionalTaxNumber", _descriptor8, this);

      _initializerDefineProperty(this, "legalNature", _descriptor9, this);

      _initializerDefineProperty(this, "taxRegime", _descriptor10, this);

      _initializerDefineProperty(this, "specialTaxRegime", _descriptor11, this);

      _initializerDefineProperty(this, "status", _descriptor12, this);

      _initializerDefineProperty(this, "fiscalStatus", _descriptor13, this);

      _initializerDefineProperty(this, "federalTaxDetermination", _descriptor14, this);

      _initializerDefineProperty(this, "municipalTaxDetermination", _descriptor15, this);

      _initializerDefineProperty(this, "loginName", _descriptor16, this);

      _initializerDefineProperty(this, "loginPassword", _descriptor17, this);

      _initializerDefineProperty(this, "authIssueValue", _descriptor18, this);

      _initializerDefineProperty(this, "environment", _descriptor19, this);

      _initializerDefineProperty(this, "rpsSerialNumber", _descriptor20, this);

      _initializerDefineProperty(this, "issRate", _descriptor21, this);

      _initializerDefineProperty(this, "rpsNumber", _descriptor22, this);

      _initializerDefineProperty(this, "openningDate", _descriptor23, this);

      _initializerDefineProperty(this, "createdOn", _descriptor24, this);

      _initializerDefineProperty(this, "modifiedOn", _descriptor25, this);

      _initializerDefineProperty(this, "address", _descriptor26, this);

      _initializerDefineProperty(this, "certificate", _descriptor27, this);

      _initializerDefineProperty(this, "isCertificateActive", _descriptor28, this);

      _initializerDefineProperty(this, "isCertificatePending", _descriptor29, this);

      _initializerDefineProperty(this, "isActive", _descriptor30, this);

      _initializerDefineProperty(this, "isPending", _descriptor31, this);

      _initializerDefineProperty(this, "isNotDevelopmentEnvironment", _descriptor32, this);

      _initializerDefineProperty(this, "isNotProductionEnvironment", _descriptor33, this);

      _initializerDefineProperty(this, "isDevelopmentEnvironment", _descriptor34, this);

      _initializerDefineProperty(this, "isProductionEnvironment", _descriptor35, this);

      _initializerDefineProperty(this, "isValid", _descriptor36, this);

      _initializerDefineProperty(this, "isInvalid", _descriptor37, this);
    }

    get isCertificateExpired() {
      return new Date(this.certificate.expiresOn) < new Date();
    }

    get isCertificateExpiring() {
      let now = new Date();
      now.setDate(now.getDate() + 30);
      return new Date(this.certificate.expiresOn) < now;
    }

    get isCertificateWithIssue() {
      return this.isCertificateExpiring || this.isCertificateExpired;
    }

    get isActiveAndCertificateActive() {
      return this.fiscalStatus === 'Active' && this.get('certificate.status') === 'Active';
    }

    get certificateStatusLabel() {
      if (this.isCertificatePending) {
        return this.intl.t(`certificateStatuses.Pending`);
      } else if (this.isCertificateExpired) {
        return this.intl.t(`certificateStatuses.Expired`);
      } else if (this.isCertificateExpiring) {
        return this.intl.t(`certificateStatuses.Expiring`);
      } else {
        return this.intl.t(`certificateStatuses.Active`);
      }
    }

    get environmentLabel() {
      return this.intl.t(`enums.apiEnvironments.${this.environment}`);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tradeName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "federalTaxNumber", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "municipalTaxNumber", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "authentication", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "regionalTaxNumber", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "legalNature", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "taxRegime", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "specialTaxRegime", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "fiscalStatus", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "federalTaxDetermination", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "municipalTaxDetermination", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "loginName", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "loginPassword", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "authIssueValue", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "rpsSerialNumber", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "issRate", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "rpsNumber", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "openningDate", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "createdOn", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "modifiedOn", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "certificate", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "isCertificateActive", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "isCertificatePending", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isCertificateExpired", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "isCertificateExpired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCertificateExpiring", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "isCertificateExpiring"), _class.prototype), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isCertificateWithIssue", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "isCertificateWithIssue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isActiveAndCertificateActive", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "isActiveAndCertificateActive"), _class.prototype), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "isNotDevelopmentEnvironment", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "isNotProductionEnvironment", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "isDevelopmentEnvironment", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "isProductionEnvironment", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "certificateStatusLabel", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "certificateStatusLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "environmentLabel", [_dec41], Object.getOwnPropertyDescriptor(_class.prototype, "environmentLabel"), _class.prototype), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "isInvalid", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CompanyModel;
  ;
});