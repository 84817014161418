define("nfe-customer-console-v2/helpers/concat-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatAddress = concatAddress;
  _exports.default = void 0;

  function concatAddress([street, number, additionalInformation, district, cidade, estado, cep, pais]) {
    if (!number) number = "";

    if (pais !== "BRA") {
      return `${street}, ${number} - ${district}, ${cidade} - ${estado}`;
    } else {
      if (street === undefined || district === undefined || cidade === undefined || estado === undefined || cep === undefined) {
        return;
      } else {
        return `${street}, ${number} - ${additionalInformation ? `${additionalInformation} - ` : ''} ${district}, ${cidade} - ${estado}, ${cep}`;
      }
    }
  }

  var _default = Ember.Helper.helper(concatAddress);

  _exports.default = _default;
});