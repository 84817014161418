define("nfe-customer-console-v2/models/service-invoice-query", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ServiceInvoiceQueryModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('date'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _attributes.fragment)('service-invoice/borrower'), _dec15 = Ember.computed('status'), _dec16 = Ember.computed('status'), _dec17 = Ember.computed('status'), _dec18 = Ember.computed('status', 'flowStatus'), _dec19 = Ember.computed('status', 'flowStatus'), _dec20 = Ember.computed('status', 'flowStatus'), _dec21 = Ember.computed('status', 'flowStatus'), (_class = (_temp = class ServiceInvoiceQueryModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "environment", _descriptor, this);

      _initializerDefineProperty(this, "flowStatus", _descriptor2, this);

      _initializerDefineProperty(this, "flowMessage", _descriptor3, this);

      _initializerDefineProperty(this, "status", _descriptor4, this);

      _initializerDefineProperty(this, "description", _descriptor5, this);

      _initializerDefineProperty(this, "rpsSerialNumber", _descriptor6, this);

      _initializerDefineProperty(this, "number", _descriptor7, this);

      _initializerDefineProperty(this, "rpsNumber", _descriptor8, this);

      _initializerDefineProperty(this, "servicesAmount", _descriptor9, this);

      _initializerDefineProperty(this, "issuedOn", _descriptor10, this);

      _initializerDefineProperty(this, "createdOn", _descriptor11, this);

      _initializerDefineProperty(this, "modifiedOn", _descriptor12, this);

      _initializerDefineProperty(this, "cancelledOn", _descriptor13, this);

      _initializerDefineProperty(this, "borrower", _descriptor14, this);
    }

    // Computed properties
    get isIssued() {
      return this.status === 'Issued';
    }

    get isCancelled() {
      return this.status === 'Cancelled';
    }

    get isCreated() {
      return this.status === 'Created';
    }

    get isInvoiceError() {
      return this.status === 'Error' || this.flowStatus === 'IssueFailed';
    }

    get hasPDF() {
      return this.status === 'Issued' || this.status === 'Cancelled';
    }

    get downloadFailed() {
      return this.status == 'Issued' && this.flowStatus == 'IssueFailed';
    }

    get generalStatus() {
      let result;

      switch (this.flowStatus) {
        case 'Issued':
          result = 'Issued';
          break;

        case 'IssueFailed':
          result = 'IssueFailed';
          break;

        case 'Cancelled':
          result = 'Cancelled';
          break;

        case 'CancelFailed':
          result = 'CancelFailed';
          break;

        case 'WaitingCalculateTaxes':
          result = 'WaitingCalculateTaxes';
          break;

        case 'WaitingDefineRpsNumber':
          result = 'WaitingDefineRpsNumber';
          break;

        case 'WaitingSend':
          result = 'WaitingSend';
          break;

        case 'WaitingSendCancel':
          result = 'WaitingSendCancel';
          break;

        case 'WaitingReturn':
          result = 'WaitingReturn';
          break;

        case 'WaitingDownload':
          result = 'WaitingDownload';
          break;
      }

      return result == 'IssueFailed' && this.status == 'Issued' ? 'DownloadFailed' : result;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flowStatus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flowMessage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "rpsSerialNumber", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "number", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rpsNumber", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "servicesAmount", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "issuedOn", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "createdOn", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "modifiedOn", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "cancelledOn", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "borrower", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isIssued", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "isIssued"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCancelled", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "isCancelled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCreated", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "isCreated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInvoiceError", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "isInvoiceError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasPDF", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "hasPDF"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadFailed", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "downloadFailed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generalStatus", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "generalStatus"), _class.prototype)), _class));
  _exports.default = ServiceInvoiceQueryModel;
  ;
});