define("nfe-customer-console-v2/components/state-taxes-loader/component", ["exports", "ember-concurrency", "nfe-customer-console-v2/models/enums"], function (_exports, _emberConcurrency, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    model: null,

    init() {
      this._super(...arguments);

      this.results = null;
    },

    didReceiveAttrs() {
      let params = {
        companyId: this.model.id
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      let data = yield this.store.query('state-tax', {
        company_id: params.companyId
      });

      if (data.length === 0) {
        let localData = this.store.peekAll('state-tax');
        data = localData.filterBy('companyId', params.companyId);
      }

      if (data.length === 1) {
        if (data.firstObject.specialTaxRegime) data.firstObject.set('specialTaxRegimeLabel', _enums.default.specialTaxRegimes.filter(v => v.code === data.firstObject.specialTaxRegime)[0].name);
        this.set("results", data);
      } else {
        this.set('results', data);
      }
    }).restartable()
  });

  _exports.default = _default;
});