define("nfe-customer-console-v2/models/sefaz-nfe-batch-blob", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    contentType: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    url: (0, _model.attr)('string')
  });

  _exports.default = _default;
});