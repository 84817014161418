define("nfe-customer-console-v2/mirage/fixtures/companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    name: "Company Test",
    tradeName: "",
    federalTaxNumber: '00000000000191',
    address: {
      country: "BRA",
      postalCode: "03385-005",
      street: "Rua teste",
      number: "123",
      additionalInformation: "",
      district: "Bairro teste",
      city: {
        code: "3550308",
        name: "São Paulo"
      },
      state: "SP"
    },
    taxRegime: "MicroempreendedorIndividual",
    specialTaxRegime: "Nenhum",
    legalNature: "EmpresaPublica",
    economicActivities: [],
    municipalTaxNumber: "",
    rpsSerialNumber: "IO",
    rpsNumber: 1,
    issRate: 0.0,
    environment: "Development",
    fiscalStatus: "Pending",
    certificate: {
      status: "Pending"
    },
    createdOn: "2018-11-06T17:43:28.6057732+00:00",
    modifiedOn: new Date()
  }];
  _exports.default = _default;
});