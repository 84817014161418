define("nfe-customer-console-v2/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i25qaltS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nfe-emptystate\",true],[10,\"data-title\",\"Ops!\"],[10,\"onClick\",\"location.assign('/dashboard')\"],[10,\"data-cloak\",\"\"],[8],[0,\"\\n    \"],[7,\"fragment\",true],[8],[0,\"\\n      A página que você tentou acessar está indisponível ou não existe.\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});