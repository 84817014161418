define("nfe-customer-console-v2/models/address-city", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    code: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    validations: {
      code: {
        presence: {
          message: 'não pode ser vazio'
        },
        length: {
          minimum: 4,
          messages: {
            tooShort: 'muito curto (minimo de 4 caracteres)'
          }
        }
      },
      name: {
        presence: {
          message: 'não pode ser vazio'
        },
        length: {
          minimum: 2,
          messages: {
            tooShort: 'muito curto (minimo de 2 caracteres)'
          }
        }
      }
    },
    serialize: function () {
      return this.getProperties(['code', 'name']);
    }
  });

  _exports.default = _default;
});