define("nfe-customer-console-v2/templates/companies-v2/edit/state-taxes/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ShpINLin",
    "block": "{\"symbols\":[\"loader\"],\"statements\":[[7,\"section\",true],[10,\"id\",\"company\"],[10,\"class\",\"layout-row-form\"],[8],[0,\"\\n    \"],[5,\"company-v2-loader\",[],[[\"@companyId\"],[[24,[\"model\",\"companyId\"]]]],{\"statements\":[[0,\"\\n        \"],[5,\"toolbar\",[],[[\"@title\",\"@subtitle\"],[[28,\"t\",[\"companies.edit.title\"],null],[28,\"if\",[[23,1,[\"isRunning\"]],\". . .\",[23,1,[\"data\",\"name\"]]],null]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n    \"],[5,\"md-card-form\",[],[[\"@titleText\",\"@returnButton\",\"@onSubmitClick\",\"@onCancelClick\"],[[28,\"t\",[\"companies.v2.new.stateTax.subTitle\"],null],[28,\"transition-to\",[\"companies-v2.edit\",[24,[\"model\",\"companyId\"]]],null],[28,\"perform\",[[24,[\"createStateTax\"]],[24,[\"model\"]]],null],[28,\"action\",[[23,0,[]],\"cancel\",[24,[\"model\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"state-tax-form\",[],[[\"@model\",\"@touched\"],[[22,\"model\"],[22,\"activateValidations\"]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/companies-v2/edit/state-taxes/new.hbs"
    }
  });

  _exports.default = _default;
});