define("nfe-customer-console-v2/routes/companies/index", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    sessionData: Ember.inject.service(),
    session: Ember.inject.service(),
    titleToken: "Empresas NFS-e",

    async model() {
      let currentAccount = await this.sessionData.getCurrentAccount();
      return {
        accountId: currentAccount.id
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', 1);
        controller.set('perPage', 10);
        controller.set('filterCompany', '');
      }
    }

  });

  _exports.default = _default;
});