define("nfe-customer-console-v2/models/usage-action-total", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    OK: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    BadRequest: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    Error: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    NotFound: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    TimedOut: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    Duplicated: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    Unavailable: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    a: Ember.observer("OK", "NotFound", "BadRequest", "Error", "TimedOut", "Unavailable", "Duplicated", function () {
      this.set('Errors', this.BadRequest + this.Error + this.TimedOut + this.Unavailable + this.Duplicated);
      this.set('Total', this.OK + this.NotFound + this.Errors);
    })
  });

  _exports.default = _default;
});