define("nfe-customer-console-v2/models/certificate-v2", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    companyId: (0, _model.attr)('string'),
    status: (0, _model.attr)('string', {
      defaultValue: "Pending"
    }),
    federalTaxNumber: (0, _model.attr)('number'),
    thumbprint: (0, _model.attr)('string'),
    validUntil: (0, _model.attr)('date'),
    modifiedOn: (0, _model.attr)('date'),
    isCertificateExpiring: Ember.computed('validUntil', function () {
      var now = new Date();
      now.setDate(now.getDate() + 30);
      return new Date(this.validUntil) < now;
    }),
    isCertificateExpired: Ember.computed('validUntil', function () {
      return new Date(this.validUntil) < new Date();
    }),
    isCertificateActive: Ember.computed('status', function () {
      return this.status === 'Active';
    }),
    isCertificatePending: Ember.computed.equal('status', 'Pending'),
    certificateStatusLabel: Ember.computed('isCertificateActive', 'isCertificatePending', 'isCertificateExpiring', 'isCertificateExpired', function () {
      if (this.isCertificatePending) {
        return this.intl.t(`companies.v2.enums.certificateStatuses.pending`);
      } else if (this.isCertificateExpired) {
        return this.intl.t(`companies.v2.enums.certificateStatuses.expired`);
      } else if (this.isCertificateExpiring) {
        return this.intl.t(`companies.v2.enums.certificateStatuses.expiring`);
      }

      return this.intl.t(`companies.v2.enums.certificateStatuses.active`);
    }),

    async uploadCertificate(file, password) {
      try {
        const adapter = this.store.adapterFor(this.constructor.modelName);
        let response = await adapter.uploadCertificate(this.companyId, file, password);
        response.body.certificate.id = this.id;
        return response;
      } catch (error) {
        return error;
      }
    }

  });

  _exports.default = _default;
});