define("nfe-customer-console-v2/helpers/format-cep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCep = formatCep;
  _exports.default = void 0;

  function formatCep([value]) {
    if (value == null) return;
    if (typeof value == 'string') return value;
    let cep = "00000000".substring(0, 8 - value.toString().length) + value.toString();
    return cep.substr(0, 5) + "-" + cep.substr(5, 3);
  }

  var _default = Ember.Helper.helper(formatCep);

  _exports.default = _default;
});