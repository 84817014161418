define("nfe-customer-console-v2/models/user", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    sub: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    locale: (0, _model.attr)('string'),
    family_name: (0, _model.attr)('string'),
    given_name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    email_verified: (0, _model.attr)('boolean'),
    phone_number: (0, _model.attr)('string'),
    phone_number_verified: (0, _model.attr)('boolean'),
    role: (0, _attributes.array)(),
    scope: (0, _attributes.array)()
  });

  _exports.default = _default;
});