define("nfe-customer-console-v2/controllers/companies/edit/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Encapsula logica para mostrar o lerta no titulo do painel de certificado.
    showCertificateAlert: Ember.computed('model', function () {
      return this.model.isCertificatePending || this.model.isCertificateExpired;
    }),
    actions: {
      // Tab action to prevent console error,
      // remove this action and the template action to see the error
      noop() {},

      prevent(ev) {
        ev.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});