define("nfe-customer-console-v2/routes/companies-v2/edit/certificate-v2", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    titleToken: "Certificado digital da empresa",

    async model(params) {
      let model = {};

      try {
        const certificates = await this.store.query('certificate-v2', {
          company_id: params.id,
          active: true
        });
        model = certificates.find(item => item.status === "Active");
        if (Ember.isEmpty(model)) model = this.store.createRecord('certificate-v2');
      } catch (er) {
        // We should have a better error handler for this
        if (er.code === 404) {
          model = this.store.createRecord('certificate-v2');
        }
      } // set the company id (parent)


      model.set('companyId', params.id);
      return model;
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});