define("nfe-customer-console-v2/components/pix-payment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qTCI+ZFf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"class\",\"pix-logo\"],[10,\"src\",\"/assets/images/pix-banco-central-logo.svg\"],[10,\"alt\",\"Logo Pix\"],[8],[9],[0,\"\\n\"],[7,\"img\",true],[10,\"class\",\"qr-code\"],[11,\"src\",[24,[\"data\",\"url\"]]],[10,\"alt\",\"Pix QR Code\"],[8],[9],[0,\"\\n\"],[7,\"p\",true],[8],[1,[28,\"t\",[\"payment.pixModal.text\"],null],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/pix-payment/template.hbs"
    }
  });

  _exports.default = _default;
});