define("nfe-customer-console-v2/components/paper-button-progress/component", ["exports", "ember-invoke-action", "nfe-customer-console-v2/components/paper-button-progress/template", "ember-paper/components/paper-button"], function (_exports, _emberInvokeAction, _template, _paperButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperButton.default.extend({
    layout: _template.default,
    isLoading: false,
    disabled: Ember.computed.or('isLoading', 'isDisabled'),
    observablePromise: null,

    click(e) {
      let params = Ember.getWithDefault(this, 'params', []);

      let callbackHandler = promise => Ember.run(() => this.set('observablePromise', promise));

      if (typeof this.onClick === 'function') {
        let promise = (0, _emberInvokeAction.invokeAction)(this, 'onClick', e);

        if (promise && typeof promise.then === 'function') {
          callbackHandler(promise);
        }
      } else {
        let actionArguments = ['onClick', callbackHandler, ...params];
        this.sendAction(...actionArguments);
      } // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling


      return false;
    },

    handleActionPromise: Ember.observer('observablePromise', function () {
      let promise = this.observablePromise;
      if (!promise) return;
      this.set('isLoading', true);
      promise.then(() => {
        if (!this.isDestroyed) {
          this.set('isLoading', false);
          this.set('textState', 'fulfilled');
        }
      }).catch(() => {
        if (!this.isDestroyed) {
          this.set('isLoading', false);
          this.set('textState', 'rejected');
        }
      });
    })
  });

  _exports.default = _default;
});