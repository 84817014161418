define("nfe-customer-console-v2/components/companies-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    provider: Ember.inject.service(),
    tagName: '',
    // attributes from template
    page: '',
    perPage: '',
    accountId: '',
    searchTerm: '',
    // set default value to attributes to template
    results: null,
    hasOneCompany: false,
    hasMultipleCompanies: false,
    companyId: false,
    isEmpty: Ember.computed('results', function () {
      return Ember.isEmpty(this.results);
    }),
    isEmptyAndNotRunning: Ember.computed('results', function () {
      return Ember.isEmpty(this.results) && this.get('fetchData.isRunning') == false && this.page <= 1 && this.searchTerm == '';
    }),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const params = {
        accountId: this.accountId || this.account.id,
        page: this.page,
        perPage: this.perPage,
        searchTerm: this.searchTerm
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* ({
      accountId,
      page,
      perPage,
      searchTerm
    }) {
      if (Ember.isBlank(accountId)) return;
      let data = yield this.store.query('company', {
        accountId,
        pageIndex: page,
        pageCount: perPage,
        search: searchTerm
      });
      return this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});