define("nfe-customer-console-v2/controllers/consults/natural-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.inject.service(),
    shouldHideApikey: Ember.computed('model.environment', {
      get() {
        if (this.user.isAdmin) return false;
        return this.model.account.environment == "Development";
      }

    }),
    actions: {
      getApikeys(key) {
        return this.shouldHideApikey ? null : key.description.includes("Dados");
      },

      prevent(ev) {
        ev.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});