define("nfe-customer-console-v2/mirage/factories/product-invoice", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    serie: 50,
    number: 2,
    status: "Cancelled",
    operationType: "Outgoing",
    environmentType: "Test",
    purposeType: "Normal",
    issuer: {
      taxRegime: "LucroReal",
      address: {
        city: {
          name: "Embu das Artes",
          code: "3515004"
        },
        state: "SP",
        district: "Vila Bonfim",
        additionalInformation: "Mznino 2 piso 1",
        street: "Avenida Isaltino Victor de Moraes",
        number: "437",
        postalCode: "06806400",
        country: "BRA"
      },
      type: "LegalEntity",
      specialTaxRegime: "Automatico",
      regionalTaxNumber: 298278281119,
      id: "17854d55df8e40709195839aa58ac93e",
      name: "NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL",
      federalTaxNumber: 19364681000103
    },
    buyer: {
      stateTaxNumberIndicator: "None",
      taxRegime: "None",
      address: {
        city: {
          name: "jundiai",
          code: "3550308"
        },
        state: "SP",
        district: "centro",
        street: "rua petronilha antunes",
        number: "204",
        postalCode: "13207760",
        country: "BRA"
      },
      type: "Undefined",
      id: "bad68cd476a84431bcc368454eeeda6f",
      name: "NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL",
      federalTaxNumber: 8662968678
    },
    totals: {
      icms: {
        federalTaxesAmount: 6.0,
        invoiceAmount: 49.900,
        productAmount: 49.900,
        fcpstRetAmount: 0.0,
        fcpstAmount: 0.0,
        fcpufDestinationAmount: 0.0,
        othersAmount: 0.0,
        cofinsAmount: 0.0,
        pisAmount: 0.0,
        ipiAmount: 0.0,
        iiAmount: 0.0,
        discountAmount: 0.0,
        insuranceAmount: 0.0,
        freightAmount: 0.0,
        stAmount: 0.0,
        stCalculationBasisAmount: 0.0,
        icmsExemptAmount: 0.0,
        icmsAmount: 6.0,
        baseTax: 33.25
      }
    },
    transport: {
      freightModality: "Free"
    },
    payment: [{
      paymentDetail: [{
        amount: 49.900,
        method: "Others"
      }]
    }],
    lastEvents: {
      hasMore: true,
      events: [{
        sequence: 77,
        data: {
          createdOn: "2019-11-13T18:23:23.3850455Z",
          webhookDispatches: [{
            webHookId: "070485c287b14a16b19c344fde53e3d0",
            id: "8103c3fd3dc146f79f8e607f3026ad48"
          }, {
            webHookId: "586b5f4c91fa419384409e7eefa3d73d",
            id: "962721c47fe44cb4a6606f6daf5b9d59"
          }, {
            webHookId: "5f52a4c7c4b043979f5ac9fe44c95a50",
            id: "c7ea22687ee1434abd499d34295ebb10"
          }, {
            webHookId: "86b6860b45f2430998318ff4ba348eda",
            id: "38039512e42f480c82cb4867749fe52f"
          }, {
            webHookId: "9189e5066d7a4ef18e914c04e4b6e1ad",
            id: "63524ef0e5d341b99042debb4e9cf6f5"
          }, {
            webHookId: "96c3b117714e45dfb5805adc9486652f",
            id: "b8c03734d91345b8bf2282c5f60e0c37"
          }],
          action: "product_invoice.cancelled_successfully"
        },
        type: "WebHooksDispatched"
      }, {
        sequence: 76,
        data: {
          createdOn: "2019-11-13T18:23:20.2964395Z",
          contentType: "text/xml",
          uri: "https://nfeprodproductinvoice.blob.core.windows.net/19364681000103/dev/19/11/cancel/nfe/35191119364681000103550500000000021741139010-procCancNFe.xml"
        },
        type: "MergedCancellation"
      }, {
        sequence: 75,
        data: {
          createdOn: "2019-11-13T18:23:19.7873885Z",
          receiptOn: "2019-11-13T18:23:19Z",
          environmentType: "Test",
          eventDescription: "Cancelamento registrado",
          message: "Evento registrado e vinculado a NF-e",
          protocolNumber: "135190008177008",
          accessKey: "35191119364681000103550500000000021741139010"
        },
        type: "Cancelled"
      }, {
        sequence: 74,
        data: {
          createdOn: "2019-11-13T18:23:17.6912394Z",
          contentType: "text/xml",
          uri: "https://nfeprodproductinvoice.blob.core.windows.net/19364681000103/dev/19/11/lote/caneve/nfe/1-caneve-ret.xml"
        },
        type: "CancelXmlSigned"
      }, {
        sequence: 73,
        data: {
          createdOn: "2019-11-13T18:23:16.4219781Z",
          eventBatchId: 1
        },
        type: "DefinedEventBatchSuccessfully"
      }, {
        sequence: 72,
        data: {
          createdOn: "2019-11-13T18:16:48.2498816Z",
          webhookDispatches: [{
            webHookId: "070485c287b14a16b19c344fde53e3d0",
            id: "e0c72c66bab94f2585e14fac9b51e6dd"
          }, {
            webHookId: "586b5f4c91fa419384409e7eefa3d73d",
            id: "1ff584bfd8b4438fa4bd3f0ee8e941d0"
          }, {
            webHookId: "5f52a4c7c4b043979f5ac9fe44c95a50",
            id: "afd497f32f014d30a6992f44578f1eca"
          }, {
            webHookId: "86b6860b45f2430998318ff4ba348eda",
            id: "c91e7f1998604ce58eb7deb5ca984ac9"
          }, {
            webHookId: "9189e5066d7a4ef18e914c04e4b6e1ad",
            id: "33aa9b7504e04eb0bd673c46f8a20192"
          }, {
            webHookId: "96c3b117714e45dfb5805adc9486652f",
            id: "47e03f836efe485f971d18b3265ff785"
          }],
          action: "product_invoice.issued_successfully"
        },
        type: "WebHooksDispatched"
      }, {
        sequence: 71,
        data: {
          createdOn: "2019-11-13T18:16:47.0688370Z",
          contentType: "text/xml",
          uri: "https://nfeprodproductinvoice.blob.core.windows.net/19364681000103/dev/19/11/35191119364681000103550500000000021741139010-nfe.xml"
        },
        type: "Merged"
      }, {
        sequence: 70,
        data: {
          createdOn: "2019-11-13T18:16:46.4674113Z",
          statusCode: 100,
          environmentType: "Test",
          validatorDigit: "JJevA+2Z7O8nKgmj9UBU22VxTPU=",
          protocolNumber: "135190008176878",
          description: "Autorizado o uso da NF-e",
          applicationVersion: "SP_NFE_PL009_V4",
          accessKey: "35191119364681000103550500000000021741139010"
        },
        type: "Authorized"
      }, {
        sequence: 69,
        data: {
          createdOn: "2019-11-13T18:16:44.4849909Z",
          status: 103,
          receiptNumber: "351000135745340",
          message: "Lote recebido com sucesso"
        },
        type: "SentSignedBatchSuccess"
      }, {
        sequence: 68,
        data: {
          createdOn: "2019-11-13T18:16:42.2543079Z",
          contentType: "text/xml",
          uri: "https://nfeprodproductinvoice.blob.core.windows.net/19364681000103/dev/19/11/lote/2-env-lot.xml"
        },
        type: "InvoiceXmlSigned"
      }]
    },
    id: "2d9723f54d23442580a1d0896a48f260",
    operationNature: "Venda de mercadorias",
    createdOn: "0001-01-01T00:00:00Z",
    modifiedOn: "0001-01-01T00:00:00Z"
  });

  _exports.default = _default;
});