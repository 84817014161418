define("nfe-customer-console-v2/components/paper-data-table-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "srBRWRp/",
    "block": "{\"symbols\":[\"menu\",\"content\",\"item\"],\"statements\":[[5,\"paper-menu\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"trigger\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"md-select-value\",true],[10,\"id\",\"ember1206\"],[10,\"class\",\"md-select-value ember-view\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-selected-item md-menu-origin\"],[8],[0,\"\\n        \"],[1,[22,\"selected\"],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"\\n        \"],[5,\"paper-icon\",[],[[\"@icon\"],[\"arrow_drop_down\"]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"onChange\"]]],null,{\"statements\":[[0,\"        \"],[6,[23,2,[\"menu-item\"]],[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[24,[\"onChange\"]],[23,3,[]]],null]]],{\"statements\":[[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"md-menu-align-target\"],[8],[0,\"\\n            \"],[1,[23,3,[]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,[23,2,[\"menu-item\"]],[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selected\"]]],null],[23,3,[]]],null]]],{\"statements\":[[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"md-menu-align-target\"],[8],[0,\"\\n            \"],[1,[23,3,[]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[3]},null],[0,\"  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/paper-data-table-select/template.hbs"
    }
  });

  _exports.default = _default;
});