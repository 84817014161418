define("nfe-customer-console-v2/routes/companies/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: "Cadastrar empresa NFS-e",

    model() {
      return this.store.createRecord('company', {
        address: this.store.createRecord('address', {
          city: this.store.createRecord('address-city')
        })
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
        controller.setProperties({
          activateCompanyValidations: false,
          activateCertificateValidations: false,
          activatePasswordValidations: false,
          certificatePassword: null,
          certificate: null,
          file: null,
          currentStep: 0,
          companyId: null,
          isCompanyCreated: false,
          isCertificateSent: false,
          isCertificateSkiped: false,
          onboarding: false
        });
      }
    }

  });

  _exports.default = _default;
});