define("nfe-customer-console-v2/serializers/company-v2", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.companies) {
        payload.companies.map(item => {
          if (item.federalTaxNumber) {
            item.federalTaxNumber = item.federalTaxNumber.toString().padStart(14, '0');
          }

          return item;
        });
        payload = {
          companyV2s: payload.companies,
          meta: {
            hasMore: payload.hasMore
          }
        };
      }

      if (payload.company) {
        payload.company.federalTaxNumber = payload.company.federalTaxNumber.toString().padStart(14, '0');
        payload = {
          companyV2: payload.company
        };
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    payloadKeyFromModelName(modelName) {
      return 'company';
    },

    serialize(snapshot, options) {
      let json = this._super(...arguments);

      json.federalTaxNumber = parseInt(json.federalTaxNumber);
      return json;
    }

  });

  _exports.default = _default;
});