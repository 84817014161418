define("nfe-customer-console-v2/services/azure-blob-storage", ["exports", "nfe-customer-console-v2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    containerHost: _environment.default.APP.AZURE_BLOB_CONTAINER_HOST,
    containerSasToken: _environment.default.APP.AZURE_BLOB_CONTAINER_SAS_TOKEN,

    createBlobService() {
      return AzureStorage.Blob.createBlobServiceWithSas(this.containerHost, this.containerSasToken);
    },

    createStorageBlob(containerName, blobName, value) {
      let blobService = this.createBlobService();
      return new Promise((resolve, reject) => {
        blobService.createBlockBlobFromText(containerName, blobName, value, (err, blobResult) => {
          if (err) reject(err);
          resolve(blobResult);
        });
      });
    }

  });

  _exports.default = _default;
});