define("nfe-customer-console-v2/adapters/hook", ["exports", "ember-data-url-templates", "nfe-customer-console-v2/adapters/base-rest"], function (_exports, _emberDataUrlTemplates, _baseRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class HookAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryUrlTemplate", '{+host}/accounts/{accountId}/hooks');

      _defineProperty(this, "queryRecordUrlTemplate", '{+host}/accounts/{accountId}/hooks/{hookId}');

      _defineProperty(this, "createRecordUrlTemplate", '{+host}/accounts/{accountId}/hooks');

      _defineProperty(this, "updateRecordUrlTemplate", '{+host}/accounts/{accountId}/hooks/{hookId}');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/accounts/{accountId}/hooks/{hookId}');

      _defineProperty(this, "urlSegments", {
        host() {
          return this.get('host');
        },

        accountId(type, id, snapshot, query) {
          let accountId;

          if (query) {
            accountId = query.accountId;
          } else if (snapshot && snapshot.adapterOptions) {
            accountId = snapshot.adapterOptions.accountId;
          } else {
            accountId = snapshot.accountId;
          }

          return accountId;
        },

        hookId(type, id, snapshot, query) {
          let hookId;

          if (query) {
            hookId = query.hookId;
          } else if (snapshot && snapshot.id) {
            hookId = snapshot.id;
          } else {
            hookId = snapshot.hookId;
          }

          return hookId;
        }

      });
    }

    ajaxOptions(url, type, options) {
      let hash = super.ajaxOptions(...arguments);

      if (type === "DELETE" && url.includes("/hooks")) {
        hash.dataType = "text";
      }

      return hash;
    }

  }

  _exports.default = HookAdapter;
});