define("nfe-customer-console-v2/components/webhooks-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform(this.accountId);
    },

    fetchData: (0, _emberConcurrency.task)(function* (accountId) {
      this.store.unloadAll('hook-v2');
      let hooksV1 = yield this.store.query("hook", {
        accountId
      });
      let hooksV2 = yield this.store.findAll("hook-v2");
      let webHooks = Ember.A(hooksV1.toArray().concat(hooksV2.toArray()));
      return this.set('results', webHooks);
    }).restartable()
  });

  _exports.default = _default;
});