define("nfe-customer-console-v2/controllers/companies-v2/new", ["exports", "ember-concurrency", "ember-cp-validations"], function (_exports, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    certificate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione um certificado digital.',
      disabled: Ember.computed.empty('model.certificatePassword')
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /.*\.(pfx|p12)$/gi,
      message: 'Selecione um certificado digital válido.'
    })],
    certificatePassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Digite a senha do certificado.',
      disabled: Ember.computed.empty('model.certificate')
    })]
  });

  var _default = Ember.Controller.extend(validations, {
    notify: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    activateCompanyValidations: false,
    activateStateTaxValidations: false,
    activateCertificateValidations: false,
    activatePasswordValidations: false,
    certificatePassword: null,
    certificate: null,
    file: null,
    hasCertificate: Ember.computed.and("certificate", "certificatePassword"),
    cteChecked: Ember.computed.and("model.cteDist", "model.cteDist.checked"),
    nfeChecked: Ember.computed.and("model.nfeDist", "model.nfeDist.checked"),
    isCompanyCreated: false,
    isStateTaxCreated: false,
    isCertificateSent: false,
    isCertificateSkiped: false,
    isDfeParamsSkiped: false,
    isDfeParamsSent: false,
    createReadyCompany: (0, _emberConcurrency.task)(function* (model, goTo) {
      const {
        company,
        stateTax,
        cteDist,
        nfeDist
      } = model;

      if (!this.isCompanyCreated) {
        yield this.saveCompany(company, goTo);
      }

      if (this.isCompanyCreated && !this.isStateTaxCreated) {
        yield this.saveStateTax(stateTax, goTo);
      }

      if (this.isStateTaxCreated && !this.isCertificateSent && this.hasCertificate) {
        yield this.saveCertificate(company.id, this.file, this.certificatePassword, goTo);
      }

      if (this.isStateTaxCreated && !this.isDfeParamsSent && (this.cteChecked || this.nfeChecked)) {
        yield this.saveDfeParams(company.id, cteDist, nfeDist, goTo);
      }

      if (this.isCompanyCreated && this.isStateTaxCreated && (this.isCertificateSent || this.isCertificateSkiped) && (this.isDfeParamsSent || this.isDfeParamsSkiped || !this.cteChecked || !this.nfeChecked)) {
        this.notify.success('Empresa criada com sucesso!');
        const currentAccount = yield this.sessionData.getCurrentAccount();
        company.set("apikey", currentAccount.apiKey);
        this.transitionToRoute('companies-v2.edit', company);
      }
    }).drop(),

    async saveCompany(company, goTo) {
      if (Ember.isEmpty(company.address.country)) company.set('address.country', 'BRA');

      try {
        let result = await company.save();
        this.set('model.stateTax.companyId', result.id);
        this.set('isCompanyCreated', true);
        return result;
      } catch (error) {
        goTo(0);
        this.notify.error("Erro ao criar empresa");
        return error;
      }
    },

    async saveStateTax(stateTax, goTo) {
      try {
        let result = await stateTax.save();
        this.set('isStateTaxCreated', true);
        return result;
      } catch (error) {
        let err = error.errors[0].message;
        let message = "Erro ao criar inscrição estadual";
        if (err == "tax number is not valid") message = "Número da inscrição estadual inválida";
        goTo(1);
        this.notify.error(message);
        return error;
      }
    },

    async saveCertificate(companyId, file, password, goTo) {
      try {
        let result = this.store.adapterFor('certificate-v2').uploadCertificate(companyId, file, password);
        this.set('isCertificateSent', true);
        return result;
      } catch (error) {
        let err = error.body.errors[0].message;
        let message = "Erro ao enviar o certificado";
        if (err == "certificate password is not valid") message = "Senha do certificado inválida";
        if (err.includes("certificate is no longer valid")) message = "O certificado está vencido";
        goTo(2);
        this.notify.error(message);
        return error;
      }
    },

    async saveDfeParams(companyId, cteDist, nfeDist, goTo) {
      try {
        //send request only if cte is not active
        if (cteDist && cteDist.checked && !this.isCteDistActive) {
          let response = await cteDist.save({
            adapterOptions: {
              companyId
            }
          });
          this.set('isCteDistActive', response.status === "Active");
        } //send request only if nfe is not active


        if (nfeDist && nfeDist.checked && !this.isNfeDistActive) {
          let response = await nfeDist.save({
            adapterOptions: {
              companyId
            }
          });
          this.set('isNfeDistActive', response.status === "Active");
        }

        this.set('isDfeParamsSent', true);
      } catch (error) {
        goTo(3);
        this.notify.error("Erro ao enviar dados para consulta do DF-e distribuição");
      }
    },

    actions: {
      submitCompany({
        company,
        stateTax
      }, nextStep) {
        if (company.get('isValid')) {
          stateTax.set("code", company.address.state);
          return nextStep();
        } else {
          this.set('activateCompanyValidations', true);
        }
      },

      submitStateTax(model, nextStep) {
        if (model.get('isValid')) {
          return nextStep();
        } else {
          this.set('activateStateTaxValidations', true);
        }
      },

      skipCertificate(nextStep) {
        this.set('isCertificateSkiped', true);
        return nextStep();
      },

      submitCertificate(nextStep) {
        if (this.get('validations.isValid')) {
          return nextStep();
        } else {
          this.set('activatePasswordValidations', true);
          this.set('activateCertificateValidations', true);
        }
      },

      uploadCertificate(file) {
        this.set('file', file);
        this.set('certificate', file.name);
        this.set('activateCertificateValidations', true);
      },

      skipDfeParams(nextStep) {
        this.set('isDfeParamsSkiped', true);
        return nextStep();
      },

      submitDfeParams({
        cteDist,
        nfeDist
      }, nextStep) {
        const cteIsChecked = cteDist && cteDist.checked;
        const nfeIsChecked = nfeDist && nfeDist.checked;
        const cteDistisInvalid = cteIsChecked && cteDist.get('validations.isInvalid');
        const nfeDistisInvalid = nfeIsChecked && nfeDist.get('validations.isInvalid');

        if (cteDistisInvalid) {
          return this.set('activateCteValidations', true);
        }

        if (nfeDistisInvalid) {
          return this.set('activateNfeValidations', true);
        }

        return nextStep();
      }

    }
  });

  _exports.default = _default;
});