define("nfe-customer-console-v2/controllers/companies-v2/edit/nfe-dist", ["exports", "ember-concurrency", "nfe-customer-console-v2/models/enums"], function (_exports, _emberConcurrency, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    activateNfeValidations: false,
    minDate: Ember.computed('', function () {
      let date = new Date();
      date.setDate(date.getDate() - 89);
      return date;
    }),
    currentDate: new Date(),
    waitingTimes: _enums.default.nfeDistWaitingTimes,
    selectedWaitingTime: Ember.computed('model.nfe.automaticManifesting.minutesToWaitAwarenessOperation', function () {
      const item = this.model.nfe.automaticManifesting.minutesToWaitAwarenessOperation;

      if (item) {
        const waitingTime = this.waitingTimes.filter(time => time.code === item || time.name === item)[0];
        this.model.nfe.automaticManifesting.set('minutesToWaitAwarenessOperation', waitingTime.name);
        return waitingTime;
      }

      return item;
    }),
    submitNfeParams: (0, _emberConcurrency.task)(function* (companyId, nfe) {
      const hasCertificateActive = yield this.checkCertificate(companyId);

      if (!hasCertificateActive) {
        return this.notify.error(this.intl.t("companies.v2.edit.nfe.submit.certificateError"));
      }

      try {
        yield nfe.save({
          adapterOptions: {
            companyId
          }
        });
        this.notify.success(this.intl.t("companies.v2.edit.nfe.submit.success"));
        this.set('developmentAccountModal', false);
      } catch (error) {
        this.notify.error(this.intl.t("companies.v2.edit.nfe.submit.error"));
      }
    }),
    deleteNfeParams: (0, _emberConcurrency.task)(function* (companyId, nfe) {
      try {
        yield nfe.destroyRecord({
          adapterOptions: {
            companyId
          }
        });
        this.notify.success(this.intl.t("companies.v2.edit.nfe.delete.success"));
        this.set('disableNfeModal', false);
        Ember.run.later(this, function () {
          location.reload();
        }, 750);
      } catch (error) {
        this.notify.error(this.intl.t("companies.v2.edit.nfe.delete.error"));
      }
    }),
    actions: {
      handleSelectDate(model, date) {
        if (!date) return;
        const dates = date.split('/');
        model.set('startFromDate', new Date(`${dates[2]}-${dates[1]}-${dates[0]}T00:00:00`).toISOString());
      },

      handleManifestAwareness() {
        if (this.model.nfe.automaticManifestingActive) {
          this.set('model.nfe.automaticManifestingActive', false);
        } else {
          this.set('manifestAwarenessModal', true);
        }
      },

      activateManifestAwareness() {
        this.set('model.nfe.automaticManifestingActive', true);
        this.set('manifestAwarenessModal', false);
      }

    },

    async checkCertificate(companyId) {
      try {
        const certificates = await this.store.query('certificate-v2', {
          company_id: companyId,
          active: true
        });
        return certificates.length > 0 && certificates.toArray().some(item => item.status === "Active");
      } catch (error) {
        console.log(error);
      }
    }

  });

  _exports.default = _default;
});