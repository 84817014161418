define("nfe-customer-console-v2/controllers/companies-v2/edit/apikeys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onSuccess() {
        document.execCommand('copy');
      },

      onError() {
        console.log('Não pode ser copiado');
      }

    }
  });

  _exports.default = _default;
});