define("nfe-customer-console-v2/controllers/companies-v2/edit/state-taxes/new", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    createStateTax: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateValidations', true);
      if (model.isValid == false) return;

      try {
        yield model.save();
        this.notify.success('Inscrição estadual criada com sucesso!');
        this.transitionToRoute('companies-v2.edit.state-taxes', model.companyId);
      } catch (error) {
        this.notify.error('Erro ao criar a inscrição estadual!');
      }
    }),
    actions: {
      cancel(model) {
        this.transitionToRoute('companies-v2.edit.state-taxes', model.companyId);
        model.deleteRecord();
      }

    }
  });

  _exports.default = _default;
});