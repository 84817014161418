define("nfe-customer-console-v2/services/onboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    hasCompanyCreated: false,
    hasCertificateActive: false,
    companyId: null,

    async getServiceOnboardInfo(companyId, allCompanies) {
      let account = await this.sessionData.getCurrentAccount();

      if (companyId) {
        this.set('hasCompanyCreated', true);
        let company = await this.store.queryRecord('company', {
          accountId: account.id,
          companyId
        });

        if (company.isCertificateActive) {
          this.set('hasCertificateActive', true);
        }
      } else {
        let companies = allCompanies || (await this.store.query('company', {
          accountId: account.id
        }));

        if (companies.length > 0) {
          this.set('hasCompanyCreated', true);
          this.set('companyId', companies.firstObject.id);

          if (companies.firstObject.isCertificateActive) {
            this.set('hasCertificateActive', true);
          }
        }
      }
    },

    //ONBOARD TEST SERVICE INVOICE ISSUE
    issueProcessSteps: Ember.A([{
      invoiceStatus: 'SendingToNfeio',
      status: "waiting",
      task: "issueServiceInvoice",
      count: 0,
      title: 'Enviando para o sistema da NFE.io'
    }, {
      invoiceStatus: 'WaitingCalculateTaxes',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Calculando impostos',
      message: 'onboard.serviceInvoice.issueStepper.waiting.message3.waitingCalculateTaxes'
    }, {
      invoiceStatus: 'WaitingDefineRpsNumber',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Definindo número de RPS'
    }, {
      invoiceStatus: 'WaitingSend',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Enviando para o sistema da prefeitura',
      message: 'onboard.serviceInvoice.issueStepper.waiting.message3.waitingSend'
    }, {
      invoiceStatus: 'WaitingReturn',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Aguardando retorno do sistema da prefeitura',
      message: 'onboard.serviceInvoice.issueStepper.waiting.message3.waitingReturn'
    }, {
      invoiceStatus: 'WaitingDownload',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Aguardando download da nota',
      message: 'onboard.serviceInvoice.issueStepper.waiting.message3.waitingDownload'
    }]),
    cancelProcessSteps: Ember.A([{
      invoiceStatus: 'SendingToNfeio',
      status: "waiting",
      task: "cancelServiceInvoice",
      count: 0,
      title: 'Enviando solicitação para o sistema da NFE.io'
    }, {
      invoiceStatus: 'WaitingSendCancel',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Enviando cancelamento para o sistema da prefeitura',
      message: 'onboard.serviceInvoice.issueStepper.waiting.message3.waitingSend'
    }, {
      invoiceStatus: 'WaitingReturn',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Aguardando retorno do sistema da prefeitura',
      message: 'onboard.serviceInvoice.issueStepper.waiting.message3.waitingReturn'
    }, {
      invoiceStatus: 'WaitingDownload',
      status: "waiting",
      task: "checkServiceInvoiceStatus",
      count: 0,
      title: 'Aguardando download da nota',
      message: 'onboard.serviceInvoice.issueStepper.waiting.message3.waitingDownload'
    }]),

    createServiceInvoiceToTest(companyEnvironment, tax) {
      return {
        borrower: {
          federalTaxNumber: "18792479000101",
          name: "NFE.io",
          address: {
            country: "BRA",
            postalCode: "05411-000",
            street: "Rua Cristiano Viana",
            number: "517",
            district: "Pinheiros",
            city: {
              name: "São Paulo",
              code: "3550308"
            },
            state: "SP"
          }
        },
        description: `Teste de emissão de NFSe em ambiente de ${companyEnvironment === "Production" ? "produção" : "teste"}.\n\n${tax.cityService.description}`,
        servicesAmount: 0.1,
        cityServiceCode: tax.cityService.code
      };
    },

    resetSteps(steps) {
      steps.forEach(step => {
        Ember.set(step, "status", "waiting");
        Ember.set(step, "count", 0);
      });
    }

  });

  _exports.default = _default;
});