define("nfe-customer-console-v2/serializers/invoice-provider", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InvoiceProviderSerializer extends _rest.default.extend() {
    normalize(typeClass, hash) {
      if (hash && hash.municipalTaxNumber && hash.municipalTaxNumber.includes(':')) hash.municipalTaxNumber = hash.municipalTaxNumber.split(':')[0];
      return super.normalize(typeClass, hash);
    }

  }

  _exports.default = InvoiceProviderSerializer;
});