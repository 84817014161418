define("nfe-customer-console-v2/templates/companies/edit/taxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NSWbczIp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"layout-row-table taxes-container\"],[8],[0,\"\\n  \"],[5,\"toolbar\",[],[[\"@title\",\"@subtitle\"],[[28,\"t\",[\"companies.edit.title\"],null],[24,[\"model\",\"name\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"service-invoice-actions-button\",[],[[\"@companyId\",\"@defaultActions\"],[[24,[\"model\",\"id\"]],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \\n  \"],[5,\"md-card-container\",[],[[\"@headline\",\"@returnButton\",\"@buttons\"],[[28,\"t\",[\"companies.edit.taxes.title\"],[[\"city\",\"state\",\"htmlSafe\"],[[24,[\"model\",\"address\",\"city\",\"name\"]],[24,[\"model\",\"address\",\"state\"]],true]]],[28,\"transition-to\",[\"companies.edit\",[24,[\"model\",\"id\"]]],null],[28,\"array\",[[28,\"hash\",null,[[\"label\",\"action\",\"icon\"],[[28,\"t\",[\"companies.edit.taxes.list.newServiceDialog.newBtn\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showRegisterTaxForm\"]],true],null]],null],\"add\"]]]],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"list-taxes\",[],[[\"@company\",\"@cityCode\",\"@filterTax\",\"@perPage\",\"@showRegisterTaxForm\",\"@pagination\"],[[22,\"model\"],[24,[\"model\",\"address\",\"city\",\"code\"]],[22,\"filterTax\"],[22,\"limit\"],[22,\"showRegisterTaxForm\"],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/companies/edit/taxes.hbs"
    }
  });

  _exports.default = _default;
});