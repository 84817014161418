define("nfe-customer-console-v2/components/component-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    user: Ember.inject.service(),
    header: Ember.inject.service("mount-header"),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    subscription: Ember.inject.service(),

    didReceiveAttrs() {
      this.header.mountHeader();
    },

    subscriptions: Ember.computed('subscription.items', function () {
      return this.get("subscription.items").length ? this.get("subscription.items") : [];
    }),
    accounts: Ember.computed("session.data.authenticated.accounts", function () {
      return this.get("session.data.authenticated.accounts").length ? this.get("session.data.authenticated.accounts") : [];
    }),
    currentAccount: Ember.computed("session.data.authenticated.current_account", function () {
      return this.get("session.data.authenticated.current_account");
    }),
    currentAccountImpersonate: Ember.computed("session.data.authenticated.current_account_impersonate", function () {
      return this.get("session.data.authenticated.current_account_impersonate");
    }),
    hasMultipleSubscriptions: Ember.computed("session.data.authenticated.accounts", async function () {
      await this.subscription.load(this.accounts);
      return this.subscription.items.length > 1;
    }),
    currentActiveAccountId: Ember.computed("currentAccountImpersonate", "currentAccount", function () {
      return this.currentAccountImpersonate ? this.currentAccountImpersonate.id : this.currentAccount.id;
    }),
    isCompaniesNavActive: Ember.computed("router.currentURL", function () {
      return this.router.currentURL.includes("companies");
    }),
    isConsultsNavActive: Ember.computed("router.currentURL", function () {
      let {
        currentURL
      } = this.router;
      return currentURL.includes("consults") || currentURL.includes("nfe");
    }),
    actions: {
      sendToServiceCodeRequestForm() {
        const {
          name,
          email
        } = this.currentAccount;
        const url = `https://p.nfe.io/pt-br/cadastrar-codigo-servico?firstname=${name}&email=${email}`;
        window.open(url);
      }

    }
  });

  _exports.default = _default;
});