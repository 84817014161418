define("nfe-customer-console-v2/components/product-invoices-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    companyId: null,
    environment: null,
    limit: null,
    startingAfter: null,
    endingBefore: null,

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const params = {
        companyId: this.companyId,
        environment: this.environment,
        limit: this.limit,
        startingAfter: this.startingAfter,
        endingBefore: this.endingBefore,
        q: this.elasticSearch
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params.companyId)) return;
      const data = yield this.store.query('product-invoice', params);
      return this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});