define("nfe-customer-console-v2/components/nfe-query-batch-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    batchAccessKeysClient: Ember.inject.service(),
    notification: Ember.inject.service(),
    tagName: '',
    model: null,
    results: null,
    limit: '',
    startingAfter: '',
    endingBefore: '',
    isEmpty: Ember.computed('results', function () {
      return Ember.isEmpty(this.results);
    }),

    didReceiveAttrs() {
      const params = {
        limit: this.limit,
        startingAfter: this.startingAfter,
        endingBefore: this.endingBefore
      };
      return this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      const data = yield this.batchAccessKeysClient.getBatches(params);
      return this.set('results', data);
    })
  });

  _exports.default = _default;
});