define("nfe-customer-console-v2/components/exports-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform(this.accountId, this.subscriptionId);
    },

    parsePrefix(id, prefix) {
      return prefix + id.replace(prefix, '');
    },

    fetchData: (0, _emberConcurrency.task)(function* (accountId, subscriptionId) {
      if (Ember.isBlank(accountId)) return;
      accountId = this.parsePrefix(accountId, 'acc_');
      subscriptionId = this.parsePrefix(subscriptionId, 'sub_');
      const exports = yield this.store.query('export', {
        accountId,
        subscriptionId
      });
      return this.set('results', exports);
    }).restartable()
  });

  _exports.default = _default;
});