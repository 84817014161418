define("nfe-customer-console-v2/models/state-tax", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    taxNumber: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^([p][0-9]{1,12}|[0-9]{1,14})$/i,
        message: 'Insira somente letras e números'
      })]
    },
    specialTaxRegime: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    code: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    } // serie: {
    //     validators: [
    //         validator('presence', {
    //             presence: true,
    //             message: 'Não pode ser vazio'
    //         }),
    //     ]
    // },

  });

  var _default = _model.default.extend(Validations, {
    intl: Ember.inject.service(),
    stateTaxId: (0, _model.attr)('string'),
    series: (0, _attributes.array)(),
    status: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    type: (0, _model.attr)('string', {
      defaultValue: 'NFe'
    }),
    environmentType: (0, _model.attr)('string'),
    taxNumber: (0, _model.attr)('string'),
    serie: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    number: (0, _model.attr)('number'),
    specialTaxRegime: (0, _model.attr)('string'),
    accountId: (0, _model.attr)('string'),
    companyId: (0, _model.attr)('string'),
    securityCredential: (0, _model.attr)({
      defaultValue() {
        return {};
      }

    }),
    isValid: Ember.computed.and('validations.isValid'),
    isProductionEnvironment: Ember.computed.equal('environmentType', 'Production'),
    isDevelopmentEnvironment: Ember.computed.equal('environmentType', 'Test'),
    environmentLabel: Ember.computed('environmentType', function () {
      return this.intl.t(`enums.apiEnvironments.${this.environmentType}`);
    })
  });

  _exports.default = _default;
});