define("nfe-customer-console-v2/services/provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    features: Ember.inject.service(),

    hasAccountAndSomeFeature() {
      let azHubAccount = this.get('features.azHubAccount');
      let dFeTechServiceInvoice = this.get('features.dFeTechServiceInvoice');
      let dFeTechProductInvoice = this.get('features.dFeTechProductInvoice');
      let dataTechBatch = this.get('features.dataTechBatch');
      let dataTechLegalEntity = this.get('features.dataTechLegalEntity');
      let dataTechNaturalPerson = this.get('features.dataTechNaturalPerson');
      return azHubAccount && dFeTechServiceInvoice || azHubAccount && dFeTechProductInvoice || azHubAccount && dataTechBatch || azHubAccount && dataTechLegalEntity || azHubAccount && dataTechNaturalPerson;
    },

    async activate(account, provider) {
      let providersArray;
      if (provider == 'DFeTech.ServiceInvoice' || provider == 'DFeTech.ProductInvoice') providersArray = ['DFeTech.WebHook', 'DFeTech.Mail', provider];

      if (providersArray) {
        for (let i = 0; i < providersArray.length; i++) {
          await this.enableProvider(account, providersArray[i]);
        }
      } else {
        await this.enableProvider(account, provider);
      }

      this.session.store.persist(this.get('session.data'));
    },

    async deactivate(account, provider) {
      await this.disableProvider(account, provider);
      if (provider.includes('DataTech') || provider.includes('Inbound')) return this.session.store.persist(this.get('session.data'));

      if (account.providers.filter(provider => provider == 'DFeTech.ServiceInvoice' || provider == 'DFeTech.ProductInvoice').length == 0) {
        let arr = ['DFeTech.WebHook', 'DFeTech.Mail'];

        for (let i = 0; i < arr.length; i++) {
          await this.disableProvider(account, arr[i]);
        }
      }

      this.session.store.persist(this.get('session.data'));
    },

    async enableProvider(account, provider) {
      const providerAdapter = this.store.adapterFor('provider');
      let result = await providerAdapter.activateProvider(account.id, provider);

      if (result.provider.status == 'Active') {
        this.features.enable(result.provider.namespace);
        account.providers.addObject(result.provider.namespace);
      }
    },

    async disableProvider(account, provider) {
      const providerAdapter = this.store.adapterFor('provider');
      let result = await providerAdapter.deactivateProvider(account.id, provider);

      if (result.provider.status == 'Inactive') {
        this.features.disable(result.provider.namespace);
        account.providers.removeObject(result.provider.namespace);
      }
    }

  });

  _exports.default = _default;
});