define("nfe-customer-console-v2/routes/payment", ["exports", "nfe-customer-console-v2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PaymentRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", "Pagamento");
    }

    beforeModel(transition) {
      let paymentId = transition.to.params.paymentId;
      if (paymentId) window.location.href = `${_environment.default.APP.ACCOUNTS_APP}/payments/${paymentId}`;
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('paymentMethodsModal', false);
      }
    }

  }

  _exports.default = PaymentRoute;
});