define("nfe-customer-console-v2/adapters/company", ["exports", "ember-data-url-templates", "nfe-customer-console-v2/config/environment", "nfe-customer-console-v2/adapters/base-rest"], function (_exports, _emberDataUrlTemplates, _environment, _baseRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompanyAdapter = (_dec = Ember.inject.service, (_class = (_temp = class CompanyAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "files", _descriptor, this);

      _defineProperty(this, "queryUrlTemplate", '{+host}/accounts{/accountId}/companies{?pageIndex,pageCount,search}');

      _defineProperty(this, "queryRecordUrlTemplate", '{+host}/accounts{/accountId}/companies{/companyId}');

      _defineProperty(this, "createRecordUrlTemplate", '{+host}/accounts{/accountId}/companies');

      _defineProperty(this, "updateRecordUrlTemplate", '{+host}/accounts{/accountId}/companies/{companyId}');

      _defineProperty(this, "urlSegments", {
        host() {
          return this.get('host');
        },

        accountId(type, id, snapshot, query) {
          let accountId;

          if (query) {
            accountId = query.accountId;
          } else if (snapshot && snapshot.adapterOptions) {
            accountId = snapshot.adapterOptions.accountId;
          } else {
            accountId = snapshot.accountId;
          }

          return accountId;
        },

        companyId(type, id, snapshot, query) {
          let companyId;

          if (query) {
            companyId = query.companyId;
          } else if (snapshot && snapshot.id) {
            companyId = snapshot.id;
          } else {
            companyId = snapshot.companyId;
          }

          return companyId;
        },

        pageIndex(type, id, snapshot, query) {
          return query.pageIndex;
        },

        pageCount(type, id, snapshot, query) {
          return query.pageCount;
        },

        search(type, id, snapshot, query) {
          return query.search;
        }

      });
    }

    uploadCertificate(companyId, file, password) {
      let url = `${_environment.default.APP.FRONTEND_URL}/companies/${companyId}/certificate`;
      let options = {
        type: 'POST',
        data: {
          password,
          file
        },
        headers: this.headersApiKey
      };
      return this.files.upload(url, options);
    }

    async getCompanyByCnpj(cnpj) {
      const url = _environment.default.APP.OPEN_LEGAL_ENTITY_API;
      const response = await super.ajax(`${url}/${cnpj}`);
      let company = response.legalEntity;
      company.address.street = `${company.address.streetSuffix} ${company.address.street}`;
      return company;
    }

    async saveEnvironment(accountId, companyId, environment) {
      const url = this.buildURL('company', null, {
        accountId,
        companyId
      }, 'updateRecord') + '/environment';
      return this.ajax(url, 'POST', {
        data: {
          environment
        }
      });
    }

    handleResponse(status, headers, payload, requestData) {
      let response = super.handleResponse(...arguments);
      if (response === "") return response;
      response.code = status;
      response.description = payload.message;
      return response;
    }

    // override ajax dataType because ember ajaxOptions function auto set dataType to 'json'.
    // the saveEnvironment request has no response data.
    ajaxOptions(url, type, options) {
      let hash = super.ajaxOptions(...arguments);

      if (type === "POST" && url.includes("/environment")) {
        hash.dataType = "text";
      }

      return hash;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "files", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CompanyAdapter;
});