define("nfe-customer-console-v2/mirage/factories/company-v2", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _emberCliMirage.faker.locale = "pt_BR";

  var _default = _emberCliMirage.Factory.extend({
    issRate: 0.0,
    address: {
      city: {
        code: "3550308",
        name: "São Paulo"
      },
      country: "BRA",

      postalCode() {
        return _emberCliMirage.faker.address.zipCode("#####-###");
      },

      number() {
        return _emberCliMirage.faker.random.number(999);
      },

      street() {
        return _emberCliMirage.faker.address.streetAddress();
      },

      additionalInformation() {
        return _emberCliMirage.faker.lorem.sentence();
      },

      district() {
        return _emberCliMirage.faker.lorem.word();
      },

      state: "SP"
    },
    taxRegime: "MicroempreendedorIndividual",

    status() {
      return _emberCliMirage.faker.random.arrayElement(["Active", "Inactive"]);
    },

    federalTaxNumber() {
      return _emberCliMirage.faker.random.number(99999999999999);
    },

    accountId() {
      return _emberCliMirage.faker.random.uuid();
    },

    name() {
      return _emberCliMirage.faker.company.companyName();
    },

    modifiedOn() {
      return _emberCliMirage.faker.date.recent();
    },

    createdOn() {
      return _emberCliMirage.faker.date.recent();
    }

  });

  _exports.default = _default;
});