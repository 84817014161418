define("nfe-customer-console-v2/controllers/accounts/index", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AccountsListQueryParams = void 0;
  const AccountsListQueryParams = new _emberParachute.default({
    page: {
      as: 'page',
      defaultValue: 1,
      refresh: true
    },
    perPage: {
      as: 'perPage',
      defaultValue: 10,
      refresh: true
    },
    filterAccount: {
      as: 'q',
      defaultValue: null,
      refresh: true
    }
  });
  _exports.AccountsListQueryParams = AccountsListQueryParams;

  var _default = Ember.Controller.extend(AccountsListQueryParams.Mixin, {
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    perPageOptions: Ember.A([10, 20, 30, 40, 50]),
    queryParamsChanged: Ember.computed.or('queryParamsState.{page,perPage,filterAccount}.changed'),
    actions: {
      onPaginationChanged(action, page, totalPages) {
        if (action === "increment") {
          this.set('page', page + 1 > totalPages ? page : page + 1);
        } else {
          this.set('page', page - 1 < 1 ? 1 : page - 1);
        }
      },

      onClickTransitionToRoute(routeName, id) {
        this.transitionToRoute(routeName, id);
      },

      onSearchChange(value) {
        if (value == null || value && value.length == 0) {
          this.set('filterAccount', null);
          this.set('page', 1);
        } else if (value && value.length >= 3) {
          this.set('filterAccount', value);
          this.set('page', 1);
        }
      },

      async activateAccount(model) {
        const result = await model.activate();
      },

      async deactivateAccount(model) {
        const result = await model.deactivate();
      },

      transitionToNewUsage(account) {
        let url = `http://account.nfe.io/`;
        url += `impersonate/acc_${account.parentId.replace("acc_", "")}?redirect=/accounts/acc_${account.parentId.replace("acc_", "")}/subscriptions/sub_${account.id.replace("sub_", "")}/usage`;
        window.open(url) || window.location.assign(url);
        return;
      }

    }
  });

  _exports.default = _default;
});