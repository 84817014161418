define("nfe-customer-console-v2/controllers/account/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    user: Ember.inject.service(),
    actions: {
      // action to prevent anchor propagation to card click
      prevent(ev) {
        ev.stopPropagation();
      },

      transitionToNew(redirectToRoute, model) {
        let url = `http://account.nfe.io/`;
        url += `impersonate/acc_${model.parentId.replace("acc_", "")}`;

        if (redirectToRoute === "accounts.subscriptions.usage") {
          url += `?redirect=/accounts/acc_${model.parentId.replace("acc_", "")}/subscriptions/${model.subscription.id}/usage`;
        }

        if (redirectToRoute === "accounts.subscriptions.exports") {
          url += `?redirect=/accounts/acc_${model.parentId.replace("acc_", "")}/subscriptions/${model.subscription.id}/exports`;
        }

        if (redirectToRoute === "accounts.subscriptions.apikeys") {
          url += `?redirect=/accounts/acc_${model.parentId.replace("acc_", "")}/subscriptions/${model.subscription.id}/apikeys`;
        }

        if (redirectToRoute === "accounts.subscriptions.providers") {
          url += `?redirect=/accounts/acc_${model.parentId.replace("acc_", "")}/subscriptions/${model.subscription.id}/providers`;
        }

        if (redirectToRoute === "accounts.subscriptions.webhooks") {
          url += `?redirect=/accounts/acc_${model.parentId.replace("acc_", "")}/subscriptions/${model.subscription.id}/webhooks`;
        }

        if (redirectToRoute === "accounts.subscriptions.users") {
          url += `?redirect=/accounts/acc_${model.parentId.replace("acc_", "")}/subscriptions/${model.subscription.id}/users`;
        } else if (redirectToRoute !== "impersonate") {
          url += `?redirect=${redirectToRoute}`;
        }

        window.open(url) || window.location.assign(url);
        return;
      }

    }
  });

  _exports.default = _default;
});