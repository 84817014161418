define("nfe-customer-console-v2/components/company-v2-form/component", ["exports", "nfe-customer-console-v2/models/enums", "nfe-customer-console-v2/helpers/cnpj-validator"], function (_exports, _enums, _cnpjValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    taxRegimes: _enums.default.taxRegimes,
    selectTaxRegime: Ember.computed('model.taxRegime', function () {
      const value = this.get('model.taxRegime');
      return _enums.default.taxRegimes.filter(v => v.code === value)[0];
    }),
    addressAutoFill: Ember.computed(function () {
      return Ember.isPresent(this.model.address.postalCode);
    }),
    getCnpj: Ember.observer('model.federalTaxNumber', async function () {
      const taxNumber = this.get('model.federalTaxNumber');
      if ((0, _cnpjValidator.cnpjValidator)(taxNumber) === false) return;
      const companyAdapter = this.store.adapterFor('company');
      const company = await companyAdapter.getCompanyByCnpj(taxNumber);
      const {
        name,
        tradeName,
        address
      } = company;
      this.model.setProperties({
        name,
        tradeName,
        address
      });
    })
  });

  _exports.default = _default;
});