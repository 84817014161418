define("nfe-customer-console-v2/controllers/consults/dfe-dist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    limit: 20,
    perPageOptions: Ember.A([20, 50, 100]),
    page: 1,
    actions: {
      mutPerPage(perPage) {
        this.set('limit', perPage);
        this.set('endingBefore', null);
        this.set('startingAfter', null);
        this.set('page', 1);
      },

      mutPage(action, data) {
        if (action === "increment") {
          let lastItem = data.lastObject;
          this.set('startingAfter', lastItem.id);
          this.set('direction', 'forward');
          this.incrementProperty("page");
          this.set('endingBefore', null);
        } else {
          let firstItem = data.firstObject;
          this.set('endingBefore', firstItem.id);
          this.set('direction', 'backward');
          this.decrementProperty("page");
          this.set('startingAfter', null);
        }
      }

    }
  });

  _exports.default = _default;
});