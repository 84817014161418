define("nfe-customer-console-v2/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    server.loadFixtures('companies');
    server.loadFixtures('providers');
    server.createList('account', 1);
    server.createList('company', 10);
    server.createList('company-v2', 60);
    server.createList('state-tax', 1);
    server.createList('certificate-v2', 1);
    server.createList('metric', 1);
    server.createList('hook', 2);
    server.createList('hook-v2', 2);
    server.createList('service-invoice', 10);
    server.createList('product-invoice', 10);
    server.createList('batch', 6); //   server.createList('provider', 8)
  }
});