define("nfe-customer-console-v2/components/mount-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    header: Ember.inject.service("mount-header"),

    init() {
      this._super(...arguments);

      this.header.mountHeader(this.companies);
    }

  });

  _exports.default = _default;
});