define("nfe-customer-console-v2/components/usages-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    features: Ember.inject.service(),
    tagName: '',
    // attributes from template as parameters
    accountId: '',
    usedOn: new Date(),
    // set default value to attributes to template
    accountResult: null,
    results: null,

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform(this.accountId, this.subscriptionId, this.usedOn);
    },

    fetchData: (0, _emberConcurrency.task)(function* (accountId, subscriptionId, usedOn) {
      if (Ember.isBlank(accountId)) return;
      let month = usedOn.getMonth() + 1;
      let year = usedOn.getFullYear();
      const account = yield this.sessionData.getCurrentAccount();
      this.set('accountResult', account);
      const usage = yield this.store.queryRecord('usage-summary', {
        accountId,
        subscriptionId,
        date: {
          year,
          month
        }
      });
      return this.set('results', usage);
    }).restartable()
  });

  _exports.default = _default;
});