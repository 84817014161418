define("nfe-customer-console-v2/adapters/user", ["exports", "@ember-data/adapter/rest", "ember-data-url-templates", "ember-simple-auth/mixins/data-adapter-mixin", "nfe-customer-console-v2/config/environment"], function (_exports, _rest, _emberDataUrlTemplates, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_emberDataUrlTemplates.default, _dataAdapterMixin.default, {
    sessionData: Ember.inject.service(),
    host: _environment.default["ember-simple-auth-oauth2-implicit"].host,
    namespace: '',
    queryRecordUrlTemplate: '{+host}/connect/userinfo',
    headers: Ember.computed.readOnly('sessionData.getRequestTokenHeaders')
  });

  _exports.default = _default;
});