define("nfe-customer-console-v2/serializers/ticket", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TicketSerializer extends _rest.default.extend() {
    _serializeIntoHubspotObjects(data) {
      const record = data.record;
      let response = {
        ticket: {
          subject: `Ticket aberto via plataforma: ${record.category} - ${record.product.providerNamespace}`,
          content: record.description,
          hs_pipeline: 0,
          hs_pipeline_stage: 1,
          hs_ticket_category: record.category,
          tipo: record.department,
          // Provider related properties
          tipo_de_servico: record.product.providerNamespace,
          id_empresa: record.product.companyId,
          id_nota_fiscal: record.product.invoiceId,
          parametro_consulta: record.product.queryParameter,
          cpf_data_nascimento: record.product.naturalPersonbirthDate,
          data_consulta: record.product.queryDate,
          cnpj: record.product.federalTaxNumber,
          // Account related properties
          id_conta: record.accountId,
          ambiente_da_conta: record.accountEnvironment,
          status_conta: record.accountStatus,
          source_type: "Plataforma"
        },
        contact: {
          firstname: record.name,
          email: record.email,
          jobtitle: record.clientDepartment
        }
      };

      if (record.department == "Tecnico") {
        response.ticket.subcategoria_tecnico = record.type;

        if (record.type == "fiscal") {
          response.ticket.sub_tipo_fiscal_ = record.subtype;
        } else if (record.type == "integracoes") {
          response.ticket.integracao_via = record.subtype;
        } else if (record.type == "produto") {
          const lowerCaseProvider = record.product.providerNamespace.toLowerCase();

          if (lowerCaseProvider.includes('serviceinvoice')) {
            response.ticket.subtipo_nfse = record.subtype;
          } else if (lowerCaseProvider.includes('productinvoice')) {
            response.ticket.subtipo_nfe = record.subtype;
          } else if (lowerCaseProvider.includes('consumerinvoice')) {
            response.ticket.subtipo_nfce = record.subtype;
          } else {
            response.ticket.subtipo_consulta_dados = record.subtype;
          }
        }
      } else if (record.department == "Faturamento") {
        response.ticket.subcategoria_financeiro = record.type;
      }

      if (record.phone) response.contact.phone = record.phone;
      return response;
    }

    serialize(snapshot) {
      return this._serializeIntoHubspotObjects(snapshot);
    }

  }

  _exports.default = TicketSerializer;
  ;
});