define("nfe-customer-console-v2/services/user", ["exports", "ember-concurrency", "ember-local-storage", "nfe-customer-console-v2/jwt"], function (_exports, _emberConcurrency, _emberLocalStorage, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionData: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    moment: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    gravatar: Ember.computed('session.data.authenticated.access_token', function () {
      const tokenData = this._getTokenData();

      return {
        url: `https://www.gravatar.com/avatar/${hex_md5(tokenData.email)}`,
        email: tokenData.email
      };
    }),
    isAdmin: Ember.computed('session.data.authenticated.access_token', function () {
      const tokenData = this._getTokenData();

      return tokenData.role === "platform.admin";
    }),

    _getTokenData() {
      const access_token = this.get('session.data.authenticated.access_token');
      if (!Ember.isPresent(access_token)) return;
      return (0, _jwt.parseJSONWebToken)(access_token);
    },

    async getCurrentUser() {
      return this.getCurrent.perform();
    },

    getCurrent: (0, _emberConcurrency.task)(function* () {
      let currentUser = this.get('session.data.authenticated.current_user');
      if (Ember.isPresent(currentUser)) return currentUser;
      currentUser = yield this.store.queryRecord('user', {
        me: true
      });
      this.set('session.data.authenticated.current_user', currentUser);
      this.session.store.persist(this.get('session.data'));
      return currentUser;
    }).enqueue(),
    defineLocale: (0, _emberConcurrency.task)(function* () {
      const defaultLocale = 'pt-br';

      if (this.session.isAuthenticated == false) {
        this.intl.setLocale(defaultLocale);
        this.moment.setLocale(defaultLocale);
        Ember.debug(`user: set-locale ${defaultLocale}`);
        return;
      }

      let userLocale;

      const userInfoFromToken = this._getTokenData();

      if (Ember.isPresent(userInfoFromToken) && Ember.isPresent(userInfoFromToken.locale)) {
        userLocale = userInfoFromToken.locale.toLowerCase();
      } else {
        const userInfo = yield this.getCurrent.perform();

        if (Ember.isPresent(userInfo) && Ember.isPresent(userInfo.locale)) {
          userLocale = userInfo.locale.toLowerCase();
        }
      } // locale format from current_user doesn't match with the locale used inside our app
      // That's why I am doing this condition


      if (userLocale == 'en-us') userLocale = 'en';else if (userLocale == 'pt') userLocale = 'pt-br'; // Do not set a locale that we don't support yet

      if (Ember.isEmpty(userLocale) || this.intl.locales.indexOf(userLocale) == -1) userLocale = defaultLocale;
      this.set('settings.defaultLocale', userLocale);
      this.intl.setLocale(userLocale);
      this.moment.setLocale(userLocale);
      Ember.debug(`user: set-locale ${userLocale}`);
    }),
    add: (0, _emberConcurrency.task)(function* (accountId, user) {
      try {
        yield this.store.adapterFor("account-user").addUser(accountId, user);
        this.notify.success(this.intl.t('account.users.new.steps.confirmation.success'));
        return {
          status: "success",
          message: this.intl.t('account.users.new.steps.confirmation.success')
        };
      } catch (error) {
        let errorResult = {
          status: "error",
          message: this.intl.t('account.users.new.steps.confirmation.error')
        };

        if (!error || Ember.isEmpty(error.description)) {
          this.notify.error(errorResult.message);
          return errorResult;
        }

        if (error.description.includes("'email' is not admin")) errorResult.message = this.intl.t('account.users.new.steps.confirmation.userIsNotAdmin');
        if (error.description.includes("'email' does not contain account")) errorResult.message = this.intl.t('account.users.new.steps.confirmation.userWithoutAccount');
        this.notify.error(errorResult.message);
        return errorResult;
      }
    }),
    delete: (0, _emberConcurrency.task)(function* (accountId, user) {
      try {
        const adapter = this.store.adapterFor("account-user");
        yield adapter.deleteUser(accountId, user);
        return {
          status: "success",
          message: this.intl.t('account.users.delete.success')
        };
      } catch (error) {
        return {
          status: "error",
          message: this.intl.t('account.users.delete.error')
        };
      }
    })
  });

  _exports.default = _default;
});