define("nfe-customer-console-v2/components/organization-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform({
        parentId: this.parentId
      });
    },

    fetchData: (0, _emberConcurrency.task)(function* ({
      parentId
    }) {
      if (Ember.isBlank(parentId)) return this.set('hasErrors', true);

      try {
        const organization = yield this.store.queryRecord('organization', {
          accountId: parentId
        });
        this.set('hasInvalidFields', organization.validations.isInvalid);
        return this.set('results', organization);
      } catch (error) {
        this.set('hasErrors', true);
      }
    }).restartable()
  });

  _exports.default = _default;
});