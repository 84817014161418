define("nfe-customer-console-v2/adapters/ticket", ["exports", "@ember-data/adapter/rest", "ember-simple-auth/mixins/data-adapter-mixin", "nfe-customer-console-v2/config/environment"], function (_exports, _rest, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TicketAdapter = (_dec = Ember.inject.service, (_class = (_temp = class TicketAdapter extends _rest.default.extend(_dataAdapterMixin.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "files", _descriptor, this);
    }

    handleResponse(status, headers, payload, requestData) {
      let superArgs = this._super(...arguments);

      superArgs.code = status;
      return superArgs;
    }

    sendTicket(model, files) {
      let options = {
        type: 'POST',
        data: model.serialize()
      };
      options.data.files = files;
      return this.files.upload(_environment.default.APP.TICKET_API_URL, options);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "files", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TicketAdapter;
  ;
});