define("nfe-customer-console-v2/controllers/companies/edit/fiscal", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    activateValidations: false,
    formatedIss: Ember.computed('model.issRate', {
      get(key) {
        return new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
          style: 'percent'
        }).format(this.get('model.issRate'));
      }

    }),
    updateFiscalData: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateValidations', true);
      if (model.get('isInvalid')) return;
      const account = yield this.sessionData.getCurrentAccount();

      try {
        yield model.save({
          adapterOptions: {
            accountId: account.id
          }
        });
        this.notify.success(this.intl.t('companies.edit.fiscalInfo.message.success'));
        this.transitionToRoute('companies.edit', model.id);
      } catch (error) {
        this.notify.error(this.intl.t('companies.edit.fiscalInfo.message.success'));
      }

      this.set('tryChangeFiscalData', false);
    })
  });

  _exports.default = _default;
});