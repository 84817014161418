define("nfe-customer-console-v2/serializers/subscription", ["exports", "nfe-customer-console-v2/serializers/base-odata"], function (_exports, _baseOdata) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SubscriptionSerializer extends _baseOdata.default {}

  _exports.default = SubscriptionSerializer;
});