define("nfe-customer-console-v2/components/user-info-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    user: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.currentUser = null;
    },

    didReceiveAttrs() {
      this.fetchData.perform();
    },

    fetchData: (0, _emberConcurrency.task)(function* () {
      let currentUser = yield this.user.getCurrent.perform();
      this.set('currentUser', currentUser);
    }).restartable()
  });

  _exports.default = _default;
});