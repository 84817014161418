define("nfe-customer-console-v2/validators/cnpj", ["exports", "ember-cp-validations/validators/base", "nfe-customer-console-v2/helpers/cnpj-validator"], function (_exports, _base, _cnpjValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Cnpj = _base.default.extend({
    validate(value, options, model, attribute) {
      if ((0, _cnpjValidator.cnpjValidator)(value) === false) {
        return 'CNPJ inválido';
      } else {
        return true;
      }
    }

  });

  Cnpj.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor()
    /* attribute, options */
    {
      return [];
    }

  });
  var _default = Cnpj;
  _exports.default = _default;
});