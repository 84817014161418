define("nfe-customer-console-v2/components/paper-data-table-pagination", ["exports", "paper-data-table/components/paper-data-table-pagination"], function (_exports, _paperDataTablePagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperDataTablePagination.default;
    }
  });
});