define("nfe-customer-console-v2/controllers/companies/index", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CompanyListQueryParams = void 0;
  const CompanyListQueryParams = new _emberParachute.default({
    page: {
      as: 'page',
      defaultValue: 1,
      refresh: true,
      replace: true
    },
    perPage: {
      as: 'perPage',
      defaultValue: 10,
      refresh: true,
      replace: true
    },
    filterCompany: {
      as: 'q',
      defaultValue: '',
      refresh: true
    }
  });
  _exports.CompanyListQueryParams = CompanyListQueryParams;

  var _default = Ember.Controller.extend(CompanyListQueryParams.Mixin, {
    perPageOptions: Ember.A([10, 20, 30, 40, 50]),
    queryParamsChanged: Ember.computed.or('queryParamsState.{page,perPage,filterCompany}.changed'),
    isModelEmpty: Ember.computed.empty('model'),
    issueModal: false,
    companyId: null,
    actions: {
      onClickTransitionToRoute(routeName, id) {
        this.transitionToRoute(routeName, id);
        this.set('issueModal', false);
      },

      onSearchTermChange(value) {
        if (value == null || value && value.length == 0) {
          this.set('filterCompany', null);
          this.set('page', 1);
        } else if (value && value.length >= 3) {
          this.set('filterCompany', value);
          this.set('page', 1);
        }
      },

      showIssueModal(companyId) {
        this.set('companyId', companyId);
        this.set('issueModal', true);
      },

      closeDialogWithParent() {
        this.set('issueModal', false);
      },

      mutPerPage: function (perPage) {
        this.set('perPage', perPage);
        this.set('page', 1);
      },
      mutPage: function (action, page) {
        if (action === 'increment') {
          // this.set('page', (page + 1) > totalPages ? page : page + 1);
          this.set('page', page + 1);
        } else {
          this.set('page', page - 1 < 1 ? 1 : page - 1);
        }
      }
    }
  });

  _exports.default = _default;
});