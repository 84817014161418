define("nfe-customer-console-v2/models/organization", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: '4',
        message: 'Minimo de 4 caracteres'
      })]
    },
    federalTaxNumber: {
      validators: [(0, _emberCpValidations.validator)('cnpj')]
    },
    formalName: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: '6',
        message: 'Minimo de 6 caracteres'
      })]
    },
    webSiteUrl: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        allowBlank: true,
        message: 'Formato de URL inválido.'
      })]
    },
    municipalTaxNumber: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9\.:-]+$/g,
        allowBlank: true,
        allowString: true,
        message: 'Inscrição municipal inválida'
      })]
    },
    emailAddress1: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'validations.email'
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        messageKey: 'validations.validEmail'
      })]
    },
    emailAddress2: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'validations.email'
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        messageKey: 'validations.validEmail'
      })]
    },
    emailAddress3: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'validations.email'
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        messageKey: 'validations.validEmail'
      })]
    },
    telephone1: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Insira o telefone do administrador da conta. Ele pode ser repetido nos outros campos de telefone.'
      }), (0, _emberCpValidations.validator)('intl-tel', {
        message: 'Insira um telefone válido'
      })]
    },
    telephone2: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Insira um telefone para tratar de questões financeiras. Ele pode ser repetido nos outros campos de telefone.'
      }), (0, _emberCpValidations.validator)('intl-tel', {
        message: 'Insira um telefone válido'
      })]
    },
    telephone3: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Insira um telefone para contato caso exista problemas técnicos. Ele pode ser repetido nos outros campos de telefone.'
      }), (0, _emberCpValidations.validator)('intl-tel', {
        message: 'Insira um telefone válido'
      })]
    }
  });
  let OrganizationModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _attributes.fragment)('organization/address'), (_class = (_temp = class OrganizationModel extends _model.default.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "createdOn", _descriptor, this);

      _initializerDefineProperty(this, "modifiedOn", _descriptor2, this);

      _initializerDefineProperty(this, "customerType", _descriptor3, this);

      _initializerDefineProperty(this, "name", _descriptor4, this);

      _initializerDefineProperty(this, "emailAddress1", _descriptor5, this);

      _initializerDefineProperty(this, "emailAddress2", _descriptor6, this);

      _initializerDefineProperty(this, "emailAddress3", _descriptor7, this);

      _initializerDefineProperty(this, "telephone1", _descriptor8, this);

      _initializerDefineProperty(this, "telephone2", _descriptor9, this);

      _initializerDefineProperty(this, "telephone3", _descriptor10, this);

      _initializerDefineProperty(this, "formalName", _descriptor11, this);

      _initializerDefineProperty(this, "taxRegime", _descriptor12, this);

      _initializerDefineProperty(this, "locale", _descriptor13, this);

      _initializerDefineProperty(this, "federalTaxNumber", _descriptor14, this);

      _initializerDefineProperty(this, "municipalTaxNumber", _descriptor15, this);

      _initializerDefineProperty(this, "regionalTaxNumber", _descriptor16, this);

      _initializerDefineProperty(this, "webSiteUrl", _descriptor17, this);

      _initializerDefineProperty(this, "address", _descriptor18, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "createdOn", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modifiedOn", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "customerType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "emailAddress1", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "emailAddress2", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "emailAddress3", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "telephone1", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "telephone2", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "telephone3", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "formalName", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "taxRegime", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "federalTaxNumber", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "municipalTaxNumber", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "regionalTaxNumber", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "webSiteUrl", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrganizationModel;
  ;
});