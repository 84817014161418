define("nfe-customer-console-v2/serializers/account", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && payload.page || payload.totalPages || payload.totalResults) {
        payload.meta = {
          page: payload.page,
          totalPages: payload.totalPages,
          totalResults: payload.totalResults
        };
        delete payload.page;
        delete payload.totalPages;
        delete payload.totalResults;
      }

      if (payload && payload.accounts && Array.isArray(payload.accounts)) {
        payload.accounts = payload.accounts.map(item => {
          item.federalTaxNumber = item.federalTaxNumber.toString().padStart(14, '0');
          return item;
        });
      } else if (payload && payload.accounts && payload.accounts.federalTaxNumber) {
        payload.accounts.federalTaxNumber = payload.accounts.federalTaxNumber.toString().padStart(14, '0');
      }

      if (!payload.accounts.address) {
        payload.accounts.address = {
          country: null,
          postalCode: null,
          street: null,
          number: null,
          additionalInformation: null,
          district: null,
          city: {
            code: null,
            name: null
          },
          state: null
        };
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});