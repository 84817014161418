define("nfe-customer-console-v2/controllers/companies-v2/edit/state-taxes/edit", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    updateStateTax: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateValidations', true);
      if (model.isValid == false) return;

      try {
        yield model.save();
        this.notify.success('Inscrição estadual alterada com sucesso!');
        this.transitionToRoute("companies-v2.edit", model.companyId);
      } catch (error) {
        let err = error.errors[0].message;
        let message = "Erro ao alterar a inscrição estadual.";
        if (err == "tax number is not valid") message = "Número da inscrição estadual inválida.";
        this.notify.error(message);
      }
    }),
    saveEnvironment: (0, _emberConcurrency.task)(function* (model) {
      let environment = model.environmentType === "Test" ? "Production" : "Test";

      try {
        yield model.save({
          adapterOptions: {
            environmentType: environment
          }
        });
        this.notify.success(`Ambiente alterado com sucesso!`);
      } catch (error) {
        let message = "Ocorreu um erro ao alterar o ambiente!";
        this.notify.error(message);
      }

      this.set('trySwitchEnvironment', false);
    })
  });

  _exports.default = _default;
});