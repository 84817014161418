define("nfe-customer-console-v2/routes/companies-v2/edit/state-taxes/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    titleToken: "Informações da inscrição estadual da empresa",

    async model(params) {
      return await this.store.queryRecord('state-tax', {
        stateTax_id: params.stateTax_id,
        company_id: params.id
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
      }
    }

  });

  _exports.default = _default;
});