define("nfe-customer-console-v2/components/app-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4noWReFK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/images/nfe-logo.svg\"],[10,\"title\",\"NFE.io\"],[10,\"alt\",\"NFE.io\"],[10,\"data-test-logo\",\"\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/app-logo/template.hbs"
    }
  });

  _exports.default = _default;
});