define("nfe-customer-console-v2/components/nfe-batch-query-generator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    accessKeys: null,
    isLoading: false,
    hasExceededLimit: null,

    readFile(file, type) {
      let reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort();
          reject();
        };

        reader.onload = () => {
          resolve(reader.result);
        };

        if (type == 'xlsx') {
          reader.readAsArrayBuffer(file);
        } else {
          reader.readAsText(file);
        }
      });
    },

    async sheetToJson(file) {
      let arrayBuffer = await this.readFile(file, 'xlsx');
      let workbook = XLSX.read(new Uint8Array(arrayBuffer), {
        type: 'array'
      });

      if (workbook.Strings['Count'] > 3000) {
        this.notify.error('Limite máximo de 3.000 chaves por lote');
        document.getElementById('file').value = '';
      } else {
        let worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Set !ref to convert to json only 'A' column from cel 1 to 10000

        worksheet['!ref'] = "A1:A10000";
        return XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false
        });
      } // if (workbook.SheetNames.length > 1)
      //     return this.notify.error('Formato da planilha invalido'); // Put error message on translations

    },

    async txtToJson(file) {
      let strKeys = await this.readFile(file, 'txt');
      let lines = strKeys.split('\n');
      let validLines = [];
      lines.map(line => {
        if (Ember.isBlank(line) == false) {
          validLines.push([line]);
        }
      });

      if (validLines.length > 3000) {
        this.notify.error('Limite máximo de 3.000 chaves por lote');
        document.getElementById('file').value = '';
      } else {
        return validLines;
      }
    },

    actions: {
      async readFile(files) {
        if (files.length > 1) return this.notify.error('Envie apenas um arquivo por lote');
        let fileName = files[0].name.toLowerCase();
        let fileExtension = fileName.slice(fileName.lastIndexOf('.'));
        fileName = fileName.slice(0, fileName.lastIndexOf('.'));
        let accessKeys = null;

        if (fileExtension.includes('xls')) {
          accessKeys = await this.sheetToJson(files[0]);
        } else if (fileExtension.includes('txt')) {
          accessKeys = await this.txtToJson(files[0]);
        } else {
          return this.notify.error('Selecione um formato de arquivo válido'); // Put error message on translations
        }

        return accessKeys ? this.sendKeys(accessKeys, fileName) : null;
      },

      getAccessKeys(allLines) {
        let accessKeys = [];

        if (allLines && typeof allLines === "string") {
          allLines = allLines.split('\n');

          if (allLines.length > 3000) {
            this.set('hasExceededLimit', allLines.length);
          } else {
            allLines.map(line => {
              if (Ember.isBlank(line) == false) {
                accessKeys.push(line);
              }
            });
            return this.sendKeys(accessKeys);
          }
        }
      }

    }
  });

  _exports.default = _default;
});