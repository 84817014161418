define("nfe-customer-console-v2/components/validated-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nlTwx74w",
    "block": "{\"symbols\":[\"input\",\"&default\"],\"statements\":[[4,\"paper-input\",null,[[\"id\",\"label\",\"type\",\"placeholder\",\"autofocus\",\"passThru\",\"isTouched\",\"disabled\",\"value\",\"data-test-input\",\"onChange\",\"textarea\",\"errors\"],[[24,[\"identification\"]],[24,[\"label\"]],[24,[\"type\"]],[24,[\"placeholder\"]],[24,[\"autofocus\"]],[24,[\"passThru\"]],[24,[\"shouldDisplayValidations\"]],[24,[\"disabled\"]],[28,\"get\",[[24,[\"model\"]],[24,[\"valuePath\"]]],null],true,[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"textarea\"]],[28,\"get\",[[24,[\"model\"]],[28,\"concat\",[\"validations.attrs.\",[24,[\"valuePath\"]],\".messages\"],null]],null]]],{\"statements\":[[0,\"    \\n    \"],[14,2],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"fixedHint\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"hint\"],[10,\"data-test-input-hint\",\"\"],[8],[1,[22,\"hint\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[23,1,[\"hasValue\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"hint\"],[10,\"data-test-input-hint\",\"\"],[8],[1,[22,\"hint\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/validated-input/template.hbs"
    }
  });

  _exports.default = _default;
});