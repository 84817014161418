define("nfe-customer-console-v2/adapters/account", ["exports", "ember-data-url-templates", "nfe-customer-console-v2/config/environment", "nfe-customer-console-v2/adapters/base-rest"], function (_exports, _emberDataUrlTemplates, _environment, _baseRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRest.default.extend(_emberDataUrlTemplates.default, {
    queryUrlTemplate: '{+host}{/+namespace}/accounts{?pageIndex,pageCount,email,search}',
    queryRecordUrlTemplate: '{+host}{/+namespace}/accounts/{account_id}',
    createRecordUrlTemplate: '{+host}{/+namespace}/accounts/',
    updateRecordUrlTemplate: '{+host}{/+namespace}/accounts/{id}',

    create(model) {
      return this.ajax(`${this.host}/accounts`, 'POST', {
        data: model
      });
    },

    update(model) {
      return this.ajax(`${this.host}/accounts/${model.id}`, 'PUT', {
        data: model.serialize()
      });
    },

    setEnvironment(model, environment) {
      const url = this.buildURL('account', model.get('id')) + `/set-environment/${environment}`;
      return this.ajax(url, 'PUT');
    },

    activate(model) {
      const url = this.buildURL('account', model.get('id')) + "/activate";
      return this.ajax(url, 'PUT');
    },

    deactivate(model) {
      const url = this.buildURL('account', model.get('id')) + "/deactivate";
      return this.ajax(url, 'PUT');
    },

    updateUsage(model, beginDate, endDate) {
      return this.ajax(`${_environment.default.APP.USAGES_API_URL}/api/orchestrators/accounts/${model.get('id')}/usages/${beginDate}/${endDate}/sum`, 'PUT');
    },

    updateUsageAll(beginDate, endDate) {
      return this.ajax(`${_environment.default.APP.USAGES_API_URL}/api/orchestrators/accounts/*/usages/${beginDate}/${endDate}/sum`, 'PUT');
    },

    async getAllApiKeys(model, filter = null) {
      const result = await this.ajax(`${this.buildURL('account', model.id || model.get('id'))}/apikeys`);
      if (!filter) filter = () => true;
      return result.apiKeys.map(m => this.store.push(this.store.normalize('apikey', m))).filter(filter);
    }

  });

  _exports.default = _default;
});