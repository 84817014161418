define("nfe-customer-console-v2/serializers/subject-client", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SubjectClientSerializer extends _rest.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'clientId');
    }

    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        "subject-clients": payload
      };
      return super.normalizeQueryResponse(store, primaryModelClass, payload, id, requestType);
    }

    async normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        "subject-client": payload
      };
      return super.normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = SubjectClientSerializer;
  ;
});