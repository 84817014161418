define("nfe-customer-console-v2/templates/nfe/upload-batch-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sY1m3iWq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"id\",\"query-batch\"],[10,\"class\",\"layout-row-form\"],[8],[0,\"\\n    \"],[5,\"toolbar\",[],[[\"@title\",\"@subtitle\"],[[28,\"t\",[\"nfe.title\"],null],[28,\"t\",[\"nfe.subTitle\"],null]]]],[0,\"\\n\\n    \"],[5,\"md-card-container\",[],[[\"@headline\"],[[28,\"t\",[\"nfe.batch.title\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"nfe-batch-query-sender\",[],[[\"@model\"],[[22,\"model\"]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/nfe/upload-batch-query.hbs"
    }
  });

  _exports.default = _default;
});