define("nfe-customer-console-v2/controllers/companies/new", ["exports", "ember-concurrency", "ember-cp-validations", "ember-parachute"], function (_exports, _emberConcurrency, _emberCpValidations, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.createCompanyQueryParams = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    certificate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione um certificado digital.',
      disabled: Ember.computed.empty('model.certificatePassword')
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /.*\.(pfx|p12)$/gi,
      message: 'Selecione um certificado digital válido.'
    })],
    certificatePassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Digite a senha do certificado.',
      disabled: Ember.computed.empty('model.certificate')
    })]
  });
  const createCompanyQueryParams = new _emberParachute.default({
    companyEnvironment: {
      as: 'environment',
      defaultValue: 'Development',
      refresh: true,
      replace: true
    },
    onboarding: {
      defaultValue: false,
      refresh: true,
      replace: true
    }
  });
  _exports.createCompanyQueryParams = createCompanyQueryParams;

  var _default = Ember.Controller.extend(validations, createCompanyQueryParams.Mixin, {
    queryParamsChanged: Ember.computed.or('queryParamsState.{environment,onboarding}.changed'),
    notify: Ember.inject.service(),
    company: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    metrics: Ember.inject.service(),
    activateCompanyValidations: false,
    activateCertificateValidations: false,
    activatePasswordValidations: false,
    certificatePassword: null,
    certificate: null,
    file: null,
    companyId: null,
    isCompanyCreated: false,
    isCertificateSent: false,
    isCertificateSkiped: false,
    hasFiscalData: Ember.computed.or("model.municipalTaxNumber", "model.regionalTaxNumber", "model.specialTaxRegime"),
    hasCertificate: Ember.computed.and("certificate", "certificatePassword"),
    createReadyCompany: (0, _emberConcurrency.task)(function* (model, goTo) {
      let account = yield this.sessionData.getCurrentAccount();
      let {
        file,
        certificatePassword
      } = this;

      if (!this.isCompanyCreated) {
        if (this.companyEnvironment == 'Production') model.set('environment', 'Production');
        yield this.saveCompany.perform(account.id, model, goTo);
      }

      if (this.isCompanyCreated && !this.isCertificateSent && file && certificatePassword) {
        yield this.sendCertificate.perform(model.id, file, certificatePassword, goTo);
      }

      if (this.isCompanyCreated && (this.isCertificateSent || this.isCertificateSkiped || this.onboarding)) {
        this.notify.success('Empresa criada com sucesso!');

        if (this.onboarding) {
          this.transitionToRoute("onboard.service-invoice", {
            queryParams: {
              company: this.companyId,
              resetSteps: null
            }
          });
        } else {
          this.transitionToRoute('companies.edit', this.companyId);
        }
      }
    }).drop(),
    saveCompany: (0, _emberConcurrency.task)(function* (accountId, model, goTo) {
      try {
        let result = yield model.save({
          adapterOptions: {
            accountId
          }
        });
        this.set('isCompanyCreated', true);
        this.set('companyId', result.id);
        this.metrics.trackEvent({
          event: 'Submitted create company',
          category: 'Onboarding service invoice',
          action: 'Submitted create company',
          label: 'Success',
          companyId: result.id
        });
      } catch (error) {
        let err = error.errors[0].status;
        let errorMessage = "Erro ao criar empresa";
        if (err == 409) errorMessage = "Já existe uma empresa com o CNPJ informado";
        goTo(0);
        this.notify.error(errorMessage);
        this.metrics.trackEvent({
          event: 'Submitted create company',
          category: 'Onboarding service invoice',
          action: 'Submitted create company',
          label: `Error: ${errorMessage}`
        });
      }
    }),
    sendCertificate: (0, _emberConcurrency.task)(function* (companyId, file, password, goTo) {
      const response = yield this.company.uploadCertificate(companyId, file, password);

      if (response.status === "success") {
        return this.set('isCertificateSent', true);
      } else {
        this.notify.error(response.message);
        return goTo(2);
      }
    }),
    actions: {
      submitCompany(model, nextStep) {
        this.set('activateCompanyValidations', true);
        if (model.isInvalid) return;
        let {
          city
        } = model.address;

        if (city && city.name == "Brasília") {
          this.set('model.rpsSerialNumber', '99');
        }

        this.metrics.trackEvent({
          event: 'Filled basic data',
          category: 'Onboarding service invoice',
          action: 'Filled basic data'
        });
        return nextStep();
      },

      submitFiscal(model, nextStep) {
        if (model.isInvalid) return;

        if (this.hasFiscalData) {
          this.metrics.trackEvent({
            event: 'Filled fiscal data',
            category: 'Onboarding service invoice',
            action: 'Filled fiscal data'
          });
        } else {
          this.metrics.trackEvent({
            event: 'Skipped fiscal data',
            category: 'Onboarding service invoice',
            action: 'Skipped fiscal data'
          });
        }

        return nextStep();
      },

      skipCertificate(nextStep) {
        this.set('isCertificateSkiped', true);
        return nextStep();
      },

      submitCertificate(nextStep) {
        if (this.get('validations.isValid')) {
          return nextStep();
        } else {
          this.set('activatePasswordValidations', true);
          this.set('activateCertificateValidations', true);
        }
      },

      uploadCertificate(ev) {
        let file = ev.target.files[0];
        this.set('file', file);
        this.set('certificate', file.name);
        this.set('activateCertificateValidations', true);
      }

    }
  });

  _exports.default = _default;
});