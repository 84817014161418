define("nfe-customer-console-v2/routes/companies-v2/product-invoices/events", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ProductInvoiceEvents extends _authenticated.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", "Eventos da nota fiscal");
    }

    async model({
      companyId,
      invoiceId
    }) {
      return {
        companyId,
        invoiceId
      };
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('limit', 10);
        controller.set('startingAfter', null);
        controller.set('page', 1);
      }
    }

  }

  _exports.default = ProductInvoiceEvents;
  ;
});