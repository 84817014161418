define("nfe-customer-console-v2/components/batch-query-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    results: null,
    service: Ember.inject.service('batch-access-keys-client'),
    generateBatch: (0, _emberConcurrency.task)(function* (accessKeys, fileName) {
      if (accessKeys.length == 0) {
        // Criar lógica de validação para arquivo vazio no service
        return;
      }

      this.set('results', this.service.createAndValidateBatch(accessKeys, fileName));
    })
  });

  _exports.default = _default;
});