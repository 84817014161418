define("nfe-customer-console-v2/mirage/factories/state-tax", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    // id() {
    //     return faker.random.number(9);
    //     // return faker.random.uuid();
    // },
    batchId: 0,
    series: [1],

    status() {
      return faker.random.arrayElement(["Active", "Inactive"]);
    },

    code: "SP",

    type() {
      return faker.random.arrayElement(["NFe", "NFCe"]);
    },

    environmentType() {
      return faker.random.arrayElement(["Test", "Production"]);
    },

    taxNumber() {
      return faker.random.number(999999999999);
    },

    specialTaxRegime() {
      return faker.random.arrayElement(["Automatico", "Nenhum", "MicroempresaMunicipal", "Estimativa", "SociedadeDeProfissionais", "Cooperativa", "MicroempreendedorIndividual", "MicroempresarioEmpresaPequenoPorte"]);
    },

    serie: 1,
    number: 1,
    accountId: "",
    companyId: ""
  });

  _exports.default = _default;
});