define("nfe-customer-console-v2/components/account-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FETCH_DEBOUNCE = 1000;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    didReceiveAttrs() {
      const params = {
        accountId: this.accountId
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (FETCH_DEBOUNCE > 0) yield (0, _emberConcurrency.timeout)(FETCH_DEBOUNCE);
      let data = yield this.store.queryRecord('account', {
        account_id: params.accountId
      });
      return this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});