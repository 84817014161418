define("nfe-customer-console-v2/routes/account/exports", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ExportsRoute = (_dec = Ember._action, (_class = (_temp = class ExportsRoute extends _authenticated.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", "Exportações da Conta");
    }

    async model({
      accountId
    }) {
      if (accountId) {
        return {
          accountId
        };
      } else {
        const account = await this.sessionData.getCurrentAccount();
        return {
          accountId: account.id,
          parentId: account.parentId
        };
      }
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('accountId', null);
      }
    }

    updateList() {
      this.refresh();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "updateList", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateList"), _class.prototype)), _class));
  _exports.default = ExportsRoute;
});