define("nfe-customer-console-v2/templates/account/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H55wYrSf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"layout-row-form\"],[8],[0,\"\\n  \"],[5,\"toolbar\",[],[[\"@title\"],[[28,\"t\",[\"account.title\"],null]]]],[0,\"\\n\\n  \"],[5,\"paper-form\",[],[[\"@class\"],[\"layout-column layout-align-space-around-stretch\"]],{\"statements\":[[0,\"\\n    \"],[5,\"md-card-form\",[],[[\"@titleText\",\"@onSubmitClick\",\"@onCancelClick\",\"@returnButton\"],[[28,\"t\",[\"account.information.header\"],null],[28,\"perform\",[[24,[\"updateAccount\"]],[24,[\"model\"]]],null],[28,\"transition-to\",[\"account\"],null],[28,\"transition-to\",[\"account\"],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-column layout-align-start-stretch flex-grow\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex-100 flex-gt-sm-49 layout-column\"],[8],[0,\"\\n          \"],[5,\"paper-input\",[],[[\"@label\",\"@type\",\"@value\",\"@disabled\",\"@onChange\"],[[28,\"t\",[\"forms.account.id\"],null],\"text\",[24,[\"model\",\"id\"]],true,null]],{\"statements\":[[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"hint\"],[8],[1,[28,\"t\",[\"forms.account.id_hint\"],null],false],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"organization-form\",[],[[\"@model\",\"@touched\"],[[22,\"model\"],[22,\"activateValidations\"]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/account/organization.hbs"
    }
  });

  _exports.default = _default;
});