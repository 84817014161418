define("nfe-customer-console-v2/controllers/dashboard", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.dashboardQueryParams = void 0;
  const dashboardQueryParams = new _emberParachute.default({
    changedYear: {
      as: 'year',
      defaultValue: new Date().getFullYear(),
      refresh: true,
      replace: true
    }
  });
  _exports.dashboardQueryParams = dashboardQueryParams;

  var _default = Ember.Controller.extend(dashboardQueryParams.Mixin, {
    queryParamsChanged: Ember.computed.or('queryParamsState.{year}.changed'),
    actions: {
      changeYear(year) {
        this.set('changedYear', year);
      }

    }
  });

  _exports.default = _default;
});