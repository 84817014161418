define("nfe-customer-console-v2/components/webhook-form/component", ["exports", "nfe-customer-console-v2/models/enums"], function (_exports, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activateValidations: false,
    events: _enums.default.hookEvents,
    selectedEventTypes: Ember.Object.create({}),

    init() {
      this._super(...arguments);

      this.events.map(event => {
        this.set(`selectedEventTypes.${event.id}`, false);
      });
      let items = this.get('model.events');
      items && items.map(item => {
        item = item.replace('.', '-');
        this.set(`selectedEventTypes.${item}`, true);
      });
    },

    actions: {
      setEventType(selected) {
        this.toggleProperty(`selectedEventTypes.${selected}`);
        let items = this.selectedEventTypes;
        this.set('model.events', Object.keys(items).filter(key => items[key] === true));
      }

    }
  });

  _exports.default = _default;
});