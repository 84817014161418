define("nfe-customer-console-v2/serializers/state", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StateSerializer extends _rest.default {
    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        states: payload
      };
      return super.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = StateSerializer;
});