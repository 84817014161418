define("nfe-customer-console-v2/components/nfe-batch-query-sender/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    batchAccessKeysClient: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    model: null,
    accessKeys: null,
    selectedSource: "sefaz",
    pdfFileChecked: false,
    xmlFileChecked: false,

    init() {
      this._super(...arguments);

      const resource = localStorage.getItem('last_nfe_batch_query');
      const isSefazPdfEnabled = this.features.get('dataTechBatchProductInvoicePdf');
      const isSefazXmlEnabled = this.features.get('dataTechBatchProductInvoiceXml');
      const isSerproPdfEnabled = this.features.get('dataTechBatchProductInvoiceSerproPdf');
      const isSerproXmlEnabled = this.features.get('dataTechBatchProductInvoiceSerproXml');
      const splitResource = resource ? resource.split('_') : false;
      const source = splitResource ? splitResource[0] : false;
      const fileType = splitResource ? splitResource[2] : false;

      if ((source === 'sefaz' || !isSerproPdfEnabled || !isSerproXmlEnabled) && (isSefazPdfEnabled || isSefazXmlEnabled)) {
        this.set('selectedSource', 'sefaz');
      }

      if (source === 'serpro' || (!isSefazPdfEnabled || !isSefazXmlEnabled) && (isSerproPdfEnabled || isSerproXmlEnabled)) {
        this.set('selectedSource', 'serpro');
      }

      if (fileType === 'pdf' && (isSefazPdfEnabled || isSerproPdfEnabled)) {
        this.set('pdfFileChecked', true);
      }

      if (fileType === 'xml' && (isSefazXmlEnabled || isSerproXmlEnabled)) {
        this.set('xmlFileChecked', true);
      }
    },

    didRender() {
      this._super(...arguments);

      if (this.accessKeys) {
        this.element.querySelector('input[name="batch-name"]').focus();
      }
    },

    isPdfCheckboxDisabled: Ember.computed('selectedSource', function () {
      let isSourceSefaz = this.selectedSource === 'sefaz';
      let isSourceSerpro = this.selectedSource === 'serpro';
      let isSefazDisabled = !this.features.get('dataTechBatchProductInvoicePdf');
      let isSerproDisabled = !this.features.get('dataTechBatchProductInvoiceSerproPdf');
      return isSourceSefaz && isSefazDisabled || isSourceSerpro && isSerproDisabled;
    }),
    isXmlCheckboxDisabled: Ember.computed('selectedSource', function () {
      let isSourceSefaz = this.selectedSource === 'sefaz';
      let isSourceSerpro = this.selectedSource === 'serpro';
      let isSefazDisabled = !this.features.get('dataTechBatchProductInvoiceXml');
      let isSerproDisabled = !this.features.get('dataTechBatchProductInvoiceSerproXml');
      return isSourceSefaz && isSefazDisabled || isSourceSerpro && isSerproDisabled;
    }),
    isFileNameInvalid: Ember.computed('accessKeys.fileName', {
      get() {
        let fileName = this.accessKeys ? this.accessKeys.fileName : false;
        return /[<>:"\/\|\\?*.]/.test(fileName);
      }

    }),
    batchNameValidation: [{
      message: 'Insira um nome para o lote',
      //pensar em uma forma de traduzir
      validate: inputValue => {
        return !Ember.isBlank(inputValue);
      }
    }, {
      message: 'O nome do lote não deve conter caracteres inválidos: . < > : " / | \ ? *',
      //pensar em uma forma de traduzir
      validate: inputValue => {
        return !/[<>:"\/\|\\?*.]/.test(inputValue);
      }
    }],
    requestTotal: Ember.computed('accessKeys', function () {
      return this.accessKeys.count.totalKeys;
    }),
    requestValidKeys: Ember.computed('accessKeys', function () {
      return this.accessKeys.count.validKeys;
    }),
    requestInvalidKeys: Ember.computed('accessKeys', function () {
      return this.accessKeys.count.invalidKeys;
    }),
    hasInvalidKeys: Ember.computed('requestInvalidKeys', function () {
      return this.requestInvalidKeys > 0;
    }),
    sendBatchKeys: (0, _emberConcurrency.task)(function* (batch) {
      const {
        selectedSource,
        pdfFileChecked,
        xmlFileChecked
      } = this;
      let resource;

      if (pdfFileChecked && xmlFileChecked) {
        resource = selectedSource + '_nfe';
      } else if (pdfFileChecked) {
        resource = selectedSource + '_nfe_pdf';
      } else if (xmlFileChecked) {
        resource = selectedSource + '_nfe_xml';
      }

      localStorage.setItem('last_nfe_batch_query', resource);

      try {
        yield this.batchAccessKeysClient.sendBatch(resource, batch);
        this.notify.success(this.intl.t('nfe.generateBatch.sendBatchSuccess'));
      } catch (error) {
        return this.notify.error(this.intl.t('nfe.generateBatch.sendBatchError'));
      }

      this.router.transitionTo("nfe.index");
    }),
    actions: {
      changeSource(value) {
        this.setProperties({
          pdfFileChecked: false,
          xmlFileChecked: false,
          selectedSource: value
        });
      }

    }
  });

  _exports.default = _default;
});