define("nfe-customer-console-v2/adapters/prefecture", ["exports", "@ember-data/adapter/rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _rest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PrefectureAdapter extends _rest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", _environment.default.APP.PREFECTURES_API);

      _defineProperty(this, "namespace", 'v1');

      _defineProperty(this, "findAllUrlTemplate", '{+host}/{+namespace}/prefectures/');

      _defineProperty(this, "findRecordUrlTemplate", "{+host}/{+namespace}/prefectures('{id}')");
    }

  }

  _exports.default = PrefectureAdapter;
  ;
});