define("nfe-customer-console-v2/components/paper-data-table-pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'md-table-pagination',
    classNames: ['md-table-pagination'],
    isLoading: false,
    hasMore: true,
    direction: '',
    shouldPaginate: true,
    showTaxInfo: false,
    isDecrementPageDisabled: Ember.computed('pageNumber', 'isLoading', function () {
      return this.pageNumber <= 1 || this.isLoading;
    }),
    isIncrementPageDisabled: Ember.computed('hasMore', 'isLoading', 'direction', 'pageNumber', function () {
      if (this.direction == 'backward' && this.pageNumber == 1) return false;
      return this.isLoading || !this.hasMore;
    }),
    startOffset: Ember.computed('page', 'limit', function () {
      return Math.max((this.page - 1) * this.limit + 1, 1); // 1-based index
    }),
    endOffset: Ember.computed('startOffset', 'limit', 'total', function () {
      let endOffset = this.startOffset + this.limit;
      let total = this.total;
      return total ? Math.min(endOffset, total) : endOffset;
    })
  });

  _exports.default = _default;
});