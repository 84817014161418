define("nfe-customer-console-v2/models/account", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Insira um nome válido'
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Insira um email para a conta'
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        message: 'Insira um email válido'
      })]
    },
    phoneNumber: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Insira um telefone para a conta'
      }), (0, _emberCpValidations.validator)('intl-tel', {
        message: 'Insira um telefone válido'
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    federalTaxNumber: (0, _model.attr)('number'),
    email: (0, _model.attr)('string'),
    phoneNumber: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    environment: (0, _model.attr)('string'),
    createdOn: (0, _model.attr)('string'),
    modifiedOn: (0, _model.attr)('string'),
    parentId: (0, _model.attr)('string'),
    address: (0, _attributes.fragment)('address'),
    companies: (0, _model.hasMany)('company'),
    apiKeys: (0, _attributes.fragmentArray)('apikey'),
    totalResults: (0, _model.attr)('number'),
    totalPages: (0, _model.attr)('number'),
    page: (0, _model.attr)('number'),
    environmentLabel: Ember.computed('environment', function () {
      return `enums.apiEnvironments.${this.environment}`;
    }),
    toJSON: function () {
      return this._super({
        includeId: true
      });
    },

    getAllApiKeys(filter = null) {
      const adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.getAllApiKeys(this, filter);
    },

    setEnvironment(environment) {
      const adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.setEnvironment(this, environment);
    },

    activate() {
      const adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.activate(this);
    },

    deactivate() {
      const adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.deactivate(this);
    },

    updateUsage(beginDate, endDate) {
      const adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.updateUsage(this, beginDate, endDate);
    }

  });

  _exports.default = _default;
});