define("nfe-customer-console-v2/components/animated-box-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cardOpen: true,
    actions: {
      openClose() {
        let el = this.element.querySelector('md-card');

        if (this.cardOpen) {
          el.classList.remove("md-card-open");
          el.classList.add("md-card-close");
          this.set('cardOpen', false);
        } else {
          el.classList.remove("md-card-close");
          el.classList.add("md-card-open");
          this.set('cardOpen', true);
        }
      }

    }
  });

  _exports.default = _default;
});