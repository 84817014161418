define("nfe-customer-console-v2/mirage/factories/metric", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    report: [{
      period: "01",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "02",
      values: {
        count: 7.0,
        amount: 40.03
      }
    }, {
      period: "03",
      values: {
        count: 4.0,
        amount: 40.040000000000006
      }
    }, {
      period: "04",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "05",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "06",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "07",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "08",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "09",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "10",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "11",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }, {
      period: "12",
      values: {
        count: 0.0,
        amount: 0.0
      }
    }],
    meta: {
      totals: {
        count: 11.0,
        amount: 80.070000000000007
      },
      year: 2019
    },
    metrics: [{
      environment: "Development",
      name: "ServiceInvoice_Total_Error",
      unit: "unit",
      value: 6.0,
      timestamp: "2019-03-28T20:11:26.404935+00:00",
      modifiedOn: "2019-03-28T20:11:26.404935+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_None",
      unit: "unit",
      value: 0.0,
      timestamp: "2019-03-28T20:11:26.4518125+00:00",
      modifiedOn: "2019-03-28T20:11:26.4518125+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_Created",
      unit: "unit",
      value: 0.0,
      timestamp: "2019-03-28T20:11:26.4518125+00:00",
      modifiedOn: "2019-03-28T20:11:26.4518125+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_Issued",
      unit: "unit",
      value: 19.0,
      timestamp: "2019-03-28T20:11:26.3910853+00:00",
      modifiedOn: "2019-03-28T20:11:26.3910853+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_Cancelled",
      unit: "unit",
      value: 0.0,
      timestamp: "2019-03-28T20:11:26.4699906+00:00",
      modifiedOn: "2019-03-28T20:11:26.4699906+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Month_Total_2019_2",
      unit: "unit",
      value: 7.0,
      timestamp: "2019-02-01T00:00:00+00:00",
      modifiedOn: "2019-03-11T20:13:55.3603979+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Month_TotalAmount_2019_2",
      unit: "money",
      value: 40.03,
      timestamp: "2019-02-01T00:00:00+00:00",
      modifiedOn: "2019-03-11T20:13:55.3760089+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_CurrentMonth_Total",
      unit: "unit",
      value: 4.0,
      timestamp: "2019-03-28T20:11:26.4830628+00:00",
      modifiedOn: "2019-03-28T20:11:26.4830628+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_CurrentMonth_TotalAmount",
      unit: "money",
      value: 20.020000000000003,
      timestamp: "2019-03-28T20:11:26.4830628+00:00",
      modifiedOn: "2019-03-28T20:11:26.4830628+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_LastMonth_Total",
      unit: "unit",
      value: 7.0,
      timestamp: "2019-03-11T20:13:55.3760089+00:00",
      modifiedOn: "2019-03-11T20:13:55.3760089+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_LastMonth_TotalAmount",
      unit: "unit",
      value: 40.03,
      timestamp: "2019-03-11T20:13:55.3760089+00:00",
      modifiedOn: "2019-03-11T20:13:55.3760089+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Month_TotalAmount_2019_3",
      unit: "money",
      value: 20.020000000000003,
      timestamp: "2019-03-01T00:00:00+00:00",
      modifiedOn: "2019-03-28T20:11:26.4830628+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_Issued",
      unit: "unit",
      value: 19.0,
      timestamp: "2019-03-28T21:18:32.366455+00:00",
      modifiedOn: "2019-03-28T21:18:32.366455+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_Error",
      unit: "unit",
      value: 6.0,
      timestamp: "2019-03-28T21:18:32.366455+00:00",
      modifiedOn: "2019-03-28T21:18:32.366455+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_None",
      unit: "unit",
      value: 0.0,
      timestamp: "2019-03-28T21:18:32.3811142+00:00",
      modifiedOn: "2019-03-28T21:18:32.3811142+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_Created",
      unit: "unit",
      value: 0.0,
      timestamp: "2019-03-28T21:18:32.3811142+00:00",
      modifiedOn: "2019-03-28T21:18:32.3811142+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Total_Cancelled",
      unit: "unit",
      value: 0.0,
      timestamp: "2019-03-28T21:18:32.3967394+00:00",
      modifiedOn: "2019-03-28T21:18:32.3967394+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Month_Total_2019_3",
      unit: "unit",
      value: 4.0,
      timestamp: "2019-03-01T00:00:00+00:00",
      modifiedOn: "2019-03-28T21:18:32.4160603+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_Month_TotalAmount_2019_3",
      unit: "money",
      value: 20.020000000000003,
      timestamp: "2019-03-01T00:00:00+00:00",
      modifiedOn: "2019-03-28T21:18:32.4160603+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_CurrentMonth_Total",
      unit: "unit",
      value: 4.0,
      timestamp: "2019-03-28T21:18:32.4279909+00:00",
      modifiedOn: "2019-03-28T21:18:32.4279909+00:00"
    }, {
      environment: "Development",
      name: "ServiceInvoice_CurrentMonth_TotalAmount",
      unit: "money",
      value: 20.020000000000003,
      timestamp: "2019-03-28T21:18:32.4455445+00:00",
      modifiedOn: "2019-03-28T21:18:32.4455445+00:00"
    }]
  });

  _exports.default = _default;
});