define("nfe-customer-console-v2/models/hook-v2", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const Validations = (0, _emberCpValidations.buildValidations)({
    uri: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'não pode ser vazio'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
        message: 'Formato de URL inválido.'
      })]
    },
    secret: {
      validators: [(0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        min: 32,
        max: 64,
        message: 'Tamanho de senha inválido (min: 32 max: 64)'
      })]
    }
  });
  let HookV2Model = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string', {
    defaultValue: 'json'
  }), _dec4 = (0, _model.attr)('string', {
    defaultValue: 'v2'
  }), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)(), _dec10 = Ember.computed('filters'), (_class = (_temp = class HookV2Model extends _model.default.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "uri", _descriptor, this);

      _initializerDefineProperty(this, "secret", _descriptor2, this);

      _initializerDefineProperty(this, "contentType", _descriptor3, this);

      _initializerDefineProperty(this, "version", _descriptor4, this);

      _initializerDefineProperty(this, "createdOn", _descriptor5, this);

      _initializerDefineProperty(this, "modifiedOn", _descriptor6, this);

      _initializerDefineProperty(this, "insecureSsl", _descriptor7, this);

      _initializerDefineProperty(this, "client", _descriptor8, this);

      _initializerDefineProperty(this, "filters", _descriptor9, this);
    }

    get type() {
      if (!this.filters) return;
      let type = 'nfe';
      this.filters.some(filter => {
        if (filter.includes('transportation_invoice_inbound')) {
          type = 'cteDist';
        } else if (filter.includes('product_invoice_inbound')) {
          type = 'nfeDist';
        } else if (filter.includes('consumer_invoice')) {
          type = 'nfce';
        }
      });
      return type;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uri", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "secret", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contentType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdOn", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modifiedOn", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "insecureSsl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "client", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "type", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "type"), _class.prototype)), _class));
  _exports.default = HookV2Model;
});