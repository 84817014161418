define("nfe-customer-console-v2/serializers/base-odata", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BaseOdataRESTSerializer extends _rest.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.value) {
        payload = {
          [primaryModelClass.modelName]: payload.value
        };
      } else {
        payload = {
          [primaryModelClass.modelName]: payload
        };
      }

      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = BaseOdataRESTSerializer;
  ;
});