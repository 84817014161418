define("nfe-customer-console-v2/helpers/format-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPercentage = formatPercentage;
  _exports.default = void 0;

  function formatPercentage([value]) {
    if (value) return `${(value * 100).toString().replace('.', ',')}%`;
  }

  var _default = Ember.Helper.helper(formatPercentage);

  _exports.default = _default;
});