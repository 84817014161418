define("nfe-customer-console-v2/mirage/factories/batch", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let indexNumbers = [1, 2, 4];

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return _faker.default.random.word();
    },

    accountId() {
      return _faker.default.random.number(1000000000000000000);
    },

    resource() {
      return _faker.default.random.arrayElement(['sefaz_nfe', 'sefaz_nfe_pdf', 'sefaz_nfe_xml']);
    },

    flowStatus: "Completed",
    totalLines: 1000,

    totalOkLines(i) {
      return indexNumbers.includes(i) ? 9985 : null;
    },

    totalErrorLines(i) {
      return indexNumbers.includes(i) ? 15 : null;
    },

    modifiedOn() {
      return _faker.default.date.recent();
    },

    createdOn() {
      return _faker.default.date.recent();
    },

    xmlDescription(i) {
      if (this.resource != "sefaz_nfe_pdf") {
        return {
          flowStatus: "Completed",
          totalOkLines: 900,
          totalErrorLines: 100,
          modifiedOn: "2019-07-01T13:59:50.4546155-03:00",
          fileBlob: {
            name: "sefaz-nfe-export-sefaz_nfe_4a1b5361970748f3a2db31f37b8723a4-xml.zip",
            contentType: "application/zip",
            url: "https://devnfsetable.blob.core.windows.net/export/sefaz-nfe-export-sefaz_nfe_73b499eae2c24f43bccb9a7f364d9e97-xml.zip?sv=2018-03-28&sr=b&sig=0vOZsmOkGnMZ47rcU2MVIA%2FxvvGgIRjcARATrXpqI9Q%3D&st=2019-07-02T17%3A49%3A57Z&se=2020-07-03T17%3A49%3A57Z&sp=rl"
          }
        };
      }
    },

    pdfDescription() {
      if (this.resource != "sefaz_nfe_xml") {
        return {
          flowStatus: "Completed",
          totalOKLines: 900,
          totalErrorLines: 100,
          modifiedOn: "2019-07-01T13:59:50.4546155-03:00",
          fileBlob: {
            name: "sefaz-nfe-export-sefaz_nfe_4a1b5361970748f3a2db31f37b8723a4-pdf.zip",
            contentType: "application/zip",
            url: "https://devnfsetable.blob.core.windows.net/export/sefaz-nfe-export-sefaz_nfe_73b499eae2c24f43bccb9a7f364d9e97-pdf.zip?sv=2018-03-28&sr=b&sig=x6RGle0yN94Yx14JarexmkiAB8yCmyLwJmb%2F%2Fzh%2BQfQ%3D&st=2019-07-02T17%3A50%3A18Z&se=2020-07-03T17%3A50%3A18Z&sp=rl"
          }
        };
      }
    }

  });

  _exports.default = _default;
});