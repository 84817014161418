define("nfe-customer-console-v2/models/usage-month", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    accountId: (0, _model.attr)('string'),
    usedOn: (0, _model.attr)('date'),
    lastUpdatedOn: (0, _model.attr)('date'),
    services: (0, _attributes.fragmentArray)('usage-service')
  });

  _exports.default = _default;
});