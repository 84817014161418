define("nfe-customer-console-v2/templates/companies/service-invoices/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uwwws5zU",
    "block": "{\"symbols\":[\"loader\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"layout-row-form\"],[8],[0,\"\\n  \"],[5,\"service-invoice-loader\",[],[[\"@accountId\",\"@companyId\",\"@invoiceId\"],[[24,[\"model\",\"accountId\"]],[24,[\"model\",\"companyId\"]],[24,[\"model\",\"invoiceId\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[5,\"loading-substate\",[],[[],[]]],[0,\"          \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"toolbar\",[],[[\"@title\",\"@subtitle\",\"@returnPage\"],[[28,\"t\",[\"companies.edit.title\"],null],[28,\"t\",[\"companies.serviceInvoices.view.subtitle\"],null],[28,\"transition-to\",[\"companies.service-invoices\",[24,[\"model\",\"companyId\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"service-invoice-actions-button\",[],[[\"@companyId\",\"@invoice\"],[[24,[\"model\",\"companyId\"]],[23,1,[\"data\"]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n      \"],[5,\"nfse-view\",[],[[\"@invoice\"],[[23,1,[\"data\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/companies/service-invoices/view.hbs"
    }
  });

  _exports.default = _default;
});