define("nfe-customer-console-v2/services/master-tab-factory", ["exports", "ember-master-tab/services/master-tab-factory"], function (_exports, _masterTabFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _masterTabFactory.default;
    }
  });
});