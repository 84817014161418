define("nfe-customer-console-v2/serializers/product-invoice", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServiceInvoiceQuerySerializer extends _rest.default {
    extractMeta(store, modelClass, payload) {
      if (payload && payload.hasOwnProperty('hasMore')) {
        let meta = {
          hasMore: payload.hasMore
        };
        delete payload.hasMore;
        return meta;
      }
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.productInvoices) {
        payload = {
          productInvoice: payload
        };
      }

      if (payload.productInvoices) {
        payload.productInvoices.map(invoice => {
          if (!invoice.id) {
            // This is a work arround to show invoices without ID received from back-end
            invoice.id = `${new Date(invoice.modifiedOn).getTime()}${Math.floor(Math.random() * (10000 - 100000 + 1)) + 100000}`;
          }
        });
      }

      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = ServiceInvoiceQuerySerializer;
});