define("nfe-customer-console-v2/helpers/access-key-validator", ["exports", "nfe-customer-console-v2/helpers/cnpj-validator"], function (_exports, _cnpjValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accessKeyValidator = accessKeyValidator;
  _exports.default = void 0;
  const defaultLength = 44;
  const statesCode = ["11", "12", "13", "14", "15", "16", "17", "21", "22", "23", "24", "25", "26", "27", "28", "29", "31", "32", "33", "35", "41", "42", "43", "50", "51", "52", "53"];

  function _isValidDate(value) {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear() % 100;
    let currentMonth = currentDate.getMonth() + 1;
    let accessKeyYear = parseInt(value.slice(0, 2));
    let accessKeyMonth = parseInt(value.slice(2));
    if (accessKeyMonth <= 0 || accessKeyMonth > 12) return false;
    if (accessKeyYear >= 10 && accessKeyYear < currentYear) return true;
    if (accessKeyYear == currentYear && accessKeyMonth <= currentMonth) return true;
    return false;
  }

  ;

  function _removeNonNumeric(value) {
    let aux = '';

    for (let i = 0; i < value.length; i++) {
      if (!isNaN(parseInt(value[i]))) aux += value[i];
    }

    ;
    return aux;
  }

  ;

  function _digitValidate(value) {
    let sum = 0;
    let mult = 2;

    for (let i = value.length - 1; i >= 0; i--) {
      sum += value[i] * mult;
      mult += 1;
      if (mult > 9) mult = 2;
    }

    let resto = sum % 11;
    let dv = 11 - resto;
    if (dv >= 10) dv = 0;
    return dv;
  }

  function _validateKey(accessKey) {
    if (statesCode.indexOf(accessKey.slice(0, 2)) === -1) return false;
    if (!_isValidDate(accessKey.slice(2, 6))) return false;
    if (!(0, _cnpjValidator.cnpjValidator)(accessKey.slice(6, 20))) return false;
    if (accessKey.slice(20, 22) !== "55") return false;
    if (_digitValidate(accessKey.slice(0, 43)) != accessKey.substr(-1)) return false;
    return true;
  }

  ;

  function accessKeyValidator(key) {
    if (key === null || key === undefined) return false;
    if (typeof key === "number") key = key.toString();

    if (typeof key === "string") {
      let aux = _removeNonNumeric(key);

      if (aux === "") return false;
      if (aux.length != defaultLength) return false;
      return _validateKey(aux);
    } else {
      return false;
    }
  }

  ;

  var _default = Ember.Helper.helper(accessKeyValidator);

  _exports.default = _default;
});