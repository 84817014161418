define("nfe-customer-console-v2/components/credit-card-form/component", ["exports", "card-validator"], function (_exports, _cardValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // number: '',
    // expiration: '',
    // securityCode: '',
    // holderName: '',
    placeholders: {
      name: 'Nome'
    },
    // Credit Card Validations
    creditCardNumberValidation: [{
      message: '',
      validate: value => {
        let validation = _cardValidator.default.number(value);

        return validation.isValid;
      }
    }],
    creditCardExpirationValidation: [{
      message: "",
      validate: value => {
        let validation = _cardValidator.default.expirationDate(value);

        return validation.isValid;
      }
    }],
    creditCardSecurityCodeValidation: [{
      message: "",
      validate: value => {
        let validation = _cardValidator.default.cvv(value, [3, 4]);

        return validation.isValid;
      }
    }],
    creditCardHolderNameValidation: [{
      message: "",
      validate: value => {
        let validation = _cardValidator.default.cardholderName(value);

        return validation.isValid;
      }
    }, {
      message: "",
      validate: value => {
        let nameRegex = /^[A-Za-z]+[ ][A-Za-z]+(?:[ ][A-Za-z]+)*$/;
        return nameRegex.test(value);
      }
    }]
  });

  _exports.default = _default;
});