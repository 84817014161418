define("nfe-customer-console-v2/components/validated-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    value: null,
    type: 'text',
    valuePath: '',
    validation: null,
    isTyping: false,
    showValidations: false,
    notValidating: Ember.computed.not('validation.isValidating').readOnly(),
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    hasWarnings: Ember.computed.notEmpty('validation.warnings').readOnly(),
    isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly(),
    shouldDisplayValidations: Ember.computed.or('showValidations', 'didValidate', 'hasContent', 'isTouched'),
    showErrorClass: Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly(),
    showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    showWarningMessage: Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    init() {
      this._super(...arguments);

      let valuePath = this.valuePath;
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly(`model.validations.attrs.${valuePath}`));
      Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
    },

    focusOut() {
      this._super(...arguments);

      this.set('showValidations', true);
    },

    actions: {
      changeSelect(prop, value) {
        if (value && value.code) {
          this.set(`model.${prop}`, prop == "city" ? value : value.code);
        }

        if (Ember.isPresent(this.onChange)) {
          this.onChange(value);
        }
      }

    }
  });

  _exports.default = _default;
});