define("nfe-customer-console-v2/controllers/account/users/new", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentStep: 0,
    activateValidations: false,
    sendInvite: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateValidations', true);

      if (model.user.get('validations.isValid')) {
        this.set('currentStep', 1);
        let result = yield this.user.add.perform(model.accountId, model.user.email);
        result.icon = result.status.toLowerCase() === "success" ? "check" : "error-outline";
        return result;
      }
    }),
    actions: {
      reset() {
        this.send('resetRoute');
      }

    }
  });

  _exports.default = _default;
});