define("nfe-customer-console-v2/routes/companies/service-invoices/view", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    notify: Ember.inject.service(),
    titleToken: "Visualização da nota fiscal",

    async model(params) {
      const paramsParent = this.paramsFor('companies.service-invoices');
      const account = await this.sessionData.getCurrentAccount();
      return {
        accountId: account.id,
        companyId: paramsParent.companyId,
        invoiceId: params.invoice_id
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', 1);
        controller.set('perPage', 10);
        controller.set('status', "none");
        controller.set('currentRouteName', "companies.service-invoices.none");
      }
    }

  });

  _exports.default = _default;
});