define("nfe-customer-console-v2/components/paper-select-trigger", ["exports", "ember-paper/components/paper-select-trigger"], function (_exports, _paperSelectTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperSelectTrigger.default;
    }
  });
});