define("nfe-customer-console-v2/components/dashboard-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionData: Ember.inject.service(),
    report: Ember.inject.service(),
    tagName: '',

    didReceiveAttrs() {
      this.fetchData.perform(this.changedYear);
    },

    fetchData: (0, _emberConcurrency.task)(function* (year) {
      let currentYear = new Date().getFullYear();
      const account = yield this.sessionData.getCurrentAccount();
      let response = yield this.report.serviceInvoiceMetrics(account.id, year);
      return this.set('data', {
        chart: response.chart,
        totals: response.totals,
        selectedYear: year ? year : currentYear,
        pastYears: [currentYear, currentYear - 1, currentYear - 2]
      });
    }).restartable()
  });

  _exports.default = _default;
});