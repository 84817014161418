define("nfe-customer-console-v2/controllers/account/usage", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    export: Ember.inject.service(),
    notify: Ember.inject.service(),
    minDate: new Date('2017-01-02'),
    maxDate: new Date(),
    queryParams: ["accountId"],
    accountId: null,
    isModelEmpty: Ember.computed.empty('model.usage'),
    exportUsage: (0, _emberConcurrency.task)(function* (accountId, subscriptionId, usedOn) {
      const formatedDate = this.export.formatDateBeginEnd(usedOn);

      try {
        let options = {
          resource: "account-usage",
          beginOn: formatedDate.begin,
          endOn: formatedDate.end
        };
        yield this.store.adapterFor("export").newExport(accountId, subscriptionId, options);
        yield (0, _emberConcurrency.timeout)(1000);

        if (this.accountId) {
          this.transitionToRoute("account.exports", {
            queryParams: {
              accountId: this.accountId
            }
          });
        } else {
          this.transitionToRoute("account.exports");
        }
      } catch (error) {
        this.notify.error("Não foi possível fazer a exportação.");
      }
    }),
    actions: {
      filterByDate(date) {
        if (date) {
          const dates = date.split('/');
          this.set('model.usedOn', new Date(`${dates[1]}-${dates[0]}-15`));
        }
      }

    }
  });

  _exports.default = _default;
});