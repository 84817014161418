define("nfe-customer-console-v2/helpers/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormat = dateFormat;
  _exports.default = void 0;

  function dateFormat([value]) {
    if (!value) return;
    const offset = 3;
    const date = new Date(value);
    const dateOffset = offset * 60 * 60000;
    const userOffset = date.getTimezoneOffset() * 60000;
    const time = new Date(date.getTime() + dateOffset + userOffset);
    return `${("0" + time.getDate()).slice(-2)}/${("0" + (time.getMonth() + 1)).slice(-2)}/${time.getFullYear()}`;
  }

  var _default = Ember.Helper.helper(dateFormat);

  _exports.default = _default;
});