define("nfe-customer-console-v2/components/notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notification: Ember.inject.service(),
    // Todo: change property name to be agnostic
    refreshRoute: null,

    async didReceiveAttrs() {
      this._super(...arguments);

      if (typeof this.refreshRoute != 'function') return;
      let connection = await this.notification.connect();
      connection.on('refresh', data => this.refreshRoute(data));
    },

    async willDestroyElement() {
      await this.notification.disconnect();

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});