define("nfe-customer-console-v2/components/paper-step-actions", ["exports", "ember-paper-stepper/components/paper-step-actions"], function (_exports, _paperStepActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperStepActions.default;
    }
  });
});