define("nfe-customer-console-v2/services/webhook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WebhookService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = (_temp = class WebhookService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);
    }

    async createHook(model) {
      try {
        const account = await this.sessionData.getCurrentAccount();

        if (model.version === "v1") {
          await model.save({
            adapterOptions: {
              accountId: account.id
            }
          });
        } else {
          await model.save();
        }

        this.notify.success(this.intl.t('hooks.new.success'));
        return {
          ok: true
        };
      } catch (error) {
        let message = this.checkError(error);
        return this.notify.error(message);
      }
    }

    async updateHook(model) {
      try {
        const account = await this.sessionData.getCurrentAccount();

        if (model.version === "v1") {
          await model.save({
            adapterOptions: {
              accountId: account.id
            }
          });
        } else {
          await model.save();
        }

        this.notify.success(this.intl.t('hooks.edit.success'));
        return {
          ok: true
        };
      } catch (error) {
        let message = this.checkError(error);
        return this.notify.error(message);
      }
    }

    async deleteHook(model) {
      try {
        const account = await this.sessionData.getCurrentAccount();

        if (model.version === "v1") {
          await model.destroyRecord({
            adapterOptions: {
              accountId: account.id
            }
          });
        } else {
          await model.destroyRecord();
        }

        this.notify.success(this.intl.t('hooks.delete.success'));
        return {
          ok: true
        };
      } catch (error) {
        return this.notify.error(this.intl.t('hooks.delete.error'));
      }
    }

    checkError(error) {
      let err = error.errors[0];
      let {
        status,
        message
      } = err;
      let finalMessage = this.intl.t('hooks.error.default');

      if (message && message.includes("Please ensure that the WebHook URI is valid and that the endpoint is accessible")) {
        finalMessage = this.intl.t('hooks.error.validUrl');
      } else if (message && message.includes("The WebHook URI must be absolute with a scheme of either 'http' or 'https'")) {
        finalMessage = this.intl.t('hooks.error.invalidSchema');
      } else if (message && message.includes("Invalid URI: The hostname could not be parsed.")) {
        finalMessage = this.intl.t('hooks.error.invalidUri');
      }

      if (status == "409") {
        finalMessage = this.intl.t('hooks.error.alreadyExists');
      } else if (status == "504") {
        finalMessage = this.intl.t('hooks.error.accessibleUrl');
      }

      return finalMessage;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WebhookService;
  ;
});