define("nfe-customer-console-v2/services/service-invoice", ["exports", "nfe-customer-console-v2/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let serviceInvoice = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = (_temp = class serviceInvoice extends Ember.Service.extend(_fileSaver.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    async _request(path, options, url = null) {
      (true && !(Ember.isPresent(url) || Ember.isPresent(path)) && Ember.assert('You need to pass url or path parameters', Ember.isPresent(url) || Ember.isPresent(path)));

      if (options) {
        options.headers = {
          'X-NFEIO-APIKEY': this.sessionData.getRequestHeaders.Authorization
        };
      } else {
        options = {
          headers: {
            'X-NFEIO-APIKEY': this.sessionData.getRequestHeaders.Authorization
          }
        };
      }

      let requestUrl = url ? url : _environment.default.APP.FRONTEND_URL + path;
      return this.ajax.request(requestUrl, options);
    }

    async cancel(companyId, invoice) {
      if (invoice.status !== "Issued") return this.notify.error("Nota fiscal deve estar emitida para ser cancelada");

      try {
        const cancelledInvoice = await this.store.adapterFor('service-invoice').cancelInvoice(companyId, invoice.id);
        this.notify.success("Nota fiscal enviada para cancelamento!");
        return cancelledInvoice;
      } catch (error) {
        this.notify.error("Ocorreu um erro ao enviar para cancelamento!");
      }
    }

    async sendEmail(companyId, invoice) {
      if (invoice && invoice.borrower && Ember.isEmpty(invoice.borrower.email)) return this.notify.error('O e-mail do tomador na nota fiscal está vázio!');

      try {
        await this.store.adapterFor('service-invoice').sendEmail(companyId, invoice.id);
        this.notify.success(`Nota fiscal foi enviada para o cliente!`);
      } catch (error) {
        this.notify.error('Ocorreu um erro ao enviar e-mail!');
      }
    }

    async download(fileType, invoice, companyId, reprocess = false) {
      (true && !(fileType == 'pdf' || fileType == 'xml') && Ember.assert('fileType is not valid', fileType == 'pdf' || fileType == 'xml'));
      (true && !(invoice && invoice.isEmpty == false) && Ember.assert('invoice is not valid', invoice && invoice.isEmpty == false));
      (true && !(Ember.isPresent(companyId)) && Ember.assert('companyId can not be empty', Ember.isPresent(companyId)));
      if (fileType != 'pdf' && fileType != 'xml' || invoice && invoice.isEmpty || Ember.isEmpty(companyId)) return;

      try {
        const adapterUrl = this.store.adapterFor('service-invoice').buildURL('service-invoice', invoice.id, {
          adapterOptions: {
            company_id: companyId
          }
        }, 'findRecord');
        let requestOptions = {
          method: 'GET',
          dataType: 'arraybuffer',
          processData: false
        };
        const invoiceBuffer = await this._request(null, requestOptions, `${adapterUrl}/${fileType}?force=${reprocess}`);
        this.saveFileAs(`NFSe_${fileType}_${invoice.number}`, invoiceBuffer, `application/${fileType}`);
      } catch (error) {
        this.notify.error('Erro ao fazer o download da nota fiscal');
        console.log(error);
        return;
      }
    }

    async reprocessWebhook(companyId, invoiceId) {
      try {
        await this.store.adapterFor('service-invoice').reprocessWebhook(companyId, invoiceId);
        this.notify.success('Solicitação de reprocessamento de webhook enviada com sucesso!');
      } catch (error) {
        this.notify.error('Erro ao reprocessar webhook.');
      }
    }

    async duplicate(companyId, invoiceId) {
      try {
        let invoice = await this.store.findRecord('service-invoice', invoiceId, {
          adapterOptions: {
            company_id: companyId
          }
        });
        if (invoice.isInvoiceError == false) return;
        invoice = invoice.toJSON();
        delete invoice.provider;
        delete invoice.id;
        delete invoice.flowStatus;
        delete invoice.flowMessage;
        delete invoice.batchNumber;
        delete invoice.batchCheckNumber;
        delete invoice.status;
        delete invoice.cancelledOn;
        delete invoice.createdOn;
        delete invoice.modifiedOn;
        delete invoice.rpsNumber;
        delete invoice.number;
        const invoiceRecord = this.store.createRecord('service-invoice', invoice);
        await invoiceRecord.save({
          adapterOptions: {
            company_id: companyId
          }
        });
        this.notify.success('NFS-e foi enviada para re-emissão!');
      } catch (error) {
        this.notify.error('Erro ao enviar a NFS-e para re-emissão');
      }

      ;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = serviceInvoice;
});