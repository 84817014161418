define("nfe-customer-console-v2/components/nfe-batch-query-generator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GZdQNE6I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"accessKeys\",\"getAccessKeys\",\"readFiles\",\"hasExceededLimit\"],[[24,[\"accessKeys\"]],[28,\"action\",[[23,0,[]],\"getAccessKeys\"],null],[28,\"action\",[[23,0,[]],\"readFile\"],null],[24,[\"hasExceededLimit\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/nfe-batch-query-generator/template.hbs"
    }
  });

  _exports.default = _default;
});