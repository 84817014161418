define("nfe-customer-console-v2/mirage/factories/certificate-v2", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    // validUntil() {
    //     return faker.random.arrayElement(["2019-09-19T15:00:00Z", "2028-06-12T03:00:00Z"]);
    // },
    validUntil: "2019-09-19T15:00:00Z",
    status: "Active",
    modifiedOn: "2019-09-15T00:00:00Z",
    federalTaxNumber: "19364681000103",
    thumbprint: "F1D1A06E32EDC63F36FE1AD7EAC8B05B592B5472",
    subject: "CN=certificateName"
  });

  _exports.default = _default;
});