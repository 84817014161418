define("nfe-customer-console-v2/components/companies-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lu0IsrPd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isRunning\",\"isEmptyAndNotRunning\",\"isEmpty\",\"data\"],[[24,[\"fetchData\",\"isRunning\"]],[24,[\"isEmptyAndNotRunning\"]],[24,[\"isEmpty\"]],[24,[\"results\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/companies-loader/template.hbs"
    }
  });

  _exports.default = _default;
});