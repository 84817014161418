define("nfe-customer-console-v2/components/certificate-form/component", ["exports", "ember-concurrency", "ember-cp-validations"], function (_exports, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    certificate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o certificado.'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*\.(pfx|p12)$/gi,
      message: 'Selecione um certificado digital válido.'
    })],
    certificatePassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Digite a senha do certificado.'
    })]
  });

  var _default = Ember.Component.extend(validations, {
    tagName: "",
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    activateFileValidations: false,
    activateValidations: false,
    certificate: null,
    certificatePassword: null,
    file: null,
    model: null,
    saveCertificate: (0, _emberConcurrency.task)(function* (file, password) {
      this.set('activateValidations', true);
      if (this.get('validations.isValid') === false) return;
      let response = yield this.model.uploadCertificate(file, password);

      if (response.body.errors) {
        let errorMessage = response.body.errors[0].message;

        if (errorMessage == "certificate password is not valid") {
          errorMessage = this.intl.t("companies.edit.certificate.errors.invalidPassword");
        } else if (errorMessage.includes("certificate is no longer valid")) {
          errorMessage = this.intl.t("companies.edit.certificate.errors.overdue");
        } else if (errorMessage.includes("certificate tax number is not equal to")) {
          errorMessage = this.intl.t("companies.edit.certificate.errors.invalidCnpj");
        } else {
          errorMessage = this.intl.t("companies.edit.certificate.errors.default");
        }

        this.notify.error(errorMessage);
      } else {
        this.triggerReload();
      }
    }),
    actions: {
      uploadCertificate(file) {
        this.set('file', file);
        this.set('certificate', file.name);
        this.set('activateFileValidations', true);
      }

    }
  });

  _exports.default = _default;
});