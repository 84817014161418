define("nfe-customer-console-v2/components/service-invoice-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    // attributes from template
    accountId: '',
    companyId: '',
    invoiceId: '',

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const params = {
        accountId: this.accountId,
        companyId: this.companyId,
        invoiceId: this.invoiceId
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params)) return;
      let data = yield this.store.findRecord('service-invoice', params.invoiceId, {
        adapterOptions: {
          company_id: params.companyId
        }
      });
      return this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});