define("nfe-customer-console-v2/routes/error", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ErrorRoute extends _authenticated.default {}

  _exports.default = ErrorRoute;
});