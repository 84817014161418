define("nfe-customer-console-v2/controllers/nfe/index", ["exports", "ember-parachute", "ember-concurrency"], function (_exports, _emberParachute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AccountsListQueryParams = void 0;
  const AccountsListQueryParams = new _emberParachute.default({
    limit: {
      as: "limit",
      defaultValue: 10,
      refresh: true
    },
    startingAfter: {
      as: "startingAfter",
      defaultValue: null,
      refresh: true
    },
    endingBefore: {
      as: "endingBefore",
      defaultValue: null,
      refresh: true
    },
    page: {
      as: "page",
      defaultValue: 1,
      refresh: true
    }
  });
  _exports.AccountsListQueryParams = AccountsListQueryParams;

  var _default = Ember.Controller.extend(AccountsListQueryParams.Mixin, {
    batchAccessKeysClient: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    features: Ember.inject.service(),
    batch: null,
    tryDeleteBatchModal: false,
    isDevAccount: Ember.computed.equal('model.account.environment', 'Development'),
    refreshList: false,
    perPageOptions: Ember.A([10, 20, 30, 40, 50]),
    queryParamsChanged: Ember.computed.or('queryParamsState.{limit,startingAfter,endingBefore}.changed'),
    direction: Ember.computed('endingBefore', 'statingAfter', function () {
      let direction = null;

      if (this.endingBefore) {
        direction = 'backward';
      } else if (this.endingBefore) {
        direction = 'forward';
      }

      return direction;
    }),
    deleteBatch: (0, _emberConcurrency.task)(function* (batch) {
      try {
        const account = yield this.sessionData.getCurrentAccount();
        yield batch.destroyRecord({
          adapterOptions: {
            account_id: account.id
          }
        });
        this.notify.success(this.intl.t("nfe.deleteBatch.success"));
      } catch (error) {
        this.notify.error(this.intl.t("nfe.deleteBatch.error"));
      }

      this.set('tryDeleteBatchModal', false);
      let sefazNfeBatchRecords = this.store.peekAll('sefaz-nfe-batch');
      if (sefazNfeBatchRecords.content.length == 0) this.toggleProperty('refreshList');
    }),
    downloadBatch: (0, _emberConcurrency.task)(function* (fileName, contentType, url, fileType) {
      try {
        yield this.batchAccessKeysClient.downloadBatch(fileName, fileType, url, contentType);
      } catch (error) {
        this.notify.error(this.intl.t("nfe.downloadBatch.error"));
      }
    }),
    actions: {
      executeRefresh(data) {
        if (data && data.type === 'nfe-query-batch-loader') this.toggleProperty('refreshList');
      },

      tryDeleteBatch(batch) {
        this.set('batch', batch);
        this.set('tryDeleteBatchModal', true);
      },

      mutPerPage(perPage) {
        this.set('limit', perPage);
        this.set('endingBefore', null);
        this.set('startingAfter', null);
        this.set('page', 1);
      },

      mutPage(action, data) {
        if (action === "increment") {
          let lastItem = data.content.lastObject;
          this.set('startingAfter', lastItem.id);
          this.set('direction', 'forward');
          this.incrementProperty("page");
          this.set('endingBefore', null);
        } else {
          let firstItem = data.content.firstObject;
          this.set('endingBefore', firstItem.id);
          this.set('direction', 'backward');
          this.decrementProperty("page");
          this.set('startingAfter', null);
        }
      }

    }
  });

  _exports.default = _default;
});