define("nfe-customer-console-v2/helpers/format-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatString = formatString;
  _exports.default = void 0;

  function formatString([str]) {
    return Ember.String.capitalize(str.toLowerCase());
  }

  var _default = Ember.Helper.helper(formatString);

  _exports.default = _default;
});