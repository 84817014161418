define("nfe-customer-console-v2/services/files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    _convertIntoFormData(formData, data, parentKey) {
      if (Ember.isEmpty(formData)) formData = new FormData();
      if (formData instanceof FormData == false) throw new Error('formData parameter must be an instance of FormData');

      if (Ember.isEmpty(data)) {
        formData.append(parentKey, '');
        return;
      }

      if (typeof data != 'object' || data instanceof Date || data instanceof File) {
        formData.append(parentKey, data);
        return;
      }

      Object.keys(data).forEach(key => {
        if (Ember.isPresent(data[key]) && data[key].hasOwnProperty('blob')) {
          this._convertIntoFormData(formData, data[key].blob, parentKey ? `${parentKey}[${key}]` : key);
        } else {
          this._convertIntoFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        }
      });
      return formData;
    },

    async upload(url, options) {
      let formData = this._convertIntoFormData(null, options.data);

      options.data = formData;
      options.type = options.type || 'POST';
      options.dataType = options.dataType || 'text';
      options.processData = options.processData || false;
      options.contentType = options.contentType || false;
      let response;
      let result = {
        status: 'success',
        message: ''
      };

      try {
        response = await this.ajax.raw(url, options);
      } catch (error) {
        result.status = 'error';
        response = error;
      }

      if (response && response.payload && typeof response.payload == 'string') {
        result.message = response.payload;
      } else if (response && response.payload && response.payload.message) {
        result.message = response.payload.message;
      }

      return result;
    }

  });

  _exports.default = _default;
});