define("nfe-customer-console-v2/components/copy-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ijwqk/5C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-button\",null,[[\"iconButton\",\"onClick\"],[[24,[\"iconButton\"]],[28,\"action\",[[23,0,[]],\"copyToClipboard\",[24,[\"elemId\"]]],null]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/copy-button/template.hbs"
    }
  });

  _exports.default = _default;
});