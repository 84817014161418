define("nfe-customer-console-v2/helpers/format-cnpj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCnpj = formatCnpj;
  _exports.default = void 0;

  function formatCnpj([value]) {
    if (Ember.isEmpty(value)) return;
    return value.toString().padStart(14, '0').replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
  }

  var _default = Ember.Helper.helper(formatCnpj);

  _exports.default = _default;
});