define("nfe-customer-console-v2/components/onboard-steps/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onboard: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    accountEnvironment: Ember.computed.readOnly('session.data.authenticated.current_account.environment'),

    init() {
      this._super(...arguments);

      this.fetchData.perform();
    },

    fetchData: (0, _emberConcurrency.task)(function* () {
      if (!this.reset) {
        yield this.onboard.getServiceOnboardInfo(this.company, this.companies);
      }
    }),
    actions: {
      skipOnboard() {
        this.metrics.trackEvent({
          event: 'Skipped onboarding page',
          category: 'Onboarding service invoice',
          action: 'Skipped onboarding page',
          label: `accountID: ${this.get('session.data.authenticated.current_account.id')}`
        });
        this.features.enable('DFeTech.Onboarding.ServiceInvoice');
        this.router.transitionTo('dashboard');
      },

      startCreateCompany() {
        this.metrics.trackEvent({
          event: 'Started create company page',
          category: 'Onboarding service invoice',
          action: 'Started create company page'
        });
        this.router.transitionTo('companies.new', {
          queryParams: {
            environment: this.accountEnvironment,
            onboarding: true
          }
        });
      },

      startSubmitCertificate(companyId) {
        this.metrics.trackEvent({
          event: 'Started upload certificate page',
          category: 'Onboarding service invoice',
          action: 'Started upload certificate page'
        });
        this.router.transitionTo('companies.edit.certificate', companyId || this.company, {
          queryParams: {
            onboarding: true
          }
        });
      },

      startIssueInvoice(companyId) {
        this.metrics.trackEvent({
          event: 'Started test invoice page',
          category: 'Onboarding service invoice',
          action: 'Started test invoice page'
        });
        this.router.transitionTo('onboard.service-invoice.issue-step', companyId || this.company);
      }

    }
  });

  _exports.default = _default;
});