define("nfe-customer-console-v2/helpers/cnpj-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cnpjValidator = cnpjValidator;
  _exports.default = void 0;

  function _internalVerifyValue(value) {
    if (value.includes('.') || value.includes('-') || value.includes('/')) {
      if (value[2] != '.' || value[6] != '.' || value[10] != '/' || value[15] != '-') {
        return false;
      }
    }

    let formatNumber = value.replace('.', '').replace('.', '').replace('-', '').replace('/', '');

    for (let i = 0; i < formatNumber.length; i++) {
      if (isNaN(parseInt(formatNumber[i]))) {
        return false;
      }
    }

    return true;
  }

  function _internalRemoveNonNumeric(value) {
    let aux = '';

    for (let i = 0; i < value.length; i++) {
      if (!isNaN(parseInt(value[i]))) {
        aux += value[i];
      }
    }

    return aux;
  }

  function _internalCalculateDigit(value) {
    let tmp = value;
    let weight = 1;
    let checksum = 0;

    while (tmp > 0) {
      weight = weight == 9 ? 2 : ++weight;
      checksum += weight * (tmp % 10);
      tmp = Math.trunc(tmp / 10);
    }

    let digit = 11 - checksum % 11;
    if (digit > 9) digit = 0;
    return digit;
  }

  function _internalCalculateDigits(value) {
    // copy current check digits for compare later
    const checkDigits = value % 100; // copy all non-check digits, to calculate check digits.

    value = Math.trunc(value / 100); // calculate first check digit

    let calcDigits = _internalCalculateDigit(value); // append calculated check digit to end of value


    value = value * 10 + calcDigits; // calculate second check digit

    calcDigits = calcDigits * 10 + _internalCalculateDigit(value);
    return calcDigits == checkDigits;
  }

  function cnpjValidator(value) {
    const Length = 14;
    const MaxValue = 99999999999999;
    const MinValue = 191;

    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === "string") {
      if (value === "") {
        return false;
      }

      if (!_internalVerifyValue(value)) {
        return false;
      }

      let aux = _internalRemoveNonNumeric(value);

      if (aux.length != Length) {
        return false;
      }

      if (aux === "00000000000000" || aux === "11111111111111" || aux === "22222222222222" || aux === "33333333333333" || aux === "44444444444444" || aux === "55555555555555" || aux === "66666666666666" || aux === "77777777777777" || aux === "88888888888888" || aux === "99999999999999") {
        return false;
      }

      return _internalCalculateDigits(Number(aux));
    }

    if (typeof value === "number") {
      if (value === 0 || value === 11111111111111 || value === 22222222222222 || value === 33333333333333 || value === 44444444444444 || value === 55555555555555 || value === 66666666666666 || value === 77777777777777 || value === 88888888888888 || value === 99999999999999) {
        return false;
      }

      if (value < MinValue || value > MaxValue) return false;
      return _internalCalculateDigits(value);
    }

    return false;
  }

  var _default = Ember.Helper.helper(cnpjValidator);

  _exports.default = _default;
});