define("nfe-customer-console-v2/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h68eBLHp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"error\"]],\"apikeys\"],null]],null,{\"statements\":[[0,\"\\t\"],[5,\"empty-data-table\",[],[[\"@title\",\"@text\"],[[28,\"t\",[\"error.apikey.title\"],[[\"htmlSafe\"],[true]]],[28,\"t\",[\"error.apikey.text\"],[[\"htmlSafe\"],[true]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"page-not-found\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/error.hbs"
    }
  });

  _exports.default = _default;
});