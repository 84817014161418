define("nfe-customer-console-v2/controllers/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    actions: {
      goToDashboard() {
        this.transitionTo('/dashboard');
      }

    }
  });

  _exports.default = _default;
});