define("nfe-customer-console-v2/adapters/subscription", ["exports", "ember-data-url-templates", "nfe-customer-console-v2/adapters/base-odata"], function (_exports, _emberDataUrlTemplates, _baseOdata) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseOdata.default.extend(_emberDataUrlTemplates.default, {
    queryUrlTemplate: "{+host}{/+namespace}/accounts('{account_id}')/subscriptions",
    queryRecordUrlTemplate: "{+host}{/+namespace}/accounts('{account_id}')/subscriptions('{subscription_id}')"
  });

  _exports.default = _default;
});