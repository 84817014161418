define("nfe-customer-console-v2/mirage/factories/company", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _emberCliMirage.faker.locale = "pt_BR";

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return _emberCliMirage.faker.company.companyName();
    },

    tradeName: "",

    federalTaxNumber() {
      return _emberCliMirage.faker.random.number(99999999999999);
    },

    address: {
      country: "BRA",

      postalCode() {
        return _emberCliMirage.faker.address.zipCode("#####-###");
      },

      street() {
        return _emberCliMirage.faker.address.streetAddress();
      },

      number() {
        return _emberCliMirage.faker.random.number(999);
      },

      additionalInformation() {
        return _emberCliMirage.faker.lorem.sentence();
      },

      district() {
        return _emberCliMirage.faker.lorem.word();
      },

      city: {
        code: "3550308",
        name: "São Paulo"
      },
      state: "SP"
    },
    taxRegime: "MicroempreendedorIndividual",
    specialTaxRegime: "Nenhum",
    legalNature: "EmpresaPublica",
    economicActivities: [],
    municipalTaxNumber: "",
    rpsSerialNumber: "IO",
    rpsNumber: 1,
    issRate: 0.0,
    environment: "Development",
    fiscalStatus: "Pending",
    certificate: {
      expiresOn: "2019-09-03T11:15:34+00:00",
      modifiedOn: "2019-02-19T12:29:28.4533909+00:00",
      status: "Active",
      thumbprint: "1D1827FFF2D63EF4F1E1BFC657425D4FA396B78F"
    },
    createdOn: "2018-11-06T17:43:28.6057732+00:00",
    modifiedOn: "2019-02-05T13:05:22.9002691+00:00"
  });

  _exports.default = _default;
});