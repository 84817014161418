define("nfe-customer-console-v2/helpers/nfe-events", ["exports", "nfe-customer-console-v2/models/enums-nfe"], function (_exports, _enumsNfe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nfeEvents = nfeEvents;
  _exports.default = void 0;

  function nfeEvents([value]) {
    return _enumsNfe.default.events.filter(v => v.id == value)[0].text;
  }

  var _default = Ember.Helper.helper(nfeEvents);

  _exports.default = _default;
});