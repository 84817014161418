define("nfe-customer-console-v2/models/payment", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    number: (0, _model.attr)('string'),
    effectiveOn: (0, _model.attr)('string'),
    cancelledOn: (0, _model.attr)('string'),
    createdOn: (0, _model.attr)('string'),
    modifiedOn: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    netAmount: (0, _model.attr)('number'),
    accountId: (0, _model.attr)('string'),
    gatewayCustomerId: (0, _model.attr)('string'),
    gatewayOrderId: (0, _model.attr)('string'),
    refundAmount: (0, _model.attr)('number'),
    feeAmount: (0, _model.attr)('number'),
    paidAmount: (0, _model.attr)('number'),
    discountAmount: (0, _model.attr)('number'),
    amount: (0, _model.attr)('number'),
    penaltyAmount: (0, _model.attr)('number'),
    taxWithheldAmount: (0, _model.attr)('number'),
    dueOn: (0, _model.attr)('string'),
    paymentMethodTypes: (0, _attributes.array)(),
    invoices: (0, _attributes.array)()
  });

  _exports.default = _default;
});