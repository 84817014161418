define("nfe-customer-console-v2/serializers/state-tax", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.hasOwnProperty("hasMore") && payload.stateTaxes) {
        payload = {
          stateTaxes: payload.stateTaxes,
          meta: {
            hasMore: payload.hasMore
          }
        };
      }

      if (payload.stateTax) payload.stateTax.stateTaxId = payload.stateTax.id;
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serialize(snapshot, options) {
      let json = this._super(...arguments);

      if (snapshot.adapterOptions) json.environmentType = snapshot.adapterOptions.environmentType;
      if (!json.specialTaxRegime) delete json.specialTaxRegime;
      if (json.stateTaxId) json.id = json.stateTaxId;
      return json;
    }

  });

  _exports.default = _default;
});