define("nfe-customer-console-v2/controllers/account/providers", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    serviceProvider: Ember.inject.service('provider'),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    changeProviderStatusMessage: Ember.computed('provider.isActive', function () {
      const message = this.intl.t("account.providers.dialog.content", {
        action: this.provider.isActive ? "deactivate" : "activate"
      });
      return message + this.intl.t(`account.providers.tableBody.${this.provider.namespace}`);
    }),
    toggleProvider: (0, _emberConcurrency.task)(function* (action, provider) {
      let currentAccountImpersonate = this.get('session.data.authenticated.current_account_impersonate');
      let currentAccount = this.get('session.data.authenticated.current_account');
      let account = currentAccountImpersonate ? currentAccountImpersonate : currentAccount;

      try {
        if (action === "activate") yield this.serviceProvider.activate(account, provider);
        if (action === "deactivate") yield this.serviceProvider.deactivate(account, provider);
        this.send("refreshRoute");
      } catch (error) {
        this.notify.error(this.intl.t('account.providers.toggleProvider.error', {
          action
        }));
      }

      this.set('toggleProviderModal', false);
    }),
    actions: {
      toggleProviderModal(provider) {
        this.set('toggleProviderModal', true);
        this.set('provider', provider);
      }

    }
  });

  _exports.default = _default;
});