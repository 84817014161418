define("nfe-customer-console-v2/models/organization/address", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _fragment, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const Validations = (0, _emberCpValidations.buildValidations)({
    postalCode: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^\d{2}\.?\d{3}\-?\d{3}$/,
        message: 'CEP inválido'
      })]
    },
    line1: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: 4,
        message: 'Minimo de 4 caracteres'
      })]
    },
    line2: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'Minimo de 1 caracteres'
      }), (0, _emberCpValidations.validator)('length', {
        max: 10,
        message: 'Máximo de 10 caracteres'
      })]
    },
    districtOrCounty: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: 3,
        message: 'Minimo de 3 caracteres'
      })]
    },
    city: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    stateOrProvince: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    }
  });
  let OrganizationAddressModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _attributes.fragment)('address-city'), (_class = (_temp = class OrganizationAddressModel extends _fragment.default.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "country", _descriptor, this);

      _initializerDefineProperty(this, "stateOrProvince", _descriptor2, this);

      _initializerDefineProperty(this, "districtOrCounty", _descriptor3, this);

      _initializerDefineProperty(this, "postalCode", _descriptor4, this);

      _initializerDefineProperty(this, "line1", _descriptor5, this);

      _initializerDefineProperty(this, "line2", _descriptor6, this);

      _initializerDefineProperty(this, "line3", _descriptor7, this);

      _initializerDefineProperty(this, "city", _descriptor8, this);
    } // serialize() {
    //   let model = this.getProperties([
    //     'country',
    //     'stateOrProvince',
    //     'city',
    //     'districtOrCounty',
    //     'postalCode',
    //     'line1',
    //     'line2',
    //     'line3'
    //   ]);
    //   model.city = this.get('city.name');
    //   model.cityCode = this.get('city.code');
    //   model.country = "BRA";
    //   return model;
    // }


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "country", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stateOrProvince", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "districtOrCounty", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "postalCode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "line1", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "line2", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "line3", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrganizationAddressModel;
  ;
});