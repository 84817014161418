define("nfe-customer-console-v2/components/webhook-v2-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WebhookV2FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = (_temp = class WebhookV2FormComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _defineProperty(this, "selectedContentType", "json");

      _defineProperty(this, "clients", Ember.A([]));

      _defineProperty(this, "contentTypes", Ember.A(["json", "form-urlencoded"]));

      _defineProperty(this, "nfeFilters", Ember.Object.create({
        "product_invoice-issued_successfully": false,
        "product_invoice-issued_failed": false,
        "product_invoice-issued_error": false,
        "product_invoice-cancelled_successfully": false,
        "product_invoice-cancelled_failed": false,
        "product_invoice-cancelled_error": false,
        "product_invoice-cce_failed": false,
        "product_invoice-cce_error": false,
        "product_invoice-cce_successfully": false
      }));

      _defineProperty(this, "gnreFilters", Ember.Object.create({
        "tax_payment_form-created_successfully": false,
        "tax_payment_form-creation_failed": false,
        "tax_payment_form-creation_not_needed": false
      }));

      _defineProperty(this, "productFilters", Ember.Object.create({
        "product_tax-created_successfully": false,
        "product_tax-custom_rules_requested": false,
        "product_tax-creation_failed": false
      }));

      _defineProperty(this, "nfceFilters", Ember.Object.create({
        "consumer_invoice-issued_successfully": false,
        "consumer_invoice-issued_failed": false,
        "consumer_invoice-issued_error": false,
        "consumer_invoice-cancelled_successfully": false,
        "consumer_invoice-cancelled_failed": false,
        "consumer_invoice-cancelled_error": false
      }));

      _defineProperty(this, "nfeDistFilters", Ember.Object.create({
        "product_invoice_inbound-issued_successfully": false,
        "product_invoice_inbound-event_raised_successfully": false,
        "product_invoice_inbound_summary-issued_successfully": false,
        "product_invoice_inbound_summary-event_raised_successfully": false
      }));

      _defineProperty(this, "cteDistFilters", Ember.Object.create({
        "transportation_invoice_inbound-issued_successfully": false,
        "transportation_invoice_inbound-event_raised_successfully": false
      }));
    }

    async setClientIds() {
      this._clients = Ember.A([]);
      let currentAccount = await this.sessionData.getCurrentAccount();
      this.store.query('subject-client', {
        subscription_id: currentAccount.id
      }).then(subjectClients => {
        subjectClients.map(client => this.clients.pushObject(client.id));
      });
    }

    async init() {
      super.init(...arguments);
      let model = this.model;
      let filters = this[`${model.type}Filters`];
      let modelFilters = this.get('model.filters');
      modelFilters && modelFilters.map(item => {
        filters.set(item, true);
      });

      if (this.features.get("dFeTechWebHookEncryption")) {
        this.contentTypes.push("json-web-encryption");
        await this.setClientIds();
      }
    }

    toggleFilter(type, filter) {
      let items = this[`${type}Filters`];
      items.toggleProperty(filter);
      let filters = Object.keys(items).filter(key => items[key] === true);
      this.set('model.filters', filters);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleFilter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFilter"), _class.prototype)), _class));
  _exports.default = WebhookV2FormComponent;
  ;
});