define("nfe-customer-console-v2/adapters/provider", ["exports", "@ember-data/adapter/rest", "ember-simple-auth/mixins/data-adapter-mixin", "ember-data-url-templates", "nfe-customer-console-v2/config/environment"], function (_exports, _rest, _dataAdapterMixin, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_emberDataUrlTemplates.default, _dataAdapterMixin.default, {
    host: _environment.default.APP.API_URL,
    sessionData: Ember.inject.service(),
    queryUrlTemplate: '{+host}/accounts/{account_id}/providers',
    createRecordUrlTemplate: '{+host}/accounts/{account_id}/providers/{provider}/register',
    deleteRecordUrlTemplate: '{+host}/accounts/{account_id}/providers/{provider}/unregister',
    headers: Ember.computed.readOnly('sessionData.getRequestTokenHeaders'),

    getProviders(accountId) {
      const url = this.buildURL('provider', null, {
        account_id: accountId
      }, 'query');
      return this.ajax(url);
    },

    activateProvider(accountId, provider) {
      const url = this.buildURL('provider', null, {
        account_id: accountId,
        provider
      }, 'createRecord');
      return this.ajax(url, "POST");
    },

    deactivateProvider(accountId, provider) {
      const url = this.buildURL('provider', null, {
        account_id: accountId,
        provider
      }, 'deleteRecord');
      return this.ajax(url, "POST");
    }

  });

  _exports.default = _default;
});