define("nfe-customer-console-v2/components/account-invoices-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FETCH_DEBOUNCE = 1000;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform(this.accountId);
    },

    fetchData: (0, _emberConcurrency.task)(function* (accountId) {
      if (Ember.isBlank(accountId)) return;
      if (FETCH_DEBOUNCE > 0) yield (0, _emberConcurrency.timeout)(FETCH_DEBOUNCE);
      const adapter = this.store.adapterFor('organization');
      let invoices = yield adapter.getAllInvoices(accountId);
      return this.set('results', invoices.value);
    }).restartable()
  });

  _exports.default = _default;
});