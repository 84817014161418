define("nfe-customer-console-v2/controllers/account/organization", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    activateValidations: false,
    updateAccount: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateValidations', true);

      if (model.get('validations.isInvalid')) {
        return this.notify.error('Verifique os campos obrigatórios!');
      }

      try {
        yield model.save(); // const adapter = this.store.adapterFor('organization');
        // yield adapter.update(model);

        this.notify.success('Conta atualizada com sucesso!');
        this.transitionToRoute('account');
      } catch (error) {
        this.notify.error('Erro ao atualizar conta!');
      }
    }).drop()
  });

  _exports.default = _default;
});