define("nfe-customer-console-v2/serializers/certificate-v2", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    primaryKey: 'thumbprint',

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.certificates) payload = {
        certificateV2s: payload.certificates
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});