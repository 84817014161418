define("nfe-customer-console-v2/adapters/address", ["exports", "@ember-data/adapter/rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _rest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AddressAdapter extends _rest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", _environment.default.APP.ADDRESS_API_URL);

      _defineProperty(this, "apiKey", _environment.default.APP.OPEN_API_APIKEY);
    }

    // namespace = 'v2';
    // queryRecordUrlTemplate = "{+host}/{+namespace}/addresses/{postalCode}";
    // headers = {
    //   'Authorization': this.apiKey
    // };
    getAddress(postalCode) {
      const url = `${this.host}/v2/addresses/${postalCode}`;
      return super.ajax(url, "GET", {
        headers: {
          Authorization: this.apiKey
        }
      });
    }

  }

  _exports.default = AddressAdapter;
  ;
});