define("nfe-customer-console-v2/services/report", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    convertToGoogleChart(data) {
      const result = [];
      result.push(['', 'Quantidade', 'Valor em Reais', {
        role: 'style'
      }]);
      result.push(...data.map(i => [(0, _moment.default)(i.period, 'MM').format('MMMM'), i.values.count, i.values.amount, 'opacity: 0.8']));
      return result;
    },

    convertTotals(data) {
      let totals = {};
      data.forEach(item => {
        let name = item.name.replace("ServiceInvoice_", "").toLowerCase();
        totals[name] = {
          timestamp: item.timestamp,
          value: item.value
        };
      });
      return totals;
    },

    async serviceInvoiceMetrics(accountId, year) {
      const result = await this.store.adapterFor('usage-report').getUsageReport(accountId, 'service_invoices', year);
      return {
        chart: this.convertToGoogleChart(result.report),
        totals: this.convertTotals(result.metrics)
      };
    }

  });

  _exports.default = _default;
});