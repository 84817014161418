define("nfe-customer-console-v2/routes/account/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    titleToken: 'Gerenciamento de contas',

    async model() {
      const account = await this.sessionData.getCurrentAccount();
      const subscription = await this.store.queryRecord('account', {
        account_id: account.id
      });
      return {
        parentId: account.parentId,
        subscription
      };
    },

    actions: {
      saveAccount() {
        this.sendAction('saveAccount');
      }

    }
  });

  _exports.default = _default;
});