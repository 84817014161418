define("nfe-customer-console-v2/models/company-v2", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: '2',
        max: '60',
        message: 'Deve ter entre 2 a 60 caracteres'
      })]
    },
    federalTaxNumber: {
      validators: [(0, _emberCpValidations.validator)('cnpj')]
    },
    taxRegime: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    accountId: (0, _model.attr)('string'),
    federalTaxNumber: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    tradeName: (0, _model.attr)('string'),
    address: (0, _attributes.fragment)('address'),
    issRate: (0, _model.attr)('number'),
    taxRegime: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    createdOn: (0, _model.attr)('date'),
    modifiedOn: (0, _model.attr)('date'),
    isActive: Ember.computed.equal('status', 'Active'),
    isValid: Ember.computed.and('validations.isValid', 'address.validations.isValid')
  });

  _exports.default = _default;
});