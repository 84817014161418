define("nfe-customer-console-v2/controllers/onboard/service-invoice/issue-step", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const debounce10s = 10000;
  const debounce1m = 60000;
  const debounce2m = 120000;
  const debounce5m = 300000;

  var _default = Ember.Controller.extend({
    serviceInvoice: Ember.inject.service(),
    session: Ember.inject.service(),
    provider: Ember.inject.service(),
    features: Ember.inject.service(),
    metrics: Ember.inject.service(),
    store: Ember.inject.service(),
    onboard: Ember.inject.service(),
    alreadyIssued: true,
    wantToIssue: true,
    selectedTax: null,
    invoiceIssuedSuccess: false,
    invoiceIssuedError: false,
    invoiceCancelledSuccess: false,
    invoiceCancelledError: false,
    issueServiceInvoiceModal: false,
    cancelServiceInvoiceModal: false,
    waitingModal: false,
    companyEnvironment: Ember.computed.readOnly("model.environment"),
    companyId: Ember.computed.readOnly('model.id'),
    invoice: null,
    //This task will run all steps of the issue process
    testIssueProcess: (0, _emberConcurrency.task)(function* (returnLaterInvoice) {
      let invoice = null; //Close issue confirmation modal

      this.set('issueServiceInvoiceModal', false); //Reset property to rollback the step error style

      this.set('invoiceIssuedError', false); //Check if has an invoice that already enter in the issue process
      //If true, use this invoice for the remaining steps

      if (returnLaterInvoice && returnLaterInvoice.id) {
        invoice = returnLaterInvoice;
        this.set('invoice', invoice);
      } //Start loop to run all issue steps


      for (let i = 0; i < this.onboard.issueProcessSteps.length; i++) {
        const step = this.onboard.issueProcessSteps[i]; //If step status is completed, go to next step

        if (step.status === "completed") continue;
        Ember.set(step, 'status', "running"); //If invoice flowStatus is "IssueFailed" or "Issued", don't make request to API again

        if (!invoice || invoice.flowStatus != "IssueFailed" && invoice.flowStatus != "Issued") {
          try {
            invoice = yield this[step.task].perform(step);
          } catch (error) {
            console.log(error);
          }
        }

        yield (0, _emberConcurrency.timeout)(debounce10s); //If flowStatus still is equal to step invoiceStatus, rollback to run step again

        if (invoice.flowStatus == step.invoiceStatus) {
          i--;
          if (step.count == 2) yield (0, _emberConcurrency.timeout)(debounce1m);
          if (step.count == 3) yield (0, _emberConcurrency.timeout)(debounce2m);

          if (step.count > 3) {
            this.set('waitingModal', step);
            yield (0, _emberConcurrency.timeout)(debounce5m);
          }
        } else if (invoice.flowStatus == "IssueFailed") {
          //If the invoice issue failed, exit the loop
          break;
        } else {
          Ember.set(step, 'status', "completed");
          Ember.set(step, 'count', 0);
        }
      } //If the invoice was issued, go to next step where user can download it


      if (invoice.flowStatus == "Issued") {
        this.set('invoiceIssuedSuccess', true);
        this.set('currentStep', 5);
        this.metrics.trackEvent({
          event: 'Invoice issued',
          category: 'Onboarding service invoice',
          action: 'Invoice issued',
          invoiceId: invoice.id
        });
      } //If the invoice was failed, go to next step where user can see the error


      if (invoice.flowStatus == "IssueFailed") {
        //Reset issue steps if the user wants to do the issue process again
        this.onboard.resetSteps(this.onboard.issueProcessSteps); //Go to next step where user can see the error

        this.set('invoiceIssuedError', invoice.flowMessage);
        this.set('currentStep', 5);
        this.metrics.trackEvent({
          event: 'Invoice issue failed',
          category: 'Onboarding service invoice',
          action: 'Invoice issue failed',
          label: `Error: ${invoice.flowMessage}`,
          invoiceId: invoice.id
        });
      }
    }),
    issueServiceInvoice: (0, _emberConcurrency.task)(function* () {
      let metricsData = {
        event: 'Submitted invoice to issue',
        category: 'Onboarding service invoice',
        action: 'Submitted invoice to issue',
        label: null
      };

      try {
        const invoice = this.store.createRecord('service-invoice', this.onboard.createServiceInvoiceToTest(this.companyEnvironment, this.selectedTax));
        const result = yield invoice.save({
          adapterOptions: {
            company_id: this.companyId
          }
        });
        this.set('invoiceId', result.id);
        this.set('invoice', result);
        metricsData.label = 'Success';
        metricsData.invoiceId = result.id;
        return result;
      } catch (error) {
        let statusCode = error.status;
        let message = `[${statusCode}] ${error.payload.message}`;
        this.set('invoiceIssuedError', message);
        this.set('currentStep', 5);
        this.testIssueProcess.cancelAll();
        metricsData.label = `Error: ${message}`;
      }

      this.metrics.trackEvent(metricsData);
    }),
    //This task will run all steps of the cancel process
    testCancelProcess: (0, _emberConcurrency.task)(function* (returnLaterInvoice) {
      let invoice = null; //Close cancel confirmation modal

      this.set('cancelServiceInvoiceModal', false); //Check if has an invoice that already enter in the cancel process
      //If true, use this invoice for the remaining steps

      if (returnLaterInvoice && returnLaterInvoice.id) {
        invoice = returnLaterInvoice;
        this.set('invoice', invoice);
      } //Start loop to run all cancel steps


      for (let i = 0; i < this.onboard.cancelProcessSteps.length; i++) {
        const step = this.onboard.cancelProcessSteps[i]; //If step status is completed, go to next step

        if (step.status === "completed") continue;
        Ember.set(step, 'status', "running"); //If invoice flowStatus is "IssueFailed" or "Issued", don't make request to API again

        if (!invoice || invoice.flowStatus != "CancelFailed" && invoice.flowStatus != "Cancelled") {
          try {
            invoice = yield this[step.task].perform(step);
          } catch (error) {
            console.log(error);
          }
        }

        yield (0, _emberConcurrency.timeout)(debounce10s); //If flowStatus still is equal to step invoiceStatus, rollback to run step again

        if (invoice.flowStatus == step.invoiceStatus) {
          i--;
          if (step.count == 2) yield (0, _emberConcurrency.timeout)(debounce1m);
          if (step.count == 3) yield (0, _emberConcurrency.timeout)(debounce2m);

          if (step.count > 3) {
            this.set('waitingModal', step);
            yield (0, _emberConcurrency.timeout)(debounce5m);
          }
        } else if (invoice.flowStatus == "CancelFailed") {
          //If the invoice cancel failed, exit the loop
          break;
        } else {
          Ember.set(step, 'status', "completed");
          Ember.set(step, 'count', 0);
        }
      } //If the invoice was cancelled, go to next step where user can download it


      if (invoice.flowStatus == "Cancelled") {
        this.set('invoiceCancelledSuccess', true);
        this.set('currentStep', 7);
        this.metrics.trackEvent({
          event: 'Invoice cancelled',
          category: 'Onboarding service invoice',
          action: 'Invoice cancelled',
          invoiceId: invoice.id
        });
      }

      if (invoice.flowStatus == "CancelFailed") {
        //Reset cancel steps if the user wants to do the issue process again
        this.onboard.resetSteps(this.onboard.cancelProcessSteps); //Go to next step where user can see the error

        this.set('invoiceCancelledError', invoice.flowMessage);
        this.set('currentStep', 7);
        this.metrics.trackEvent({
          event: 'Invoice cancel failed',
          category: 'Onboarding service invoice',
          action: 'Invoice cancel failed',
          label: `Error: ${invoice.flowMessage}`,
          invoiceId: invoice.id
        });
      }
    }),
    cancelServiceInvoice: (0, _emberConcurrency.task)(function* () {
      let metricsEvent = {
        event: 'Submitted invoice to cancel',
        category: 'Onboarding service invoice',
        action: 'Submitted invoice to cancel',
        label: null
      };

      try {
        const result = yield this.serviceInvoice.cancel(this.companyId, this.invoice);
        metricsEvent.label = 'Success';
        metricsEvent['invoiceId'] = result.id;
        return result;
      } catch (ex) {
        let message = 'Falha ao enviar a nota fiscal para cancelamento';

        if (ex && ex.isAdapterError && ex.errors.length > 0) {
          message = ex.errors[0].detail || ex.errors[0].title;
          metricsEvent.label = `Error: [${ex.errors[0].status}] ${message}`;
        }

        metricsEvent.label = `Error: [unknow] ${message}`;
        this.set('invoiceCancelledError', message);
        this.set('currentStep', 7);
        this.testCancelProcess.cancelAll();
      }

      this.metrics.trackEvent(metricsEvent);
    }),
    checkServiceInvoiceStatus: (0, _emberConcurrency.task)(function* (step) {
      Ember.set(step, 'count', step.count + 1);
      return yield this.store.findRecord('service-invoice', this.invoiceId, {
        adapterOptions: {
          company_id: this.companyId
        }
      });
    }),
    downloadPdf: (0, _emberConcurrency.task)(function* (status) {
      yield this.serviceInvoice.download('pdf', this.invoice, this.companyId);
      this.metrics.trackEvent({
        event: 'Downloaded PDF',
        category: 'Onboarding service invoice',
        action: 'Downloaded PDF',
        label: `${status} invoice`,
        invoiceId: this.invoiceId
      });
    }),
    downloadXml: (0, _emberConcurrency.task)(function* (status) {
      yield this.serviceInvoice.download('xml', this.invoice, this.companyId);
      this.metrics.trackEvent({
        event: 'Downloaded XML',
        category: 'Onboarding service invoice',
        action: 'Downloaded XML',
        label: `${status} invoice`,
        invoiceId: this.invoiceId
      });
    }),
    actions: {
      skipTestInvoice() {
        this.metrics.trackEvent({
          event: 'Skipped test invoice page',
          category: 'Onboarding service invoice',
          action: 'Skipped test invoice page',
          label: `accountID: ${this.get('session.data.authenticated.current_account.id')}`
        });
        return this.transitionToRoute('onboard.service-invoice');
      },

      selectTax(nextStep) {
        this.metrics.trackEvent({
          event: 'Selected tax',
          category: 'Onboarding service invoice',
          action: 'Selected tax',
          tax: this.selectedTax.cityService.code,
          city: this.selectedTax.city.name
        });
        return nextStep();
      },

      returnLater(step) {
        this.features.enable('DFeTech.Onboarding.ServiceInvoice');
        this.metrics.trackEvent({
          event: 'Clicked return later button',
          category: 'Onboarding service invoice',
          action: 'Clicked return later button',
          label: `${step.invoiceStatus} modal`
        });
        return this.transitionToRoute('dashboard');
      },

      async finishOnboard() {
        let account = this.get('session.data.authenticated.current_account');
        await this.provider.activate(account, "DFeTech.Onboarding.ServiceInvoice");
        this.metrics.trackEvent({
          event: 'Finished onboard',
          category: 'Onboarding service invoice',
          action: 'Finished onboard'
        });
        this.transitionToRoute("onboard.service-invoice", {
          queryParams: {
            success: true
          }
        });
      }

    }
  });

  _exports.default = _default;
});