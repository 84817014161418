define("nfe-customer-console-v2/models/ticket/product", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const Validations = (0, _emberCpValidations.buildValidations)({
    providerNamespace: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    companyId: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    queryParameter: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    naturalPersonbirthDate: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    queryDate: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    }
  });
  let ProductModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('date'), (_class = (_temp = class ProductModel extends _fragment.default.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "providerNamespace", _descriptor, this);

      _initializerDefineProperty(this, "invoiceId", _descriptor2, this);

      _initializerDefineProperty(this, "companyId", _descriptor3, this);

      _initializerDefineProperty(this, "federalTaxNumber", _descriptor4, this);

      _initializerDefineProperty(this, "queryParameter", _descriptor5, this);

      _initializerDefineProperty(this, "naturalPersonbirthDate", _descriptor6, this);

      _initializerDefineProperty(this, "queryDate", _descriptor7, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "providerNamespace", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoiceId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "companyId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "federalTaxNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "queryParameter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "naturalPersonbirthDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "queryDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProductModel;
});