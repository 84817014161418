define("nfe-customer-console-v2/routes/onboard/service-invoice/issue-step", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    titleToken: "Etapas para a emissão de NFSe",

    async model({
      companyId
    }) {
      const account = this.sessionData.getSessionCurrentAccount();
      return this.store.queryRecord('company', {
        accountId: account.id,
        companyId
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          currentStep: 0,
          alreadyIssued: true,
          wantToIssue: true,
          selectedTax: null,
          invoiceId: null,
          invoiceIssuedSuccess: false,
          invoiceIssuedError: false,
          invoiceCancelledSuccess: false,
          invoiceCancelledError: false,
          waitingMessage: null,
          invoice: null
        });
        controller.testIssueProcess.cancelAll();
        controller.testCancelProcess.cancelAll();
      }
    }

  });

  _exports.default = _default;
});