define("nfe-customer-console-v2/jwt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseJSONWebToken = _exports.decodeJSONWebToken = void 0;

  // Decode JSON Web Token
  const decodeJSONWebToken = str => {
    if (typeof atob === 'function') {
      return atob(str);
    } else if (typeof FastBoot === 'object') {
      try {
        const buffer = FastBoot.require('buffer');

        return buffer.Buffer.from(str, 'base64').toString('utf-8');
      } catch (err) {
        throw new Error('buffer must be available for decoding base64 strings in FastBoot. Make sure to add buffer to your fastbootDependencies.');
      }
    } else {
      throw new Error('Neither atob nor the FastBoot global are avaialble. Unable to decode base64 strings.');
    }
  }; // Parse JSON Web Token


  _exports.decodeJSONWebToken = decodeJSONWebToken;

  const parseJSONWebToken = token => {
    if (!token) return null;
    if (token.access_token) token = token.access_token;
    const payload = token.split('.')[1];
    const decodedPayload = decodeJSONWebToken(payload.replace(/-/g, '+').replace(/_/g, '/'));
    const tokenData = decodeURIComponent(window.escape(decodedPayload));

    try {
      return JSON.parse(tokenData);
    } catch (error) {
      return tokenData;
    }
  };

  _exports.parseJSONWebToken = parseJSONWebToken;
});