define("nfe-customer-console-v2/mirage/fixtures/apikeys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    parentId: "54244a1be340420fdc94ab59",
    id: "5EThQZhp3Uto6JoeDWiIRjmjrGug8fi4k2b1R9gLUQ0Raexd0WmI5PrnWdJU9rO",
    description: "Dados (open.nfe.io)",
    createdOn: "2019-02-20T15:00:02.7166629+00:00",
    modifiedOn: "2018-09-19T20:06:58.0863057+00:00",
    status: "Active"
  }, {
    parentId: "54244a1be340420fdc94ab59",
    id: "sd2rM9ssEUMKbTWBuP5kKZq8gSSwGEsVwBMKVsj7xWpaZamq43vwCxWaEHeYNqu",
    description: "Nota Fiscal (api.nfe.io)",
    createdOn: "2019-02-20T15:00:02.7166629+00:00",
    modifiedOn: "2018-08-02T19:08:52.399209+00:00",
    status: "Active"
  }, {
    parentId: "54244a1be340420fdc94ab59",
    id: "yucG1xbixd2SxjihAwmTKnx7z9pmD9NwfMYxzCVWDx3G999NAkC6LpBnqqWQoXScMWl",
    description: "Nota Fiscal (api.nfe.io)",
    createdOn: "2014-09-25T17:00:11+00:00",
    modifiedOn: "2016-09-08T00:29:39.4816084+00:00",
    status: "Active"
  }];
  _exports.default = _default;
});