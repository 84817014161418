define("nfe-customer-console-v2/mirage/fixtures/providers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": "accounts/54244a1be340420fdc94ab59/providers/DFeTech.ServiceInvoice",
    "namespace": "DFeTech.ServiceInvoice",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DFeTech.ProductInvoice",
    "namespace": "DFeTech.ProductInvoice",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DFeTech.ConsumerInvoice",
    "namespace": "DFeTech.ConsumerInvoice",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DFeTech.Company",
    "namespace": "DFeTech.Company",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DFeTech.WebHook",
    "namespace": "DFeTech.WebHook",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DFeTech.Mail",
    "namespace": "DFeTech.Mail",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/AzHub.Billing",
    "namespace": "AzHub.Billing",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/AzHub.Account",
    "namespace": "AzHub.Account",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/AzHub.Usage",
    "namespace": "AzHub.Usage",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DataTech.WebHook",
    "namespace": "DataTech.WebHook",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DataTech.ProductInvoice",
    "namespace": "DataTech.ProductInvoice",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DataTech.NaturalPerson",
    "namespace": "DataTech.NaturalPerson",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DataTech.LegalEntity",
    "namespace": "DataTech.LegalEntity",
    "status": "Inactive"
  }, {
    "id": "accounts/54244a1be340420fdc94ab59/providers/DataTech.Batch",
    "namespace": "DataTech.Batch",
    "status": "Inactive"
  }];
  _exports.default = _default;
});