define("nfe-customer-console-v2/adapters/usage-month", ["exports", "@ember-data/adapter/rest", "ember-data-url-templates", "nfe-customer-console-v2/config/environment"], function (_exports, _rest, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_emberDataUrlTemplates.default, {
    host: _environment.default.APP.USAGES_API_URL,
    queryUrlTemplate: '{+host}/api/accounts{/account_id}/usages{/year}{/month}',
    queryRecordUrlTemplate: '{+host}/api/accounts{/account_id}/usages{/year}{/month}'
  });

  _exports.default = _default;
});