define("nfe-customer-console-v2/models/enums-nfe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let enums = Ember.Object.extend();
  enums.reopenClass({
    status: [{
      id: "Error",
      text: 'Erro'
    }, {
      id: "None",
      text: 'Nenhum'
    }, {
      id: "Created",
      text: 'Criada'
    }, {
      id: "Processing",
      text: 'Processando'
    }, {
      id: "Issued",
      text: 'Emitida'
    }, {
      id: "Cancelled",
      text: 'Cancelada'
    }],
    taxRegimes: [{
      id: "MicroempreendedorIndividual",
      text: 'Microempreendedor Individual (MEI)'
    }, {
      id: 'SimplesNacional',
      text: 'Simples Nacional'
    }, {
      id: 'SimplesNacionalExcessoSublimite',
      text: 'Simples Nacional, excesso sublimite da receita bruta'
    }, {
      id: "LucroPresumido",
      text: 'Lucro Presumido'
    }, {
      id: "LucroReal",
      text: 'Lucro Real'
    }, {
      id: "Isento",
      text: 'Isento'
    }],
    specialTaxRegimes: [{
      id: 'Nenhum',
      text: 'Nenhum'
    }, {
      id: 'Automatico',
      text: 'Automatico'
    }, {
      id: 'MicroempresaMunicipal',
      text: 'Microempresa Municipal'
    }, {
      id: 'Estimativa',
      text: 'Estimativa'
    }, {
      id: 'SociedadeDeProfissionais',
      text: 'Sociedade de Profissionais'
    }, {
      id: 'Cooperativa',
      text: 'Cooperativa'
    }, {
      id: 'MicroempreendedorIndividual',
      text: 'Microempreendedor Individual'
    }, {
      id: 'MicroempresarioEmpresaPequenoPorte',
      text: 'Microempresario e Empresa de Pequeno Porte'
    }],
    destination: [{
      id: 'International_Operation',
      text: '3 - Operação com exterior'
    }, {
      id: 'Interstate_Operation',
      text: '2 - Operação Interestadual'
    }, {
      id: 'Internal_Operation',
      text: '1 - Operação Interna'
    }],
    consumerType: [{
      id: 'FinalConsumer',
      text: 'Consumidor final'
    }, {
      id: 'Normal',
      text: 'Normal'
    }],
    presenceType: [{
      id: 'None',
      text: '0 - Não se aplica'
    }, {
      id: 'Presence',
      text: '1 - Operação presencial'
    }, {
      id: 'Internet',
      text: '2 - Internet, operação não presencial'
    }, {
      id: 'Telephone',
      text: '3 - Teleatendimento, operação não presencial'
    }, {
      id: 'Delivery',
      text: '4 - NFC-e em operação com entrega a domicílio'
    }, {
      id: 'OthersNoPresente',
      text: '9 - Outros, operação não presencial'
    }],
    processType: [{
      id: 'OwnSoftware',
      text: '0 - Software Proprio'
    }, {
      id: 'FiscoSingle',
      text: '1 - Avulso pelo Fisco'
    }, {
      id: 'TaxPayerSingle',
      text: '2 - Avulso pelo Contribuinte'
    }, {
      id: 'FiscoSoftware',
      text: '3 - Software Fisco'
    }],
    purposeType: [{
      id: 'Devolution',
      text: '4 - Devolução'
    }, {
      id: 'Adjustment',
      text: '3 - Ajuste'
    }, {
      id: 'Complement',
      text: '2 - Complementar'
    }, {
      id: 'Normal',
      text: '1 - Normal'
    }],
    operationType: [{
      id: 'Incoming',
      text: '0 - Entrada'
    }, {
      id: 'Outgoing',
      text: '1 - Saida'
    }],
    paymentType: [{
      id: 'InCash',
      text: '0 - A vista'
    }, {
      id: 'Term',
      text: '1 - Prazo'
    }, {
      id: 'Others',
      text: '2 - Outros'
    }],
    currentStatus: [{
      id: 'Unknown',
      text: 'Desconhecido'
    }, {
      id: 'Authorized',
      text: 'Autorizado'
    }, {
      id: 'Canceled',
      text: 'Cancelado'
    }],
    environmentType: [{
      id: 'Production',
      text: 'Produção'
    }, {
      id: 'Test',
      text: 'Teste'
    }],
    freightModality: [{
      id: 'ByIssuer',
      text: 'Por conta do emitente'
    }, {
      id: 'ByReceiver',
      text: 'Por conta do destinatário'
    }, {
      id: 'ByThirdParties',
      text: 'Por conta de terceiro'
    }, {
      id: 'Free',
      text: 'Sem frete'
    }],
    //NFe Events
    events: [// Utils
    {
      id: 'InvoiceCreated',
      text: 'Nota cridada'
    }, {
      id: 'IssuerCreated',
      text: 'Emitente criado'
    }, {
      id: 'Refused',
      text: 'Recusada'
    }, // Define Number
    {
      id: 'MaxDefineNumberAndSerieFailed',
      text: 'Máxima retentativa ao definir número e série'
    }, {
      id: 'DefineNumberAndSerieFailed',
      text: 'Falha ao definir número e série'
    }, {
      id: 'DefinedNumberAndSerieSuccessfully',
      text: 'Número e série definidos com sucesso'
    }, // Send signed
    {
      id: 'MaxSignAndUploadBatchXmlFailed',
      text: 'Máxima retentativa ao assinar e gravar o lote'
    }, {
      id: 'SignAndUploadBatchXmlFailed',
      text: 'Falha ao assinar e gravar o lote'
    }, {
      id: 'SignAndUploadBatchXmlWithFailed',
      text: 'Assinar e gravar o lote com falha'
    }, {
      id: 'InvoiceXmlSigned',
      text: 'Nota assinada com sucesso'
    }, {
      id: 'SentSignedBatchSuccess',
      text: 'Lote enviado com sucesso'
    }, {
      id: 'SentSignedBatchWithError',
      text: 'Lote enviado com erro'
    }, {
      id: 'MaxSendSignedBatchFailed',
      text: 'Máxima retentativa ao enviar lote'
    }, {
      id: 'SendSignedBatchFailed',
      text: 'Falha ao enviar lote'
    }, // Check Authorization
    {
      id: 'MaxAuthorizationFailed',
      text: 'Máxima retentativa ao autorizar'
    }, {
      id: 'Authorized',
      text: 'Autorizado com sucesso'
    }, {
      id: 'AuthorizationFailed',
      text: 'Falha ao autorizar'
    }, {
      id: 'AuthorizationDuplicated',
      text: 'Autorização duplicada'
    }, {
      id: 'AuthorizationWithFailed',
      text: 'Autorização com falha'
    }, // Merge
    {
      id: 'MaxMergeProtocolAuthorizationTryCount',
      text: 'Máxima retentativa de inclusão do protocolo de autorização'
    }, {
      id: 'MergeAuthorizationProtocolInvalid',
      text: 'Inclusão do protocolo de autorização inválida'
    }, {
      id: 'MergeAuthorizationProtocolFailed',
      text: 'Falha na inclusão do protocolo de autorização'
    }, {
      id: 'Merged',
      text: 'Protocolo de autorização incluído com sucesso'
    }, // Reprocess
    {
      id: 'ReprocessExecuted',
      text: 'Reprocessamento executado'
    }, {
      id: 'ReprocessDefineNumber',
      text: 'Reprocessamento de definição de número'
    }, {
      id: 'ReprocessSendSignedBatch',
      text: 'Reprocessamento de envio do lote'
    }, {
      id: 'ReprocessCheckAuthorization',
      text: 'Reprocessamento de checagem de autorização'
    }, {
      id: 'ReprocessMergeProtrocol',
      text: 'Reprocessamento de inclusão do protocolo'
    }, {
      id: 'InvoiceSetAccessKey',
      text: 'Definido a chave de acesso da nota'
    }, // Cancel
    {
      id: 'DefinedEventBatchSuccessfully',
      text: 'Sucesso ao definir evento do lote'
    }, {
      id: 'DefineEventBatchFailed',
      text: 'Falha ao definir evento do lote'
    }, {
      id: 'MaxDefineEventBatchFailed',
      text: 'Máxima retentativa ao definir evento do lote'
    }, {
      id: 'MaxSignAndUploadCancelXmlFailed',
      text: 'Máxima retentativa ao assinar e gravar XML de cancelamento'
    }, {
      id: 'SignAndUploadCancelXmlFailed',
      text: 'Falha ao assinar e gravar XML de cancelamento'
    }, {
      id: 'SignAndUploadCancelXmlWithFailed',
      text: 'Assinar e gravar XML de cancelamento com falha'
    }, {
      id: 'Cancelled',
      text: 'Cancelada com sucesso'
    }, {
      id: 'SendToCancelFailed',
      text: 'Falha ao enviar para cancelamento'
    }, {
      id: 'SentToCancelWithFailed',
      text: 'Enviado para cancelamento com falha'
    }, {
      id: 'MaxSendToCancel',
      text: 'Máxima retentativa no envio para cancelamento'
    }, {
      id: 'MergeCancellationInvalid',
      text: 'Inclusão de cancelamento inválida'
    }, {
      id: 'MergeCancellationFailed',
      text: 'Falha da inclusãod e cancelamento'
    }, {
      id: 'MaxMergeCancellation',
      text: 'Máxima retentativa na inclusão de cancelamento'
    }, {
      id: 'CancelXmlSigned',
      text: 'Nota de cancelamento assinada com sucesso'
    }, {
      id: 'MergedCancellation',
      text: 'Cancelamento incluído com sucesso'
    }, // Notification
    {
      id: 'WebHooksNotFound',
      text: 'Nenhum webhook encontrado'
    }, {
      id: 'WebHooksDispatched',
      text: 'Webhooks enviados com sucesso'
    }]
  });
  var _default = enums;
  _exports.default = _default;
});