define("nfe-customer-console-v2/components/issue-batch-stepper/component", ["exports", "ember-cp-validations", "ember-concurrency"], function (_exports, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    spreadsheet: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione sua planilha.'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*\.(xlsx)$/gi,
      message: 'Selecione uma planilha de excel válida.'
    })]
  });

  var _default = Ember.Component.extend(validations, {
    tagName: '',
    batchLimit: 50,
    features: Ember.inject.service(),
    modalData: Ember.computed('selectedSheet', function () {
      let data = {
        videoUrl: "https://www.youtube.com/embed/Jfyz3S8wd_c",
        docLink: "https://nfe.io/docs/nossa-plataforma/nota-fiscal-servico/emitir-nota-servico/"
      };

      if (this.selectedSheet == 'advanced') {
        data.videoUrl = "https://www.youtube.com/embed/OBDz_qgOlcM";
      }

      return data;
    }),
    statusReadFile: {
      status: "success",
      message: "Planilha importada com sucesso!",
      icon: "check"
    },
    readFile: (0, _emberConcurrency.task)(function* (nextStep) {
      nextStep();
      yield (0, _emberConcurrency.timeout)(3000);
      let reader = new FileReader();

      reader.onload = e => {
        let workbook = XLSX.read(e.target.result, {
          type: 'binary'
        }); // Get worksheet

        let worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Set !ref to convert to json only the first 50 lines
        // worksheet['!ref'] = "A1:AG1000";

        if (this.get("features.dFeTechServiceInvoiceBatch")) this.set('batchLimit', 500); // Read all rows from First Sheet into an JSON array.

        let excelRows = this.parseSheet(XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false
        }));

        if (excelRows.length > this.batchLimit) {
          this.set('statusReadFile', {
            status: "error",
            message: `Insira um planilha com no máximo ${this.batchLimit} notas fiscais`,
            icon: "error-outline"
          });
        } else {
          excelRows.forEach(item => {
            this.validateFields(item);
          });
          this.set('results', excelRows.map(item => Ember.Object.create(item)));
        }
      };

      return reader.readAsBinaryString(this.file);
    }),

    parseSheet(rows) {
      // get index of location fields
      let locationState = rows[0].indexOf('Estado_Prestacao_Servico');
      let locationCity = rows[0].indexOf('Cidade_Prestacao_Servico');
      let locationCityCode = rows[0].indexOf('Codigo_Cidade_Prestacao_Servico'); // get index of taxes with held

      let irAmountWithheld = rows[0].indexOf('Retencao_IR');
      let pisAmountWithheld = rows[0].indexOf('Retencao_PIS');
      let cofinsAmountWithheld = rows[0].indexOf('Retencao_COFINS');
      let csllAmountWithheld = rows[0].indexOf('Retencao_CSLL');
      let inssAmountWithheld = rows[0].indexOf('Retencao_INSS');
      let issAmountWithheld = rows[0].indexOf('Retencao_ISS');
      let othersAmountWithheld = rows[0].indexOf('Retencao_OUTROS'); // get index of other optional fields

      let additionalInformation = rows[0].indexOf('Informacoes_Adicionais');
      let taxationType = rows[0].indexOf('Tipo_Tributacao');
      let issRate = rows[0].indexOf('Aliquota_ISS');
      let issTaxAmount = rows[0].indexOf('Valor_ISS');
      let cnaeCode = rows[0].indexOf('CNAE');
      let municipalTaxNumber = rows[0].indexOf('Inscricao_Municipal');
      let borrowerType = rows[0].indexOf('Tipo_Tomador');
      let externalId = rows[0].indexOf('ID_Externo'); //get index of deductions

      let deductionsAmount = rows[0].indexOf('Valor_Deducoes');
      let discountUnconditionedAmount = rows[0].indexOf('Valor_Desconto_Incondicionado');
      let discountConditionedAmount = rows[0].indexOf('Valor_Desconto_Condicionado'); //get index of paid amount

      let paidAmount = rows[0].indexOf('Valor_Recebido'); // Slice to remove the headers from sheet

      let rowsWithoutHeaders = rows.slice(1);
      return rowsWithoutHeaders.map(row => {
        if (row[0]) row[0] = row[0].toString().replace(/\D/g, ''); // format percentage value

        if (row[issRate]) row[issRate] = row[issRate] / 100; // Format amount fields

        if (row[3] && typeof row[3] != 'number') row[3] = this.formatAmount(row[3]);
        if (row[issTaxAmount] && typeof row[issTaxAmount] != 'number') row[issTaxAmount] = this.formatAmount(row[issTaxAmount]);
        if (row[irAmountWithheld] && typeof row[irAmountWithheld] != 'number') row[irAmountWithheld] = this.formatAmount(row[irAmountWithheld]);
        if (row[pisAmountWithheld] && typeof row[pisAmountWithheld] != 'number') row[pisAmountWithheld] = this.formatAmount(row[pisAmountWithheld]);
        if (row[cofinsAmountWithheld] && typeof row[cofinsAmountWithheld] != 'number') row[cofinsAmountWithheld] = this.formatAmount(row[cofinsAmountWithheld]);
        if (row[csllAmountWithheld] && typeof row[csllAmountWithheld] != 'number') row[csllAmountWithheld] = this.formatAmount(row[csllAmountWithheld]);
        if (row[inssAmountWithheld] && typeof row[inssAmountWithheld] != 'number') row[inssAmountWithheld] = this.formatAmount(row[inssAmountWithheld]);
        if (row[issAmountWithheld] && typeof row[issAmountWithheld] != 'number') row[issAmountWithheld] = this.formatAmount(row[issAmountWithheld]);
        if (row[othersAmountWithheld] && typeof row[othersAmountWithheld] != 'number') row[othersAmountWithheld] = this.formatAmount(row[othersAmountWithheld]);
        if (row[deductionsAmount] && typeof row[deductionsAmount] != 'number') row[deductionsAmount] = this.formatAmount(row[deductionsAmount]);
        if (row[discountUnconditionedAmount] && typeof row[discountUnconditionedAmount] != 'number') row[discountUnconditionedAmount] = this.formatAmount(row[discountUnconditionedAmount]);
        if (row[discountConditionedAmount] && typeof row[discountConditionedAmount] != 'number') row[discountConditionedAmount] = this.formatAmount(row[discountConditionedAmount]);
        if (row[paidAmount] && typeof row[paidAmount] != 'number') row[paidAmount] = this.formatAmount(row[paidAmount]);
        return {
          // default fields
          CPF_CNPJ: row[0],
          Nome: row[1],
          Email: row[2],
          Valor: row[3],
          Codigo_Servico: row[4],
          Endereco_Pais: row[5],
          Endereco_Cep: row[6],
          Endereco_Logradouro: row[7],
          Endereco_Numero: row[8],
          Endereco_Complemento: row[9],
          Endereco_Bairro: row[10],
          Endereco_Cidade_Codigo: row[11],
          Endereco_Cidade_Nome: row[12],
          Endereco_Estado: row[13],
          Descricao: row[14],
          Data_Competencia: row[15],
          // optional fields
          CNAE: row[cnaeCode],
          Inscricao_Municipal: row[municipalTaxNumber],
          Informacoes_Adicionais: row[additionalInformation],
          Tipo_Tributacao: row[taxationType],
          Aliquota_ISS: row[issRate],
          Valor_ISS: row[issTaxAmount],
          Retencao_IR: row[irAmountWithheld],
          Retencao_PIS: row[pisAmountWithheld],
          Retencao_COFINS: row[cofinsAmountWithheld],
          Retencao_CSLL: row[csllAmountWithheld],
          Retencao_INSS: row[inssAmountWithheld],
          Retencao_ISS: row[issAmountWithheld],
          Retencao_OUTROS: row[othersAmountWithheld],
          Valor_Deducoes: row[deductionsAmount],
          Valor_Desconto_Incondicionado: row[discountUnconditionedAmount],
          Valor_Desconto_Condicionado: row[discountConditionedAmount],
          Tipo_Tomador: row[borrowerType],
          Estado_Prestacao_Servico: row[locationState],
          Cidade_Prestacao_Servico: row[locationCity],
          Codigo_Cidade_Prestacao_Servico: row[locationCityCode],
          ID_Externo: row[externalId],
          Valor_Recebido: row[paidAmount]
        };
      });
    },

    formatAmount(value, hasDecimals = true) {
      let result = 0;
      let dotIndex = value.indexOf(".");
      let commaIndex = value.indexOf(",");

      if (dotIndex >= 0 && commaIndex == -1 && value.lastIndexOf(".") === dotIndex) {
        result = Number(value);
      } else if (commaIndex >= 0 && dotIndex == -1 && value.lastIndexOf(",") === commaIndex) {
        result = Number(value.replace(",", "."));
      } else {
        if (commaIndex == -1) {
          result = Number(value.replace(/[.]/g, ""));
        } else if (dotIndex == -1) {
          result = Number(value.replace(/[,]/g, ""));
        } else if (dotIndex < commaIndex) {
          result = Number(value.replace(/[.]/g, "").replace(",", "."));
        } else {
          result = Number(value.replace(/[,]/g, ""));
        }
      }

      if (!hasDecimals) result = result | 0;
      return result;
    },

    validateFields(item) {
      let requiredFields = ['Codigo_Servico', 'Endereco_Pais', 'Valor', 'Descricao'];
      let invalidFields = [];

      if (item.Data_Competencia && item.Data_Competencia.toString().includes('-') == false) {
        invalidFields.push('Data_Competencia');
        item.hasInvalidFields = true;
        item.invalidFields = invalidFields;
      }

      if (isNaN(item.Valor)) {
        invalidFields.push('Valor');
        item.hasInvalidFields = true;
        item.invalidFields = invalidFields;
      } // Check valid taxation type 


      if (item.Tipo_Tributacao) {
        const taxationTypeList = Ember.A(["None", "WithinCity", "OutsideCity", "Export", "Free", "Immune", "SuspendedCourtDecision", "SuspendedAdministrativeProcedure", "OutsideCityFree", "OutsideCityImmune", "OutsideCitySuspended", "OutsideCitySuspendedAdministrativeProcedure", "ObjectiveImune", "ObjectiveOutsideCityImune"]);

        if (!taxationTypeList.some(i => i == item.Tipo_Tributacao)) {
          invalidFields.push('Tipo_Tributacao');
          item.hasInvalidFields = true;
          item.invalidFields = invalidFields;
        }
      }

      for (const key in item) {
        let element = item[key];

        if (element === undefined || element === '') {
          if (requiredFields.indexOf(key) >= 0) {
            invalidFields.push(key);
            item.hasInvalidFields = true;
            item.invalidFields = invalidFields;
          }
        }
      }

      return item;
    },

    actions: {
      uploadSheet(ev) {
        let file = ev.target.files[0];
        this.set('spreadsheet', file.name);
        this.set('file', file);
        this.set('activateFileValidations', true);
      },

      selectSheet(selected) {
        this.setProperties({
          selectedBasicSheet: false,
          selectedAdvancedSheet: false
        });
        this.set(selected, true);
      }

    }
  });

  _exports.default = _default;
});