define("nfe-customer-console-v2/components/animated-box-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dtar/yJF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"box-info\",[],[[\"@transitionTo\",\"@contentClass\",\"@headline\"],[[28,\"action\",[[23,0,[]],\"openClose\"],null],\"layout-row layout-align-center-center\",[22,\"headline\"]]],{\"statements\":[[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/animated-box-info/template.hbs"
    }
  });

  _exports.default = _default;
});