define("nfe-customer-console-v2/controllers/companies-v2/edit/basic-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    activateValidations: false,
    actions: {
      submit(model) {
        this.set('activateValidations', true);
        return new Promise((resolve, reject) => {
          if (model.get('isValid') === false) {
            reject();
          } else {
            this.send('updateCompany', model, resolve, reject);
          }
        });
      },

      slowClick() {
        return new Promise(resolve => {
          Ember.run.later(function () {
            console.log('slowClick promise finished'); // eslint-disable-line

            resolve();
          }, 5000);
        });
      }

    }
  });

  _exports.default = _default;
});