define("nfe-customer-console-v2/routes/onboard/service-invoice/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: "Primeiros passos para a emissão de NFSe",
    sessionData: Ember.inject.service(),

    async model() {
      const account = this.sessionData.getSessionCurrentAccount();
      const companies = await this.store.query('company', {
        accountId: account.id
      });
      return {
        companies
      };
    }

  });

  _exports.default = _default;
});