define("nfe-customer-console-v2/controllers/account/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    needs: ['main']
  });

  _exports.default = _default;
});