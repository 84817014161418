define("nfe-customer-console-v2/components/payment-actions/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    paymentStatus: null,
    paymentMessage: null,
    wireTransfer: false,
    instantPayment: false,
    isRunning: Ember.computed.or('payCreditCard.isRunning', 'payBankSlip.isRunning', 'payPix.isRunning'),
    payCreditCard: (0, _emberConcurrency.task)(function* (paymentId, card) {
      this.set('paymentStatus', 'processing');
      yield (0, _emberConcurrency.timeout)(3000);

      try {
        const adapter = this.store.adapterFor('payment');
        const result = yield adapter.payCreditCard(paymentId, card);

        if (result.status === 'captured') {
          result.status = 'success';
        }

        this.set('paymentStatus', result.status);
        this.set('paymentMessage', result.message);
      } catch (error) {
        this.set('paymentStatus', 'internalServerError');
      }
    }),
    payBankSlip: (0, _emberConcurrency.task)(function* (paymentId) {
      try {
        const adapter = this.store.adapterFor('payment');
        const result = yield adapter.payBankSlip(paymentId);
        if (result.status === 'Success') return window.open(result.url);
      } catch (error) {
        return this.notify.error('Não foi possível carregar o boleto.');
      }
    }),
    payPix: (0, _emberConcurrency.task)(function* (paymentId) {
      try {
        const result = yield this.store.adapterFor('payment').payPix(paymentId);

        if (result.status === "Success") {
          this.set('instantPayment', result);
        }
      } catch (error) {
        return this.notify.error('Erro ao carregar QR Code');
      }
    }),
    actions: {
      pay(paymentId, paymentMethod) {
        switch (paymentMethod.type) {
          case 'CreditCard':
            return this.payCreditCard.perform(paymentId, paymentMethod);

          case 'WireTransfer':
            return this.set('wireTransfer', true);

          case 'BankSlip':
            return this.payBankSlip.perform(paymentId);

          case 'InstantPayment':
            return this.payPix.perform(paymentId);
        }
      }

    }
  });

  _exports.default = _default;
});