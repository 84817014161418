define("nfe-customer-console-v2/adapters/apikey", ["exports", "nfe-customer-console-v2/adapters/base-rest"], function (_exports, _baseRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApiKeysAdapter extends _baseRest.default {
    findMany(store, type, id, snapshots) {
      // In the adapter you will have access to adapterOptions.
      let adapterOptions = snapshots[0].adapterOptions;
      return super.findMany(...arguments);
    }

  }

  _exports.default = ApiKeysAdapter;
});