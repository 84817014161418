define("nfe-customer-console-v2/components/nfe-events-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const {
        companyId,
        invoiceId,
        limit,
        startingAfter
      } = this;
      const query = {
        limit,
        startingAfter
      };
      this.fetchData.perform(companyId, invoiceId, query);
    },

    fetchData: (0, _emberConcurrency.task)(function* (companyId, invoiceId, query) {
      if (Ember.isBlank(invoiceId)) return;
      const data = yield this.store.adapterFor('product-invoice').getEvents(companyId, invoiceId, query); //debounce

      yield (0, _emberConcurrency.timeout)(1000);
      return this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});