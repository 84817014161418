define("nfe-customer-console-v2/mirage/fixtures/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: "54244a1be340420fdc94ab59",
    createdOn: "2014-09-25T17:00:11.395506+00:00",
    modifiedOn: "2019-01-31T16:50:50.4936134+00:00",
    name: "Infra",
    federalTaxNumber: 0,
    email: "infra@nfe.io",
    address: {
      country: "",
      postalCode: "05416-011",
      street: "Rua Fradique Coutinho",
      number: "701",
      additionalInformation: "",
      district: "Pinheiros",
      city: {
        code: "3550308",
        name: "São Paulo"
      },
      state: "SP"
    },
    paymentMethods: [],
    status: "Active",
    environment: "Development"
  }];
  _exports.default = _default;
});