define("nfe-customer-console-v2/controllers/create-account", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    metrics: Ember.inject.service(),
    leads: Ember.inject.service(),
    user: Ember.inject.service(),
    createAccount: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateContactValidations', true);
      if (model.validations.isInvalid) return;

      try {
        const adapter = this.store.adapterFor('account');
        yield adapter.create(model);
        this.notify.success('Conta criada com sucesso!');
      } catch (error) {
        let message = 'Erro ao criar conta.';
        if (error.errors.length > 0 && error.errors[0].status === "409") message = 'Nome da conta já está sendo utilizado.';
        return this.notify.error(message);
      }

      try {
        const currentUser = yield this.user.getCurrent.perform();
        yield this.leads.sendLeads(currentUser.name, currentUser.email, currentUser.phone_number, model.name);
        const account = yield this.sessionData.getCurrentAccount();
        this.metrics.identify({
          distinctId: account.id,
          $email: account.email,
          $name: account.name,
          Environment: account.environment
        });
        this.transitionToRoute('onboard');
      } catch (error) {
        this.transitionToRoute('onboard');
      }
    }),
    actions: {
      checkAccountName(model, nextStep) {
        this.set('activateNameValidations', true);
        let validName = model.validations.attrs.name.isValid;
        if (validName) return nextStep();
      }

    }
  });

  _exports.default = _default;
});