define("nfe-customer-console-v2/components/validated-input-mask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P6WSiYOm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-input-mask\",null,[[\"id\",\"label\",\"type\",\"placeholder\",\"autocomplete\",\"mask\",\"regex\",\"skipOptionalPartCharacter\",\"numericInput\",\"rightAlign\",\"showMaskOnHover\",\"disabled\",\"isTouched\",\"value\",\"onChange\",\"errors\"],[[24,[\"id\"]],[24,[\"label\"]],[24,[\"type\"]],[24,[\"placeholder\"]],[24,[\"autocomplete\"]],[24,[\"mask\"]],[24,[\"regex\"]],\" \",true,false,false,[24,[\"disabled\"]],[24,[\"shouldDisplayValidations\"]],[28,\"get\",[[24,[\"model\"]],[24,[\"valuePath\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[28,\"get\",[[24,[\"model\"]],[24,[\"valuePath\"]]],null]],null]],null],[28,\"get\",[[24,[\"model\"]],[28,\"concat\",[\"validations.attrs.\",[24,[\"valuePath\"]],\".messages\"],null]],null]]],{\"statements\":[[0,\"\\n    \"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"fixedHint\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"hint\"],[10,\"data-test-input-hint\",\"\"],[8],[1,[22,\"hint\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[24,[\"input\",\"hasValue\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"hint\"],[10,\"data-test-input-hint\",\"\"],[8],[1,[22,\"hint\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/validated-input-mask/template.hbs"
    }
  });

  _exports.default = _default;
});