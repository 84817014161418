define("nfe-customer-console-v2/components/validated-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "16x2GYdb",
    "block": "{\"symbols\":[\"select\"],\"statements\":[[5,\"paper-select\",[],[[\"@label\",\"@value\",\"@selected\",\"@options\",\"@searchField\",\"@searchPlaceholder\",\"@searchEnabled\",\"@closeOnSelect\",\"@disabled\",\"@isInvalidAndTouched\",\"@onChange\"],[[22,\"label\"],[28,\"get\",[[24,[\"model\"]],[24,[\"valuePath\"]]],null],[22,\"selected\"],[22,\"options\"],[22,\"searchField\"],[22,\"searchPlaceholder\"],[22,\"searchEnabled\"],true,[22,\"disabled\"],[28,\"and\",[[24,[\"isTouched\"]],[28,\"get\",[[24,[\"model\"]],[28,\"concat\",[\"validations.attrs.\",[24,[\"valuePath\"]],\".isInvalid\"],null]],null]],null],[28,\"action\",[[23,0,[]],\"changeSelect\",[24,[\"valuePath\"]]],null]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[28,\"or\",[[28,\"eq\",[[24,[\"valuePath\"]],\"state\"],null],[28,\"eq\",[[24,[\"valuePath\"]],\"code\"],null],[28,\"eq\",[[24,[\"valuePath\"]],\"stateOrProvince\"],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[\"code\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"fixedHint\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"hint\"],[8],[1,[22,\"hint\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[24,[\"selected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"hint\"],[8],[1,[22,\"hint\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/validated-select/template.hbs"
    }
  });

  _exports.default = _default;
});