define("nfe-customer-console-v2/controllers/companies-v2/product-invoices/events", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ProductInvoiceEventsQueryParams = void 0;
  const ProductInvoiceEventsQueryParams = new _emberParachute.default({
    limit: {
      as: "limit",
      defaultValue: 10,
      refresh: true,
      replace: true
    },
    startingAfter: {
      as: "startingAfter",
      defaultValue: null,
      refresh: true,
      replace: true
    },
    page: {
      as: "page",
      defaultValue: 1,
      refresh: true,
      replace: true
    }
  });
  _exports.ProductInvoiceEventsQueryParams = ProductInvoiceEventsQueryParams;

  var _default = Ember.Controller.extend(ProductInvoiceEventsQueryParams.Mixin, {
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    perPageOptions: Ember.A([10, 15, 20]),
    queryParamsChanged: Ember.computed.or('queryParamsState.{limit,startingAfter}.changed'),
    actions: {
      mutPerPage(perPage) {
        this.set('limit', perPage);
        this.set('startingAfter', null);
        this.set('page', 1);
      },

      mutPage(action, data) {
        if (action === "increment") {
          let lastItem = data.lastObject.sequence - this.limit - 1;
          let startingAfter = lastItem < 0 ? 0 : lastItem;
          this.set('startingAfter', startingAfter);
          this.set('direction', 'forward');
          this.incrementProperty("page");
        } else {
          let firstItem = data.firstObject;
          this.set('startingAfter', this.page === 2 ? null : firstItem.sequence);
          this.set('direction', 'backward');
          this.decrementProperty("page");
        }
      }

    }
  });

  _exports.default = _default;
});