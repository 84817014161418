define("nfe-customer-console-v2/routes/companies-v2/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    titleToken: "Empresas NF-e",

    async model() {
      let currentAccount = await this.sessionData.getCurrentAccount();
      return {
        accountId: currentAccount.id
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('limit', 50);
        controller.set('startingAfter', null);
        controller.set('endingBefore', null);
        controller.set('page', 1);
      }
    }

  });

  _exports.default = _default;
});