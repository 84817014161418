define("nfe-customer-console-v2/adapters/cte-dist", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _baseRest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CteDistAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", _environment.default.APP.NFSE_V2_API_URL);

      _defineProperty(this, "namespace", 'v2');

      _defineProperty(this, "queryRecordUrlTemplate", '{+host}/{+namespace}/companies/{companyId}/inbound{/apiKey}/transportationinvoices');

      _defineProperty(this, "createRecordUrlTemplate", '{+host}/{+namespace}/companies/{companyId}/inbound{/apiKey}/transportationinvoices');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/{+namespace}/companies/{companyId}/inbound{/apiKey}/transportationinvoices');

      _defineProperty(this, "urlSegments", {
        host() {
          return this.get('host');
        },

        namespace() {
          return this.get('namespace');
        },

        companyId(type, id, snapshot, query) {
          let companyId;

          if (query) {
            companyId = query.companyId;
          } else if (snapshot && snapshot.adapterOptions) {
            companyId = snapshot.adapterOptions.companyId;
          } else {
            companyId = snapshot.companyId;
          }

          return companyId;
        },

        apiKey(type, id, snapshot, query) {
          let apiKey;

          if (query) {
            apiKey = query.apiKey;
          } else if (snapshot && snapshot.adapterOptions) {
            apiKey = snapshot.adapterOptions.apiKey;
          } else {
            apiKey = snapshot.apiKey;
          }

          return apiKey;
        }

      });
    }

    get headers() {
      return super.get('headersApiKey');
    }

    // async updateCteParams(companyId, cte) {
    //   const url = this.buildURL('cte-dist', null, { companyId }, 'createRecord');
    //   await this.deleteCteParams(companyId);
    //   return this.ajax(url, 'POST', {
    //     data: cte.serialize()
    //   });
    // }
    deleteCteParams(companyId) {
      const url = this.buildURL('cte-dist', null, {
        companyId
      }, 'deleteRecord');
      return this.ajax(url, 'DELETE');
    }

    handleResponse(status, headers, payload, requestData) {
      let response = super.handleResponse(...arguments);

      if (status !== 200) {
        response.code = status;
        response.description = payload.errors ? payload.errors[0].message : payload;
      }

      return response;
    }

  }

  _exports.default = CteDistAdapter;
  ;
});