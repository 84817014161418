define("nfe-customer-console-v2/models/ticket", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberCpValidations, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // TODO: convert all these messages into intl translations
  const Validations = (0, _emberCpValidations.buildValidations)({
    category: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    department: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    type: {
      validators: [(0, _emberCpValidations.validator)('dependent', {
        on: ['category', 'department']
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    subtype: {
      validators: [(0, _emberCpValidations.validator)('dependent', {
        on: ['type']
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        message: 'Insira um email válido'
      })]
    },
    description: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    clientDepartment: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Insira um telefone para tratar de questões financeiras'
      }), (0, _emberCpValidations.validator)('intl-tel', {
        message: 'Insira um telefone válido'
      })]
    }
  });
  let TicketModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _attributes.fragment)('ticket/product'), _dec14 = (0, _attributes.fragment)('account'), (_class = (_temp = class TicketModel extends _model.default.extend(Validations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "accountId", _descriptor, this);

      _initializerDefineProperty(this, "accountEnvironment", _descriptor2, this);

      _initializerDefineProperty(this, "accountStatus", _descriptor3, this);

      _initializerDefineProperty(this, "category", _descriptor4, this);

      _initializerDefineProperty(this, "department", _descriptor5, this);

      _initializerDefineProperty(this, "type", _descriptor6, this);

      _initializerDefineProperty(this, "subtype", _descriptor7, this);

      _initializerDefineProperty(this, "name", _descriptor8, this);

      _initializerDefineProperty(this, "clientDepartment", _descriptor9, this);

      _initializerDefineProperty(this, "email", _descriptor10, this);

      _initializerDefineProperty(this, "description", _descriptor11, this);

      _initializerDefineProperty(this, "phone", _descriptor12, this);

      _initializerDefineProperty(this, "product", _descriptor13, this);

      _initializerDefineProperty(this, "account", _descriptor14, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accountId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "accountEnvironment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "accountStatus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "department", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "subtype", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "clientDepartment", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "product", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TicketModel;
  ;
});