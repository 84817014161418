define("nfe-customer-console-v2/services/authenticate-implicit", ["exports", "ember-simple-auth/authenticators/oauth2-implicit-grant", "nfe-customer-console-v2/jwt", "nfe-customer-console-v2/config/environment"], function (_exports, _oauth2ImplicitGrant, _jwt, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticateImplicit = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = (_temp = class AuthenticateImplicit extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "masterTab", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _defineProperty(this, "authenticator", 'authenticator:oauth2-implicit-grant');

      _defineProperty(this, "namespace", 'authenticate-implicit');

      _defineProperty(this, "isSilentRefreshEnabled", true);

      _defineProperty(this, "tokenExpireName", 'exp');

      _defineProperty(this, "refreshLeeway", 240);

      _defineProperty(this, "refreshFrame", null);

      _defineProperty(this, "backupTimer", null);
    }

    mountRedirectUrl(action) {
      const configs = _environment.default['ember-simple-auth-oauth2-implicit'];
      let queryUrl = {
        client_id: configs.clientId,
        grant_type: configs.grantType || 'code',
        response_type: configs.responseType || 'token',
        scope: configs.scope,
        redirect_uri: `${window.location.origin}/login-callback`,
        state: this._randomString(20),
        nonce: this._randomString(20)
      };

      if (action == 'refreshToken') {
        queryUrl.prompt = 'none';
        queryUrl.redirect_uri = `${window.location.origin}/silent-callback.html`;
        if (this.session.data.authenticated && this.session.data.authenticated.state) queryUrl.state = this.session.data.authenticated.state;
      }

      const queryString = Object.keys(queryUrl).map(k => `${k}=${encodeURIComponent(queryUrl[k])}`).join('&');
      return `${configs.host}${configs.authEndpoint}?${queryString}`;
    }

    initialize() {
      if (!this.isSilentRefreshEnabled) {
        Ember.debug(`${this.namespace}: silent refresh is disabled`);
        return;
      }

      const data = this.get('session.data.authenticated.access_token');
      if (!data) return;
      const token = (0, _jwt.parseJSONWebToken)(data);
      const expiresAt = token[this.tokenExpireName];
      const now = Math.floor(new Date().getTime() / 1000); // get current time

      if (Ember.isEmpty(expiresAt) || expiresAt <= now) return;
      let wait = expiresAt - now - this.refreshLeeway;
      if (wait < 0) wait = undefined;
      Ember.run.once(this, '_schedule', wait);
    }

    _schedule(delay) {
      Ember.debug(`${this.namespace}: schedule silent refresh in ${delay}s`); // Schedule main refresh attempt

      Ember.run.later(this, '_refreshToken', delay * 1000); // Schedule backup refresh attempt (in case master tab fails / is replaced)

      const additionalDelay = Math.floor(0.5 * this.refreshLeeway);
      let timer = Ember.run.later(this, '_refreshToken', (delay + additionalDelay) * 1000);
      this.set('backupTimer', timer);
    }

    _refreshToken() {
      Ember.debug(`${this.namespace}: refresh token init`); // Set up callback for hidden iframe refresh flow

      if (!window._ESA_Oauth2_Process_Implicit_Refresh) {
        window._ESA_Oauth2_Process_Implicit_Refresh = data => new Ember.RSVP.Promise(resolve => this._callbackRefreshToken(data, resolve));
      }

      const masterTab = this.masterTab;
      masterTab.run(() => {
        // Refresh already in progress?
        if (!!this.refreshFrame) return;
        const frame = Ember.$(`<iframe style="display:none" src="${this.mountRedirectUrl('refreshToken')}"></iframe>`);
        this.set('refreshFrame', frame);
        Ember.$('body').append(frame);
      }).else(() => masterTab.contestMasterTab());
    }

    async _callbackRefreshToken(hash, resolve) {
      Ember.debug(`${this.namespace}: refresh token callback`);
      const impersonatedAccount = this.get('sessionData.isImpersonated') ? this.session.data.authenticated.current_account_impersonate : null; // Authenticate using provided hash data

      await this.session.authenticate(this.authenticator, (0, _oauth2ImplicitGrant.parseResponse)(hash)); // Remove iframe / reset reference

      if (!!this.refreshFrame) {
        this.refreshFrame.remove();
        this.set('refreshFrame', null);
      } // Remove backup schedule


      if (!!this.backupTimer) {
        Ember.run.cancel(this.backupTimer);
        this.set('backupTimer', null);
      }

      await this.sessionData.getCurrentAccountEqueue.perform();

      if (impersonatedAccount) {
        await this.sessionData.impersonate(impersonatedAccount.id);
      }

      resolve();
    }

    _randomString(length) {
      const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
      let result = [];
      window.crypto.getRandomValues(new Uint8Array(length)).forEach(function (c) {
        result.push(charset[c % charset.length]);
      });
      return result.join('');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "masterTab", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AuthenticateImplicit;
});