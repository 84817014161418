define("nfe-customer-console-v2/components/register-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VRIrJqwA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"is-empty\",[[24,[\"text\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"--\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isCurrency\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"format-number\",[[24,[\"text\"]]],[[\"format\"],[\"BRL\"]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"success\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"md-label-success\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"md-label-error\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"warn\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"md-label-warning\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"href\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[7,\"a\",true],[11,\"href\",[22,\"text\"]],[10,\"target\",\"_blank\"],[8],[1,[22,\"text\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[22,\"text\"],false],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/register-data/template.hbs"
    }
  });

  _exports.default = _default;
});