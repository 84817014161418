define("nfe-customer-console-v2/adapters/account-order", ["exports", "nfe-customer-console-v2/adapters/base-odata", "ember-data-url-templates"], function (_exports, _baseOdata, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AccountOrderAdapter extends _baseOdata.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryUrlTemplate", "{+host}/Accounts('{accountId}')/orders");

      _defineProperty(this, "queryRecordUrlTemplate", "{+host}/Accounts('{accountId}')/orders({orderId})?$expand=items");

      _defineProperty(this, "urlSegments", {
        host() {
          return this.get('host');
        },

        accountId(type, id, snapshot, query) {
          return id ? id : query.accountId;
        },

        orderId(type, id, snapshot, query) {
          return query.orderId;
        }

      });
    }

    downloadOrderPdf(accountId, orderId) {
      const url = `${this.host}/Accounts('${accountId}')/orders(${orderId})/api.exportpdf`;
      return window.open(url);
    }

    approveOrder(accountId, orderId) {
      const url = `${this.host}/Accounts('${accountId}')/orders(${orderId})/api.approve`;
      return this.ajax(url, 'POST');
    }

    refuseOrder(accountId, orderId, refuseData) {
      const url = `${this.host}/Accounts('${accountId}')/orders(${orderId})/api.refuse`;
      const data = {
        description: refuseData.description,
        approver: {
          fullname: refuseData.name,
          emailAddress1: refuseData.email
        }
      };
      return this.ajax(url, 'POST', {
        data
      });
    }

  }

  _exports.default = AccountOrderAdapter;
});