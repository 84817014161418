define("nfe-customer-console-v2/components/service-invoices-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    // attributes from template
    accountId: '',
    companyId: '',
    page: '',
    perPage: '',
    status: '',
    createdBegin: '',
    createdEnd: '',
    issuedBegin: '',
    issuedEnd: '',
    borrowerFederalTaxNumber: '',

    init() {
      this._super(...arguments);

      this.results = null;
    },

    didReceiveAttrs() {
      const params = {
        accountId: this.accountId,
        companyId: this.companyId,
        page: this.page,
        perPage: this.perPage,
        status: this.status,
        createdBegin: this.createdBegin,
        createdEnd: this.createdEnd,
        issuedBegin: this.issuedBegin,
        issuedEnd: this.issuedEnd,
        borrowerFederalTaxNumber: this.borrowerFederalTaxNumber
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params)) return;
      let data = yield this.store.query('service-invoice-query', params);
      return this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});