define("nfe-customer-console-v2/serializers/usage-month", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    primaryKey: 'accountId',

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.Usage || payload.usage) {
        payload = {
          usageMonth: payload.Usage || payload.usage
        };
      }

      payload.usageMonth.services = payload.usageMonth.services.map(service => {
        service.actions = service.actions.map(item => {
          if (item.totals) {
            item.totals.OK = item.totals.OK || 0;
            item.totals.BadRequest = item.totals.BadRequest || 0;
            item.totals.Error = item.totals.Error || 0;
            item.totals.NotFound = item.totals.NotFound || 0;
            item.totals.TimedOut = item.totals.TimedOut || 0;
            item.totals.Duplicated = item.totals.Duplicated || 0;
            item.totals.Unavailable = item.totals.Unavailable || 0;
          }

          return item;
        });
        return service;
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});