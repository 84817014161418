define("nfe-customer-console-v2/components/hubspot-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    autoCompleteQueryParams: null,
    portalId: null,
    formId: null,
    hasValidFormConfig: Ember.computed('portalId', 'formId', function () {
      return Ember.isPresent(this.portalId) && Ember.isPresent(this.formId);
    }),
    queryParamsString: Ember.computed('autoCompleteQueryParams', function () {
      const queryParams = this.autoCompleteQueryParams;
      let urlParams = new URLSearchParams();

      for (const param in queryParams) {
        if (Object.hasOwnProperty.call(queryParams, param)) {
          const element = queryParams[param];
          urlParams.set(param, element);
        }
      }

      return '?' + urlParams.toString();
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      (true && Ember.warn('Ember Hubspot Form could not be inserted: it requires a valid `portalId` and `formId`.', this.get('hasValidFormConfig'), {
        id: 'has-invalid-form-config'
      }));
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.get('hasValidFormConfig')) return; // DOM selector to insert form into

      const target = this.get('target') || `#${this.get('elementId')}`; // add query params to auto complete hubspot fields

      history.replaceState(null, null, this.queryParamsString); //create hubspot form

      hbspt.forms.create({
        portalId: this.get('portalId'),
        formId: this.get('formId'),
        target: target
      });
    }

  });

  _exports.default = _default;
});