define("nfe-customer-console-v2/components/nfe-sheet-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8MRad1Ug",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isLoading\",\"showStepper\",\"hasData\",\"workbook\",\"retroactiveInvoices\",\"email\",\"borrowerType\",\"cnaeCode\",\"municipalTaxNumber\",\"taxRegime\",\"additionalInformation\",\"taxationType\",\"issRate\",\"issTaxAmount\",\"irAmountWithheld\",\"pisAmountWithheld\",\"cofinsAmountWithheld\",\"csllAmountWithheld\",\"inssAmountWithheld\",\"issAmountWithheld\",\"othersAmountWithheld\",\"deductionsAmount\",\"discountUnconditionedAmount\",\"discountConditionedAmount\",\"locationState\",\"locationCity\",\"locationCityCode\",\"externalId\",\"paidAmount\",\"disableSendButton\",\"resetBatch\"],[[24,[\"isLoading\"]],[24,[\"showStepper\"]],[24,[\"hasData\"]],[24,[\"results\"]],[24,[\"hasRetroactiveInvoices\"]],[24,[\"hasEmail\"]],[24,[\"hasBorrowerType\"]],[24,[\"hasCnaeCode\"]],[24,[\"hasMunicipalTaxNumber\"]],[24,[\"hasTaxRegime\"]],[24,[\"hasAdditionalInformation\"]],[24,[\"hasTaxationType\"]],[24,[\"hasIssRate\"]],[24,[\"hasIssTaxAmount\"]],[24,[\"hasIrAmountWithheld\"]],[24,[\"hasPisAmountWithheld\"]],[24,[\"hasCofinsAmountWithheld\"]],[24,[\"hasCsllAmountWithheld\"]],[24,[\"hasInssAmountWithheld\"]],[24,[\"hasIssAmountWithheld\"]],[24,[\"hasOthersAmountWithheld\"]],[24,[\"hasDeductionsAmount\"]],[24,[\"hasDiscountUnconditionedAmount\"]],[24,[\"hasDiscountConditionedAmount\"]],[24,[\"hasLocationState\"]],[24,[\"hasLocationCity\"]],[24,[\"hasLocationCityCode\"]],[24,[\"hasExternalId\"]],[24,[\"hasPaidAmount\"]],[24,[\"hasAnyChecked\"]],[28,\"action\",[[23,0,[]],\"resetBatch\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/nfe-sheet-loader/template.hbs"
    }
  });

  _exports.default = _default;
});