define("nfe-customer-console-v2/components/certificate-upload-stepper/component", ["exports", "ember-concurrency", "ember-cp-validations"], function (_exports, _emberConcurrency, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    certificate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o certificado.'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*\.(pfx|p12)$/gi,
      message: 'Selecione um certificado digital válido.'
    })],
    certificatePassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Digite a senha do certificado.'
    })]
  });

  var _default = Ember.Component.extend(validations, {
    company: Ember.inject.service(),
    file: null,
    certificate: null,
    certificatePassword: null,
    activateFileValidations: false,
    chosenSystem: null,
    modalData: Ember.computed('chosenSystem', function () {
      let data = {
        videoUrl: "https://www.youtube.com/embed/dxuqfVj8RCw",
        docLink: "https://nfe.io/docs/certificado-digital/guia-windows/#passo-a-passo-no-formato-de-texto"
      };

      if (this.chosenSystem == 'macOs') {
        data.videoUrl = "https://www.youtube.com/embed/eBL3SY-pFaw";
        data.docLink = "https://nfe.io/docs/certificado-digital/guia-macos/#passo-a-passo-no-formato-de-texto";
      }

      return data;
    }),
    submitCertificateFile: (0, _emberConcurrency.task)(function* (companyId, nextStep) {
      nextStep();
      const response = yield this.company.uploadCertificate(companyId, this.file, this.certificatePassword);
      let result = {
        status: response.status,
        message: response.message
      };
      response.status === "success" ? result.icon = "check" : result.icon = "error-outline";
      return result;
    }),
    actions: {
      uploadCertificate(ev) {
        let file = ev.target.files[0];
        this.set('certificate', file.name);
        this.set('file', file);
        this.set('activateFileValidations', true);
      },

      showVideo(chosenSystem) {
        this.set('chosenSystem', chosenSystem);
      }

    }
  });

  _exports.default = _default;
});