define("nfe-customer-console-v2/controllers/companies/edit/taxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['limit', {
      filterTax: 'tax'
    }],
    filterTax: null,
    limit: 10,
    showRegisterTaxSteps: false
  });

  _exports.default = _default;
});