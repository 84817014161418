define("nfe-customer-console-v2/services/ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TicketService = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class TicketService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "model", null);

      _defineProperty(this, "showTicketModal", false);
    }

    _populateTicketModel(record, providerNamespace, options) {
      let ticketModel = this.store.createRecord('ticket', {
        product: this.store.createRecord('ticket/product')
      });

      if (record && record.constructor.modelName.includes('invoice')) {
        ticketModel.product.set('invoiceId', record.id);
        ticketModel.set('category', 'Problema');
        ticketModel.set('department', 'Tecnico');
        ticketModel.set('type', 'produto');
      }

      if (options && options.companyId) {
        ticketModel.product.set('companyId', options.companyId);
      }

      if (providerNamespace) {
        ticketModel.product.set('providerNamespace', providerNamespace);
      }

      return ticketModel;
    }

    showModal(record, providerNamespace = null, options) {
      let ticketModel = null;

      if (record && record.constructor.modelName) {
        if (record.constructor.modelName.includes('invoice')) {
          ticketModel = this._populateTicketModel(record, providerNamespace, options);
        } else if (record.constructor.modelName.includes('ticket')) {
          ticketModel = record;
        }
      }

      this.set('ticketModel', ticketModel);
      this.set('showTicketModal', true);
    }

    hideModal() {
      this.set('showTicketModal', false);

      if (this.ticketModel) {
        this.ticketModel.rollbackAttributes();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideModal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hideModal"), _class.prototype)), _class));
  _exports.default = TicketService;
  ;
});