define("nfe-customer-console-v2/components/intl-tel-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fUDPiFP9",
    "block": "{\"symbols\":[\"error\",\"index\"],\"statements\":[[7,\"label\",true],[11,\"for\",[22,\"inputElementId\"]],[11,\"class\",[28,\"if\",[[24,[\"required\"]],\"md-required\"],null]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[5,\"phone-input\",[[12,\"class\",[29,[\"md-input \",[28,\"if\",[[24,[\"isInvalid\"]],\"ng-invalid\"],null],\" \",[28,\"if\",[[24,[\"isTouched\"]],\"ng-dirty\"],null]]]],[12,\"onblur\",[28,\"action\",[[23,0,[]],\"handleBlur\"],null]]],[[\"@id\",\"@initialCountry\",\"@preferredCountries\",\"@number\",\"@update\",\"@separateDialCode\"],[[22,\"inputElementId\"],\"br\",[22,\"preferredCountries\"],[28,\"get\",[[24,[\"model\"]],[24,[\"valuePath\"]]],null],[28,\"action\",[[23,0,[]],\"handleUpdate\"],null],[22,\"separateDialCode\"]]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"md-errors-spacer\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isInvalidAndTouched\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-input-messages-animation md-auto-hide\"],[11,\"id\",[28,\"concat\",[[24,[\"elementId\"]],\"-error-messages\"],null]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"validationErrorMessages\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"id\",[29,[\"error-\",[22,\"inputElementId\"],\"-\",[23,2,[]]]]],[10,\"class\",\"paper-input-error ng-enter ng-enter-active md-input-message-animation\"],[10,\"style\",\"opacity: 1; margin-top: 0\"],[8],[0,\"\\n        \"],[1,[23,1,[\"message\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[24,[\"hasValue\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"hint\"],[8],[1,[22,\"hint\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/intl-tel-input/template.hbs"
    }
  });

  _exports.default = _default;
});