define("nfe-customer-console-v2/components/account-orders-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform(this.accountId);
    },

    fetchData: (0, _emberConcurrency.task)(function* (accountId) {
      if (Ember.isBlank(accountId)) return; //debounce

      yield (0, _emberConcurrency.timeout)(1000);
      const orders = yield this.store.query('account-order', {
        accountId
      });
      return this.set('results', orders);
    }).restartable()
  });

  _exports.default = _default;
});