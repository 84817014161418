define("nfe-customer-console-v2/adapters/usage-report", ["exports", "ember-data-url-templates", "nfe-customer-console-v2/adapters/base-rest"], function (_exports, _emberDataUrlTemplates, _baseRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UsageReportAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryUrlTemplate", '{+host}/accounts/{accountId}/reports/{reportType}/{year}');
    }

    getUsageReport(accountId, reportType, year) {
      const url = super.buildURL('usage-report', null, {
        accountId,
        reportType,
        year
      }, 'query');
      return super.ajax(url, 'GET');
    }

  }

  _exports.default = UsageReportAdapter;
  ;
});