define("nfe-customer-console-v2/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accessKey": "Access Key",
    "account": {
      "accessKeys": {
        "clientSecrets": {
          "itemClientId": "Client ID",
          "itemDescription": "Description",
          "itemExpiration": "Expiration",
          "itemHead": "Client ID and Secrets",
          "itemHeadExpanded": "{clientName}",
          "itemSecret": "Secret",
          "itemType": "Secret Type",
          "secretsList": "Secrets list",
          "title": "Clients and Secrets list"
        },
        "header": "API Keys and Clients & Secrets",
        "invoice": "API Keys for ",
        "knowMore": "Learn more about our integrations.",
        "listHeader": {
          "key": "API Key",
          "title": "API Keys (Access Keys) list",
          "type": "Type"
        },
        "text": "We use the <strong>API Keys</strong> or <strong>Client & Secrets</strong> to authenticate and authorize all requests made on our platform. Keep them safe for your safety and never pass on to others.",
        "title": "API Keys and Clients & Secrets",
        "types": {
          "data": "This Access Key is unique to the data query",
          "issueInvoices": "This Access Key is unique for the invoices issuance"
        }
      },
      "changePassword": {
        "confirmNewPassword": "Confirm New Password",
        "logout": "Logout",
        "newPassword": "New Password",
        "oldPassword": "Current Password",
        "text": "To change your password you need to fill in the fields below.",
        "title": "Change Password"
      },
      "environmentDescriptions": {
        "Development": "Your account is running in test mode. <strong>Contact our team to switch to production mode.</strong>",
        "Production": "Your account is running in production mode, all platform features are enabled."
      },
      "export": {
        "action": "Export",
        "emptyTable": {
          "text": "We didn't find any exports. You can export an account usage report or a NFS-e batch on your company invoice list.",
          "title": "No exports found"
        },
        "gotToExports": "Got to exports",
        "modal": {
          "error": "Error requesting export. Try again later.",
          "filters": {
            "period": "Period",
            "status": "Invoice status"
          },
          "invalidPeriod": "Maximum period of 31 days.",
          "success": "Your export will be available for download soon. Visit the exports page for more details.",
          "text": "Do you want to proceed with the export?"
        },
        "resource": {
          "account-usage": "Account usage",
          "company-product-invoice-analytical-csv": "NF-e report - CSV",
          "company-product-invoice-pdf": "NF-e batch - PDF",
          "company-product-invoice-xml": "NF-e batch - XML",
          "company-service-invoice-analytical-csv": "NFS-e report - CSV",
          "company-service-invoice-pdf": "NFS-e batch - PDF",
          "company-service-invoice-xml": "NFS-e batch - XML"
        },
        "status": {
          "Compressing": "Compressing",
          "Done": "Done",
          "Exporting": "Exporting",
          "Failed": "Failed",
          "None": "None",
          "Pending": "Pending",
          "Running": "Running"
        },
        "table": {
          "date": "Date",
          "download": "Download export",
          "timeSpent": "Time spent",
          "totalLines": "N°. of lines"
        },
        "text": "Here you will see a list of all requested usage reports and can download them.",
        "title": "Exports list"
      },
      "header": "Account",
      "information": {
        "footer": "Account details",
        "header": "Account information",
        "subscriptionFooter": "Subscription details",
        "subscriptionHeader": "Subscription information",
        "title": "Basic Info"
      },
      "invoices": {
        "downloadEInvoice": "Download EInvoice",
        "downloadEInvoiceXML": "Download EInvoice XML",
        "downloadInvoice": "Download Invoice",
        "emptyTable": {
          "text": "",
          "title": "No invoices found"
        },
        "footer": "Subscription invoices details",
        "header": "Invoices",
        "paymentNow": "Pay Now",
        "status": {
          "AwaitingAccounting": "Awaiting accounting",
          "AwaitingEInvoice": "Awaiting EInvoice",
          "AwaitingPayment": "Awaiting payment",
          "AwaitingPaymentMethod": "Awaiting payment method",
          "Cancelled": "Cancelled",
          "Paid": "Paid",
          "PartiallyPaid": "Partially paid",
          "PaymentDone": "Payment done",
          "Pending": "Pending",
          "Refunded": "Refunded"
        },
        "table": {
          "createdOn": "Created on",
          "dueOn": "Due em",
          "invoiceCode": "Invoice Cod."
        },
        "text": "In this panel you will see your invoices history, where you can view the details and download the PDF of each one, in addition to the invoice."
      },
      "linkNewVersion": {
        "header": "Contracts and orders (beta)",
        "link": "Go to accounts",
        "text": "Check out our new account platform, where you can view your contract details, orders and payment methods."
      },
      "paymentMethods": {
        "addPaymentMethod": "Add payment method",
        "emptyList": {
          "text": "We did not find any credit cards registered in your account. Click the button below to register your card.",
          "title": "No credit cards found"
        },
        "header": "Payment methods",
        "text": "Access this panel to manage your payment methods. Here you can add a new one or remove an existing one."
      },
      "preInvoices": {
        "detailedUsage": "See detailed usage",
        "header": "Pre-Invoice",
        "text": "Access this panel to see your pre-invoices history, where you can view the details, download the PDF and the usage report for each one."
      },
      "providers": {
        "actionBtn": "{action, select, activate {Activate} deactivate {Deactivate}}",
        "dialog": {
          "content": "This action will {action, select, activate {activate} deactivate {deactivate}} the feature of ",
          "title": "Do you really want to {action, select, activate {activate} deactivate {deactivate}} this feature?",
          "warningMessage": "Enable this feature and use it for free during 15 days, you can always contact us through comercial@nfe.io to acquire the best plan that fit your needs"
        },
        "header": "Features",
        "tableBody": {
          "AzHub.Account": "Accounts",
          "AzHub.Billing": "Billing",
          "AzHub.Billing.Order": "Billing - Pre-Invoice",
          "AzHub.Usage": "Dashboard Usage",
          "DFeTech.Company": "Multi-company",
          "DFeTech.ConsumerInvoice": "Issue Consumer Invoice (NFC-e)",
          "DFeTech.Mail": "Email delivery",
          "DFeTech.ProductInvoice": "Issue Product Invoice (NF-e)",
          "DFeTech.ProductInvoice.Inbound": "NF-e distribution",
          "DFeTech.ServiceInvoice": "Issue Service Invoice (NFS-e)",
          "DFeTech.ServiceInvoice.Batch": "Issue Service Invoice (NFS-e) up to 500 invoice per worksheet",
          "DFeTech.TransportationInvoice.Inbound": "CT-e distribution",
          "DFeTech.WebHook": "Webhooks",
          "DFeTech.WebHook.Encryption": "Webhook Encryption",
          "DataTech.Batch": "Get Product Invoice (NF-e) Batch - PDF and XML",
          "DataTech.Batch.ProductInvoice.Pdf": "Get Product Invoice (NF-e) Batch via SEFAZ - PDF",
          "DataTech.Batch.ProductInvoice.Serpro": "Get Product Invoices (NFE-e) Batch via SEFAZ and SERPRO as Backup",
          "DataTech.Batch.ProductInvoice.Serpro.Pdf": "Get Product Invoice (NF-e) Batch via SERPRO - PDF",
          "DataTech.Batch.ProductInvoice.Serpro.Xml": "Get Product Invoice (NF-e) Batch via SERPRO - XML",
          "DataTech.Batch.ProductInvoice.Xml": "Get Product Invoice (NF-e) Batch via SEFAZ - XML",
          "DataTech.LegalEntity": "Get Legal Entity (CNPJ)",
          "DataTech.NaturalPerson": "Get Natural Person (CPF)",
          "DataTech.ProductInvoice": "Get Product Invoice (NF-e)",
          "DataTech.WebHook": "Webhooks for data",
          "status": {
            "Active": "Active",
            "Inactive": "Inactive"
          }
        },
        "tableHeader": {
          "providerName": "Feature"
        },
        "text": "In this panel you will see all the features available on our platform and choose which ones you would like to use.",
        "toggleProvider": {
          "error": "Error {action, select, activate {activating} deactivate {deactivating}} feature.",
          "success": "Successfully {action, select, activate {activated} deactivate {deactivated}} feature!"
        }
      },
      "title": "Account management",
      "usage": {
        "actions": {
          "Company": {
            "All": "Consulta de todas",
            "Create": "Criação",
            "Delete": "Remoção",
            "One": "Consulta por identificador",
            "Save": "Alteração",
            "UploadCertificate": "Upload de certificado digital"
          },
          "ConsumerInvoice": {
            "CancelInvoice": "Enviar para cancelamento",
            "CheckAuthorizationBatch": "Validar protocolo do lote de autorização",
            "DequeueIssue": "Desenfileirar para emissão",
            "EnqueueCancel": "Inclusão na fila de cancelamento",
            "EnqueueIssue": "Enfileirar para emissão",
            "GenerateDanfe": "Geração do DANFE",
            "GenerateXml": "Geração de XML",
            "GetEvents": "Consulta de eventos",
            "GetInvoice": "SEFAZ > Consulta do NFe em JSON",
            "GetInvoicePdf": "SEFAZ > Consulta do DANFE",
            "GetInvoiceXml": "SEFAZ > Consulta do NFe em XML",
            "GetOne": "Consulta por identificador",
            "MergeAuthorizationProtocol": "Adicionar protocolo no XML de envio",
            "MergeCancelProtocol": "Adicionar protocolo no XML de cancelamento",
            "Push": "Disparo de WebHooks",
            "SeekNextSerialNumber": "Encontrar próximo número da série",
            "SendAuthorizationBatch": "Enviar lote de autorização"
          },
          "LegalEntity": {
            "All": "Pessoas Jurídicas > Consulta de todas ",
            "Create": "Pessoa Jurídica > Criação",
            "GetBasicInfo": "Receita Federal > Consulta de dados básicos",
            "GetBasicInfoV2": "Receita Federal > Consulta de dados básicos V2",
            "GetStateTaxInfo": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetStateTaxInfoWebService": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetTaxRegime": "Receita Federal > Consulta do Simples Nacional",
            "One": "Pessoa Jurídica > Consulta por identificador",
            "Save": "Pessoa Jurídica > Alteração"
          },
          "LegalEntityV2": {
            "All": "Pessoas Jurídicas > Consulta de todas ",
            "Create": "Pessoa Jurídica > Criação",
            "GetBasicInfo": "Receita Federal > Consulta de dados básicos",
            "GetBasicInfoV2": "Receita Federal > Consulta de dados básicos V2",
            "GetStateTaxInfo": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetStateTaxInfoWebService": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetTaxRegime": "Receita Federal > Consulta do Simples Nacional",
            "One": "Pessoa Jurídica > Consulta por identificador",
            "Save": "Pessoa Jurídica > Alteração"
          },
          "NaturalPerson": {
            "All": "Pessoas Físicas > Consulta de todas",
            "Create": "Pessoa Física > Criação",
            "GetAddress": "Correios > Consulta do endereço",
            "GetComplete": "Consulta de dados completa",
            "GetStatus": "Receita Federal > Consulta do status",
            "One": "Pessoa Física > Consulta por identificador",
            "Save": "Pessoa Física > Alteração"
          },
          "OneWebhook": "Conta > Consulta de um WebHook",
          "ProductInvoice": {
            "CancelInvoice": "Enviar para cancelamento",
            "CheckAuthorizationBatch": "Validar protocolo do lote de autorização",
            "DequeueIssue": "Desenfileirar para emissão",
            "EnqueueCancel": "Inclusão na fila de cancelamento",
            "EnqueueIssue": "Enfileirar para emissão",
            "GenerateDanfe": "Geração do DANFE",
            "GenerateXml": "Geração de XML",
            "GetEvents": "Consulta de eventos",
            "GetInvoice": "SEFAZ > Consulta do NFe em JSON",
            "GetInvoicePdf": "SEFAZ > Consulta do DANFE",
            "GetInvoiceXml": "SEFAZ > Consulta do NFe em XML",
            "GetOne": "Consulta por identificador",
            "MergeAuthorizationProtocol": "Adicionar protocolo no XML de envio",
            "MergeCancelProtocol": "Adicionar protocolo no XML de cancelamento",
            "Push": "Disparo de WebHooks",
            "SeekNextSerialNumber": "Encontrar próximo número da série",
            "SendAuthorizationBatch": "Enviar lote de autorização"
          },
          "ProductInvoicesV2": "Nota Fiscal Eletrônica (v2)",
          "ServiceInvoice": {
            "AllWebhooks": "Consulta de WebHooks",
            "CalculateTaxes": "Cálculo de impostos e taxas",
            "Cancel": "Envio do lote de cancelamento na Prefeitura",
            "Cancelled": "Cancelada",
            "CheckBatch": "Retorno do lote na Prefeitura",
            "Create": "Lote criado",
            "Download": "Download de arquivo",
            "GetOne": "Consulta por identificador",
            "Issued": "Emitida",
            "ListAll": "Consulta de todas",
            "PushToWebhooks": "Disparo de WebHooks",
            "QueueCancel": "Inclusão na fila de cancelamento",
            "QueueIssue": "Inclusão na fila de emissão",
            "QueueRetry": "Inclusão na fila de processamento",
            "Save": "Lote atualizado",
            "SendBorrowerEmail": "Envio de e-mail",
            "Sent": "Envio do lote na Prefeitura",
            "Update": "Atualização de dados"
          },
          "StateTaxV2": {
            "All": "Pessoas Jurídicas > Consulta de todas ",
            "Create": "Pessoa Jurídica > Criação",
            "GetBasicInfo": "Receita Federal > Consulta de dados básicos",
            "GetBasicInfoV2": "Receita Federal > Consulta de dados básicos V2",
            "GetStateTaxInfo": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetStateTaxInfoWebService": "Sintegra > Consulta do Cadastro de Inscrição Estadual",
            "GetStateTaxSuggestedForInvoice": "Sintegra > Inscrição Estadual melhor avaliada para emissão de Nota Fiscal",
            "GetTaxRegime": "Receita Federal > Consulta do Simples Nacional",
            "One": "Pessoa Jurídica > Consulta por identificador",
            "Save": "Pessoa Jurídica > Alteração",
            "TaxPayerRegistrationGetOneAbledStateTaxInfoAsync": "Sintegra > Inscrição Estadual com filtro de habilitadas"
          },
          "UpdateWebhook": "Conta > Atualização de WebHook"
        },
        "descriptions": {
          "ConsumerInvoice": "Processamento de Nota Fiscal De Consumidor (NFC-e)",
          "DataTech.ProductInvoice": "Consulta de Nota Fiscal Eletrônica (NF-e)",
          "LegalEntity": "Consulta de Pessoa Jurídica (CNPJ)",
          "LegalEntityV2": "Consulta de Pessoa Jurídica (CNPJ) V2",
          "NaturalPerson": "Consulta de Pessoa Física (CPF)",
          "ProductInvoice": "Processamento de Nota Fiscal Eletrônica (NF-e)",
          "ServiceInvoice": "Processamento de Nota Fiscal de Serviço (NFS-e)",
          "StateTaxV2": "Consulta de Inscrição Estadual V2"
        },
        "error": "Error",
        "filter": "Monthly Filter",
        "footer": "Account usage details",
        "header": "Usage summary",
        "noUsage": "There was no usage in",
        "notFound": "Not found",
        "resource": "Resource",
        "resourceName": {
          "Company": "Empresas",
          "ConsumerInvoice": "Nota Fiscal de Consumidor",
          "LegalEntity": "Pessoa Jurídica",
          "LegalEntityV2": "Pessoa Jurídica",
          "NaturalPerson": "Pessoa Física",
          "ProductInvoice": "Nota Fiscal Eletrônica",
          "ServiceInvoice": "Nota Fiscal de Serviço",
          "StateTaxV2": "Inscrição Estadual"
        },
        "subTitle": "{accountName}, updated {lastUpdate}",
        "success": "Success",
        "text": "In this dashboard you'll have access to your account usage summary based on the record we make of all calls made to our APIs. <strong>Important: the summary is updated only once a day</strong>.",
        "total": "Total"
      },
      "users": {
        "createBtn": "Add User",
        "delete": {
          "error": "Falha ao excluir o usuário",
          "success": "User "
        },
        "dialog": {
          "content": "This action will delete the user <strong>{userEmail}</strong>",
          "title": "Do you really want to delete this user?"
        },
        "header": "Users",
        "new": {
          "steps": {
            "confirmation": {
              "error": "Fail to send invite",
              "label": "Confirmation",
              "sending": "Sending...",
              "success": "Invite sent with success!",
              "userIsNotAdmin": "Only the account admin can add users",
              "userWithoutAccount": "User does not have an account"
            },
            "defineUser": {
              "addUserBtn": "Send invite",
              "label": "New user email"
            }
          },
          "title": "Add User"
        },
        "tableBody": {
          "buttons": {
            "deleteUser": "Delete"
          },
          "emptyList": {
            "text": "Only the account admin can edit and delete users, contact your account admin and request the change you need to be done.",
            "title": "Feature exclusive for the account admin"
          },
          "tooltips": {
            "deleteUser": "Delete user"
          }
        },
        "tableHeader": {
          "avatar": "Avatar"
        },
        "text": "Access this panel to see all users with access to your account. Here you can add or remove users."
      },
      "webhooks": {
        "createBtn": "Create Webhook",
        "emptyList": {
          "text": "<strong> Webhooks </strong> are responsible for monitoring and reporting on events generated by our platform, for example when an invoice has been issued or canceled.",
          "title": "No webhook found"
        },
        "header": "Webhooks",
        "knowMore": "Learn more about how webhooks work.",
        "search": "Type to Search...",
        "text": "A webhook is a way for an application to provide other applications its real-time information. Create a Webhook for an url of your application to listen to the NFe.io events. NFe.io will send a POST request with the events data in JSON format to this url.",
        "text2": "The <strong>Webhooks</strong> are responsible for monitoring and reporting the events generated by our platform, for example, when an invoice has been issued or canceled.",
        "title": "There's no Webhooks created yet. What's a Webhook?",
        "tooltip": "Edit Webhook"
      }
    },
    "accounts": {
      "activateAction": "Activate account",
      "changeAccount": "Accounts management",
      "deactivateAction": "Deactivate account",
      "emptyList": {
        "text": "The <b>Account</b> is a basis for the control of the platform in general, in this key record that we have everything related as <b>Companies</b>, <b>Access keys</b>, <b>Webhooks</b>, <b>Usage</b>, <b>Payments</b> and more.",
        "title": "Nothing found on search"
      },
      "header": "Accounts",
      "search": "Search by term",
      "setEnvironmentDevelopmentAction": "Change to Development",
      "setEnvironmentProductionAction": "Change to Production",
      "textLinkToAccounts": "Go to accounts",
      "title": "Change Account",
      "updateUsage": "Update Usage",
      "usage": "See usage",
      "useAs": "Use as account"
    },
    "action": "Action",
    "actionsBtn": "Actions",
    "activate": "Activate Now",
    "activateNow": "Activate Now",
    "active": "Active",
    "additionalInformation": "Additional information",
    "address": "Address",
    "agency": "Agency",
    "amount": "Amount",
    "apisReference": "APIs Reference",
    "approve": "Approve",
    "bank": "Bank",
    "begin": "Start",
    "cancelBtn": "Cancel",
    "certificateStatuses": {
      "Active": "Active",
      "Expired": "Expired",
      "Expiring": "Expiring",
      "Pending": "Waiting upload"
    },
    "cityState": "City/State",
    "closeBtn": "Close",
    "companies": {
      "actions": {
        "editLong": "Edit company",
        "editTooltip": "Edit company",
        "issueBatchInvoices": "Issue",
        "issueBatchInvoicesLong": "Issue batch invoices",
        "issueBatchInvoicesTooltip": "Issue batch invoices",
        "listInvoices": "List",
        "listInvoicesLong": "List Invoices",
        "listInvoicesTooltip": "List Invoices",
        "testIssue": "Test invoice issue"
      },
      "batch": {
        "all": "All",
        "cancelled": "Cancelled",
        "error": "Error",
        "exitIssueBatchModal": {
          "cancelBtn": "Cancel issuance",
          "text": "Your invoices are still being issued. If you leave, the issuing process will end.",
          "title": "Do you really want to leave the issuance?"
        },
        "header": "Issue batch invoices",
        "issued": "Issued",
        "processing": "Processing",
        "sendBtn": "Issue in batch",
        "sent": "Sent",
        "status": {
          "fail": "Sent Failed",
          "issued": "Issued successfully",
          "sending": "Sending...",
          "success": "Sent with success",
          "uploadFail": "failed upload",
          "waiting": "Waiting"
        },
        "steps": {
          "howToFill": {
            "text": "Access the <a href={link} target='_blank'>documetation</a> for more details about the fields.",
            "title": "How to fill your sheet"
          },
          "step1": {
            "description": "In this step by step we will teach you how to issue invoices in batch using an excel spreadsheet, and all the technical knowledge for you to be able to fill in the fields of your spreadsheet.",
            "title": "Do you want to learn how to issue invoices in batch?"
          },
          "step2": {
            "advancedSheet": {
              "description": "In this model the taxes are <strong>calculated based on the aliquots informed by you</strong> on the sheet.",
              "title": "Advanced sheet"
            },
            "basicSheet": {
              "description": "In this model the taxes are <strong>automatically calculated </strong>based on the aliquots from our system.",
              "title": "Basic sheet"
            },
            "description": "We have two spreadsheet templates for you to use to issue your invoices.",
            "title": "Choose spreadsheet template"
          },
          "step3": {
            "description": "In the buttons below you can download your spreadsheet and understand how to fill it out properly.",
            "download": "Download sheet",
            "title": "Download and fill sheet"
          },
          "step4": {
            "inputLabel": "Excel sheet file with extension XLSX.",
            "title": "Select sheet and validate"
          },
          "step5": {
            "goToIssue": "Go to issue",
            "status": "Importing sheet",
            "title": "Import sheet"
          }
        },
        "table": {
          "additionalInformation": "Additional information",
          "address": "Address",
          "borrowerType": "Borrower type",
          "cnaeCode": "CNAE",
          "cofinsAmountWithheld": "COFINS with held (R$)",
          "country": "Country",
          "csllAmountWithheld": "CLSS with held (R$)",
          "deductionsAmount": "Deductions Amount (R$)",
          "description": "Description",
          "discountConditionedAmount": "Discount Conditioned Amount (R$)",
          "discountUnconditionedAmount": "Discount Unconditioned Amount (R$)",
          "federalTaxNumber": "Borrower Federal Tax Number",
          "inssAmountWithheld": "INSS with held (R$)",
          "invalidAddress": "Invalid address",
          "irAmountWithheld": "IR with held (R$)",
          "issAmountWithheld": "ISS with held (R$)",
          "issRate": "ISS Rate",
          "issTaxAmount": "ISS Amount (R$)",
          "issuedOn": "Issued On",
          "location": "Service Provided in",
          "modal1": "Please correct the fields listed below",
          "modal2": "Error sending invoice",
          "municipalTaxNumber": "Municipal Tax Number",
          "name": "Borrower Name/Company",
          "othersAmountWithheld": "OTHERS with held (R$)",
          "paidAmount": "Paid Amount (R$)",
          "pisAmountWithheld": "PIS with held (R$)",
          "postalCode": "Postal Code",
          "serviceCode": "Service Code",
          "showError": "Click to show more details",
          "state": "City / State",
          "status": "Status",
          "taxationType": "Taxatio Type",
          "value": "Value (R$)"
        },
        "total": "Total",
        "uploadBtn": "New upload"
      },
      "doubt": "Still have some doubts? Click and learn more!",
      "edit": {
        "alert": "Alert!",
        "apikeys": {
          "accessKeyDetails": {
            "companyId": "Company ID",
            "text": "<strong>The API key</strong> and <strong>Company ID</strong> are responsible for the authentication of your communication with the NFe.io API and to link the transactions to your account and the correct company. Keep it in a private and safe place to ensure the security of your data. See the <a href='http://nfe.io/docs' target='_blank'>documentation</a> for more details.",
            "title": "Access Keys"
          },
          "title": "Access Keys"
        },
        "basicInfo": {
          "boxFooter": "Company details",
          "subtitle": "Showing company basic data",
          "title": "Company information"
        },
        "certificate": {
          "description": "The Digital Certificate provides a legal valid electronic signature that guarantees protection on the transactions and other internet services, allowing persons and companies to indentify themself and digitally sign their documents in any place of the world with secutity and agility.",
          "errors": {
            "default": "Error on certificate upload",
            "invalidCnpj": "Certificate CNPJ is not equal to company CNPJ",
            "invalidPassword": "Certificate password is not valid",
            "invalidType": "Certificate Type is diferent from e-CNPJ or e-NFE",
            "overdue": "Certificate is no longer valid"
          },
          "expiresOn": "Expires on",
          "modifiedOn": "Modified on",
          "stepper": {
            "chooseFile": {
              "label": "Select the digital certificate file"
            },
            "exportFile": {
              "description": "Select the system you use to access the step by step to export the Certificate File",
              "label": "Export the digital certificate file",
              "modal": {
                "certificateExportDoc": "To access the step by step in text format, <a href=\"{docLink}\" target=\"blank\">Click here!</a>",
                "macOs": {
                  "name": "Mac OS",
                  "title": "How to export the certificate file on Mac OS"
                },
                "windows": {
                  "name": "Windows",
                  "title": "How to export the certificate file on Windows"
                }
              }
            },
            "hasCertificate": {
              "description": "You need to have the digital certificate file in your computer, this file always has .PFX or .P12. as its extention",
              "label": "Do you have the digital certificate file?"
            },
            "password": {
              "label": "Fill in the password for your digital certificate"
            },
            "sendFile": {
              "label": "Send the file",
              "messages": {
                "sending": "Sending the file to our system"
              }
            }
          },
          "subtitle": "Showing digital certificate",
          "text": "Use the form below to upload the digital certificate",
          "title": "Digital Certificate",
          "update": {
            "alreadyBoughtBtn": "Yes I bought",
            "didntBoughtBtn": "No I didn't buy",
            "title": "Did you buy a digital certificate with type e-CNPJ A1 or NF-e A1?",
            "usageTip": "The digital certificate will be used on the authentication with government and prefectures systems. It is like an user and password that you use to sign in on a website. <a href=\"https://nfe.io/docs/certificado-digital/conceitos/\" target=\"blank\">Know more!</a>",
            "usageTip2": "We will also use your certificate as a digital signature within the requests made to prefectures and government systems. e.g. To issue and cancel invoices."
          },
          "updatedWithSuccess": "Certificate updated with success!"
        },
        "certificateDateInfo": "Certificate expires",
        "certificateExpiredInfo": "Certificate expired on",
        "certificateExpiringInfo": "Certificate is expiring",
        "certificateExpiringTip": "Update your digital certificate soon as you can and avoid problems ",
        "certificateUploadInfo": "Upload the Digital Certificate to Issue Invoices",
        "certificateUploadTip": "Update your digital certificate to start issue invoices",
        "environment": {
          "cardText": "Here you will be able to change the environment where your company is operating. Use our <strong>testing</strong> environment to simulate the issuance of your tax free invoices or the <strong>production</strong> environment to issue through Government and Prefectures systems.",
          "cardTitle": "Company operating on {environmentTitle}",
          "developmentEnv": {
            "description": "In <strong>development environment</strong> all the invoices will be processed by servers and systems that mimic the behavior of the Government and City Halls.",
            "text": "In this environment the invoices are <u>NOT</u> processed by the Government and City Hall system, that is, <strong>you will not pay taxes for the invoices</strong>.",
            "title": "Development Environmnent"
          },
          "error": {
            "default": "Error changing the environment",
            "needActivate": "Your account needs to be activated, talk to the team comercial@nfe.io",
            "notReady": "Could not change the environment. Check your company data and digital certificate."
          },
          "modal": {
            "text": {
              "certificateExpired": "Your company's digital certificate expired.",
              "changeEnvironment": "This operation will modify the invoice processing environment.",
              "confirm": "Are you sure you want to continue?",
              "uploadCertificate": "To change the environment for production, you first need to upload your company's digital certificate."
            },
            "title": {
              "confirm": "Change environment to {environmentTitle}?",
              "waitingCertificate": "Waiting for Digital Certificate"
            },
            "uploadBtn": "Upload digital certificate"
          },
          "productionEnv": {
            "description": "In <strong>production environment</strong> all the invoices that will be processed by the Government and City Hall servers and systems.",
            "text": "In this environment the invoices will be processed by the Government and City Hall system, that is, <strong>you will pay taxes by the invoices</strong>.",
            "title": "Production Environmnent"
          },
          "subtitle": "Mostrando as informações do Ambiente",
          "success": "Successfully changed environment!",
          "switchEnvironmentButton": "Switch to {environmentTitle}",
          "title": "Environment"
        },
        "environmentDescriptions": {
          "Development": "Your company is runing in test mode. <strong>All issued invoices will not have fiscal value.</strong>",
          "Production": "Your company is runing in production mode, all invoices will be issued at the prefecture."
        },
        "fiscalInfo": {
          "issInfo": {
            "text": "This rate will be used by our ISS calculation platform, if the <b>Tax Regime</b> field is marked as <b>Simple National</b> or <b>Individual Microentrepreneur (MEI)</b>",
            "title": "Rate of Service Tax (ISS)"
          },
          "message": {
            "error": "Error when changing company.",
            "success": "Company successfully changed!"
          },
          "modal": {
            "changeCertificateWarning": "<p>This operation will change fiscal data for this company. Below are the fields that will change if you confirm the change:</p><ul style='list-style:inside'><li>ISS Rate: {issAliquot};</li><li>RPS Serial Number: {rpsSerie}</li><li>RPS Number: {rpsNumber}</li></ul><p>Do you want to continue? This change will directly affect the processing of invoices!</p>",
            "title": "Confirm fiscal info changes?"
          },
          "rpsInfo": {
            "text": "RPS is a tax document that is used temporarily until NFS-e is issued. Because RPS is a tax document, our platform has a duty to control the RPS Number so that the NFS-e is issued correctly. <br><b>RPS Series:</b> is a text used to group RPS sequence numbers. <br><b>RPS Number:</b> is a sequential integer that identifies the RPS.",
            "title": "Provisional Service Receipt (RPS)"
          },
          "subtitle": "Showing fiscal data",
          "title": "Fiscal Data"
        },
        "taxes": {
          "card": {
            "title": "City Service Code list"
          },
          "description": "Search for the service code you want to use in your issuances and see if it is available in our automatic tax calculation system.",
          "emptyList": {
            "text": "Please, click the button below to request the registration of your service code on our platform. We will give top priority to your request.",
            "title": "Oops! This service code is not registered on our platform, yet!"
          },
          "list": {
            "header": {
              "cityServiceCode": "City Service Code",
              "federalCode": "Federal Code (LC-116)",
              "issAliquota": "ISS Rate"
            },
            "newServiceDialog": {
              "getFile": {
                "header": "Get invoice file for reference",
                "issuersDescription": "<b>Already issue invoices:</b> use a file (pdf or xml) of an invoice that you already issued recently.",
                "notIssuersDescription": "<b>Doesn't issue invoices:</b> validate wiht your Accountant if you can issue an invoice on the prefecture website to get the file that is necessary.</li>"
              },
              "header": "Service code request",
              "newBtn": "Request Registration",
              "sendFile": {
                "header": "Send the data to register your service",
                "receiveResponse": "Now we just wait, our Accountant will register the new service on our taxes platform, after this we will contact you.",
                "sendEmailWithInvoice": "You need to send an email to <a href=\"mailto:suporte@nfe.io?subject=Cadastro%20de%20código%20de%20serviço\">suporte@nfe.io</a> putting on subject \"Cadastro de código de serviço\", remember to attach the file (pdf ou xml) of your issued invoice"
              }
            },
            "rates": {
              "cofins": "COFINS",
              "csll": "CSLL",
              "inss": "INSS",
              "ir": "IR",
              "iss": "ISS",
              "pis": "PIS"
            },
            "taxDialog": {
              "cnae": "CNAE",
              "title": "City Service Details",
              "withHoldingTitle": "Withholding tax rates"
            }
          },
          "searchTax": "Search service code",
          "title": "List of services registered in {city} - {state}"
        },
        "title": "Managing company",
        "updateCertificateOk": "Digital Certificate was updated"
      },
      "emptyList": {
        "onFirstTime": {
          "text": "Everything starts with <b>Company</b>, for <b>invoice processing</b>. Companies are an essential part of the platform because at the time of issuing the invoice you need to tell who is the company that provided the service or who sold the product.",
          "title": "No companies registered"
        },
        "onNotFound": {
          "text": "Everything starts with <b>Company</b>, for <b>invoice processing</b>. Companies are an essential part of the platform because at the time of issuing the invoice you need to tell who is the company that provided the service or who sold the product.",
          "title": "Nothing found on search"
        }
      },
      "header": "Companies",
      "infoForNewCompany": "To start, you'll need a company",
      "invoices": {
        "filter": {
          "applyBtn": "Apply",
          "created": "Created On",
          "curMonth": "Created this month",
          "curWeek": "Created this week",
          "curYear": "Created this year",
          "date": "Created On",
          "email": "Borrower email",
          "federalTaxNumber": "Borrower CNPJ/CPF",
          "invoiceId": "NFe ID",
          "invoiceNumber": "NFe Number",
          "issued": "Issued On",
          "lastMonth": "Created last month",
          "name": "Borrower name",
          "options": "Choose one of the options",
          "placeholder": "Equivalent to",
          "status": "Invoice status"
        }
      },
      "linkForNewCompany": "Create a company now!",
      "menu": {
        "details": "See details",
        "issue": "Issue Invoices",
        "listInvoices": "List Invoices"
      },
      "modal": {
        "description": "<p>Issue batch invoices by uploading a spreadsheet with invoices data.</p><h5><b>Don't know how to start?</b></h5><ul><li>Click the 'Upload' button.</li><li>Follow de step by step to issue invoices</li></ul>",
        "header": "Issue batch invoices",
        "uploadBtn": "Upload"
      },
      "new": {
        "basicDataLabel": "Company Basic Data",
        "certificate": {
          "text": "To successfully issue your invoices we need the digital certificate linked to your company. If you just want to test our service, you can skip this step.",
          "title": "Digital certificate upload"
        },
        "digitalCertificateLabel": "Digital Certificate (<strong>Optional</strong>)",
        "fiscalDataLabel": "Company Fiscal Data (<strong>Optional</strong>)",
        "lastStep": {
          "text": "<p>Clicking on create company button, you will have a ready company to issue service invoices in a safe test environment.</p><p>This company will be created in a prefecture simulator, so you can test our platform.</p><p><strong>We recommend that your next step is to issue your first test invoice. In the next screen we will give you some options.</p></strong>",
          "title": "You are one step closer to creating your company"
        },
        "serviceCode": {
          "description": "Search below for the service code provided by your company.",
          "title": "Service Code"
        },
        "success": "Successfully created company!",
        "title": "Create Company",
        "waysToStart": "Your company has not yet issued any invoices, so we suggest that you start by learning all about service invoice, below we have some options of how you can issue invoices with our platform."
      },
      "productInvoices": {
        "events": {
          "modal": {
            "accessKeyCheckDigit": "AccessKey check digit",
            "batchId": "Batch ID",
            "protocolNumber": "Protocol number",
            "receiptNumber": "Receipt number",
            "title": "Event details"
          },
          "title": "Invoice events"
        },
        "header": {
          "Production": "Showing invoices from production environment",
          "Test": "Showing invoices from test environment"
        },
        "modal": {
          "cancelInvoiceMessage": "<p>This operation will cancel the NF-e <i>#{invoiceNumber}</i>,</p><p>issued on <i>{issuedOn}</i>.</p><p><strong>Are you sure you want to continue?</strong></p>",
          "errorTitle": "NF-e Error message"
        },
        "status": {
          "cancelled": "Cancelled",
          "created": "Created",
          "disabled": "Disabled",
          "error": "Error",
          "issueDenied": "Issue denied",
          "issued": "Issued",
          "none": "All invoices",
          "processing": "Processing"
        },
        "view": {
          "buyer": "Buyer",
          "cityState": "City/State",
          "eventsDetails": "More events details",
          "freightModality": "Freight Modality",
          "generalData": "General Data",
          "icms": {
            "baseTax": "ICMS Base Tax",
            "icmsAmount": "ICMS Amount",
            "icmsExemptAmount": "ICMS Exempt Amount",
            "stCalculationBasisAmount": "ICMS ST Calculation Basis"
          },
          "issuance": "Issuance",
          "issueDate": "Issue Date",
          "issuer": "Issuer",
          "lastEvents": "Last Events",
          "operationNature": "Operation Nature",
          "operationType": "Operation Type",
          "purpose": "Purpose",
          "serieNumber": "Serie/Number",
          "totals": {
            "cofinsAmount": "COFINS Amount",
            "discountAmount": "Discount Amount",
            "federalTaxesAmount": "Federal Taxes Amount",
            "freightAmount": "Freight Amount",
            "header": "Totals",
            "iiAmount": "II Amount",
            "insuranceAmount": "Insurance Amount",
            "ipiAmount": "IPI Amount",
            "othersAmount": "Other ancillary expenses",
            "pisAmount": "PIS Amount",
            "productAmount": "Product Amount",
            "total": "Total Amount"
          }
        }
      },
      "serviceInvoices": {
        "actions": {
          "reissue": "Send to reissue",
          "sendEmail": "Send e-mail",
          "showError": "View error",
          "showInvoice": "Show invoice"
        },
        "emptyList": {
          "onFirstTime": {
            "text": "The <b>Invoice</b> is an electronic document for fiscal purposes, which documents the operations of circulation of goods and rendering of service between the parts, be it <b>Companies</b> or <b>Natural People</b>.<br>Still have doubts, <a target=_blank href='https://nfe.io/docs/conceitos/notas-fiscais/'>know more</a> and understand everything about Electronic Invoice.",
            "title": "No invoices issued"
          },
          "onNotFound": {
            "text": "The <b>Invoice</b> is an electronic document for fiscal purposes, which documents the operations of circulation of goods and rendering of service between the parts, be it <b>Companies</b> or <b>Natural People</b>.<br>Still have doubts, <a target=_blank href='https://nfe.io/docs/conceitos/notas-fiscais/'>know more</a> and understand everything about Electronic Invoice.",
            "title": "Nothing found in the search"
          }
        },
        "flowStatus": {
          "CancelFailed": "Cancel failed",
          "Cancelled": "Cancelled",
          "IssueFailed": "Issue failed",
          "Issued": "Issued",
          "WaitingCalculateTaxes": "Calculating taxes",
          "WaitingDefineRpsNumber": "Waiting",
          "WaitingDownload": "Waiting",
          "WaitingReturn": "Waiting",
          "WaitingReturnCancel": "Waiting cancel",
          "WaitingSend": "Waiting",
          "WaitingSendCancel": "Waiting"
        },
        "generalStatus": {
          "CancelFailed": "with failed to cancel",
          "Cancelled": "Cancelled",
          "DownloadFailed": "with failed to download invoice",
          "IssueFailed": "Issue failed",
          "Issued": "Issued",
          "WaitingCalculateTaxes": "Calculating taxes",
          "WaitingDefineRpsNumber": "Defining RPS number",
          "WaitingDownload": "Downloading invoice",
          "WaitingReturn": "Waiting prefecture",
          "WaitingReturnCancel": "Waiting cancel",
          "WaitingSend": "Sending",
          "WaitingSendCancel": "Sending cancel"
        },
        "header": "Invoices",
        "modal": {
          "cancelInvoiceMessage": "<p>This operation will cancel the NFS-e <i>#{invoiceNumber}</i>,</p><p>issued on <i>{issuedOn}</i></p><p>to borrower <i>{borrowerName}</i>.</p><p><strong>Are you sure you want to continue?</strong></p>",
          "cancelInvoiceTitle": "Confirm cancellation operation",
          "duplicateInvoiceMessage": "<p><strong>This operation will issue a new NFS-e based on NFS-e below,</strong></p><p>created for editing in <i>{issuedOn}</i>,</p><p>to the borrower <i>{borrowerName}</i>,</p><p>with Federal Tax Number <i>{borrowerFederalTaxNumber}</i>,</p><p>in the amount of <i>{servicesAmount}</i>.</p><p><strong>Are you sure you want to continue?</strong></p><p><strong>this procedure will issue an invoice!</strong></p>",
          "duplicateInvoiceTitle": "Confirm NFS-e duplication",
          "errorTitle": "NFS-e Error message"
        },
        "status": {
          "Cancelled": "Cancelled",
          "Created": "Processing",
          "Error": "Error",
          "Issued": "Issued",
          "None": "All invoices"
        },
        "tableBody": {
          "cancelledDateDescription": "Cancelled on ",
          "createdDateDescription": "Created on ",
          "issuedDateDescription": "Issued on "
        },
        "tableHeader": {
          "amount": "Amount (R$)",
          "borrower": "Service Taker",
          "cancelledOn": "Canceled",
          "createdOn": "Created",
          "date": "Date",
          "description": "Description",
          "federalTaxNumber": "Borrower Federal Tax Number",
          "issuedOn": "Issued",
          "number": "#NF",
          "rps": "#RPS",
          "serie_number": "Serie/#NF",
          "status": "Status"
        },
        "view": {
          "additionalDetails": "Additional Details",
          "additionalInformation": "Additional Information",
          "address": "Address",
          "amountNet": "Amount Net",
          "baseTaxAmount": "BaseTax Amount",
          "batchCheckNumber": "Batch Check Number",
          "batchNumber": "Batch Number",
          "borrowerData": "Borrower Data",
          "cancelledOn": "Cancelled On",
          "checkCode": "Check Code",
          "cityServiceCode": "City Service Code",
          "cnpj": "CNPJ",
          "cpf": "CPF",
          "createdOn": "Created On",
          "deductionsAmount": "Deductions Amount",
          "federalServiceCode": "Complementary Law 116",
          "flowMessage": "Processing Reason",
          "flowStatus": "Processing Status",
          "id": "Identificator (ID)",
          "invoiceData": "Invoice data",
          "issRate": "ISS Rate (%)",
          "issTaxAmount": "ISS Tax Amount",
          "issuedOn": "Issued On",
          "legalNature": "Legal Nature",
          "modifiedOn": "Modified On",
          "moreDetails": "More information and technical details",
          "municipalTaxNumber": "Municipal Tax Number",
          "number": "Number",
          "paidAmount": "Paid Amount",
          "processedOn": "Processed On",
          "providerData": "Provider Data",
          "rpsNumber": "RPS Number",
          "rpsSerialNumber": "RPS Serial Number",
          "rpsStatus": "RPS Status",
          "rpsType": "RPS Type",
          "serviceDescription": "Service Description",
          "serviceValues": "Service Values",
          "servicesAmount": "Services Amount",
          "specialTaxRegime": "Special Tax Regime",
          "subtitle": "Showing invoice data",
          "taxRegime": "Tax Regime",
          "taxUnderService": "Tax Under Service (ISS)",
          "taxesWithheld": "Taxes Withheld",
          "title": "Invoice",
          "withheld": {
            "cofinsAmountWithheld": "COFINS Amount",
            "csllAmountWithheld": "CSLL Amount",
            "inssAmountWithheld": "INSS Amount",
            "irAmountWithheld": "IR Amount",
            "issAmountWithheld": "ISS Amount",
            "pisAmountWithheld": "PIS Amount"
          }
        }
      },
      "singular": "Company",
      "types": {
        "nfce": "NFC-e",
        "nfe": "NF-e",
        "nfse": "NFS-e"
      },
      "v2": {
        "edit": {
          "cte": {
            "delete": {
              "error": "Error disabling CT-e distribution query",
              "success": "CT-e distribution query disabled with success!"
            },
            "description": "The CT-e (Eletronic Transport knowledge) is a service provided by Sefaz in order to make reliable information available to the actors involved in the operation using the A1 type digital certificate.",
            "developmentAccountModal": {
              "text": "Your account is in test mode. So we will only consider queries from the current day.",
              "title": "Account in test mode"
            },
            "disableCteModal": {
              "text": "This action will remove the parameters and deactivate the CT-e distribution query service. Do you wish to continue?",
              "title": "Deactivate CT-e distribution query"
            },
            "requireActivation": {
              "description": "Click on the button below and follow the steps to active the <strong>CT-e distribution</strong> feature.",
              "title": "This feature is not active!"
            },
            "requireSupport": {
              "description": "Click the button below to contact support",
              "title": "There is no CT-e key registered for the company!"
            },
            "submit": {
              "certificateError": "Please, check if your company has an active digital certificate on our platform",
              "error": "Error enabling CT-e distribution query",
              "success": "CT-e distribution query enabled with success!"
            },
            "title": "CT-e distribution",
            "update": {
              "error": "Error updating CT-e distribution parameters",
              "success": "CT-e distribution parameters updated with success!"
            }
          },
          "environment": {
            "cardText": "Here you will be able to change the environment where your state tax is operating. Use our <strong>testing</strong> environment to simulate the issuance of your tax free invoices or the <strong>production</strong> environment to issue through Government systems.",
            "cardTitle": "State Tax operating on {environmentTitle}",
            "developmentEnv": {
              "description": "In <strong>development environment</strong> all the invoices will be processed by servers and systems that mimic the behavior of the Government.",
              "text": "In this environment the invoices are <u>NOT</u> processed by the Government system, that is, <strong>you will not pay taxes for the invoices</strong>.",
              "title": "Development Environmnent"
            },
            "productionEnv": {
              "description": "In <strong>production environment</strong> all the invoices will be processed by the Government servers and systems.",
              "text": "In this environment the invoices will be processed by the Government system, that is, <strong>you will pay taxes by the invoices</strong>.",
              "title": "Production Environmnent"
            }
          },
          "environmentDescriptions": {
            "Production": "Your state tax is runing in production mode, all invoices will be issued at the Government System.",
            "Test": "Your state tax is runing in test mode. <strong>All issued invoices will not have fiscal value.</strong>"
          },
          "nfe": {
            "delete": {
              "error": "Error disabling NF-e distribution query",
              "success": "NF-e distribution query disabled with success!"
            },
            "description": "NF-e distribution is a service provided by Sefaz in order to make reliable information for recipients and/or interested in the NF-e using the A1 type digital certificate.",
            "developmentAccountModal": {
              "text": "Your account is in test mode. So we will only consider queries from the current day.",
              "title": "Account in test mode"
            },
            "disableNfeModal": {
              "text": "This action will remove the parameters and deactivate the NF-e distribution query service. Do you wish to continue?",
              "title": "Deactivate NF-e distribution query"
            },
            "manifestAwarenessModal": {
              "text": "Are you sure you want the system to automatically send the manifest wareness operation event to the invoices with this pending?",
              "title": "Enable automatic submission of manifest wareness operation event"
            },
            "requireActivation": {
              "description": "Click on the button below and follow the steps to active the <strong>NF-e distribution</strong> feature.",
              "title": "This feature is not active!"
            },
            "submit": {
              "certificateError": "Please, check if your company has an active digital certificate on our platform",
              "error": "Error enabling NF-e distribution query",
              "success": "NF-e distribution query enabled with success!"
            },
            "title": "NF-e distribution",
            "update": {
              "error": "Error updating NF-e distribution parameters",
              "success": "NF-e distribution parameters updated with success!"
            },
            "warning": "To use this SEFAZ process environment, it is possible to guarantee the production that the resource is not being executed through another system, because in the case of the possibility of consumption in systems."
          },
          "stateTax": {
            "actions": {
              "editStateTax": "Edit"
            },
            "edit": {
              "title": "Showing State Tax data"
            },
            "emptyList": {
              "text": "The <strong>State Tax</strong> are used to hold required data about companies state registration (ICMS). Here you can create an State Tax, to do that just click in \"Create State tax\".",
              "title": "No state taxes created"
            },
            "new": "Create State Tax",
            "nfeNumberInfo": {
              "text": "<b>Series</b>: is a number used to group Eletronic Invoices, serving for control and identification of the invoices group.<br><b>NFe Number</b>:is a sequential integer that identifies the Eletronic Invoice.",
              "title": "Invoice Number"
            },
            "nfeSecurityCredentialInfo": {
              "text": "<b>CSC Token Identifier:</b> is a number that starts with 1 and identifies which token is being used.<br><b>Taxpayer Security Code (CSC):</b> is an alphanumeric code exclusive to the taxpayer and the Treasury Department (SEFAZ), which is used to generate the two-dimensional code, called “QR Code” of the Electronic Consumer Invoice (NFC-e).",
              "title": "Why do we need these fields?"
            },
            "stateTaxDetails": {
              "stateTaxNumber": "State Tax Number",
              "text": "The <strong>State Tax</strong> represents the required state registration data (ICMS) to process tax documents at SEFAZ. Use this page to edit and/or create new State Taxes."
            },
            "subTitle": "State Tax Information",
            "tableBody": {
              "status": {
                "Active": "Active",
                "Inactive": "Inactive"
              }
            },
            "tableHeader": {
              "createdOn": "Created on",
              "number": "State Tax Number",
              "serie": "Serie",
              "state": "State",
              "type": "Type"
            },
            "title": "State Taxes"
          }
        },
        "enums": {
          "certificateStatuses": {
            "active": "Active",
            "expired": "Expired",
            "expiring": "Expiring soon",
            "pending": "Waiting upload"
          }
        },
        "header": "Empresas-v2",
        "new": {
          "dfeDist": {
            "text": "Select the service you want to use.",
            "title": "Data for consultation"
          },
          "dfeDistLabel": "DF-e distribution consult",
          "lastStep": {
            "text": "<p>Clicking on create company button, you will have a ready company to issue product invoices.</p><p><strong>We recommend that your next step is to issue your first test invoice.</p></strong>",
            "title": "You are one step closer to creating your company"
          },
          "stateTax": {
            "subTitle": "Create State Tax"
          },
          "stateTaxLabel": "State Tax Data"
        }
      }
    },
    "companyName": "Name",
    "confirmBtn": "Confirm",
    "contactUs": "Contact us",
    "continueBtn": "Continue",
    "copy": "Copy",
    "country": "Country",
    "createAccountBtn": "Create account",
    "createBtn": "Create",
    "customer": "Customer",
    "dashboard": {
      "currentMonth": "Current month",
      "graphic": {
        "qtdAxis": "Issued quantity",
        "valueAxis": "Total amount(R$)"
      },
      "header": "Dashboard",
      "lastMonth": "Last month",
      "totalAmount": "Invoice amount",
      "totalCanceled": "Total Cancelled",
      "totalCreated": "Total Processing",
      "totalFailed": "Total Failed",
      "totalInvoices": "Invoice quantity",
      "totalIssued": "Total Issued",
      "totalIssuedPerMonth": "Total Invoices Issued per Month"
    },
    "deactivate": "Deactivate",
    "description": "Description",
    "district": "District",
    "docs": "Documentation",
    "downloadPdf": "Download PDF",
    "downloadXml": "Download XML",
    "edit": "Edit",
    "email": "Email",
    "end": "End",
    "enums": {
      "apiEnvironments": {
        "Development": "Development",
        "Production": "Production",
        "Sandbox": "Sandbox",
        "Test": "Test"
      },
      "companyStatuses": {
        "Active": "Ready to issue",
        "CityNotSupported": "Not supported",
        "Pending": "Waiting data"
      },
      "productInvoiceStatuses": {
        "Cancelled": "cancelled",
        "Created": "Created",
        "Error": "Issue failed",
        "IssueDenied": "Issue denied",
        "Issued": "Issued",
        "None": "-",
        "Processing": "Processing"
      },
      "serviceInvoiceStatuses": {
        "cancelled": "Cancelled",
        "created": "Processing",
        "error": "Error",
        "issued": "Issued",
        "none": "All"
      },
      "ticket": {
        "DFeTech.ConsumerInvoice.Subtypes": {
          "certificate": "Digital Certificate",
          "errorSendInvoiceData": "Failed to sent NFC-e data",
          "issueFailed": "Failed to issue",
          "others": "Others"
        },
        "DFeTech.ProductInvoice.Subtypes": {
          "certificate": "Digital Certificate",
          "errorSendInvoiceData": "Failed to sent NF-e data",
          "invoiceDenied": "NF-e issue denied",
          "invoicePdf": "NF-e PDF",
          "invoiceRefused": "NF-e refused by SEFAZ",
          "issueFailed": "Failed to issue",
          "others": "Others"
        },
        "DFeTech.ServiceInvoice.Subtypes": {
          "borrowerEmail": "Borrower E-mail",
          "certificate": "Digital Certificate",
          "divergingStatus": "NFS-e with diverging status",
          "invoiceInfomation": "Invoice Information",
          "invoicePdf": "NFS-e PDF",
          "invoiceStuckedInProcessingState": "NFS-e stuck on processing state",
          "issueFailed": "Failed to issue",
          "others": "Others"
        },
        "categories": {
          "cancellation": "Cancellation",
          "doubt": "Doubt",
          "problem": "Problem"
        },
        "dataQuerySubtypes": {
          "others": "Others",
          "queryFailed": "Query failed",
          "queryResponse": "Query response"
        },
        "departments": {
          "comercial": "Commercial",
          "financial": "Financial",
          "others": "Others",
          "technical": "Technical"
        },
        "financialProblemSubtypes": {
          "accessBlock": "Access blocked",
          "cancellation": "Contract cancellation",
          "changeTicketExpirationDate": "Change ticket expiration date",
          "fine": "Invoice intesrest",
          "generateTicketCopy": "Generate new ticket",
          "invoiceInfo": "Invoice information",
          "missingInvoice": "Invoice missing",
          "paymentSlip": "Ticket delivery",
          "refund": "Refund"
        },
        "fiscalSubtypes": {
          "cityServiceCodeRegistration": "Service code registration",
          "fiscalDoubt": "Fiscal doubt",
          "fiscalFailure": "failed to issue due to fiscal error",
          "others": "Outros",
          "prefectureIntegration": "Prefecture integration",
          "taxCalculation": "Tax calculation"
        },
        "integrationSubtypes": {
          "WooCommerce": "WooCommerce",
          "batchPlataformIssue": "Invoice issuance by spreadsheet on NFE.io customer dashboard",
          "customerIntegration": "Custom integration",
          "gestaoDs": "Gestão DS - Sistema",
          "internIntegration": "NFE.io integration",
          "mundipagg": "Mundipagg",
          "pluga": "Pluga",
          "vindi": "Vindi",
          "whmcs": "WHMCS"
        },
        "queryParameterTypes": {
          "accessKey": "Access key",
          "legalPerson": "CNPJ",
          "naturalPerson": "CPF"
        },
        "submitMessages": {
          "error": "Failt to send ticket!",
          "sending": "Sending ticklet",
          "success": "Ticket sent with success!"
        },
        "technicalProblemSubtypes": {
          "fiscal": "Fiscal",
          "integration": "Integration",
          "product": "Product"
        }
      }
    },
    "environment": "Environment",
    "error": {
      "apikey": {
        "text": "Dear customer, please contact NFE.IO support by email <a href='mailto:suporte.nfe.io'>suporte.nfe.io</a> or telephone <a href='tel:+55114063-8091'>+55 11 4063-8091</a>",
        "title": "We detected something wrong with your account"
      },
      "failToLoadData": {
        "description": "There was a technical issue when loading the data. Use the button below to contact our support team.",
        "title": "Fail to load data!"
      },
      "serviceUnavailable": "Service unavailable! Please try again soon."
    },
    "exitBtn": "Exit",
    "export": "Export",
    "finish": "Finish",
    "forms": {
      "account": {
        "email": "Email",
        "id": "Identification Code (ID)",
        "id_hint": "If you contact our team, we will use your ID to identify your account and assist you with any needs.",
        "name": "Name"
      },
      "address": {
        "additionalInformation": "Additional Info",
        "city": {
          "label": "City",
          "searchPlaceholder": "Search for a City"
        },
        "district": "District",
        "header": "Address",
        "number": "Number",
        "postalCode": "Postal Code",
        "state": {
          "label": "State",
          "searchPlaceholder": "Search for a State"
        },
        "street": "Street",
        "type": {
          "label": "Type",
          "searchPlaceholder": "Type"
        }
      },
      "certificate": {
        "extensionInfo": "Digital Certificate File Extension: PFX or P12",
        "password": "Digital Certificate Password",
        "selectFileBtn": "Select file",
        "sendFileBtn": "Send Digital Certificate"
      },
      "company": {
        "federalTaxNumber": "Federal Tax Number (CNPJ)",
        "header": "Company data",
        "legalNature": {
          "label": "Legal Nature",
          "searchPlaceholder": "Search for a Legal Nature"
        },
        "loginName": "User for accessing the Webservice",
        "loginPassword": "Password for accessing the Webservice",
        "name": "Name",
        "tax": {
          "federalTaxDetermination": {
            "label": "“Tax by the federation",
            "searchPlaceholder": "Search for the type of incidence"
          },
          "header": "Tax data",
          "municipalTaxDetermination": {
            "label": "Tax by the municipality",
            "searchPlaceholder": "Search for the type of incidence"
          },
          "municipalTaxNumber": "Municipal Tax Number",
          "municipalTaxNumberHint": "This is the code that identifies the company in the city/Prefecture and has a link with the ISS rate, <a href='https://nfe.io/docs/nota-fiscal-servico/conceitos-nfs-e/#o-que-e-inscric%C3%A3o-municipal' target='_blank'>know more!</a>",
          "passwordHint": "This is the password that authenticate the company in the prefecture system for issuing invoices.",
          "specialTaxRegime": {
            "hint": "It is a form of taxation, where particular sector or even business activity has a differentiated form of tax application.",
            "label": "Special Tax Regime",
            "searchPlaceholder": "Search for a Special Tax Regime"
          },
          "stateTaxNumber": "Regional Tax Number",
          "stateTaxNumberHint": "This is the code that identifies the company as being able to carry out sales operations of products in the national territory, <a href='https://nfe.io/docs/nota-fiscal-eletronica/conceitos-nf-e/#o-que-e-inscric%C3%A3o-estadual' target='_blank'>know more!</a>",
          "taxRegime": {
            "hint": "It is the set of laws that regulates the form of taxation of Legal Person/Company.",
            "label": "Tax Regime",
            "searchPlaceholder": "Search for a Tax Regime"
          }
        },
        "tradeName": "Trade Name"
      },
      "dfe": {
        "automaticManifesting": "Time to send the awareness operation",
        "automaticManifestingHint": "Time to be waited to send the awareness operation event to a nfe from its capture.",
        "cteLabel": "CT-e distribution",
        "manifestModal": {
          "text": "Are you sure you want the system to automatically send the awareness operation event for invoices with this pending?",
          "title": "Activate automatic dispatch of the operation science event"
        },
        "nfeLabel": "NF-e distribution",
        "startFromDate": "Start from date",
        "startFromDateHint": "Consult documents from the selected date. Obs: max. 90 days.",
        "startFromNsu": "Start from NSU",
        "startFromNsuHint": "Consults from the informed NSU. If the value entered is 0, information from the past 90 days will be considered.",
        "switchLabel": "Automatic dispatch of the operation science event"
      },
      "fiscal": {
        "rps": {
          "header": "RPS data",
          "number": "RPS Number",
          "serialNumber": "RPS Serial Number"
        },
        "taxes": {
          "authIssueValue": "Amount for authorization to issue",
          "header": "Taxes data",
          "iss": "ISS Rate for Tax Regime"
        }
      },
      "hook": {
        "events": {
          "eventType": {
            "ProductInvoice": "Product Invoice",
            "serviceInvoice": "Service Invoice"
          },
          "eventsOptions": {
            "cancel": "Canceled",
            "issue": "Issued",
            "pull": "Pulled"
          },
          "label": "Which events would you like to watch?"
        },
        "secret": "Pasword for message authentication (HMAC)",
        "statusOptions": {
          "active": "Active",
          "inactive": "Inactive"
        },
        "url": "Payload URL"
      },
      "stateTaxNumber": {
        "header": "NFe Number",
        "number": "Invoice Number",
        "serie": "Series"
      },
      "stateTaxSecurityCredential": {
        "code": "Taxpayer Security Code",
        "header": "Security Credential",
        "id": "Token Identifier"
      }
    },
    "goToPanel": "Go to panel",
    "header": {
      "helpText": "Send an email to <a href='mailto:suporte@nfe.io'>suporte@nfe.io</a>.<br>If you prefer contact us <a href='tel:+551140638091'>(11) 4063-8091</a>.",
      "helpTooltip": "Needing help?",
      "manageAccounts": "Manage Accounts",
      "manageUser": "Manage User",
      "managingAccount": "Managing account",
      "managingAccountAs": "Managing account as",
      "nfsFirstSteps": "NFSe First steps",
      "queries": "Queries",
      "query": "{name} query",
      "versionSelector": "Switch to previous version",
      "viewingWhat": "Showing all {entity}"
    },
    "hooks": {
      "data": "Webhook Data",
      "delete": {
        "error": "Error removing webhook",
        "success": "Webhook successfully removed!"
      },
      "edit": {
        "modal": {
          "header": "Delete this Webhook?",
          "text": "<p>This operation will delete the webhook.</p><p><strong>Are you sure you want to continue?</strong></p>"
        },
        "success": "Webhook successfully changed!",
        "title": "Webhook information"
      },
      "error": {
        "accessibleUrl": "Unable to validate URL. Make sure the URL is accessible.",
        "alreadyExists": "Webhook already exists",
        "default": "Unable to test your url. Our test expects your server to return an http status between 200 and 299.",
        "invalidSchema": "The WebHook URI must be absolute with a scheme of either 'http' or 'https'",
        "invalidUri": "Invalid URI: The hostname could not be parsed.",
        "validUrl": "Please ensure that the WebHook URI is valid and that the endpoint is accessible."
      },
      "filters": {
        "consumer_invoice-cancelled_error": "Cancelled error",
        "consumer_invoice-cancelled_failed": "Cancelled failed",
        "consumer_invoice-cancelled_successfully": "Cancelled successfully",
        "consumer_invoice-issued_error": "Issued Error",
        "consumer_invoice-issued_failed": "Issued failed",
        "consumer_invoice-issued_successfully": "Issued successfully",
        "product_invoice-cancelled_error": "Cancelled error",
        "product_invoice-cancelled_failed": "Cancelled failed",
        "product_invoice-cancelled_successfully": "Cancelled successfully",
        "product_invoice-cce_error": "CCE error",
        "product_invoice-cce_failed": "CCE failed",
        "product_invoice-cce_successfully": "CCE successfully",
        "product_invoice-issued_error": "Issued Error",
        "product_invoice-issued_failed": "Issued failed",
        "product_invoice-issued_successfully": "Issued successfully",
        "product_invoice_inbound-event_raised_successfully": "NF-e event generated successfully",
        "product_invoice_inbound-issued_successfully": "Issued successfully",
        "product_invoice_inbound_summary-event_raised_successfully": "NF-e summary event generated successfully",
        "product_invoice_inbound_summary-issued_successfully": "NF-e summary issued successfully",
        "product_tax-created_successfully": "Created successfully",
        "product_tax-creation_failed": "Creation failed",
        "product_tax-custom_rules_requested": "Custom rules requested",
        "tax_payment_form-created_successfully": "Tax successfully",
        "tax_payment_form-creation_failed": "Tax failed",
        "tax_payment_form-creation_not_needed": "Tax not needed",
        "transportation_invoice_inbound-event_raised_successfully": "CT-e event generated successfully",
        "transportation_invoice_inbound-issued_successfully": "Issued successfully"
      },
      "new": {
        "error": "Error creating webhook",
        "fillFields": "fill in the webhook data",
        "selectType": "Select webhook type",
        "success": "Webhook successfully registered!",
        "title": "Create Webhook"
      },
      "type": {
        "cteDist": "CT-e distribution",
        "gnre": "GNRE",
        "nfce": "NFC-e issuance",
        "nfe": "NF-e issuance",
        "nfeDist": "NF-e distribution",
        "nfse": "NFS-e issuance",
        "product": "Product"
      }
    },
    "import": "Import",
    "inactive": "Inactive",
    "invoice": "Invoice",
    "invoices": "Invoices",
    "issue": "Issue",
    "language": "Language",
    "languages": {
      "english": "English",
      "portuguese": "Português"
    },
    "legalNature": "Legal Nature",
    "loading": "Loading...",
    "login": {
      "signInBtn": "Sign in",
      "subtitle": "to access Dashboard",
      "title": "Sign in"
    },
    "message": "Message",
    "municipalTaxNumber": "Municipal Tax Number",
    "name": "Name",
    "nextBtn": "Next",
    "nfe": {
      "batch": {
        "generateBothBtn": "Gerar batch DANFE + XML",
        "generatePdfBtn": "Gerar batch DANFE",
        "generateXmlBtn": "Gerar batch XML",
        "invalid": "Invalid",
        "title": "Generate query batch",
        "total": "Total",
        "uploadBtn": "Send new batch",
        "valid": "Valid"
      },
      "batchesList": {
        "actions": {
          "downloadPdf": "Download PDF",
          "downloadXml": "Download XML"
        },
        "flowStatus": {
          "Completed": "Processed",
          "Error": "Query failed",
          "Failed": "Query failed",
          "Pending": "Pending",
          "Running": "Processing",
          "Running_Zip": "Processing"
        },
        "tableHeader": {
          "date": "Modified at",
          "name": "Name",
          "newBatch": "New batch",
          "quantity": {
            "total": "No. Keys",
            "totalError": "Error",
            "totalOk": "Ok"
          },
          "source": "Source",
          "status": "Status",
          "timeSpent": "Time spent"
        }
      },
      "deleteBatch": {
        "error": "Error to delete batch",
        "success": "Batch deleted successfully!",
        "text": "<p>This action will permanently delete batch.</p><p><b>Are you sure you want to continue?</b></p>",
        "title": "Delete batch?"
      },
      "devAccount": {
        "text": "You need to have an active account so that you can make queries, for this you need to speak to the sales team. <a href='mailto:comercial@nfe.io'>comercial@nfe.io</a> or <a href='tel:+551140638091'>(11) 4063-8091</a>.",
        "title": "Your account has not been activated for queries!"
      },
      "downloadBatch": {
        "error": "Error to download batch"
      },
      "emptyList": {
        "text": "Clique on \"Generate batch\" button to start consulting batch invoices using just the invoices access key. Here you can track the status of all batches submitted for query, and if the batch is successfully processed, it will be available for download.",
        "title": "No batch generated"
      },
      "generateBatch": {
        "batchInfo": "Batch information",
        "batchName": "Batch name",
        "batchNameValidation": "Enter a name for the batch",
        "chooseBatchName": "Choose a name for the output batch",
        "invalidKeys": "Invalid keys:",
        "keysInfo": "Keys information",
        "sendBatch": "Send batch",
        "sendBatchError": "Error to send batch",
        "sendBatchSuccess": "Batch sent successfully!",
        "validKeys": "Valid keys:"
      },
      "header": "Nfe Query",
      "subHeader": "Batches",
      "subTitle": "Eletronic Inovices",
      "title": "NFe Query Management",
      "uploadBatch": {
        "copyPaste": "Copy and paste the access keys in the field below",
        "dragDropText": "Drag and drop or click to select the file",
        "exampleExcel": "Excel example",
        "exampleText": "Text example",
        "hasExceededLimit": "keys limit exceeded (No. keys: {limit} Limit: 3000)",
        "loadFile": "Upload an excel file(.xlsx) or text file(.txt)",
        "placeholder": "Access keys",
        "step": "Step",
        "validateKeys": "Validate keys"
      }
    },
    "no": "No",
    "number": "Number",
    "okBtn": "OK",
    "onboard": {
      "activateErrorMsg": "Error activating services. Try again.",
      "cteInbound": {
        "content": "Consults of CT-e and CT-e events for the actors involved in the operation using digital certificate type A1.",
        "title": "CT-e distribution consult"
      },
      "legalEntityQuery": {
        "content": "Consult legal entity data using only the CNPJ number, such as: basic data, tax regime and state tax.",
        "title": "Legal Entity consult"
      },
      "multiCompanies": {
        "content": "Create and manage multiple companies in your account. This service necessarily works in conjunction with the NFe or NFSe issue service.",
        "title": "Multiple companies"
      },
      "naturalPersonQuery": {
        "content": "Consult natural person data using only the CPF number and have access to the status",
        "title": "Natural Person consult"
      },
      "nfceIssuance": {
        "content": "Issue Product Invoices; Manage multiple companies; Consult and track the events of your invoices; Download the PDF and XML.",
        "title": "Consumer Invoice issuance"
      },
      "nfeInbound": {
        "content": "Consults of NF-e and NF-e events for recipients and / or interested using digital certificate type A1.",
        "title": "NF-e distribution consult"
      },
      "nfeIssuance": {
        "content": "Issue Product Invoices; Manage multiple companies; Consult and track the events of your invoices; Download the PDF and XML.",
        "title": "Product Invoice issuance"
      },
      "nfeQuery": {
        "content": "Consult invoices in batch only with the access key by excel file, text or pasting the keys.",
        "title": "Product Invoice batch consult"
      },
      "nfseIssuance": {
        "content": "Issue Service Invoices; Manage multiple companies; Consult and track the processing of your invoices; Download the PDF and XML.",
        "title": "Service Invoice issuance"
      },
      "products": {
        "dataTech": "Services for consults",
        "dfeTech": "Services for issuing eletronic invoices"
      },
      "serviceInvoice": {
        "firstSteps": {
          "createCompany": {
            "text": "Register the company data that will issue nfse on the platform.",
            "title": "Register Company"
          },
          "issueInvoice": {
            "text": "Issue your first service invoice on the platform.",
            "title": "Issue Invoice"
          },
          "title": "First steps to issue a Service Invoice",
          "uploadCertificate": {
            "text": "Upload your digital certificate to the platform.",
            "title": "Certificate Upload"
          }
        },
        "issueStepper": {
          "cancelModal": {
            "text": "Upon confirmation, we will cancel the service invoice issued in the previous step.",
            "title": "Confirm cancelation?"
          },
          "issueModal": {
            "text": "Upon confirmation, we will issue the service invoice with the information provided.",
            "title": "Confirm issue?"
          },
          "step1": {
            "message": "Contact your accountant and make your first service invoice issue to use it as a basis for the next issues.",
            "title": "Have you issued a service invoice before?"
          },
          "step2": {
            "text1": "It is important to issue the first invoice in your campaign, already in a production environment. Don't worry, the amount will be 10 cents and we at NFE.io will be the borrower. Thus, we will be able to validate all registered data are correct.",
            "text2": "It is important to issue the first invoice for the validation of your company's registered data. Don't worry, it will be a test invoice of 10 cents and we at NFE.io will be the borrower.",
            "title": "Let's issue your first invoice with NFE.io?"
          },
          "step3": {
            "text": "Use the search bar to find the service code provided by your company. We will use this code to issue the invoice. See below the list of services registered at {cityName}, {state}. Note: it is possible to select only one code.",
            "title": "Search and select your invoice service"
          },
          "step4": {
            "borrowerData": "Borrower data",
            "issuerData": "Issuer data",
            "nfseData": "NFSe data",
            "serviceDescription": "Issuance of service invoice in {environment} environment for validation of registered data.",
            "text1": "Review the data below to issue the invoice in your campaign already in <b>production environment</b>. Recalling that the issuer will be your company previously registered and the borrower will be the company NFE.io.",
            "text2": "Review the data below to issue the invoice at the <b>test prefecture</b> of NFE.io. Recalling that the issuer will be your company previously registered and the borrower will be the company NFE.io.",
            "title": "Confirm issuance data",
            "totalAmount": "Total amount"
          },
          "step5": {
            "calculatingTaxes": "Calculating taxes",
            "definingRpsNumber": "Defining RPS number",
            "sendingToNfeio": "Sending to the NFE.io system",
            "sendingToPrefecture": "{isCompanyInProduction, select, true {Sending to prefecture system} false {Sending to the NFE.io test prefecture}}",
            "text": "In this step we will issue a service invoice with the data confirmed in the previous step. <br> Follow the invoice sending below.",
            "title": "Issue service invoice",
            "waitingReturn": "{isCompanyInProduction, select, true {Awaiting return of the prefecture system} false {Awaiting return of the test prefecture}}"
          },
          "step6": {
            "errorMessage": "There was an error in the issue.<br><b>Message:</b> {message}",
            "successMessage": "Congratulations! We have successfully issued an invoice at the NFE.io test prefecture. View your invoice by downloading the PDF/XML and validate that all information is correct.",
            "titleDefault": "View invoice",
            "titleError": "Failed to issue service invoice",
            "titleSuccess": "Service invoice successfully issued!"
          },
          "step7": {
            "sendingToNfeio": "Sending request to the NFE.io system",
            "sendingToPrefecture": "Sending cancellation to the prefecture system",
            "text": "In this step, we will cancel the service invoice issued in the previous step. Click on the button below to perform the cancellation. <br> Follow the invoice cancellation below.",
            "title": "Cancel service invoice",
            "waitingReturn": "{isCompanyInProduction, select, true {Awaiting return of the prefecture system} false {Awaiting cancellation at the test prefecture}}"
          },
          "step8": {
            "errorMessage": "There was an error canceling.<br><b>Message:</b> {message}",
            "successMessage": "Congratulations! Your service invoice has been successfully canceled. <br> View your invoice by downloading the PDF / XML.",
            "titleDefault": "View invoice",
            "titleError": "Failed to cancel service invoice",
            "titleSuccess": "Service invoice canceled successfully!"
          },
          "title": "Issue first invoice",
          "waiting": {
            "message1": "Please, wait a moment!",
            "message2": "We are processing your invoice, please wait.",
            "message3": {
              "waitingCalculateTaxes": "This step is usually faster. It could be that our system is unstable at the moment, would you like to come back later?",
              "waitingDownload": "This step is usually faster. It could be that our system is unstable at the moment, would you like to come back later?",
              "waitingReturn": "The prefectures may show slowness and instability with some frequency and this stage may take a little longer than expected, would you like to come back later?",
              "waitingSend": "We have a high number of invoices in our queue and this step may take a little longer than expected, would you like to come back later?"
            },
            "modalTitle": "Sorry for the waiting"
          }
        },
        "skipOnboardModal": {
          "text": "We recommend that you take the first steps so that we can correctly validate all the information needed to issue a service invoice.",
          "title": "Do you really want to skip this step?"
        },
        "successMessage": {
          "api": {
            "text": "Integration with our API (requires a development team).",
            "title": "API"
          },
          "batchByExcel": {
            "text": "Issuing invoices in batch using an excel spreadsheet.",
            "title": "Batch by excel"
          },
          "integrations": {
            "text": "Integration with payment systems (Ex: Vindi, Iugu).",
            "title": "Integrations"
          },
          "startIssuing": "Start now to issue your service invoices",
          "text": "You issued your first invoice at your company. If you still have questions, we suggest you start by learning<br><a href='https://nfe.io/docs/nota-fiscal-servico/conceitos-nfs-e/' target='_blank'>everything about Service Invoice</a>. Below are some options for how you can issue invoices using our system.",
          "title": "Congratulations! You have completed all steps for issuing service invoices"
        }
      },
      "text": "Your account has been successfully created! To continue, select below the services you want to use on our platform.",
      "title": "Select the services you want to use on platform"
    },
    "openTicket": "Open Ticket",
    "password": "Password",
    "pay": "Pay",
    "payment": {
      "addNewCard": "Add new card",
      "bankSlip": "Bank Slip",
      "bankTransferModal": {
        "account": "Account",
        "selectBank": "Select the bank you want to make the transfer",
        "title": "Bank transfer"
      },
      "creditCard": "Credit Card",
      "creditCardForm": {
        "expiration": "MM / YY",
        "holderName": "Name (same on the card)",
        "number": "Card number",
        "securityCode": "CVC"
      },
      "creditCardModal": {
        "failedTitle": "Payment failed",
        "internalServerError": {
          "text": "Our payment system is unavailable. Contact our support (11) 4063-8091.",
          "title": "Payment could not be made"
        },
        "processingTitle": "Please wait while we process your payment...",
        "successTitle": "Payment accept!",
        "unauthorized": {
          "text": "Please, check your details or contact your card operator.",
          "title": "Payment declined"
        }
      },
      "customer": {
        "title": "Customer's data"
      },
      "discount": "Discount",
      "federalTaxNumber": "CPF/CNPJ",
      "invoice": {
        "createdOn": "Created on",
        "downloadEInvoice": "Download EInvoice",
        "downloadEInvoiceXML": "Download EInvoice XML",
        "downloadInvoice": "Download Invoice",
        "dueOn": "Due on"
      },
      "payInvoice": "Pay now",
      "penalty": "Penalty",
      "pixModal": {
        "text": "Search your bank application or digital account for PIX payment and scan the QR Code above."
      },
      "provider": {
        "title": "Provider's data"
      },
      "status": {
        "Cancelled": "Cancelled",
        "Expired": "Expired",
        "New": "New",
        "Paid": "Paid",
        "PartiallyPaid": "Partially paid",
        "Pending": "Pending",
        "Refunded": "Refunded",
        "WaitingGateway": "Waiting Gateway"
      },
      "subtitle": "<small>#{paymentNumber}</small>",
      "taxWithheld": "Tax Withheld",
      "title": "Payment"
    },
    "postalCode": "CEP",
    "preInvoice": {
      "approvalModal": {
        "confirmation": "Do you really want to approve this Pre-Invoice?",
        "title": "Approve Pre-invoice"
      },
      "customer": {
        "title": "Customer's data"
      },
      "discount": "Discount",
      "federalTaxNumber": "CPF/CNPJ",
      "invoice": {
        "downloadInvoice": "Download Pre-Invoice",
        "dueOn": "Due on",
        "effectiveOn": "Created on",
        "title": "Pre-Invoice details",
        "usageUpdatedOn": "Usage updated on"
      },
      "provider": {
        "title": "Provider's data"
      },
      "refusalModal": {
        "confirmation": "Let us know why you are refusing this Pre-Invoice.",
        "title": "Refuse Pre-invoice"
      },
      "status": {
        "Approved": "Approved",
        "Declined": "Declined",
        "Done": "Billed",
        "Draft": "New",
        "Executing": "In progress",
        "Refused": "Refused",
        "WaitingApproval": "Waiting",
        "WaitingBilling": "Billing",
        "unknown": "Unknown"
      },
      "subtitle": "<small>#{orderNumber}</small>",
      "taxes": "Taxes",
      "title": "Pre-Invoice"
    },
    "productInvoices": "Product Invoices",
    "provider": "Provider",
    "quantity": "Quantity",
    "quantityAbbr": "Qty",
    "refuse": "Refuse",
    "register": {
      "company": "Company",
      "name": "Name",
      "passwordMessage": "Use 8 or more characters with a combination of letters, numbers, and symbols.",
      "phone": "Phone",
      "signInBtn": "Sign in instead",
      "subtitle": "to continue and access Dashboard",
      "title": "Create your Account"
    },
    "registerBtn": "Register",
    "reload": "Refresh",
    "remove": "Remove",
    "removeBtn": "Remove",
    "reprocessPdf": "Reprocess PDF",
    "reprocessWebhook": "Reprocess Webhook",
    "returnBtn": "Return",
    "select": "Select",
    "selected": "Selected",
    "sendEmail": "Send email",
    "sendFile": "Send file",
    "sequence": "Sequence",
    "serie": "Serie",
    "serviceInvoices": "Service Invoices",
    "signupBtn": "Sign up",
    "skipBtn": "Skip",
    "specialTaxRegime": "Special Tax Regime",
    "start": "Start",
    "stateTax": "State Tax",
    "submitBtn": "Save",
    "table": {
      "buyer": {
        "name": "Buyer"
      },
      "certificateStatus": "Certificate",
      "createdOn": "Created on",
      "description": "Description",
      "email": "Email",
      "environment": "Environment",
      "federalTaxNumber": "CNPJ",
      "modifiedDate": "Modified",
      "name": "Name",
      "nextPage": "Go to next page",
      "page": "Page",
      "prevPage": "Go to previous page",
      "rowsPerPage": "Rows per page",
      "search": "Add a Filter",
      "state": "City/State",
      "status": "Status",
      "tableBody": {
        "status": {
          "Active": "Active",
          "Inactive": "Inactive"
        }
      },
      "type": "Type",
      "url": "URL"
    },
    "taxRegime": "Tax Regime",
    "ticketDialog": {
      "steps": {
        "advancedData": {
          "formTitle": "Advanced data",
          "inputs": {
            "company": {
              "hint": "Select the company used to issue the invoice",
              "label": "Company",
              "searchPlaceholder": "Select a company"
            },
            "invoiceId": {
              "hint": "Fill in the invoice ID which you need our help",
              "label": "Invoice ID"
            },
            "naturalPersonbirthDate": {
              "label": "Birth date",
              "placeholder": "Birth date"
            },
            "problemSubType": {
              "hint": "Select the option that is most related to the problem",
              "label": "Problem subtype",
              "searchPlaceholder": "Select one option"
            },
            "queryDate": {
              "hint": "Select the day that you made the query (format: YYYY-MM-DD)",
              "label": "Query date",
              "placeholder": "Query date"
            },
            "queryParameter": {
              "hint": "Fill in the query parameter",
              "label": "Query parameter"
            }
          },
          "label": "Product data"
        },
        "basicData": {
          "formTitle": "Basic data",
          "inputs": {
            "category": {
              "label": "Category",
              "searchPlaceholder": "Select the categoria"
            },
            "department": {
              "hint": "Select the Department from NFE.io which you think that is responsible for this type of problem",
              "label": "Department",
              "searchPlaceholder": "Select one option"
            },
            "product": {
              "hint": "Select the product on which you found the problem",
              "label": "Product",
              "searchPlaceholder": "Select a product"
            },
            "ticketType": {
              "hint": "Select the option that is most related to the problem",
              "label": "Problem type",
              "searchPlaceholder": "Select the type of the problem"
            }
          },
          "label": "Basic ticket data"
        },
        "identification": {
          "formTitle": "Identification and description",
          "label": "Identification and problem description",
          "sendButton": "Send Ticket"
        },
        "send": {
          "awaitingSendMessage": "Please wait! We are sending your ticket for our team",
          "error": {
            "failedExplanation": "Our system apears to be instable, your ticket wasn't send to our team.",
            "failedToSend": "Failed to send you ticket",
            "sorryMessage": "We are very sorry for the inconvinient. You can try again later or call us on the following number: +55 11 4063-8091"
          },
          "label": "Status",
          "sentWithSuccess": "Your ticket was sent with success!"
        },
        "uploadFiles": {
          "instructions": "Use this step to upload files related to the problem or doubt, e.g: invoice XML or PDF",
          "label": "Upload files",
          "table": {
            "actions": "Actions",
            "fileName": "Files name"
          },
          "uploadAdvise": "These files can speed up the resolution of your ticket",
          "uploadBtn": "Upload files"
        }
      },
      "title": "Open Ticket"
    },
    "total": "Total",
    "type": "Type",
    "user": "User",
    "validations": {
      "email": "Enter an email",
      "password": "Enter a password",
      "register": {
        "company": "Enter at least 2 characters",
        "name": "Enter your full name",
        "phone": "Enter your phone number",
        "validName": "Enter your valid full name",
        "validPhone": "Enter a valid phone number"
      },
      "validEmail": "Enter a valid email",
      "validPassword": "Enter a valid password (min. 6 digits)"
    },
    "version": "Version",
    "yes": "Yes"
  };
  _exports.default = _default;
});