define("nfe-customer-console-v2/serializers/city", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CitySerializer extends _rest.default {
    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        cities: payload
      };
      return super.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = CitySerializer;
});