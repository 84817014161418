define("nfe-customer-console-v2/components/accounts-loader/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FETCH_DEBOUNCE = 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    tagName: '',
    // attributes from template
    page: '',
    perPage: '',
    filterAccount: '',

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      return this.performLoad();
    },

    performLoad() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        filterAccount: this.filterAccount
      };
      return this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params)) {
        return;
      }

      if (FETCH_DEBOUNCE > 0) yield (0, _emberConcurrency.timeout)(FETCH_DEBOUNCE);

      try {
        let data = yield this.store.query('account', {
          pageIndex: params.page,
          pageCount: params.perPage,
          search: params.filterAccount
        });
        return this.set('results', data);
      } catch (error) {
        return this.set('results', null);
      }
    }).restartable(),
    activateAction: (0, _emberConcurrency.task)(function* (model) {
      yield (0, _emberConcurrency.timeout)(200);
      yield model.activate();
      yield this.performLoad();
      this.notify.success('Conta ativada com sucesso!');
    }).drop(),
    deactivateAction: (0, _emberConcurrency.task)(function* (model) {
      yield (0, _emberConcurrency.timeout)(200);
      yield model.deactivate();
      yield this.performLoad();
      this.notify.success('Conta desativada com sucesso!');
    }).drop(),
    setEnvironmentProdAction: (0, _emberConcurrency.task)(function* (model) {
      yield (0, _emberConcurrency.timeout)(200);
      yield model.setEnvironment('production');
      yield this.performLoad();
      this.notify.success('Ambiente da conta alterado com sucesso!');
    }).drop(),
    setEnvironmentDevAction: (0, _emberConcurrency.task)(function* (model) {
      yield (0, _emberConcurrency.timeout)(200);
      yield model.setEnvironment('development');
      yield this.performLoad();
      this.notify.success('Ambiente da conta alterado com sucesso!');
    }).drop(),
    updateUsageSum: (0, _emberConcurrency.task)(function* (model, subtractMonths = 0) {
      var lasMonth = (0, _moment.default)(new Date()).subtract(subtractMonths, 'months');
      let begin = lasMonth.startOf('month').format('YYYY-MM-DD');
      let end = lasMonth.endOf('month').format('YYYY-MM-DD');
      yield (0, _emberConcurrency.timeout)(200);
      yield model.updateUsage(begin, end);
      yield this.performLoad();
      this.notify.success('Atualização do resumo de uso solicitado!');
    }).drop(),
    updateUsageSumAny: (0, _emberConcurrency.task)(function* (subtractMonths = 1) {
      var lasMonth = (0, _moment.default)(new Date()).subtract(subtractMonths, 'months');
      let begin = lasMonth.startOf('month').format('YYYY-MM-DD');
      let end = lasMonth.endOf('month').format('YYYY-MM-DD');
      yield (0, _emberConcurrency.timeout)(200);
      const adapter = this.store.adapterFor('account');
      yield adapter.updateUsageAll(begin, end);
      yield this.performLoad();
      this.notify.success('Atualização do resumo de uso solicitado, para todas as contas!');
    }).drop()
  });

  _exports.default = _default;
});