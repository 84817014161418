define("nfe-customer-console-v2/controllers/companies/edit/basic-info", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    activateValidations: false,
    updateCompany: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateValidations', true);
      if (model.get('isInvalid')) return;
      const account = yield this.sessionData.getCurrentAccount();

      try {
        yield model.save({
          adapterOptions: {
            accountId: account.id
          }
        });
        this.notify.success('Empresa alterada com sucesso!');
        this.transitionToRoute('companies.edit', model.id);
      } catch (error) {
        this.notify.error('Erro ao alterar empresa!');
      }
    })
  });

  _exports.default = _default;
});