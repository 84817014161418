define("nfe-customer-console-v2/templates/companies-v2/edit/basic-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1q2+4Kbl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"layout-row-form\"],[8],[0,\"\\n    \"],[5,\"toolbar\",[],[[\"@title\",\"@subtitle\"],[[28,\"t\",[\"companies.edit.title\"],null],[24,[\"model\",\"name\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"product-invoice-actions-button\",[],[[\"@companyId\",\"@defaultActions\"],[[24,[\"model\",\"id\"]],true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"paper-form\",[],[[\"@onSubmit\"],[[28,\"action\",[[23,0,[]],\"submit\",[24,[\"model\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"md-card-form\",[],[[\"@titleText\",\"@onSubmitClick\",\"@onCancelClick\",\"@returnButton\"],[[28,\"t\",[\"companies.edit.basicInfo.title\"],null],[28,\"action\",[[23,0,[]],\"submit\",[24,[\"model\"]]],null],[28,\"transition-to\",[\"companies-v2.edit\",[24,[\"model\",\"id\"]]],null],[28,\"transition-to\",[\"companies-v2.edit\",[24,[\"model\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n            \"],[5,\"company-v2-form\",[],[[\"@model\",\"@touched\"],[[22,\"model\"],[22,\"activateValidations\"]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/companies-v2/edit/basic-info.hbs"
    }
  });

  _exports.default = _default;
});