define("nfe-customer-console-v2/services/route-redirect", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    user: Ember.inject.service(),
    features: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    provider: Ember.inject.service(),
    onboard: Ember.inject.service(),
    router: Ember.inject.service(),
    routeAfterLogin: (0, _emberConcurrency.task)(function* () {
      let accountsResult = yield this.sessionData.getUserAccounts();

      if (accountsResult.accounts && accountsResult.accounts.length == 0) {
        return this.router.replaceWith('create-account');
      }

      yield this.sessionData.getCurrentAccount();

      if (this.provider.hasAccountAndSomeFeature()) {
        if (this.get('features.dFeTechServiceInvoice')) {
          this.router.replaceWith('dashboard');
        } else {
          this.router.replaceWith('account');
        }
      }

      if (this.get('features.dFeTechServiceInvoice') && !this.get('features.dFeTechOnboardingServiceInvoice') && !this.user.isAdmin) {
        this.router.replaceWith('onboard.service-invoice');
      }
    })
  });

  _exports.default = _default;
});