define("nfe-customer-console-v2/services/notification", ["exports", "nfe-customer-console-v2/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionData: Ember.inject.service(),
    url: _environment.default.APP.NOTIFICATION_URL,
    accountId: null,
    connection: null,
    isConnected: Ember.computed('connection.state', function () {
      return this.connection != null && this.connection.state == signalR.HubConnectionState.Connected;
    }),

    async connect() {
      const account = await this.sessionData.getCurrentAccount();

      if (this.isConnected && this.accountId && account && this.accountId != account.id) {
        await this.disconnect();
      } else if (this.isConnected) {
        return this.connection;
      }

      this.accountId = account.id;
      let connection = new signalR.HubConnectionBuilder().withUrl(`${this.url}/${this.accountId}`).configureLogging(signalR.LogLevel.Information).build();
      this.set('connection', connection);
      this.connection.onclose(() => console.debug('Disconnected from Notification server'));
      console.debug(`Connecting to Notification server with account ${this.accountId}...`);
      await this.connection.start();
      return this.connection;
    },

    async disconnect() {
      if (!this.connection || this.connection.state == signalR.HubConnectionState.Disconnected) return Promise.resolve();
      console.debug(`Disconnecting from Notification server with account ${this.accountId}...`);
      return this.connection.stop();
    }

  });

  _exports.default = _default;
});