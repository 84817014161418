define("nfe-customer-console-v2/models/pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Pricing extends Ember.Object.extend() {}

  _exports.default = Pricing;

  _defineProperty(Pricing, "table_items", [{
    // name: "invoicesAmount",
    name: "Quantidade de notas",
    description: "",
    smallBusiness: "50/mês",
    basic: "100/mês",
    enterprise: "200+/mês",
    icon: false
  }, {
    // name: "unitPrice",
    name: "Preço unitário",
    description: "",
    smallBusiness: "R$0,98",
    basic: "R$0,95",
    enterprise: "sob demanda",
    icon: false
  }, {
    // name: "usersAmount",
    name: "Usuários",
    description: "",
    smallBusiness: 1,
    basic: 2,
    enterprise: "Múltiplos",
    icon: false
  }, {
    // name: "automaticCalculatingTax",
    name: "Cálculo automático de impostos",
    description: "",
    smallBusiness: true,
    basic: true,
    enterprise: true,
    icon: true
  }, // {
  //   // name: "consultaGuru",
  //   name: "Consulta de dados pelo consulta.guru",
  //   description: "",
  //   smallBusiness: true,
  //   basic: true,
  //   enterprise: true,
  //   icon: true
  // },
  {
    // name: "nfseBatch",
    name: "Emissão de NFS-e em lote via excel",
    description: "",
    smallBusiness: true,
    basic: true,
    enterprise: true,
    icon: true
  }, // {
  //   // name: "email",
  //   name: "Envio automático de e-mails",
  //   description: "",
  //   smallBusiness: true,
  //   basic: true,
  //   enterprise: true,
  //   icon: true
  // },
  {
    // name: "ecommerce",
    name: "Integração com e-commerce",
    description: "",
    smallBusiness: true,
    basic: true,
    enterprise: true,
    icon: true
  }, {
    // name: "paymentMethods",
    name: "Integração com meios de pagamentos",
    description: "",
    smallBusiness: true,
    basic: true,
    enterprise: true,
    icon: true
  }, {
    // name: "retroactiveInvoices",
    name: "Emissão de notas retroativas",
    description: "",
    smallBusiness: false,
    basic: true,
    enterprise: true,
    icon: true
  }, {
    // name: "api",
    name: "Acesso a API",
    description: "",
    smallBusiness: false,
    basic: false,
    enterprise: true,
    icon: true
  }, {
    // name: "storage",
    name: "Armazenamemto da nota por 5 anos",
    description: "",
    smallBusiness: false,
    basic: false,
    enterprise: true,
    icon: true
  }, // {
  //   // name: "apiIntegrationAssist",
  //   name: "Integração com a API assistida por um especialista",
  //   description: "",
  //   smallBusiness: false,
  //   basic: false,
  //   enterprise: true,
  //   icon: true
  // },
  {
    // name: "multicompanies",
    name: "Múltiplas empresas",
    description: "",
    smallBusiness: false,
    basic: false,
    enterprise: true,
    icon: true
  }, {
    name: "Onboarding",
    description: "",
    smallBusiness: "Virtual",
    basic: "Virtual",
    enterprise: "Com especialista",
    icon: false
  }, {
    // name: "support",
    name: "Suporte",
    description: "",
    smallBusiness: "Ticket",
    basic: "E-mail / Ticket",
    enterprise: "E-mail / Ticket / Chat",
    icon: false
  }, {
    // name: "membershipFee",
    name: "Taxa de adesão <small>(apenas no primeiro mês)</small>",
    description: "",
    smallBusiness: "R$199,00",
    basic: "R$199,00",
    enterprise: "Comercial",
    icon: false
  }, {
    // name: "price",
    name: "Preço",
    description: "",
    smallBusiness: "*R$49,00/mês",
    basic: "*R$95,00/mês",
    enterprise: "Comercial",
    icon: false
  }]);

  _defineProperty(Pricing, "plans", [{
    name: "smallBusiness",
    invoicesAmount: "50",
    unitPrice: 0.99,
    membershipFee: 199,
    price: 96.90,
    features: {
      usersAmount: 1,
      automaticCalculatingTax: true,
      nfseBatch: true,
      ecommerce: true,
      paymentMethods: true,
      retroactiveInvoices: false,
      api: false,
      storage: false,
      multicompanies: false,
      onboarding: "Virtual",
      support: "Ticket"
    }
  }, {
    name: "basic",
    invoicesAmount: "100",
    unitPrice: 0.96,
    membershipFee: 199,
    price: 49.90,
    features: {
      usersAmount: 2,
      automaticCalculatingTax: true,
      nfseBatch: true,
      ecommerce: true,
      paymentMethods: true,
      retroactiveInvoices: true,
      api: false,
      storage: false,
      multicompanies: false,
      onboarding: "Virtual",
      support: "E-mail / Ticket"
    }
  }, {
    name: "enterprise",
    invoicesAmount: "200+",
    unitPrice: "sob demanda",
    membershipFee: "Comercial",
    price: "Comercial",
    features: {
      usersAmount: "Múltiplos",
      automaticCalculatingTax: true,
      nfseBatch: true,
      ecommerce: true,
      paymentMethods: true,
      retroactiveInvoices: true,
      api: true,
      storage: true,
      multicompanies: true,
      onboarding: "Com especialista",
      support: "E-mail / Ticket / Chat"
    }
  }]);
});