define("nfe-customer-console-v2/helpers/safe-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function safeString([value]
  /*, hash*/
  ) {
    return Ember.String.htmlSafe(value);
  });

  _exports.default = _default;
});