define("nfe-customer-console-v2/serializers/service-invoice", ["exports", "nfe-customer-console-v2/serializers/service-invoice-query"], function (_exports, _serviceInvoiceQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServiceInvoiceSerializer extends _serviceInvoiceQuery.default.extend() {
    normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      let municipalTaxNumber = payload.serviceInvoice.invoiceProvider.municipalTaxNumber || null;
      if (municipalTaxNumber && municipalTaxNumber.includes(":")) payload.serviceInvoice.invoiceProvider.municipalTaxNumber = municipalTaxNumber.substring(0, municipalTaxNumber.indexOf(":"));
      return super.normalizeFindRecordResponse(...arguments);
    }

    serializeIntoHash(hash, type, record, options) {
      let serializedRecord = this.serialize(record, options);
      serializedRecord.borrower.federalTaxNumber = parseInt(serializedRecord.borrower.federalTaxNumber || '0').toString().replace(/\D+/g, '');
      Ember.assign(hash, serializedRecord);
    }

    serialize(snapshot, options) {
      let json = super.serialize(...arguments);
      let address = json?.borrower?.address || {};
      if (Ember.isNone(json.borrower.address) || Ember.isNone(json.borrower.address.city) && Ember.isNone(json.borrower.address.city.code) && Ember.isNone(json.borrower.address.city.name) && Ember.isNone(json.borrower.address.district) && Ember.isNone(json.borrower.address.number) && Ember.isNone(json.borrower.address.postalCode) && Ember.isNone(json.borrower.address.state) && Ember.isNone(json.borrower.address.street)) json.borrower.address = null;
      return json;
    }

  }

  _exports.default = ServiceInvoiceSerializer;
});