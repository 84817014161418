define("nfe-customer-console-v2/serializers/user", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload) {
        id = payload.email;
        payload = {
          user: payload
        };
        payload.user.id = id;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});