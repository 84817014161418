define("nfe-customer-console-v2/adapters/service-invoice", ["exports", "ember-data-url-templates", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment"], function (_exports, _emberDataUrlTemplates, _baseRest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ServiceInvoiceAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", _environment.default.APP.FRONTEND_URL);

      _defineProperty(this, "findRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices{/id}');

      _defineProperty(this, "createRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices');

      _defineProperty(this, "updateRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices{/id}');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/companies/{company_id}/serviceInvoices{/id}');

      _defineProperty(this, "urlSegments", {
        // The urlSegments property declared here override the urlSegments from ember-date-url-templates addon
        // that is why I declared the host again
        host() {
          return this.host;
        },

        id(type, id, snapshot, query) {
          return id;
        },

        company_id(type, id, snapshot, query) {
          if (snapshot.adapterOptions && snapshot.adapterOptions.company_id) return snapshot.adapterOptions.company_id;
          return query.companyId;
        }

      });
    }

    get headers() {
      return super.headersApiKey;
    }

    reprocessWebhook(companyId, invoiceId) {
      const url = this.buildURL('service-invoce', invoiceId, {
        adapterOptions: {
          company_id: companyId
        }
      }, 'updateRecord');
      return super.ajax(url + `/delivery-webhooks`, "PUT");
    }

    sendEmail(companyId, invoiceId) {
      const url = this.buildURL('service-invoce', invoiceId, {
        adapterOptions: {
          company_id: companyId
        }
      }, 'updateRecord');
      return super.ajax(url + `/sendemail`, "PUT");
    }

    cancelInvoice(companyId, invoiceId) {
      const url = this.buildURL('service-invoce', invoiceId, {
        adapterOptions: {
          company_id: companyId
        }
      }, 'deleteRecord');
      return super.ajax(url, "DELETE");
    }

    handleResponse(status, headers, payload, requestData) {
      // Prevent exception when ember-data try to parse an empty text response as JSON
      if (requestData.method === "PUT" && requestData.url.includes("/sendemail")) return super.handleResponse(204, headers, payload, requestData);

      if (status >= 400 && payload && payload.message) {
        payload = {
          errors: [{
            status,
            detail: payload.message
          }]
        };
      }

      return super.handleResponse(status, headers, payload, requestData);
    }

    ajaxOptions(url, type, options) {
      let hash = super.ajaxOptions(...arguments);
      if (type === "PUT" && (url.includes("/sendemail") || url.includes("/delivery-webhooks"))) hash.dataType = "text";
      return hash;
    }

  }

  _exports.default = ServiceInvoiceAdapter;
  ;
});