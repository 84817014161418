define("nfe-customer-console-v2/models/account-order", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountOrderModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('number'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _attributes.array)(), _dec20 = Ember.computed('status'), _dec21 = Ember.computed('items'), (_class = (_temp = class AccountOrderModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "accountId", _descriptor, this);

      _initializerDefineProperty(this, "orderNumber", _descriptor2, this);

      _initializerDefineProperty(this, "status", _descriptor3, this);

      _initializerDefineProperty(this, "description", _descriptor4, this);

      _initializerDefineProperty(this, "name", _descriptor5, this);

      _initializerDefineProperty(this, "createdOn", _descriptor6, this);

      _initializerDefineProperty(this, "modifiedOn", _descriptor7, this);

      _initializerDefineProperty(this, "effectiveOn", _descriptor8, this);

      _initializerDefineProperty(this, "dueOn", _descriptor9, this);

      _initializerDefineProperty(this, "fulfilledOn", _descriptor10, this);

      _initializerDefineProperty(this, "usageUpdatedOn", _descriptor11, this);

      _initializerDefineProperty(this, "totalAmount", _descriptor12, this);

      _initializerDefineProperty(this, "discountAmount", _descriptor13, this);

      _initializerDefineProperty(this, "discountPercentage", _descriptor14, this);

      _initializerDefineProperty(this, "totalTax", _descriptor15, this);

      _initializerDefineProperty(this, "totalDiscountAmount", _descriptor16, this);

      _initializerDefineProperty(this, "totalItemsAmount", _descriptor17, this);

      _initializerDefineProperty(this, "totalItemsDiscountAmount", _descriptor18, this);

      _initializerDefineProperty(this, "items", _descriptor19, this);
    }

    get preInvoiceStatus() {
      const {
        status
      } = this;
      let label;
      if (status !== "Done" && status !== "Approved" && status !== "WaitingBilling" && status !== "WaitingApproval" && status !== "Refused" && status !== "Declined" && status !== "Draft" && status !== "Executing") return;

      if (status === "Done" || status === "Approved") {
        label = "success";
      } else if (status === "WaitingBilling" || status === "WaitingApproval") {
        label = "warning";
      } else if (status === "Refused" || status === "Declined") {
        label = "error";
      } else {
        return status;
      }

      return label;
    }

    get subscriptionItems() {
      let {
        items
      } = this;

      if (items.length >= 2) {
        let hasSubscriptions = items.every((val, i, arr) => val.subscription && arr.firstObject.subscription);

        if (hasSubscriptions) {
          let isSubscriptionsEqual = items.every((val, i, arr) => val.subscription.id === arr.firstObject.subscription.id);

          if (!isSubscriptionsEqual) {
            let subscriptions = this.formatSubscriptionsWithItems(items);
            return subscriptions;
          }
        }
      }
    }

    formatSubscriptionsWithItems(arr) {
      let subscriptions = arr.map(item => {
        item.subscription.items = [];
        return item.subscription;
      }).filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
      arr.forEach(item => {
        let index = subscriptions.findIndex(sub => sub.id === item.subscription.id);

        if (item.subscription.id === subscriptions[index].id) {
          delete item.subscription;
          subscriptions[index].items.push(item);
        }
      });
      return subscriptions;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accountId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "orderNumber", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "createdOn", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modifiedOn", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "effectiveOn", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dueOn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fulfilledOn", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "usageUpdatedOn", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "totalAmount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "discountAmount", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "discountPercentage", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "totalTax", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "totalDiscountAmount", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "totalItemsAmount", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "totalItemsDiscountAmount", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "preInvoiceStatus", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "preInvoiceStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "subscriptionItems", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "subscriptionItems"), _class.prototype)), _class));
  _exports.default = AccountOrderModel;
});