define("nfe-customer-console-v2/helpers/format-cpf-cnpj", ["exports", "nfe-customer-console-v2/helpers/cpf-validator", "nfe-customer-console-v2/helpers/cnpj-validator", "nfe-customer-console-v2/helpers/format-cpf", "nfe-customer-console-v2/helpers/format-cnpj"], function (_exports, _cpfValidator, _cnpjValidator, _formatCpf, _formatCnpj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCpfCnpj = formatCpfCnpj;
  _exports.default = void 0;

  function formatCpfCnpj([value]) {
    if ((0, _cpfValidator.cpfValidator)(value)) {
      return (0, _formatCpf.formatCpf)([value]); // let cpf = "00000000000".substring(0, 11 - value.toString().length) + value.toString();
      // return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(6, 3)}-${cpf.substr(9, 2)}`
    } else if ((0, _cnpjValidator.cnpjValidator)(value)) {
      return (0, _formatCnpj.formatCnpj)([value]); // let cnpj = "00000000000000".substring(0, 14 - value.toString().length) + value.toString();
      // return `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(5, 3)}/${cnpj.substr(8, 4)}-${cnpj.substr(12, 2)}`
    }

    return value;
  }

  var _default = Ember.Helper.helper(formatCpfCnpj);

  _exports.default = _default;
});