define("nfe-customer-console-v2/components/account-payment-methods-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZSEqb9yk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isRunning\",\"data\"],[[24,[\"fetchData\",\"isRunning\"]],[24,[\"results\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/account-payment-methods-loader/template.hbs"
    }
  });

  _exports.default = _default;
});