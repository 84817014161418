define("nfe-customer-console-v2/components/validate-input-mask-percentage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Wi1wWHX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-input-mask\",null,[[\"id\",\"label\",\"type\",\"placeholder\",\"autocomplete\",\"mask\",\"showMaskOnFocus\",\"showMaskOnHover\",\"clearIncomplete\",\"getMaskedValue\",\"regex\",\"rightAlign\",\"disabled\",\"isTouched\",\"value\",\"onChange\",\"errors\"],[[24,[\"id\"]],[24,[\"label\"]],[24,[\"type\"]],[24,[\"placeholder\"]],[24,[\"autocomplete\"]],\"9,99 %\",true,false,false,true,[24,[\"regex\"]],false,[24,[\"disabled\"]],[24,[\"shouldDisplayValidations\"]],[24,[\"valueAsString\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"valueAsString\"]]],null]],null],[28,\"get\",[[24,[\"model\"]],[28,\"concat\",[\"validations.attrs.\",[24,[\"valuePath\"]],\".messages\"],null]],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/validate-input-mask-percentage/template.hbs"
    }
  });

  _exports.default = _default;
});