define("nfe-customer-console-v2/serializers/hook-v2", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.webHooks) payload = {
        hookV2s: payload.webHooks
      };

      if (payload.webHook) {
        payload.webHook.filters = payload.webHook.filters.map(filter => filter.replace(".", "-"));
        if (payload.webHook.encryption) payload.webHook.client = payload.webHook.encryption.jweClientId;
        payload = {
          hookV2: payload.webHook
        };
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    payloadKeyFromModelName(modelName) {
      return 'webHook';
    },

    serialize(snapshot, options) {
      let json = this._super(...arguments);

      json.filters = json.filters.map(filter => filter.replace("-", "."));

      if (json.client) {
        json.encryption = {
          "jweClientId": json.client
        };
        delete json.client;
      }

      delete json.createdOn;
      return json;
    }

  });

  _exports.default = _default;
});