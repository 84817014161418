define("nfe-customer-console-v2/components/certificate-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    companyId: null,

    init() {
      this._super(...arguments);

      this.results = null;
    },

    didReceiveAttrs() {
      this.fetchData.perform(this.companyId);
    },

    fetchData: (0, _emberConcurrency.task)(function* (company_id) {
      let data = null;
      let shouldCreateRecord = false;

      try {
        const certificates = yield this.store.query('certificate-v2', {
          company_id,
          active: true
        });
        data = certificates.find(item => item.status === "Active");
      } catch (er) {
        shouldCreateRecord = er.code === 400;
      }

      if (Ember.isEmpty(data) || shouldCreateRecord) data = this.store.createRecord('certificate-v2'); // set the company id (parent)

      data.set('companyId', company_id);
      this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});