define("nfe-customer-console-v2/components/fullwidth-tabs", ["exports", "ember-responsive-tabs/components/fullwidth-tabs"], function (_exports, _fullwidthTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fullwidthTabs.default;
    }
  });
});