define("nfe-customer-console-v2/controllers/account/apikeys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    shouldHideApikey: Ember.computed('model.environment', {
      get() {
        if (this.user.isAdmin) return false;
        return this.model.account.environment == "Development";
      }

    }),
    actions: {
      onSuccess(index) {
        document.execCommand('copy');
      },

      onError() {},

      getApikeys(key) {
        return this.shouldHideApikey ? key.description.includes("Nota Fiscal") : key;
      }

    }
  });

  _exports.default = _default;
});