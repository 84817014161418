define("nfe-customer-console-v2/routes/account/orders", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    titleToken: "Pré-Faturas da assinatura",

    async model() {
      return await this.sessionData.getCurrentAccount();
    }

  });

  _exports.default = _default;
});