define("nfe-customer-console-v2/components/nfe-batch-sender/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    metrics: Ember.inject.service(),
    store: Ember.inject.service(),
    allCheckedDisabled: false,
    total: 0,
    sent: 0,
    error: 0,
    issued: 0,
    requestsTotal: Ember.computed('total', function () {
      return this.total;
    }),
    requestsCreated: Ember.computed('sent', function () {
      return this.sent;
    }),
    requestsError: Ember.computed('error', function () {
      return this.error;
    }),
    requestsIssued: Ember.computed('issued', function () {
      return this.issued;
    }),
    actions: {
      checkAll(workbook) {
        this.toggleProperty('allChecked');
        workbook.forEach(item => {
          if (item.isSuccess == true || item.isError == true || item.hasInvalidFields) {
            return;
          } else {
            item.set('checked', this.allChecked);
          }
        });
      },

      newUpload(loader) {
        this.set('loader.isEmpty', true);
        this.router.transitionTo('companies.service-invoices.batch-issue', this.model.company.id);
      },

      showInvoiceError(message, invalidFields, flowMessage) {
        this.set('invoiceErrorModal', true);

        if (invalidFields) {
          this.set('invalidFields', invalidFields);
        } else if (flowMessage) {
          this.set('invoiceError', flowMessage);
        } else {
          this.set('invoiceError', message);
        }
      },

      closeDialog() {
        this.set('invoiceErrorModal', false);

        if (this.invalidFields) {
          this.set('invalidFields', null);
        }
      }

    },
    sendBatchInvoices: (0, _emberConcurrency.task)(function* (workbook) {
      this.set('isSendBatchRunning', true);
      let rows = workbook.filterBy('checked', true),
          tasks = [];

      if (rows.length == 0) {
        return this.notify.error('Selecione as notas que deseja enviar');
      }

      this.toggleProperty('allCheckedDisabled');
      let total = this.total;

      if (total > 0) {
        this.set('total', total + rows.length);
      } else {
        this.set('total', rows.length);
      }

      for (let i = 0; i < rows.length; i++) {
        rows[i].set('status', 'Aguardando');
        yield rows[i];
        tasks.push(this.postBatchItem.perform(rows[i]));
      }

      this.metrics.trackEvent({
        event: 'Clicked issue batch invoices button',
        category: 'Issue service invoice',
        action: 'Clicked issue batch invoices button'
      });
      yield (0, _emberConcurrency.all)(tasks);
      this.set('allChecked', false);
      this.toggleProperty('allCheckedDisabled');
      this.set('isSendBatchRunning', false);
    }),
    postBatchItem: (0, _emberConcurrency.task)(function* (batchItem) {
      batchItem.set('status', this.intl.t('companies.batch.status.sending'));
      const serviceInvoiceRecord = this.parseSheet(batchItem);

      try {
        const data = yield serviceInvoiceRecord.save({
          adapterOptions: {
            company_id: this.model.company.id
          }
        });
        batchItem.set('isWarning', false);
        this.set('sent', this.sent + 1);
        batchItem.set('status', this.intl.t('companies.batch.status.success'));
        batchItem.set('id', data.id);
        batchItem.set('isSuccess', true);
        batchItem.set('disabled', true);
        batchItem.set('checked', false);
        batchItem.set('indeterminate', true);
      } catch (ex) {
        this.set('error', this.error + 1);
        if (ex && ex.isAdapterError && ex.errors.length > 0) batchItem.set('statusMessage', ex.errors[0].detail || ex.errors[0].title);
        batchItem.set('status', this.intl.t('companies.batch.status.fail'));
        batchItem.set('isError', true);
        batchItem.set('disabled', true);
        batchItem.set('checked', false);
        batchItem.set('indeterminate', true);
      }

      yield (0, _emberConcurrency.timeout)(5000);
    }).enqueue().maxConcurrency(2),

    parseSheet(item) {
      // Todo: levar para o batch service
      // format type to correct value (naturalPerson or legalEntity)
      let borrowerType = Ember.isPresent(item.Tipo_Tomador) ? item.Tipo_Tomador.toLowerCase() : null;
      if (borrowerType == "pf" || borrowerType == "cpf") borrowerType = 'NaturalPerson';
      if (borrowerType == "pj" || borrowerType == "cnpj") borrowerType = 'LegalEntity';
      let invoice = {
        borrower: {
          federalTaxNumber: item.CPF_CNPJ,
          type: borrowerType,
          municipalTaxNumber: item.Inscricao_Municipal,
          taxRegime: item.Regime_Tributario_Tomador,
          name: item.Nome,
          email: item.Email,
          address: {
            country: item.Endereco_Pais,
            postalCode: item.Endereco_Cep,
            street: item.Endereco_Logradouro,
            number: item.Endereco_Numero,
            additionalInformation: item.Endereco_Complemento,
            district: item.Endereco_Bairro,
            city: {
              name: item.Endereco_Cidade_Nome,
              code: item.Endereco_Cidade_Codigo
            },
            state: item.Endereco_Estado
          }
        },
        description: item.Descricao,
        servicesAmount: item.Valor,
        cityServiceCode: item.Codigo_Servico,
        issuedOn: new Date(`${item.Data_Competencia}T12:00:00`),
        cnaeCode: item.CNAE ? item.CNAE.toString() : undefined,
        additionalInformation: item.Informacoes_Adicionais,
        taxationType: item.Tipo_Tributacao,
        issRate: item.Aliquota_ISS,
        issTaxAmount: item.Valor_ISS,
        irAmountWithheld: item.Retencao_IR,
        pisAmountWithheld: item.Retencao_PIS,
        cofinsAmountWithheld: item.Retencao_COFINS,
        csllAmountWithheld: item.Retencao_CSLL,
        inssAmountWithheld: item.Retencao_INSS,
        issAmountWithheld: item.Retencao_ISS,
        othersAmountWithheld: item.Retencao_OUTROS,
        deductionsAmount: item.Valor_Deducoes,
        discountUnconditionedAmount: item.Valor_Desconto_Incondicionado,
        discountConditionedAmount: item.Valor_Desconto_Condicionado,
        externalId: item.ID_Externo,
        paidAmount: item.Valor_Recebido
      };

      if (Ember.isEmpty(item.Endereco_Cep) && Ember.isEmpty(item.Endereco_Cidade_Codigo) && Ember.isEmpty(item.Endereco_Cidade_Nome) && Ember.isEmpty(item.Endereco_Estado) && Ember.isEmpty(item.Endereco_Logradouro)) {
        delete invoice.borrower.address;
      }

      if (Ember.isPresent(item.Estado_Prestacao_Servico) && Ember.isPresent(item.Cidade_Prestacao_Servico) && Ember.isPresent(item.Codigo_Cidade_Prestacao_Servico)) {
        invoice.location = {
          state: item.Estado_Prestacao_Servico,
          city: {
            name: item.Cidade_Prestacao_Servico,
            code: item.Codigo_Cidade_Prestacao_Servico
          }
        };
      }

      return this.store.createRecord('service-invoice', invoice);
    }

  });

  _exports.default = _default;
});