define("nfe-customer-console-v2/serializers/sefaz-nfe-batch", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SefazNfeBatch extends _rest.default.extend() {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.batches) {
        payload = {
          sefazNfeBatches: payload.batches,
          meta: payload.meta
        };
      }

      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      return super.normalizeCreateRecordResponse(store, primaryModelClass, {
        sefazNfeBatch: payload.batch
      }, id, requestType);
    } // Serialize without null attributes


    serializeAttribute(snapshot, json, key, attribute) {
      if (Ember.isPresent(snapshot.record.get(key))) super.serializeAttribute(...arguments);
    }

    serializeIntoHash(hash, type, record, options) {
      hash['batch'] = super.serialize(record, options);
    }

  }

  _exports.default = SefazNfeBatch;
  ;
});