define("nfe-customer-console-v2/components/custom-paper-chips/component", ["exports", "ember-invoke-action", "ember-concurrency"], function (_exports, _emberInvokeAction, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'md-chips',
    classNames: ['md-default-theme'],
    activeChip: -1,
    focusedElement: 'none',
    debounceSearch: false,
    debounceTimeout: 800,
    isFocused: Ember.computed('focusedElement', function () {
      if (this.focusedElement === 'none') {
        return false;
      }

      return true;
    }),
    lastItemChosen: false,
    handleFocusChange: Ember.observer('focusedElement', 'activeChip', function () {
      let element = this.focusedElement;

      if (!this.isFocused) {
        this.set('activeChip', -1);
      }

      if (element === 'chips' && this.activeChip !== -1 || element === 'input') {
        (0, _emberInvokeAction.invokeAction)(this, 'focusIn', window.event);
      } else {
        (0, _emberInvokeAction.invokeAction)(this, 'focusOut', window.event);
      }
    }),

    click() {
      this.getInput().focus();
    },

    restartableDebounceSearch: (0, _emberConcurrency.task)(function* (item) {
      yield (0, _emberConcurrency.timeout)(this.debounceTimeout);
      (0, _emberInvokeAction.invokeAction)(this, 'addItem', item);
      this.set('newChipValue', '');
      this.set('searchText', '');
    }).restartable(),
    actions: {
      closePromptDialog(action, field, fieldValue) {
        if (action == 'close' || action == 'ok') {
          this.set('showPromptDialog', false);
        }

        if (action == 'ok') {
          (0, _emberInvokeAction.invokeAction)(this, 'addItem', {
            name: field,
            value: fieldValue
          });
        }
      },

      addItem(newItem) {
        if (this.requireMatch || Ember.isEmpty(newItem)) {
          // Don't add a new item - we're set to require a match.
          return;
        }

        let item = newItem;

        if (Ember.isPresent(this.searchField)) {
          item = {};
          item[this.searchField] = newItem;
        }

        if (this.debounceSearch) {
          if (item.length < 3) {
            return;
          }

          return this.restartableDebounceSearch.perform(item);
        }

        (0, _emberInvokeAction.invokeAction)(this, 'addItem', item);
        this.set('newChipValue', '');
        this.set('searchText', '');
      },

      removeItem(item) {
        (0, _emberInvokeAction.invokeAction)(this, 'removeItem', item);
        let current = this.activeChip;

        if (current === -1 || current >= this.content.length) {
          this.set('activeChip', -1);
        }
      },

      inputFocus(autocomplete) {
        if (autocomplete && autocomplete.options) {
          autocomplete.options.map(item => {
            if (item.value) {
              delete item.value;
            }

            return item;
          });
        }

        let input = this.getInput();
        this.set('focusedElement', 'input');

        if (!this.content.length && input !== document.activeElement) {
          input.focus();
        } else {
          this.set('activeChip', -1);
        } // Keep track of the autocomplete, so we can force it to close when navigating to chips.


        if (Ember.isEmpty(this.autocomplete) && input.classList.contains('ember-paper-autocomplete-search-input')) {
          this.set('autocomplete', autocomplete);
        } // We don't want the autocomplete to open on focus - it'll open when the user starts typing.


        if (this.showOnFocus === false && Ember.isPresent(autocomplete)) {
          autocomplete.actions.close();
        }
      },

      inputBlur(_, event) {
        if (this.focusMovingTo('.ember-power-select-option', event)) {
          // Focus has shifted to an item - don't mess with this event.
          return true;
        }

        if (this.lastItemChosen) {
          // Last item has been chosen; select will be replaced with an input - ignore blur event.
          this.set('lastItemChosen', false);
          return true;
        }

        if (!this.focusMovingTo('md-chips-wrap', event)) {
          this.set('focusedElement', 'none');
        }
      },

      chipsFocus() {
        this.set('focusedElement', 'chips');
      },

      chipsBlur(event) {
        if (!this.focusMovingTo('.md-chip-input-container input', event)) {
          this.set('focusedElement', 'none');
          this.set('activeChip', -1);
        }
      },

      chipClick(index, event) {
        // Prevent click from bubbling up to the chips element.
        event.stopPropagation(); // If we have a valid chip index, make it active.

        if (!Ember.isEmpty(index) && !this.readOnly) {
          // Shift actual focus to wrap so that subsequent blur events work as expected.
          this.element.querySelector('md-chips-wrap').focus(); // Update state to reflect the clicked chip being active.

          this.set('focusedElement', 'chips');
          this.set('activeChip', index);
        }
      },

      autocompleteChange(item) {
        // We don't want the autocomplete to open on focus - it'll open when the user starts typing.
        if (Ember.isPresent(this.autocomplete)) {
          this.autocomplete.actions.close();
        }

        if (item) {
          // Trigger onChange for the new item.
          (0, _emberInvokeAction.invokeAction)(this, 'addItem', item);
          this.set('searchText', ''); // Track selection of last item if no match required.

          if (this.options.length === 1 && !this.requireMatch) {
            this.set('lastItemChosen', true);
            this.set('autocomplete', null);
          }
        }
      },

      searchTextChange(value, select) {
        this.set('searchText', value); // Close dropdown if search text is cleared by the user.

        if (Ember.isEmpty(value)) {
          select.actions.close();
        }
      },

      keyDown(event) {
        let input = this.getInput();

        if (!this.readOnly && Ember.isEmpty(input.value) && Ember.isPresent(this.content)) {
          this.keyboardNavigation(event);

          if (this.activeChip >= 0) {
            this.closeAutocomplete();
          }
        } else {
          // Make sure we don't leave a chip focused while typing.
          this.set('activeChip', -1);
          this.set('focusedElement', 'input');
        }
      },

      noUnselected(old, event) {
        if (['Backspace', 'Delete', 'Del', 'ArrowLeft', 'Left', 'ArrowRight', 'Right'].includes(event.key)) {
          (0, _emberInvokeAction.invokeAction)(this, 'keyDown', event);
        } else if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
          // Reject printable key presses
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }

    },

    keyboardNavigation({
      key
    }) {
      // No text has been entered, but we have chips; cursor keys should select chips.
      let current = this.activeChip;
      let chips = this.content;
      let input = this.getInput();

      if (['ArrowLeft', 'Left'].includes(key) || key === 'Backspace' && current === -1) {
        if (current === -1) {
          input.blur();
          this.element.querySelector('md-chips-wrap').focus();
          this.set('activeChip', chips.length - 1);
        } else if (current > 0) {
          this.decrementProperty('activeChip');
        }
      } else if (['ArrowRight', 'Right'].includes(key)) {
        if (current >= 0) {
          this.incrementProperty('activeChip');
        }

        if (this.activeChip >= chips.length) {
          this.set('activeChip', -1);
          input.focus();
        }
      } else if (current >= 0 && ['Backspace', 'Delete', 'Del'].includes(key)) {
        (0, _emberInvokeAction.invokeAction)(this, 'removeItem', chips[current]);

        if (current >= chips.length) {
          this.set('activeChip', -1);
        }

        if (Ember.isEmpty(this.options)) {
          this.getInput().focus();
          this.set('focusedElement', 'input');
        }
      }
    },

    closeAutocomplete() {
      if (!Ember.isEmpty(this.autocomplete) && !Ember.isEmpty(this.autocomplete.actions)) {
        this.autocomplete.actions.close();
      }
    },

    getInput() {
      return this.element.querySelector('.md-chip-input-container input');
    },

    focusMovingTo(selector, event) {
      let el = this.element.querySelector(selector);

      if (!Ember.isEmpty(event) && !Ember.isEmpty(event.relatedTarget) && event.relatedTarget === el) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});