define("nfe-customer-console-v2/adapters/tax", ["exports", "@ember-data/adapter/rest", "ember-simple-auth/mixins/data-adapter-mixin", "ember-data-url-templates"], function (_exports, _rest, _dataAdapterMixin, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_emberDataUrlTemplates.default, _dataAdapterMixin.default, {
    host: 'https://data-prod-taxes-api.azurewebsites.net',
    namespace: 'v1',
    queryUrlTemplate: '{+host}/{+namespace}/cities/{cityCode}/taxes',

    getTaxes(cityCode) {
      const url = this.buildURL('tax', null, {
        cityCode
      }, 'query');
      return this.ajax(url);
    }

  });

  _exports.default = _default;
});