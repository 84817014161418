define("nfe-customer-console-v2/mirage/factories/hook-v2", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    uri() {
      return _emberCliMirage.faker.internet.url();
    },

    secret: _emberCliMirage.faker.internet.password(8),
    filters: ["product_invoice.issued_failed", "product_invoice.issued_successfully"],
    status: "Active",
    createdOn: "2018-10-25T14:28:53.5072407+00:00",
    modifiedOn: "2019-03-24T19:51:15.7550984+00:00"
  });

  _exports.default = _default;
});