define("nfe-customer-console-v2/components/drag-and-drop/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['drag-n-drop'],
    classNameBindings: ['hasOver'],
    dragOver: function (ev) {
      this.set('hasOver', true);
      ev.preventDefault();
    },
    dragLeave: function (ev) {
      this.set('hasOver', false);
      ev.preventDefault();
    },

    drop(ev) {
      this.set('hasOver', false);
      ev.stopPropagation();
      ev.preventDefault();
      this.sendFiles(ev.dataTransfer.files);
    },

    actions: {
      change(ev) {
        this.sendFiles(ev.target.files);
      }

    }
  });

  _exports.default = _default;
});