define("nfe-customer-console-v2/models/usage-summary/totals", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UsageSummaryTotalsModel = (_dec = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec2 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec3 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec4 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec5 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec6 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec7 = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec8 = Ember.computed('BadRequest', 'Error', 'TimedOut', 'Duplicated', 'Unavailable'), _dec9 = Ember.computed('OK', 'NotFound', 'Errors'), (_class = (_temp = class UsageSummaryTotalsModel extends _fragment.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "OK", _descriptor, this);

      _initializerDefineProperty(this, "BadRequest", _descriptor2, this);

      _initializerDefineProperty(this, "Error", _descriptor3, this);

      _initializerDefineProperty(this, "NotFound", _descriptor4, this);

      _initializerDefineProperty(this, "TimedOut", _descriptor5, this);

      _initializerDefineProperty(this, "Duplicated", _descriptor6, this);

      _initializerDefineProperty(this, "Unavailable", _descriptor7, this);
    }

    get Errors() {
      let errors = [this.BadRequest, this.Error, this.TimedOut, this.Duplicated, this.Unavailable];
      errors = errors.filter(Number);
      return errors.length > 0 ? errors.reduce((accumulator, currentValue) => accumulator + currentValue) : null;
    }

    get Total() {
      let totals = [this.OK, this.NotFound, this.Errors];
      totals = totals.filter(Number);
      return totals.length > 0 ? totals.reduce((accumulator, currentValue) => accumulator + currentValue) : null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "OK", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "BadRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "Error", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "NotFound", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "TimedOut", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "Duplicated", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "Unavailable", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "Errors", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "Errors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "Total", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "Total"), _class.prototype)), _class));
  _exports.default = UsageSummaryTotalsModel;
});