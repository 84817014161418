define("nfe-customer-console-v2/models/sefaz-nfe-batch-description", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment"], function (_exports, _model, _attributes, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    fileBlob: (0, _attributes.fragment)('sefaz-nfe-batch-blob'),
    flowStatus: (0, _model.attr)('string'),
    totalErrorLines: (0, _model.attr)('number'),
    totalOKLines: (0, _model.attr)('number')
  });

  _exports.default = _default;
});