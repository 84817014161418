define("nfe-customer-console-v2/adapters/account-user", ["exports", "ember-data-url-templates", "nfe-customer-console-v2/adapters/base-rest"], function (_exports, _emberDataUrlTemplates, _baseRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AccountUserAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryUrlTemplate", '{+host}/accounts/{account_id}/owners');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/accounts/{account_id}/owners/{owner_email}');
    }

    async getUsers(account_id) {
      const url = this.buildURL('account-user', null, {
        account_id
      }, 'query');
      var data = await this.ajax(url);
      return data.owners.map(e => {
        console.log(e);
        return {
          email: e
        };
      });
    }

    addUser(account_id, email) {
      const url = this.buildURL('account-user', null, {
        account_id
      }, 'query');
      const options = {
        data: {
          owner: email
        }
      };
      return this.ajax(url, "POST", options);
    }

    deleteUser(account_id, owner_email) {
      const url = this.buildURL('account-user', null, {
        account_id,
        owner_email
      }, 'deleteRecord');
      return this.ajax(url, "DELETE");
    }

    handleResponse(status, headers, payload, requestData) {
      if (status >= 400 && Ember.isPresent(payload.message)) {
        let adapterError = super.handleResponse(...arguments);
        adapterError.code = status;
        adapterError.description = payload.message;
        return adapterError;
      }

      return super.handleResponse(...arguments);
    }

  }

  _exports.default = AccountUserAdapter;
  ;
});