define("nfe-customer-console-v2/mirage/factories/service-invoice", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    environment: "Development",
    flowStatus: _emberCliMirage.faker.list.cycle('Issued', 'Cancelled', 'WaitingCalculateTaxes', 'IssueFailed', 'WaitingReturn'),
    provider: {
      tradeName: "",
      taxRegime: "MicroempreendedorIndividual",
      specialTaxRegime: "Nenhum",
      legalNature: "SociedadeEmpresariaLimitada",
      economicActivities: [],
      municipalTaxNumber: "1234",
      issRate: 0.0,
      parentId: "54244a1be340420fdc94ab59",
      id: "5c12e9a8f85de212e48a59d5",
      name: "AGOOGLE BRASIL INTERNET LTDA.",
      federalTaxNumber: 6990590000123,
      address: {
        postalCode: "04538-133",
        street: "Avenida Brigadeiro Faria Lima",
        number: "3477",
        additionalInformation: "ANDAR 17, 18, 19 E 20 TORRE SUL",
        district: "Itaim Bibi",
        city: {
          code: "3550308",
          name: "São Paulo"
        },
        state: "SP"
      },
      status: "Active",
      type: "LegalPerson, Company",
      createdOn: "2018-12-13T23:22:16.6850127+00:00",
      modifiedOn: "2019-01-31T17:32:58.977563+00:00"
    },
    borrower: {
      name: "Guilherme",
      federalTaxNumber: 72449450785,
      email: "exemplo@teste.com",
      address: {
        country: "BRA",
        postalCode: "04403-240",
        street: "Rua Teste",
        number: "123",
        district: "bairro teste",
        city: {
          code: "3550308",
          name: "São Paulo"
        },
        state: "SP"
      },
      status: "Active",
      type: "NaturalPerson",
      createdOn: "2019-02-05T11:55:01.299918+00:00"
    },
    batchNumber: 16164955007415,
    batchCheckNumber: "9416164955007415",
    number: 94,
    checkCode: "9416164955007415",
    status: _emberCliMirage.faker.list.cycle('Issued', 'Cancelled', 'Created', 'Error', 'Created'),
    rpsType: "Rps",
    rpsStatus: "Normal",
    taxationType: "WithinCity",
    issuedOn: "2018-12-09T22:00:00-02:00",
    rpsSerialNumber: "IO",
    rpsNumber: 94,
    cityServiceCode: "1058",
    federalServiceCode: "7.05",
    description: "Descrição de exemplo\n\n\nCONFORME LEI 12.741/2012 o valor aproximado dos tributos é R$0,00 (17,74%), FONTE: IBPT/empresometro.com.br (18.2.C)",
    servicesAmount: 0.01,
    deductionsAmount: 0.0,
    discountUnconditionedAmount: 0.0,
    discountConditionedAmount: 0.0,
    baseTaxAmount: 0.01,
    issRate: 0.05,
    issTaxAmount: 0.0,
    irAmountWithheld: 0.0,
    pisAmountWithheld: 0.0,
    cofinsAmountWithheld: 0.0,
    csllAmountWithheld: 0.0,
    inssAmountWithheld: 0.0,
    issAmountWithheld: 0.0,
    othersAmountWithheld: 0.0,
    amountWithheld: 0.0,
    amountNet: 0.01,
    approximateTax: {
      source: "IBPT/empresometro.com.br",
      version: "18.2.C",
      totalRate: 0.1774
    },
    createdOn: "2019-02-05T11:55:01.299918+00:00",
    modifiedOn: "2019-02-05T11:55:08.9973629+00:00"
  });

  _exports.default = _default;
});