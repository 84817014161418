define("nfe-customer-console-v2/components/drag-and-drop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rBh8xgSI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[10,\"id\",\"file\"],[10,\"name\",\"file\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"change\"],null]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/drag-and-drop/template.hbs"
    }
  });

  _exports.default = _default;
});