define("nfe-customer-console-v2/serializers/hook", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HookSerializer extends _rest.default {
    serializeIntoHash(hash, type, record, options) {
      // remove modelName from payload
      return Ember.assign(hash, this.serialize(record, options));
    }

  }

  _exports.default = HookSerializer;
  ;
});