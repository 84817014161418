define("nfe-customer-console-v2/initializers/master-tab", ["exports", "ember-master-tab/initializers/master-tab"], function (_exports, _masterTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _masterTab.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _masterTab.initialize;
    }
  });
});