define("nfe-customer-console-v2/components/dfe-dist-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    dfeDist: Ember.inject.service(),
    companyId: null,
    apiKey: null,
    dfeType: null,

    didReceiveAttrs() {
      this.fetchData.perform(this.dfeType, this.companyId, this.apiKey);
    },

    fetchData: (0, _emberConcurrency.task)(function* (dfeType, companyId, apiKey) {
      let response;

      if (dfeType != 'cte-dist' && dfeType != 'nfe-dist') {
        response = yield this.dfeDist.getDfeConfig(dfeType, companyId);
      } else {
        response = yield this.dfeDist.getDfeConfig(dfeType, companyId, apiKey);
      }

      this.set('fetchDataResponse', response);
    }).restartable()
  });

  _exports.default = _default;
});