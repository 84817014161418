define("nfe-customer-console-v2/adapters/subject-client", ["exports", "@ember-data/adapter/rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _rest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SubjectClientAdapter extends _rest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryUrlTemplate", "{+host}/subjects/{subscription_id}/clients");

      _defineProperty(this, "queryRecordUrlTemplate", "{+host}/subjects/{subscription_id}/clients/{client_id}/secrets");

      _defineProperty(this, "urlSegments", {
        host() {
          return _environment.default.APP.IDENTITY_API_URL;
        },

        subscription_id(type, id, snapshot, query) {
          return query.subscription_id;
        },

        client_id(type, id, snapshot, query) {
          return query.client_id;
        }

      });
    }

  }

  _exports.default = SubjectClientAdapter;
});