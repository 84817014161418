define("nfe-customer-console-v2/templates/companies-v2/product-invoices/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+QCbtFa3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"layout-row-form\"],[8],[0,\"\\n    \"],[5,\"toolbar\",[],[[\"@title\",\"@subtitle\",\"@returnPage\"],[[28,\"t\",[\"companies.edit.title\"],null],[28,\"t\",[\"companies.serviceInvoices.view.subtitle\"],null],[28,\"transition-to\",[\"companies-v2.product-invoices\",[24,[\"model\",\"companyId\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"product-invoice-actions-button\",[],[[\"@companyId\",\"@invoice\"],[[24,[\"model\",\"companyId\"]],[24,[\"model\",\"invoice\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"nfe-view\",[],[[\"@nfe\",\"@companyId\"],[[24,[\"model\",\"invoice\"]],[24,[\"model\",\"companyId\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/templates/companies-v2/product-invoices/view.hbs"
    }
  });

  _exports.default = _default;
});