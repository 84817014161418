define("nfe-customer-console-v2/controllers/companies-v2/index", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CompanyListQueryParams = void 0;
  const CompanyListQueryParams = new _emberParachute.default({
    limit: {
      as: "limit",
      defaultValue: 10,
      refresh: true
    },
    startingAfter: {
      as: "startingAfter",
      defaultValue: null,
      refresh: true
    },
    endingBefore: {
      as: "endingBefore",
      defaultValue: null,
      refresh: true
    },
    page: {
      as: "page",
      defaultValue: 1,
      refresh: true
    }
  });
  _exports.CompanyListQueryParams = CompanyListQueryParams;

  var _default = Ember.Controller.extend(CompanyListQueryParams.Mixin, {
    ticket: Ember.inject.service(),
    perPageOptions: Ember.A([10, 20, 30, 40, 50]),
    queryParamsChanged: Ember.computed.or('queryParamsState.{limit,startingAfter,endingBefore}.changed'),
    issueModal: false,
    companyId: null,
    actions: {
      onClickTransitionToRoute(routeName, id) {
        this.transitionToRoute(routeName, id);
        this.set('issueModal', false);
      },

      showIssueModal(companyId) {
        this.set('companyId', companyId);
        this.set('issueModal', true);
      },

      closeDialogWithParent() {
        this.set('issueModal', false);
      },

      mutPerPage(perPage) {
        this.set('limit', perPage);
        this.set('endingBefore', null);
        this.set('startingAfter', null);
        this.set('page', 1);
      },

      mutPage(action, data) {
        if (action === "increment") {
          let lastItem = data.content.lastObject;
          this.set('startingAfter', lastItem.id);
          this.incrementProperty("page");
          this.set('endingBefore', null);
        } else {
          let firstItem = data.content.firstObject;
          this.set('endingBefore', firstItem.id);
          this.decrementProperty("page");
          this.set('startingAfter', null);
        }
      },

      openTicket(company) {
        let ticketForm = this.store.createRecord('ticket', {
          product: this.store.createRecord('ticket/product')
        });
        ticketForm.product.set('providerNamespace', "DFeTech.ProductInvoice");
        ticketForm.product.set('companyId', company.id);
        ticketForm.set('category', 'Problema');
        ticketForm.set('department', 'Tecnico');
        ticketForm.set('type', 'produto');
        ticketForm.set('subtype', "outros");
        ticketForm.set('description', `Falha ao carregar dados da empresa.\nID empresa: ${company.id}`);
        this.set('ticket.ticketModel', ticketForm);
        this.set('ticket.showTicketModal', true);
      }

    }
  });

  _exports.default = _default;
});