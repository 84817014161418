define("nfe-customer-console-v2/mirage/serializers/metric", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    serialize(object, request) {
      return object.models[0].attrs;
    }

  });

  _exports.default = _default;
});