define("nfe-customer-console-v2/components/copy-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      copyToClipboard(accessKey, e) {
        e.stopPropagation();
        let text = document.getElementById(accessKey).value || document.getElementById(accessKey).innerText;
        let elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();

        try {
          let successful = document.execCommand('copy');
          document.body.removeChild(elem); // let msg = successful ? 'successful' : 'unsuccessful';
          // console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }
      }

    }
  });

  _exports.default = _default;
});