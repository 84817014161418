define("nfe-customer-console-v2/components/accounts-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "beH5ZP2X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isRunning\",\"data\",\"activateAction\",\"deactivateAction\",\"setEnvironmentProdAction\",\"setEnvironmentDevAction\",\"updateUsageSum\",\"updateUsageSumAny\"],[[24,[\"fetchData\",\"isRunning\"]],[24,[\"results\"]],[24,[\"activateAction\"]],[24,[\"deactivateAction\"]],[24,[\"setEnvironmentProdAction\"]],[24,[\"setEnvironmentDevAction\"]],[24,[\"updateUsageSum\"]],[24,[\"updateUsageSumAny\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/accounts-loader/template.hbs"
    }
  });

  _exports.default = _default;
});