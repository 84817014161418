define("nfe-customer-console-v2/models/usage-service-action", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _model, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    intl: Ember.inject.service('intl'),
    actionId: (0, _model.attr)('string'),
    totals: (0, _attributes.fragment)('usage-action-total'),
    service: (0, _model.belongsTo)('usage-service'),
    actionName: Ember.computed('actionId', function () {
      return this.intl.t(`account.usage.names.${this.actionId}`);
    }),
    actionDescription: Ember.computed('actionId', function () {
      return this.intl.t(`account.usage.descriptions.${this.actionId}`);
    })
  });

  _exports.default = _default;
});