define("nfe-customer-console-v2/serializers/nfe-dist", ["exports", "@ember-data/serializer/rest", "nfe-customer-console-v2/models/enums"], function (_exports, _rest, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NfeDistSerializer extends _rest.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'companyId');
    }

    _serializeAutomaticManifesting(data) {
      if (!data.minutesToWaitAwarenessOperation) {
        data.minutesToWaitAwarenessOperation = null;
        return data;
      }

      let item = _enums.default.nfeDistWaitingTimes.filter(time => time.name === data.minutesToWaitAwarenessOperation || time.code === data.minutesToWaitAwarenessOperation)[0];

      if (!item && data.minutesToWaitAwarenessOperation > 1 && data.minutesToWaitAwarenessOperation < 60) item = data.minutesToWaitAwarenessOperation + ' minutos';

      if (typeof data.minutesToWaitAwarenessOperation === 'string') {
        data.minutesToWaitAwarenessOperation = item.code;
      } else {
        data.minutesToWaitAwarenessOperation = item.name || item;
      }

      return data;
    }

    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);
      json.automaticManifesting = this._serializeAutomaticManifesting(json.automaticManifesting);
      return json;
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        nfeDist: payload
      };
      payload.nfeDist.automaticManifesting = this._serializeAutomaticManifesting(payload.nfeDist.automaticManifesting);
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

    serializeIntoHash(hash, type, record, options) {
      // remove modelName from payload
      return Ember.assign(hash, this.serialize(record, options));
    }

  }

  _exports.default = NfeDistSerializer;
  ;
});