define("nfe-customer-console-v2/components/company-v2-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FETCH_DEBOUNCE = 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    tagName: '',
    // attributes from template
    // limit: '',
    // startingAfter: '',
    // endingBefore: '',
    // page: '',
    // set default value to attributes to template
    companyId: null,
    results: null,
    isEmpty: Ember.computed('results', function () {
      return Ember.isEmpty(this.results);
    }),
    isEmptyAndNotRunning: Ember.computed('results', function () {
      return Ember.isEmpty(this.results) && this.get('fetchData.isRunning') == false;
    }),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      const params = {
        companyId: this.companyId
      };
      this.fetchData.perform(params);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params)) return;
      let data = yield this.store.queryRecord('company-v2', {
        company_id: params.companyId
      });
      if (FETCH_DEBOUNCE > 0) yield (0, _emberConcurrency.timeout)(FETCH_DEBOUNCE);
      return this.set('results', data);
    }).restartable()
  });

  _exports.default = _default;
});