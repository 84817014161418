define("nfe-customer-console-v2/mirage/factories/provider", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    namespace() {
      return _emberCliMirage.faker.list.cycle('DFeTech.ServiceInvoice', 'DFeTech.ProductInvoice', 'DFeTech.WebHook', 'DFeTech.Mail', 'AzHub.Billing', 'AzHub.Account', 'AzHub.Usage', 'DataTech.Batch');
    },

    status: "Inactive"
  });

  _exports.default = _default;
});