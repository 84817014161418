define("nfe-customer-console-v2/components/credit-card", ["exports", "ember-credit-card/components/credit-card"], function (_exports, _creditCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _creditCard.default;
    }
  });
});