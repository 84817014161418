define("nfe-customer-console-v2/components/dashboard/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chartOptions: {
      height: 200,
      colors: ['#006FB6', '#306F33'],
      legend: {
        position: 'right'
      },
      resizeDelay: 300,
      chart: {
        title: '',
        subtitle: ''
      },
      bars: 'vertical',
      // Required for Material Bar Charts.
      series: {
        0: {
          axis: 'quantidade'
        },
        // Bind series 0 to an axis named 'distance'.
        1: {
          axis: 'valor em reais'
        } // Bind series 1 to an axis named 'brightness'.

      },
      vAxis: {
        format: 'decimal'
      },
      axes: {
        y: {
          'valor em reais': {
            label: "Valor em reais"
          },
          // Bottom x-axis.
          quantidade: {
            side: 'top',
            label: "Quantidade",
            color: "#006FB6"
          } // Top x-axis.

        }
      }
    }
  });

  _exports.default = _default;
});