define("nfe-customer-console-v2/adapters/sefaz-nfe-batch", ["exports", "nfe-customer-console-v2/adapters/base-rest", "ember-data-url-templates", "nfe-customer-console-v2/config/environment"], function (_exports, _baseRest, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SefazNfeBatchAdapter = (_dec = Ember.computed, (_class = (_temp = class SefazNfeBatchAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", _environment.default.APP.DATA_BATCH_API_URL);

      _defineProperty(this, "namespace", 'api');

      _defineProperty(this, "queryUrlTemplate", '{+host}/accounts/{accountId}/batch-processes{?limit,startingAfter,endingBefore}');

      _defineProperty(this, "createRecordUrlTemplate", '{+host}/accounts/{accountId}/batch-processes');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/accounts/{accountId}/batch-processes{/id}');

      _defineProperty(this, "urlSegments", {
        host() {
          return this.host;
        },

        accountId(type, id, snapshot, query) {
          if (Ember.isPresent(query)) return query.accountId;
          return snapshot.attr('accountId');
        },

        id(type, id, snapshot, query) {
          return id;
        },

        limit(type, id, snapshot, query) {
          return query.limit;
        },

        startingAfter(type, id, snapshot, query) {
          return query.startingAfter;
        },

        endingBefore(type, id, snapshot, query) {
          return query.endingBefore;
        }

      });
    }

    get headers() {
      return {
        "x-functions-key": _environment.default.APP.DATA_BATCH_API_KEY
      };
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = SefazNfeBatchAdapter;
  ;
});