define("nfe-customer-console-v2/helpers/cpf-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cpfValidator = cpfValidator;
  _exports.default = void 0;

  function _internalRemoveNonNumeric(value) {
    let aux = '';

    for (let i = 0; i < value.length; i++) {
      if (!isNaN(parseInt(value[i]))) {
        aux += value[i];
      }
    }

    return aux;
  }

  function _internalCalculateDigit(value) {
    let weight = 1;
    let checksum = 0;

    while (value > 0) {
      checksum += ++weight * (value % 10);
      value = Math.trunc(value / 10);
    }

    let digit = 0;

    if (checksum > 0) {
      digit = 11 - checksum % 11;
      if (digit > 9) digit = 0;
    }

    return digit;
  }

  function cpfValidator(value) {
    const MaxValue = 99999999999;
    const MinValue = 190;
    if (value === null || value === undefined) return false;

    if (typeof value === "string") {
      value = _internalRemoveNonNumeric(value);

      if (value === "") {
        return false;
      }
    }

    if (value <= MinValue || value > MaxValue) return false;
    if (value === 0 || value === 11111111111 || value === 22222222222 || value === 33333333333 || value === 44444444444 || value === 55555555555 || value === 66666666666 || value === 77777777777 || value === 88888888888 || value === 99999999999) return false; // copy current check digits for compare later

    let checkDigits = value % 100; // copy all non-check digits, to calculate check digits.

    let aux = Math.trunc(value / 100); // calculate first check digit

    let calcDigits = _internalCalculateDigit(aux); // append calculated check digit to end of value


    aux = aux * 10 + calcDigits; // calculate second check digit

    calcDigits = calcDigits * 10 + _internalCalculateDigit(aux);
    return calcDigits == checkDigits;
  }

  var _default = Ember.Helper.helper(cpfValidator);

  _exports.default = _default;
});