define("nfe-customer-console-v2/controllers/account/exports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    queryParams: ["accountId"],
    refreshList: false,
    actions: {
      async downloadExport(accountId, subscriptionId, exportId) {
        try {
          const adapter = this.store.adapterFor('export');
          return await adapter.getExportFileUrl(accountId, subscriptionId, exportId);
        } catch (error) {
          return this.notify.error('Não foi possível baixar o Relatório.');
        }
      },

      refresh() {
        this.send("updateList");
      },

      executeRefresh(data) {
        if (data && data.type === 'export-status') this.toggleProperty('refreshList');
      }

    }
  });

  _exports.default = _default;
});