define("nfe-customer-console-v2/controllers/account/webhooks/new", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    webhook: Ember.inject.service(),
    queryParams: ["type"],
    currentStep: 0,
    activateValidations: false,
    saveHook: (0, _emberConcurrency.task)(function* (model) {
      this.set('activateValidations', true);
      if (model.get('validations.isInvalid')) return;
      const response = yield this.webhook.createHook(model);

      if (response.ok) {
        this.transitionToRoute('account.webhooks');
      }
    }).drop(),
    actions: {
      changeType(type) {
        if (!type) return;
        this.set('type', type);
        this.set('currentStep', 1);
      },

      return(currentStep) {
        this.set('currentStep', currentStep - 1);
      }

    }
  });

  _exports.default = _default;
});