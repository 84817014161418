define("nfe-customer-console-v2/components/product-invoice-actions-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    productInvoice: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: "",
    createdInvoice: Ember.computed.equal("invoice.status", "Created"),
    processingInvoice: Ember.computed.equal("invoice.status", "Processing"),
    issuedInvoice: Ember.computed.equal("invoice.status", "Issued"),
    errorInvoice: Ember.computed.equal("invoice.status", "Error"),
    cancelledInvoice: Ember.computed.equal("invoice.status", "Cancelled"),
    actions: {
      async downloadInvoice(type, invoice) {
        return await this.productInvoice.downloadInvoice(type, this.companyId, invoice);
      },

      cancelInvoice(companyId, invoice) {
        this.productInvoice.cancelInvoice(companyId, invoice);
        this.set('tryCancelInvoiceModal', false);
        this.router.transitionTo('companies-v2.product-invoices', companyId);
      }

    },
    defaultItems: Ember.A([{
      name: "editCompany",
      route: "companies-v2.edit",
      icon: "edit",
      label: "companies.actions.editLong"
    }, {
      name: "listInvoices",
      route: "companies-v2.product-invoices",
      icon: "view_list",
      label: "companies.actions.listInvoicesLong"
    }])
  });

  _exports.default = _default;
});