define("nfe-customer-console-v2/components/payment-invoices-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FETCH_DEBOUNCE = 1000;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform(this.accountId, this.invoices);
    },

    fetchData: (0, _emberConcurrency.task)(function* (accountId, invoices) {
      if (Ember.isBlank(accountId) || Ember.isBlank(invoices)) return;
      if (FETCH_DEBOUNCE > 0) yield (0, _emberConcurrency.timeout)(FETCH_DEBOUNCE);
      const adapter = this.store.adapterFor('payment');
      let getInvoices = invoices.map(invoice => adapter.getInvoice(accountId, invoice.id));
      let allInvoices = yield (0, _emberConcurrency.all)(getInvoices);
      allInvoices.map(invoice => {
        // let correctItems = invoice.items.filter(item => item.amount !== 0 && item.quantity !== 0);
        // invoice.items = correctItems;
        if (invoice.items.length >= 2) {
          let hasSubscriptions = invoice.items.every((val, i, arr) => val.subscription && arr[0].subscription);
          invoice.hasSubscriptions = hasSubscriptions;

          if (hasSubscriptions) {
            let isSubscriptionsEqual = invoice.items.every((val, i, arr) => val.subscription.id === arr[0].subscription.id);
            invoice.isSubscriptionsEqual = isSubscriptionsEqual;

            if (!isSubscriptionsEqual) {
              let subscriptions = this.formatSubscriptionsWithItems(invoice.items);
              invoice.subscriptionItems = subscriptions;
            }
          }
        }
      });
      return this.set('results', allInvoices);
    }).restartable(),

    formatSubscriptionsWithItems(arr) {
      let subscriptions = arr.map(item => {
        item.subscription.items = [];
        return item.subscription;
      }).filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
      arr.forEach(item => {
        let index = subscriptions.findIndex(sub => sub.id === item.subscription.id);

        if (item.subscription.id === subscriptions[index].id) {
          delete item.subscription;
          subscriptions[index].items.push(item);
        }
      });
      return subscriptions;
    }

  });

  _exports.default = _default;
});