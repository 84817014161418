define("nfe-customer-console-v2/helpers/get-gravatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGravatar = getGravatar;
  _exports.default = void 0;

  function getGravatar([email]) {
    return `https://www.gravatar.com/avatar/${hex_md5(email)}`;
  }

  var _default = Ember.Helper.helper(getGravatar);

  _exports.default = _default;
});