define("nfe-customer-console-v2/components/providers-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this.fetchData.perform({
        accountId: this.accountId,
        apiKeys: this.apiKeys
      });
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      if (Ember.isBlank(params)) return;
      let providers = yield this.store.query("provider", {
        account_id: params.accountId
      });
      let filteredProviders = providers.filter(provider => {
        return provider.namespace == 'DFeTech.ServiceInvoice' || provider.namespace == 'DFeTech.ProductInvoice' || provider.namespace == 'DFeTech.ConsumerInvoice' || provider.namespace == 'DataTech.Batch.ProductInvoice.Pdf' || provider.namespace == 'DataTech.Batch.ProductInvoice.Xml' || provider.namespace == 'DataTech.Batch.ProductInvoice.Serpro' && this.hasApiKey(params.apiKeys, 'Serpro Backup (serprobackup.nfe.io)') || provider.namespace == 'DataTech.Batch.ProductInvoice.Serpro.Pdf' || provider.namespace == 'DataTech.Batch.ProductInvoice.Serpro.Xml' || provider.namespace == 'DataTech.LegalEntity' || provider.namespace == 'DataTech.NaturalPerson' || provider.namespace == 'DFeTech.ServiceInvoice.Batch' || provider.namespace == 'DFeTech.Company' || provider.namespace == 'DFeTech.ProductInvoice.Inbound' || provider.namespace == 'DFeTech.TransportationInvoice.Inbound' || provider.namespace == 'DFeTech.WebHook.Encryption' || provider.namespace == 'AzHub.Billing.Order' && this.user.isAdmin;
      });
      let azHubProviders = filteredProviders.filter(provider => provider.namespace.startsWith('AzHub'));
      let dataTechProviders = filteredProviders.filter(provider => provider.namespace.startsWith('DataTech'));
      let dfeTechProviders = filteredProviders.filter(provider => provider.namespace.startsWith('DFeTech'));
      let providersList = azHubProviders.concat(dataTechProviders, dfeTechProviders);
      return this.set('results', providersList);
    }).restartable(),

    hasApiKey(apiKeys, description) {
      return apiKeys.some(i => i.description.indexOf(description) >= 0);
    }

  });

  _exports.default = _default;
});