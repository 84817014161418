define("nfe-customer-console-v2/authenticators/oauth2-implicit-grant", ["exports", "ember-simple-auth/authenticators/oauth2-implicit-grant"], function (_exports, _oauth2ImplicitGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OAuth2ImplicitGrant = (_dec = Ember.inject.service, (_class = (_temp = class OAuth2ImplicitGrant extends _oauth2ImplicitGrant.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "authenticateImplicit", _descriptor, this);

      _defineProperty(this, "authenticator", 'authenticator:oauth2-implicit-grant');
    }

    async authenticate() {
      Ember.debug(`${this.authenticator}: authenticate`);
      Ember.run.later(this.authenticateImplicit, 'initialize');
      return super.authenticate(...arguments);
    }

    async invalidate() {
      Ember.debug(`${this.authenticator}: invalidate`);
      return super.invalidate(...arguments);
    }

    async restore() {
      Ember.debug(`${this.authenticator}: restore`);
      Ember.run.later(this.authenticateImplicit, 'initialize');
      return super.restore(...arguments);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticateImplicit", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OAuth2ImplicitGrant;
});