define("nfe-customer-console-v2/routes/companies-v2/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    features: Ember.inject.service(),
    titleToken: "Cadastrar empresa NF-e",

    model() {
      let company = this.store.createRecord('company-v2', {
        address: this.store.createRecord('address', {
          city: this.store.createRecord('address-city')
        })
      });
      let stateTax = this.store.createRecord('state-tax');
      let nfeDist, cteDist;

      if (this.features.get('dFeTechProductInvoiceInbound')) {
        nfeDist = this.store.createRecord('nfe-dist', {
          automaticManifesting: this.store.createRecord('nfe-dist/automatic-manifesting')
        });
      }

      if (this.features.get('dFeTechTransportationInvoiceInbound')) {
        cteDist = this.store.createRecord('cte-dist');
      }

      return {
        company,
        stateTax,
        nfeDist,
        cteDist
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.get('model.company').rollbackAttributes();
        controller.get('model.stateTax').rollbackAttributes();
        controller.setProperties({
          activateCompanyValidations: false,
          activateStateTaxValidations: false,
          activateCertificateValidations: false,
          activatePasswordValidations: false,
          certificatePassword: null,
          certificate: null,
          currentStep: 0,
          isCompanyCreated: false,
          isStateTaxCreated: false,
          isCertificateSent: false
        });
      }
    }

  });

  _exports.default = _default;
});