define("nfe-customer-console-v2/adapters/state-tax", ["exports", "@ember-data/adapter/rest", "ember-simple-auth/mixins/data-adapter-mixin", "ember-data-url-templates", "nfe-customer-console-v2/config/environment"], function (_exports, _rest, _dataAdapterMixin, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend(_emberDataUrlTemplates.default, _dataAdapterMixin.default, {
    sessionData: Ember.inject.service(),
    host: _environment.default.APP.NFSE_V2_API_URL,
    namespace: 'v2',
    queryUrlTemplate: '{+host}/{+namespace}/companies/{company_id}/stateTaxes',
    createRecordUrlTemplate: '{+host}/{+namespace}/companies/{companyId}/statetaxes',
    queryRecordUrlTemplate: '{+host}/{+namespace}/companies/{company_id}/statetaxes/{stateTax_id}',
    updateRecordUrlTemplate: '{+host}/{+namespace}/companies/{companyId}/statetaxes/{id}',
    urlSegments: {
      companyId: function (type, id, snapshot, query) {
        return snapshot.record.companyId;
      }
    },
    headers: Ember.computed.readOnly('sessionData.getRequestHeaders')
  });

  _exports.default = _default;
});