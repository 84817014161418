define("nfe-customer-console-v2/controllers/account/users/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    userService: Ember.inject.service('user'),
    notify: Ember.inject.service(),
    toolbarButtons: Ember.A([{
      link: "account.users.new",
      label: "account.users.createBtn"
    }]),
    deleteUser: (0, _emberConcurrency.task)(function* (accountId, user) {
      this.set('toggleDeleteUserModal', false);
      let result = yield this.userService.delete.perform(accountId, user);

      if (result.status === "success") {
        this.notify.success(result.message);
        yield (0, _emberConcurrency.timeout)(750);
        location.reload();
      } else {
        this.notify.error(result.message);
      }
    }),
    actions: {
      toggleProviderModal(user) {
        this.set('toggleDeleteUserModal', true);
        this.set('user', user);
      }

    }
  });

  _exports.default = _default;
});