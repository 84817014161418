define("nfe-customer-console-v2/routes/companies-v2/edit/state-taxes/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    titleToken: "Cadastrar inscrição estadual",

    model(params) {
      return this.store.createRecord('state-tax', {
        companyId: params.id
      });
    }

  });

  _exports.default = _default;
});