define("nfe-customer-console-v2/routes/account/usage", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    titleToken: "Resumo de utilização da conta",
    queryParams: {
      accountId: {
        refreshModel: true
      }
    },

    async model(params) {
      let accountId = params.accountId;
      let account = null;

      if (Ember.isEmpty(accountId)) {
        account = await this.sessionData.getCurrentAccount();
        accountId = account.id;
      } else {
        account = await this.store.find('account', accountId);
      }

      if (Ember.isEmpty(params.year) || Ember.isEmpty(params.month) || params.year == "now" || params.month == "now") {
        const now = new Date();
        now.setMonth(now.getMonth() - 1);
        return this.transitionTo('account.usage', now.getFullYear(), now.getMonth() + 1);
      }

      return {
        account,
        usedOn: new Date(`${params.year}-${params.month}-15`)
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('accountId', null);
      }
    }

  });

  _exports.default = _default;
});