define("nfe-customer-console-v2/adapters/organization", ["exports", "nfe-customer-console-v2/adapters/base-odata", "ember-data-url-templates"], function (_exports, _baseOdata, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OrganizationAdapter extends _baseOdata.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryRecordUrlTemplate", "{+host}/Accounts('{accountId}')");

      _defineProperty(this, "updateRecordUrlTemplate", "{+host}/Accounts('{accountId}')");

      _defineProperty(this, "urlSegments", {
        host() {
          return this.get('host');
        },

        accountId(type, id, snapshot, query) {
          return id ? id : query.accountId;
        }

      });
    }

    getAllInvoices(accountId) {
      const url = this.buildURL('organization', accountId, null, 'queryRecord') + '/invoices';
      return this.ajax(url);
    }

  }

  _exports.default = OrganizationAdapter;
  ;
});